import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Item, darkTheme, ThemeProvider } from './styleMUI.js';
import Checkbox from '@mui/material/Checkbox';
import {BsTrash} from 'react-icons/bs';
import {BiGridVertical} from 'react-icons/bi';

export default function TodoMap({
  list,
  editBtn,
  dell,
  chek,
  setBolean,
  check,
  add,
}) {
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="space-around"
      alignItems="center"
      width="auto"
    >
      <Grid item xs={6} >
        <ThemeProvider theme={darkTheme}>
          <Box
            sx={{
              p: 2,
              bgcolor: 'background.default',
              display: 'table-row-group',
               
            }}
            
          >
            {list.map((item) => (
              <Item style={{display:'flex'}} className=" mt-2 " key={item.id} elevation={12}>
                <Checkbox
                  checked={item.tv ? true : false}
                  onClick={(e) => chek(item.id, !item.tv, item.todo)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />

                <span className="lead">
                  {item.tv ? <div style={{textDecoration:'line-through'}}>{item.todo}</div> : item.todo}
                </span>
                {/* <Button
                  onClick={(e) => dell(item.id)}
                  className="btn btn-danger btn-sm float-end mt-3 mx-2"
                >
                  ✘-Dell
                </Button> */}
       <div style={{marginLeft:'12rem',display:'flex'}}>
       <div  
                onClick={(e) => editBtn(item)}>
                <BiGridVertical />
                </div>
                <div 
                className="float-end pl-5  mt-3"
                 onClick={(e) => dell(item.id)}>
                <BsTrash />
                </div>
       </div>
           
                {/* <Button
                  variant="contained"
                  size="small"
                  className="btn btn-warning btn-sm  float-end pl-5  mt-3"
                  onClick={(e) => editBtn(item)}
                >
                  ✎-edit
                </Button> */}

              </Item>
            ))}
          </Box>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}
