import { useState, useEffect } from "react";
import "./organizationmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";

// bouncei code
import BackDrop from "../../BackDrop";

const MemberModal = (props) => {
  const { auth } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [open, setOpen] = useState(false);
  const getId = props.val;
  //console.log(getId)
  const MEM_URL = `/organizations/${getId}/members`;

  // Handles user credentials inputted in the available fields
  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "email") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handles submitting of the form
  const AuthStr = "Bearer ".concat(auth?.authToken);
  const handleSubmit = async (event) => {
    event.preventDefault();

    setOpen(true);

    try {
      const response = await axios.put(
        MEM_URL,
        JSON.stringify({
          members: formData["name"],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          withCredentials: true,
        }
      );

      setTimeout(() => {
        setOpen(false);
      }, response);
      toast.success("Member added!");
    } catch (error) {
      setOpen(false);
      if (!error?.response) {
        toast.error("Check your internet connection!");
      } else if (error.response?.status === 401) {
        toast.error("Unauthorized!");
      } else if (error.response?.status === 400) {
        toast.error("Missing Value");
        console.log(error.response);
      } else {
        toast.error("Failed!");
        console.log(error);
      }
    }
  };

  // console.log(props);

  return (
    <div
      className={`organization__modal ${props.show ? "show" : ""}`}
      id="login=div"
      onClick={props.onClose}
    >
      <form
        className="organization__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="organization__modal--content-header">
          <h4>Add Member</h4>
          <CloseIcon
            className="organization__close--icon"
            onClick={props.onClose}
          />
        </div>

        {open && <BackDrop open={open} />}

        <div className="organization__inputs">
          <input
            type="text"
            name="name"
            placeholder="Name"
            autoCorrect="off"
            onChange={handleChange}
            required
          />
        </div>

        <button type="submit" href="#" className="sign-in-tag">
          Submit
        </button>
      </form>
    </div>
  );
};

export default MemberModal;
