import React, { useState, useRef, useEffect } from "react";
import "./signupmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import { BsInfoCircleFill, BsCheck2 } from "react-icons/bs";
import toast from "react-hot-toast";
import axios from "../../../api/axios";
// import axios from "axios";
import LoginModal from "../loginModal/LoginModal";
import BackDrop from "../../BackDrop";
import { Tooltip } from "@mui/material";

const CreateOrganization = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    companyName: "",
    firstName: "",
    lastName: "",
    subsciptionPlan: props.plan.toUpperCase(),
    address: "",
    phoneNumber: "",
    city: "",
    country: "",
    countryCode: "",
    logo: null,
  });
  const [displayImg, setDisplayImg] = useState(null);

  const handleFile = (event) => {
    const maxSize = 1 * 1024 * 1024; // Maximum file size in bytes (1MB: 1 * 1024 * 1024)
    if (event.target.files[0].size > maxSize) {
      toast.error("Logo is too large");
      return;
    }

    setFormData({ ...formData, ["logo"]: event.target.files[0] });

    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);

        setDisplayImg(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  // const [submit, setSubmit] = useState(false);
  const [open, setOpen] = useState(false);

  const [success, setSuccess] = useState(false);

  // Login Modal State
  const [showLog, setShowLog] = useState(false);

  useEffect(() => {
    if (formData["country"] === "nigeria") {
      setFormData({ ...formData, countryCode: "NG" });
    } else if (formData["country"] === "usa") {
      setFormData({ ...formData, countryCode: "US" });
    } else if (formData["country"] === "france") {
      setFormData({ ...formData, countryCode: "FR" });
    } else if (formData["country"] === "uk") {
      setFormData({ ...formData, countryCode: "GB" });
    } else if (formData["country"] === "southafrica") {
      setFormData({ ...formData, countryCode: "ZA" });
    } else if (formData["country"] === "ghana") {
      setFormData({ ...formData, countryCode: "GH" });
    }
  }, [formData["country"]]);

  // Handles user credentials inputted in the available fields
  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  // Handles submitting of the form
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData["logo"] === null) {
      toast.error("Please add a logo");
      return;
    }
    setOpen(true);

    try {
      const formDetails = new FormData();
      formDetails.append("file", formData["logo"]);
      formDetails.append("name", formData["companyName"]);
      formDetails.append("email", formData["email"]);
      formDetails.append("country", formData["country"]);
      formDetails.append("countryCode", formData["countryCode"]);
      formDetails.append("city", formData["city"]);
      formDetails.append("owner", formData["firstName"] + formData["lastName"]);
      formDetails.append("address", formData["address"]);
      formDetails.append("phoneNumber", formData["mobileNumber"]);
      const response = await axios.post("/organizations/create", formDetails, {
        headers: {
          "content-type": "multipart/form-data",
        },
        withCredentials: true,
      });

      setSuccess(true);

      setTimeout(() => {
        setFormData({
          email: "",
          password: "",
          commpanyName: "",
          firstName: "",
          lastName: "",
          subsciptionPlan: props.plan.toUpperCase(),
          address: "",
          phoneNumber: "",
          city: "",
          country: "",
          countryCode: "",
          logo: null,
        });
        setOpen(false);
      }, response);

      toast.success("Registered user successfully!");
    } catch (err) {
      setOpen(false);

      if (!err?.response) {
        toast.error("Check your internet connection!");
      } else {
        toast.error(err.response.data?.message);
      }
      console.log(err);
    }
  };

  // Handles Closing The LogIn Modal
  const closeLoginModal = () => {
    setShowLog(false);
    props.onClose();
  };

  return (
    <div
      className={`sign__modal ${props.show ? "show project_modal" : ""}`}
      // onClick={props.onClose}
    >
      <>
        {success ? (
          <section
            className="sign__modal--content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sign__modal--content-header">
              <h4>Registration complete!</h4>
              <CloseIcon
                className="sign__close--icon"
                onClick={props.onClose}
              />
            </div>
            <aside className="text-left text-xl !text-black">
              Check mail to verify your account
            </aside>

            <button
              onClick={() => {
                setShowLog(true);
              }}
              className="sign-up-tag"
            >
              Sign In
            </button>
            <LoginModal onClose={closeLoginModal} show={showLog} />
          </section>
        ) : (
          <form
            className="sign__modal--content"
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit}
          >
            <div className="sign__modal--content-header">
              <h4>Sign Up </h4>
              <CloseIcon
                className="sign__close--icon"
                onClick={props.onClose}
              />
            </div>

            {open && <BackDrop open={open} />}

            <div className="add_file_container mb-3">
              {displayImg ? (
                <label htmlFor="add_file" className=" cursor-pointer pb-4">
                  <img
                    src={displayImg}
                    alt="Org image"
                    className=" object-cover h-44 w-52 rounded-md"
                  />
                </label>
              ) : (
                <Tooltip title="Max size: 1mb" placement="top">
                  <label
                    htmlFor="add_file"
                    className="add_file  mb-7 text-black"
                  >
                    +
                  </label>
                </Tooltip>
              )}
              <input
                type="file"
                accept="image/*"
                id="add_file"
                className="hidden"
                placeholder="Team Logo"
                name="logo"
                onChange={handleFile}
                // required
              />
            </div>

            <div className="sign__inputs">
              <input
                type="text"
                name="companyName"
                placeholder="Organization Name"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                // ref={userRef}
                onChange={handleChange}
                autoComplete="off"
                // aria-describedby="uidnote"
                // onFocus={() => setUserFocus(true)}
                // onBlur={() => setUserFocus(false)}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Surname"
                // ref={userRef}
                onChange={handleChange}
                autoComplete="off"
                // aria-describedby="uidnote"
                // onFocus={() => setUserFocus(true)}
                // onBlur={() => setUserFocus(false)}
                required
              />

              <input
                type="text"
                placeholder={formData["subsciptionPlan"]}
                disabled
              />

              <select
                onChange={handleChange}
                style={{
                  padding: "10px 0px",
                  borderRadius: "6px",
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  backgroundColor: "white",
                }}
                name="country"
                className=" p-2"
              >
                <option className="hidden" value="none">
                  Select Country
                </option>
                <option value="nigeria">Nigeria</option>
                <option value="usa">USA</option>
                <option value="france">France</option>
                <option value="uk">UK</option>
                <option value="southafrica">South Africa</option>
                <option value="ghana">Ghana</option>
              </select>

              <input
                type="text"
                readOnly
                value={formData["countryCode"]}
                onChange={handleChange}
                name="countryCode"
                placeholder="Country Code"
                disabled={formData["country"] === "none"}
              />
              <input
                type="text"
                placeholder="City"
                name="city"
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="address"
                placeholder="Address"
                autoCorrect="off"
                onChange={handleChange}
                required
              />

              <input
                type="text"
                placeholder="Mobile Number"
                name="phoneNumber"
                onChange={handleChange}
                required
              />
            </div>
            <button className="sign-up-tag" type="submit">
              Sign Up
            </button>
          </form>
        )}
      </>
    </div>
  );
};

export default CreateOrganization;
