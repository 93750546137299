import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./loginmodal.css";
import "../fpModal/fpmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";

import BackDrop from "../../BackDrop";

const LOGIN_URL = "/login";
// Dummy Login Credentials
// email: Andindeford1975@gustr.com

const LoginModal = (props) => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [showFp, setShowFp] = useState(false);
  const [showSignB, setShowSignB] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [forgotEmail, setForgetEmail] = useState("");

  const [open, setOpen] = useState(false);

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  // Handles user credentials inputted in the available fields
  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "email") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handles forgotten password
  const forgottenRequest = async (event) => {
    event.preventDefault();
    if (!validEmailRegex.test(forgotEmail)) {
      toast.error("Invalid Email");
      return;
    }

    setOpen(true);
    try {
      const resp = await axios.post(
        "/forget-password",
        JSON.stringify({
          email: forgotEmail,
        })
      );

      setTimeout(() => {
        setOpen(false);
        toast.success("Password reset link sent");
      }, resp);

      return;
    } catch (error) {
      setOpen(false);

      if (error.response.message) {
        toast.error(error.response.message);
      } else {
        toast.error("Failed");
      }

      console.log(error);
      return;
    }
  };
  // Handles submitting of the form
  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpen(true);

    try {
      const response = await axios.post(
        LOGIN_URL,
        {
          email: formData["email"],
          password: formData["password"],
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response && response.data) {
        setOpen(false);
        const { id, email, firstName, lastName, role, authToken, owner } =
          response?.data;

        setAuth({ id, email, firstName, lastName, role, authToken });

        navigate("/frame/profile-admin");

        toast.success("Welcome back!", { duration: 3000 });
      }
    } catch (error) {
      setOpen(false);

      if (!error?.response) {
        toast.error("Check your internet connection!");
      } else if (error.response?.status === 401) {
        toast.error(error.response.data?.message);
      } else if (error.response?.status === 400) {
        toast.error("Missing Email or Password");
      } else {
        toast.error("Login Failed!");
      }
      // console.log(error);
    }
  };

  if (showFp)
    return (
      <div
        className={`fp__modal ${props.show ? "show" : ""}`}
        // onClick={props.onClose}
      >
        <div
          className="fp__modal--content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="login__modal--content-header">
            <h4>Forgot Password?</h4>
            <CloseIcon
              className="login__close--icon text-black"
              onClick={props.onClose}
              color="black"
            />
          </div>
          {open && <BackDrop open={open} />}

          <form onSubmit={forgottenRequest} className="w-full">
            <div className="fp__inputs">
              <input
                type="email"
                placeholder="Email Address"
                onChange={(e) => setForgetEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="sign-in-tag"
              onClick={forgottenRequest}
            >
              Send Reset Email
            </button>
          </form>
          <a href="#" className="fp__forgot" onClick={() => setShowFp(false)}>
            Back to Login
          </a>
          <a href="#" className="no-account" onClick={() => setShowSignB(true)}>
            Don't have an account? Sign Up
          </a>
        </div>
      </div>
    );
  if (showSignB) {
    return (
      <div
        className={`fp__modal ${props.show ? "show" : ""}`}
        // onClick={props.onClose}
      >
        <div
          className="fp__modal--content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="fp__modal--content-header">
            <h4>Sign Up </h4>
            <CloseIcon className="fp__close--icon" onClick={props.onClose} />
          </div>

          <div className="fp__inputs">
            <input type="text" placeholder="Full Name" />
          </div>
          <br />

          <div className="fp__inputs">
            <input type="email" placeholder="Email Address" />
          </div>
          <br />

          <div className="fp__inputs">
            <select>
              <option>Select your role</option>
              <option value="producer">Producer</option>
              <option value="Engineering">Engineering and Others</option>
              <option value="artist">Artist</option>
              <option value="fan">Fan</option>
            </select>
          </div>
          <br />

          <div className="fp__inputs">
            <input type="text" value="BASIC" disabled />
          </div>
          <br />

          <div className="fp__inputs">
            <input type="password" placeholder="Password" />
          </div>
          <br />

          <div className="fp__inputs">
            <input type="password" placeholder="Confirm Password" />
          </div>
          <a href="#" className="sign-in-tag" onClick={props.onClose}>
            Sign Up
          </a>
          <a
            href="#"
            className="fp__forgot"
            onClick={() => setShowSignB(false)}
          >
            Back to Login
          </a>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`login__modal ${props.show ? "show" : ""}`}
      id="login=div"
      // onClick={props.onClose}
    >
      <form
        className="login__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="login__modal--content-header">
          <h4>Login</h4>
          <CloseIcon className="login__close--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="login__inputs">
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            autoCorrect="off"
            onChange={handleChange}
            required
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            onChange={handleChange}
            required
          />
        </div>
        <a href="#" className="forgot" onClick={() => setShowFp(true)}>
          Forgot Password?
        </a>
        {/* <FpModal onClose={() => setShowFp(false)} show={showFp} /> */}
        <button type="submit" href="#" className="sign-in-tag">
          Login
        </button>
        <a href="/pricing" className="no-account">
          Don't have an account? Sign Up
        </a>
      </form>
    </div>
  );
};

export default LoginModal;
