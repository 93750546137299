import * as React from "react";
import "./FilterModal.css";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";

const FilterModal = ({ anchor, resultCheck }) => {
  const [state, setState] = React.useState({
    right: false,
  });
  const [search, setSearch] = React.useState("");
  const [member, setMember] = React.useState("");
  const [statusSearch, setstatusSearch] = React.useState("");
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleStatus = (event) => {
    setstatusSearch(event.target.value);
  };

  const searchItems = () => {
    if (search !== "") {
      resultCheck(search);
    } else if (member !== "") {
      resultCheck(member);
    } else {
      resultCheck(statusSearch);
    }
  };
  const resetsearchItems = () => {
    resultCheck("");
  };

  return (
    <Box
      sx={{
        width: { sm: 250, md: 450 },
        backgroundColor: "#111",
        color: "white",
        height: "100%",
      }}
      className="filter"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="filter__header">
        <div>Filter</div>
        {/*        <div>
          <MdOutlineCancel />
    </div>*/}
      </div>

      <div className="filter__items ">
        <input
          id="search"
          type="text"
          onChange={handleChange}
          name="filter__search"
          placeholder="Search..."
        />

        <label htmlFor="members">Members</label>
        <input
          type="text"
          onChange={(e) => setMember(e.target.value)}
          name="members"
          id="members"
        />

        <label htmlFor="due__date">Due Date</label>
        <input type="text" name="due__date" id="due__date" />

        <label htmlFor="status">Status</label>
        <select type="text" name="status" id="status" onChange={handleStatus}>
          <option value="COMPLETED">Completed</option>
          <option value="IN_PROGRESS">In Progress</option>
          <option value="ON_HOLD">On Hold</option>
        </select>

        <div className="filter__buttons">
          <Link onClick={() => searchItems()} to="#" className="add__filter">
            Add Filter
          </Link>
          <Link
            onClick={() => resetsearchItems()}
            to="#"
            className="reset__filter"
          >
            Reset All Filters
          </Link>
        </div>
      </div>
    </Box>
  );
};

export default FilterModal;
