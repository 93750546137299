import React from "react";
import LoadingScreen from "../preLoader";
import "./TimelinePage.css";
import TimeLineHeader from "../../assets/icon/timeline-header.png";
import { useState, useEffect, useRef } from "react";
import { BsEmojiSmile } from "react-icons/bs";
import axios from "axios";
import Picker from "emoji-picker-react";
import useAuth from "../../hooks/useAuth";
import LinearProgress from "@mui/material/LinearProgress";

const TimelinePage = () => {
  const { auth } = useAuth();
  const userId = auth.authToken;
  const userFullName = `${auth.firstName} ${auth.lastName}`;
  const creatorsName = `${auth.firstName} ${auth.lastName}`;
  const commentorsName = `${auth.firstName} ${auth.lastName}`;
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [progressBar, setProgressBar] = useState(false);

  const [notPlaying, setNotPlaying] = useState(true);
  const [cancelRec, setCancelRec] = useState(false);

  const [contentType, setContentType] = useState("");
  const [image, setImage] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [audio, setAudio] = useState(null);
  const [video, setVideo] = useState(null);

  const [commentReplyPost, setCommentReplyPost] = useState(null);
  const [showAllCommentReplies, setShowAllCommentReplies] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [imageName, setImageName] = useState("");
  const attachmentInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const [caption, setCaption] = useState("");
  const [emojiPostId, setEmojiPostId] = useState("");
  const [clickedAudio, setClickedAudio] = useState(null);

  const audioRef = useRef(null);

  const [commentPost, setCommentPost] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [postPicker, setPostPicker] = useState(false);
  const [posts, setPosts] = useState({ today: [], yesterday: [], older: [] });
  const pickerRef = useRef(null);
  const writeRef = useRef(null);
  const [comment, setComment] = useState({});
  const [characterCount, setCharacterCount] = useState(0);
  const [showAllComments, setShowAllComments] = useState(false);

  const [showReplyTextarea, setShowReplyTextarea] = useState(null);
  const [reply, setReply] = useState({});

  const [previewVn, setPreviewVN] = useState(null);
  const [sendRecordBtn, setSendRecordBtn] = useState(false);

  const [isSendingId, setIsSendingId] = useState(null);
  const [isPreviewingId, setIsPreviewingId] = useState(null);
  const [isRecordingId, setIsRecordingId] = useState(null);
  const [micPostId, setMicPostId] = useState(null);
  const [recording, setRecording] = useState(null);
  const [finalAudioData, setFinalAudioData] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [timer, setTimer] = useState(0);
  const maxRecordingTime = 30;

  let timerInterval;

  const toggleReplyTextarea = (commentId) => {
    setShowReplyTextarea(showReplyTextarea === commentId ? null : commentId);
  };

  const handleReplyChange = (commentId, value) => {
    setReply({
      ...reply,
      [commentId]: value,
    });
  };

  const handleReplyPost = async (postId, commentId) => {
    const replyText = reply[commentId];

    try {
      await axios.post(
        "https://kens-those-server-z0bk.onrender.com/api/replies",
        {
          userId,
          userFullName, // Include userFullName
          postId,
          commentId,
          text: replyText,
        }
      );

      // Handle successful reply post
      await setReply({
        ...reply,
        [commentId]: "", // Clear the reply textarea
      });

      await fetchPosts2();
    } catch (error) {
      console.error("Error posting reply:", error);
    }
  };

  const handleCharacterCount = (e) => {
    const inputText = e.target.value;

    if (inputText.length <= 500) {
      setCaption(inputText);
      setCharacterCount(inputText.length);
    }
  };

  const handleTextareaHeight = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  /*const handleCharacterCount = (e) => {
    const inputText = e.target.value;

    // Check if the input is within the character limit
    if (inputText.length <= 500) {
      setCaption(inputText);
      setCharacterCount(inputText.length);
    }
  };*/

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        clearInterval(timerInterval);
        setFinalAudioData(new Blob(chunks, { type: "audio/mp3" }));
      };

      mediaRecorder.start();
      setIsRecording(true);
      setRecording(mediaRecorder);

      let startTime = Date.now();
      timerInterval = setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        setTimer(elapsedTime);
        if (elapsedTime >= maxRecordingTime) {
          mediaRecorder.stop(); // Stop recording when max recording time is reached
        }
      }, 1000);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  useEffect(() => {
    if (cancelRec === false) {
      console.log(finalAudioData, "exposed");
      setPreviewVN(finalAudioData);
    }
  }, [finalAudioData]);

  useEffect(() => {
    console.log(previewVn, "exposed preview");

    if (sendRecordBtn && previewVn) {
      setPreviewVN(null);
      setSendRecordBtn(false);
      sendRecording();
    }
  }, [previewVn, sendRecordBtn]);

  const cancelRecording = async () => {
    await setCancelRec(true);
    await recording.stop();
    await setIsRecording(false);
    await setFinalAudioData(null);
    await setPreviewVN(null);
    await setSendRecordBtn(false);
    await clearInterval(timerInterval);
    await setTimer(0);
  };

  const stopRecording = async () => {
    await setCancelRec(false);
    await recording.stop();

    await setIsRecording(false);
    await clearInterval(timerInterval);
    await setTimer(0);
  };

  const sendRecording = async () => {
    if (finalAudioData) {
      console.log("there is");
      setIsSending(true);
      try {
        const formData = new FormData();
        console.log(finalAudioData, "final audio");
        formData.append("audio", finalAudioData); // "audio" is the field name on the server
        formData.append("postId", micPostId);
        formData.append("userId", userId);

        const response = await axios.post(
          "https://kens-those-server-z0bk.onrender.com/upload-audio",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Recording sent successfully!", response);
      } catch (error) {
        console.error("Error sending recording:", error);
      } finally {
        setFinalAudioData(null); // Clear final audio data
        clearInterval(timerInterval);
        setTimer(0);
        setIsSending(false);
        fetchPosts2();
        setSendRecordBtn(false);
        setPreviewVN(null);
      }
    } else {
      console.log(
        "no final data available, waiting for useEffect",
        finalAudioData
      );
    }
  };

  const handleCommentChange = (postId, value) => {
    setComment((prevCommentStates) => ({
      ...prevCommentStates,
      [postId]: value,
    }));
  };

  console.log(creatorsName);
  console.log(commentorsName);

  // Reusable function to format dates
  function formatDateToAbbreviatedMonth(dateString) {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        // Clicked outside of the picker, so close it
        setShowPicker(false);
      }

      if (writeRef.current && !writeRef.current.contains(event.target)) {
        setPostPicker(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log(auth);

  const onPostEmojiClick = (event, emojiObject) => {
    setCaption((prevInput) => prevInput + emojiObject.emoji);
    setPostPicker(false);
  };

  const onEmojiClick2 = (event, emojiObject) => {
    if (emojiPostId) {
      const newComment = (comment[emojiPostId] || "") + emojiObject.emoji;
      handleCommentChange(emojiPostId, newComment);
      setShowPicker(false);
    }
  };

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://kens-those-server-z0bk.onrender.com/api/posts"
      );
      setPosts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  function getTimeAgo(timestamp) {
    const currentDate = new Date();
    const postDate = new Date(timestamp);
    const timeDifference = currentDate - postDate;

    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const month = 30 * day;
    const year = 365 * day;

    if (timeDifference < minute) {
      const seconds = Math.floor(timeDifference / 1000);
      return seconds <= 1 ? `Just now` : `${seconds} seconds ago`;
    } else if (timeDifference < hour) {
      const minutes = Math.floor(timeDifference / minute);
      return minutes <= 1 ? `${minutes} min ago` : `${minutes} mins ago`;
    } else if (timeDifference < day) {
      const hours = Math.floor(timeDifference / hour);
      return hours <= 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (timeDifference < month) {
      const days = Math.floor(timeDifference / day);
      return days <= 1 ? `${days} day ago` : `${days} days ago`;
    } else if (timeDifference < year) {
      const months = Math.floor(timeDifference / month);
      return months <= 1 ? `${months} month ago` : `${months} months ago`;
    } else {
      const years = Math.floor(timeDifference / year);
      return years <= 1 ? `${years} year ago` : `${years} years ago`;
    }
  }

  const fetchPosts2 = async () => {
    try {
      const response = await axios.get(
        "https://kens-those-server-z0bk.onrender.com/api/posts"
      );
      setPosts(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleImageUpload = async (e) => {
    const file = await e.target.files[0];

    if (file) {
      await setImage(null);
      await setContentType("image");
      await setImage(file);
      await setImageUrl(URL.createObjectURL(file));
      await setPdf(null);
      await setVideo(null);
      await setAudio(null);
    }
  };

  const handleAttachmentUpload = async (event) => {
    const attachment = await event.target.files[0];

    if (attachment.type.includes("video")) {
      await setVideo(null);
      await setContentType("video");
      await setVideo(attachment);
      await setVideoUrl(URL.createObjectURL(attachment));
      await setImage(null);
      await setPdf(null);
      await setAudio(null);
    } else if (attachment.type.includes("pdf")) {
      await setPdf(null);
      await setContentType("pdf");
      await setPdf(attachment);
      await setPdfUrl(URL.createObjectURL(attachment));
      await setImage(null);
      await setVideo(null);
      await setAudio(null);
    } else if (attachment.type.includes("audio")) {
      await setAudio(null);
      await setContentType("");
      await setAudio(attachment);
      await setAudioUrl(URL.createObjectURL(attachment));
      await setImage(null);
      await setVideo(null);
      await setPdf(null);
    }
  };

  const handleCreatePost = async () => {
    await setProgressBar(true);

    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("caption", caption);
      formData.append("creatorsName", creatorsName);
      formData.append("image", image);
      formData.append("video", video);
      formData.append("pdf", pdf);
      formData.append("audio", audio);

      console.log(formData);

      for (const entry of formData.entries()) {
        console.log(entry[0], entry[1]);
      }

      await axios.post(
        "https://kens-those-server-z0bk.onrender.com/api/posts/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      await setProgressBar(false);
      await fetchPosts2();
      await setCaption("");
      await setContentType("");
      await setImage(null);
      await setVideo(null);
      await setPdf(null);
      await setAudio(null);
      await setCharacterCount(0);
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const handleLikePost = async (postId) => {
    try {
      await axios.post(
        "https://kens-those-server-z0bk.onrender.com/api/posts/like",
        { postId, userId }
      );
      fetchPosts2();
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };

  const handleLikeComment = async (postId, commentId) => {
    try {
      const response = await axios.post(
        "https://kens-those-server-z0bk.onrender.com/api/posts/like-comment",
        { postId, userId, commentId }
      );
      setCommentPost(response.data.post);
      console.log("Server Response:", response.data); // Log the response data
      console.log("swaggy");
      fetchPosts2();
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };

  const handleCommentPost = async (postId) => {
    try {
      console.log(comment[postId]);

      const newComment = {
        postId,
        userId,
        text: comment[postId],
        commentorsName,
      };

      await axios.post(
        "https://kens-those-server-z0bk.onrender.com/api/posts/comment",
        newComment
      );
      setComment(""); // Clear the comment input after submitting
      fetchPosts2();
    } catch (error) {
      console.error("Error commenting on post:", error);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen loading />
      ) : (
        <div className="project__page1">
          <div className="project__header1">
            <img
              src={TimeLineHeader}
              alt="timeline"
              style={{
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
              }}
              className="timeline-img"
            />
            <div className="timeline__card">
              <div className="h1-timeline">The sound of the future</div>
              <div className="inner-h1">
                <div className="dj-h1">Mix by Dj Viper</div>
                <div className="text__card">Coming soon</div>
                <button className="check-outs">
                  <div>Check out!</div>
                </button>
              </div>
            </div>
          </div>

          <div className="projects">
            <div className="toppest-circle"></div>
            <div className="space-bet"></div>
            <div className="inner-day">Today</div>
            <div className="brits-container"></div>
            <div className="Sthratham">
              <div className="extend-to-bottom"></div>
              <div className="createPostcard">
                <div className="card-margin">
                  <div className="firstSection">
                    <div className="usertimeline">
                      <div className="name-spot">
                        {creatorsName[0].toUpperCase()}
                      </div>
                    </div>
                    <textarea
                      className="captions-area"
                      rows="3"
                      cols="3"
                      placeholder="Write something..."
                      value={caption}
                      onChange={(e) => {
                        handleCharacterCount(e);
                        handleTextareaHeight(e);
                      }}
                      maxLength="500"
                    ></textarea>
                  </div>

                  {/*Preview area*/}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {contentType === "image" && image && (
                      <img
                        style={{
                          position: "relative",
                          width: "250px",
                          height: "150px",
                          borderRadius: "5px",
                          margin: "20px 0px",
                        }}
                        src={imageUrl}
                        alt="Selected"
                        className="selected-image"
                      />
                    )}

                    {contentType === "video" && video && (
                      <video
                        controls
                        style={{
                          position: "relative",
                          width: "250px",
                          height: "150px",
                          borderRadius: "5px",
                          margin: "20px 0px",
                        }}
                      >
                        <source src={videoUrl} type="video/mp4" />
                      </video>
                    )}

                    {contentType === "pdf" && pdf && (
                      <iframe
                        title="Selected PDF"
                        src={pdfUrl}
                        style={{
                          position: "relative",
                          width: "250px",
                          height: "150px",
                          borderRadius: "5px",
                          margin: "20px 0px",
                        }}
                      />
                    )}

                    {contentType && (
                      <div
                        class="cancel-media"
                        onClick={async () => {
                          await setContentType("");
                          await setVideo(null);
                          await setImage(null);
                          await setPdf(null);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.7308 4.33038C13.0236 4.03749 13.0236 3.56261 12.7308 3.26972C12.4379 2.97683 11.963 2.97683 11.6701 3.26972L8.00011 6.9397L4.33013 3.26972C4.03724 2.97683 3.56237 2.97683 3.26947 3.26972C2.97658 3.56261 2.97658 4.03749 3.26947 4.33038L6.93945 8.00036L3.26947 11.6703C2.97658 11.9632 2.97658 12.4381 3.26947 12.731C3.56237 13.0239 4.03724 13.0239 4.33013 12.731L8.00011 9.06102L11.6701 12.731C11.963 13.0239 12.4379 13.0239 12.7308 12.731C13.0236 12.4381 13.0236 11.9632 12.7308 11.6703L9.06077 8.00036L12.7308 4.33038Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {audio && (
                      <audio
                        style={{
                          position: "relative",
                          width: "280px",
                          height: "50px",
                          margin: "10px 0px 20px  0px",
                        }}
                        controls
                        src={audioUrl}
                      />
                    )}

                    {audio && (
                      <div
                        class="cancel-audio"
                        onClick={async () => {
                          await setAudio(null);
                          await setContentType("");
                          await setAudioUrl(null);
                          await setImage(null);
                          await setVideo(null);
                          await setPdf(null);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.7308 4.33038C13.0236 4.03749 13.0236 3.56261 12.7308 3.26972C12.4379 2.97683 11.963 2.97683 11.6701 3.26972L8.00011 6.9397L4.33013 3.26972C4.03724 2.97683 3.56237 2.97683 3.26947 3.26972C2.97658 3.56261 2.97658 4.03749 3.26947 4.33038L6.93945 8.00036L3.26947 11.6703C2.97658 11.9632 2.97658 12.4381 3.26947 12.731C3.56237 13.0239 4.03724 13.0239 4.33013 12.731L8.00011 9.06102L11.6701 12.731C11.963 13.0239 12.4379 13.0239 12.7308 12.731C13.0236 12.4381 13.0236 11.9632 12.7308 11.6703L9.06077 8.00036L12.7308 4.33038Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  <div className="thirdSection">
                    <button onClick={handleCreatePost} disabled={!caption}>
                      Post
                    </button>

                    <div className="limit-reached-div">
                      {characterCount >= 500 && (
                        <div className="limit-reached">Limit reached</div>
                      )}
                    </div>

                    <div className="caption-sep1">
                      <div
                        onClick={() => {
                          attachmentInputRef.current.click();
                        }}
                        className="outter-img-icon1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M11.4286 2.5C13.401 2.5 15 3.99238 15 5.83333V13.8333C15 16.4107 12.7614 18.5 10 18.5C7.23858 18.5 5 16.4107 5 13.8333V8.5H6.42857V13.8333C6.42857 15.6743 8.02755 17.1667 10 17.1667C11.9724 17.1667 13.5714 15.6743 13.5714 13.8333V5.83333C13.5714 4.72876 12.612 3.83333 11.4286 3.83333C10.2451 3.83333 9.28571 4.72876 9.28571 5.83333V13.8333C9.28571 14.2015 9.60551 14.5 10 14.5C10.3945 14.5 10.7143 14.2015 10.7143 13.8333V6.5H12.1429V13.8333C12.1429 14.9379 11.1835 15.8333 10 15.8333C8.81653 15.8333 7.85714 14.9379 7.85714 13.8333V5.83333C7.85714 3.99238 9.45613 2.5 11.4286 2.5Z"
                            fill="white"
                          />
                        </svg>
                        <input
                          ref={attachmentInputRef}
                          type="file"
                          accept="video/*,application/pdf,audio/*"
                          onChange={handleAttachmentUpload}
                          style={{ display: "none" }}
                        />
                      </div>

                      <div
                        className="outter-img-icon1"
                        onClick={() => setPostPicker((val) => !val)}
                      >
                        <BsEmojiSmile className="smiley1" />
                      </div>

                      <div>
                        <div
                          onClick={() => {
                            imageInputRef.current.click(); // Trigger the file input click event
                          }}
                          className="outter-img-icon1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.36111 4C3.88553 4 3.5 4.38553 3.5 4.86111V16.1389C3.5 16.5209 3.74869 16.8447 4.09298 16.9574L12.6919 8.35856C12.9848 8.06566 13.4596 8.06566 13.7525 8.35856L16.5 11.106V4.86111C16.5 4.38553 16.1145 4 15.6389 4H4.36111ZM16.5 13.2273L13.2222 9.94955L6.17174 17H15.6389C16.1145 17 16.5 16.6145 16.5 16.1389V13.2273ZM2 4.86111C2 3.55711 3.05711 2.5 4.36111 2.5H15.6389C16.9429 2.5 18 3.55711 18 4.86111V16.1389C18 17.4429 16.9429 18.5 15.6389 18.5H4.36111C3.05711 18.5 2 17.4429 2 16.1389V4.86111ZM7.18056 7.2222C6.92743 7.2222 6.72223 7.4274 6.72223 7.68053C6.72223 7.93366 6.92743 8.13887 7.18056 8.13887C7.43369 8.13887 7.6389 7.93366 7.6389 7.68053C7.6389 7.4274 7.43369 7.2222 7.18056 7.2222ZM5.22223 7.68053C5.22223 6.59897 6.099 5.7222 7.18056 5.7222C8.26212 5.7222 9.1389 6.59897 9.1389 7.68053C9.1389 8.76209 8.26212 9.63887 7.18056 9.63887C6.099 9.63887 5.22223 8.76209 5.22223 7.68053Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <input
                          ref={imageInputRef}
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>

                  {postPicker && (
                    <div
                      ref={writeRef}
                      style={{
                        position: "relative",
                        left: "170px",
                        zIndex: "2",
                        top: "10px",
                        width: "290px",
                        cursor: "pointer",
                      }}
                    >
                      <Picker
                        style={{ width: "290px" }}
                        onEmojiClick={onPostEmojiClick}
                      />
                    </div>
                  )}

                  {progressBar && (
                    <LinearProgress
                      sx={{
                        backgroundColor: "#ffb74d",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: "#ba68c8",
                        },
                      }}
                      style={{ marginTop: "30px" }}
                    />
                  )}
                </div>
              </div>

              {/*posts*/}
              <div className="crip-wrapper">
                <div className="post-container">
                  {posts.today.map((post, index) => (
                    <div key={post._id} className="post-item">
                      <div
                        className={`post ${
                          index % 2 === 1 ? "raised-post" : ""
                        }`}
                      >
                        <div className="mk-flex">
                          <div className="usertimeline">
                            <div className="name-spot">
                              {post.creatorsName
                                ? post.creatorsName[0].toUpperCase()
                                : "Z"}
                            </div>
                          </div>
                          <div className="top-id">
                            <div className="authors-name">
                              {post.creatorsName
                                ? post.creatorsName
                                : "Testing User"}
                            </div>
                            <div className="post-date">
                              {formatDateToAbbreviatedMonth(post.createdDate)}
                            </div>
                          </div>
                        </div>
                        <div
                          className={index % 2 === 1 ? "circle3" : "square3"}
                        ></div>
                        {post.video ? (
                          <video src={post.video} controls alt="post" />
                        ) : post.image ? (
                          <img src={post.image} alt="post" />
                        ) : post.pdf ? (
                          <iframe src={post.pdf} title="post" />
                        ) : (
                          post.audio && (
                            <div className="post-audio-div">
                              <audio
                                style={{
                                  position: "relative",
                                  width: "140px",
                                  height: "30px",
                                  margin: "10px 0px 20px  0px",
                                  display: "none",
                                }}
                                ref={audioRef}
                                controls
                                src={post.audio}
                              />

                              <svg
                                onClick={() => {
                                  audioRef.current.currentTime -= 2;
                                }}
                                className="rewind"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M6.09545 0.579545L1.19659 5.71136C1.16377 5.74568 1.13724 5.7895 1.11899 5.8395C1.10074 5.88951 1.09126 5.94439 1.09126 6C1.09126 6.05561 1.10074 6.11049 1.11899 6.1605C1.13724 6.2105 1.16377 6.25432 1.19659 6.28864L6.09545 11.4205C6.27784 11.6114 6.54545 11.4386 6.54545 11.1318V0.868182C6.54545 0.561364 6.27784 0.388636 6.09545 0.579545ZM11.55 0.579545L6.65114 5.71136C6.61832 5.74568 6.59178 5.7895 6.57353 5.8395C6.55528 5.88951 6.5458 5.94439 6.5458 6C6.5458 6.05561 6.55528 6.11049 6.57353 6.1605C6.59178 6.2105 6.61832 6.25432 6.65114 6.28864L11.55 11.4205C11.7324 11.6114 12 11.4386 12 11.1318V0.868182C12 0.561364 11.7324 0.388636 11.55 0.579545ZM0.981818 0H0.109091C0.0494317 0 0 0.0613636 0 0.136364V11.8636C0 11.9386 0.0494317 12 0.109091 12H0.981818C1.04148 12 1.09091 11.9386 1.09091 11.8636V0.136364C1.09091 0.0613636 1.04148 0 0.981818 0Z"
                                  fill="#E8E8E8"
                                />
                              </svg>

                              {notPlaying ||
                              (notPlaying === false &&
                                audioRef.current.src !== post.audio) ? (
                                <svg
                                  onClick={async () => {
                                    await setNotPlaying(true);
                                    if (audioRef.current.src !== post.audio) {
                                      audioRef.current.src = post.audio;
                                    } // Set the audio source
                                    if (audioRef.current.paused) {
                                      if (audioRef.current.src === post.audio) {
                                        await setNotPlaying(false);
                                        await audioRef.current.play();
                                        console.log(audioRef.current.src);
                                      }
                                    } else {
                                      await audioRef.current.pause();
                                      await setNotPlaying(true);
                                    }
                                  }}
                                  className="play-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM6.79 5.093C6.71524 5.03977 6.62726 5.00814 6.53572 5.00159C6.44418 4.99503 6.35259 5.01379 6.27101 5.05583C6.18942 5.09786 6.12098 5.16154 6.07317 5.23988C6.02537 5.31823 6.00006 5.40822 6 5.5V10.5C6.00006 10.5918 6.02537 10.6818 6.07317 10.7601C6.12098 10.8385 6.18942 10.9021 6.27101 10.9442C6.35259 10.9862 6.44418 11.005 6.53572 10.9984C6.62726 10.9919 6.71524 10.9602 6.79 10.907L10.29 8.407C10.3548 8.36075 10.4076 8.29968 10.4441 8.22889C10.4805 8.1581 10.4996 8.07963 10.4996 8C10.4996 7.92037 10.4805 7.8419 10.4441 7.77111C10.4076 7.70031 10.3548 7.63925 10.29 7.593L6.79 5.093Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                notPlaying === false &&
                                audioRef.current.src === post.audio && (
                                  <svg
                                    onClick={async () => {
                                      if (audioRef.current.paused) {
                                        audioRef.current.play();
                                        setNotPlaying(false);
                                      } else {
                                        audioRef.current.pause();
                                        setNotPlaying(true);
                                      }
                                    }}
                                    className="pause-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="57"
                                    height="57"
                                    viewBox="0 0 57 57"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_351_61455)">
                                      <path
                                        d="M57 28.5C57 36.0587 53.9973 43.3078 48.6525 48.6525C43.3078 53.9973 36.0587 57 28.5 57C20.9413 57 13.6922 53.9973 8.34746 48.6525C3.00267 43.3078 0 36.0587 0 28.5C0 20.9413 3.00267 13.6922 8.34746 8.34746C13.6922 3.00267 20.9413 0 28.5 0C36.0587 0 43.3078 3.00267 48.6525 8.34746C53.9973 13.6922 57 20.9413 57 28.5ZM22.2656 17.8125C19.8075 17.8125 17.8125 19.8075 17.8125 22.2656V34.7344C17.8125 35.9154 18.2817 37.0481 19.1168 37.8832C19.9519 38.7183 21.0846 39.1875 22.2656 39.1875C23.4467 39.1875 24.5793 38.7183 25.4145 37.8832C26.2496 37.0481 26.7188 35.9154 26.7188 34.7344V22.2656C26.7188 19.8075 24.7237 17.8125 22.2656 17.8125ZM34.7344 17.8125C32.2762 17.8125 30.2812 19.8075 30.2812 22.2656V34.7344C30.2812 35.9154 30.7504 37.0481 31.5855 37.8832C32.4207 38.7183 33.5533 39.1875 34.7344 39.1875C35.9154 39.1875 37.0481 38.7183 37.8832 37.8832C38.7183 37.0481 39.1875 35.9154 39.1875 34.7344V22.2656C39.1875 19.8075 37.1925 17.8125 34.7344 17.8125Z"
                                        fill="#FFF"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_351_61455">
                                        <rect
                                          width="57"
                                          height="57"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                )
                              )}

                              <svg
                                onClick={() => {
                                  audioRef.current.currentTime += 2;
                                }}
                                className="fastfoward"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M10.8034 5.71136L5.90455 0.579545C5.72216 0.388636 5.45455 0.561364 5.45455 0.868182V11.1318C5.45455 11.4386 5.72216 11.6114 5.90455 11.4205L10.8034 6.28864C10.8362 6.25432 10.8628 6.2105 10.881 6.1605C10.8993 6.11049 10.9087 6.05561 10.9087 6C10.9087 5.94439 10.8993 5.88951 10.881 5.8395C10.8628 5.7895 10.8362 5.74568 10.8034 5.71136ZM5.34886 5.71136L0.45 0.579545C0.267614 0.388636 0 0.561364 0 0.868182V11.1295C0 11.4364 0.267614 11.6091 0.45 11.4182L5.34886 6.28636C5.41875 6.21364 5.45455 6.10455 5.45455 5.99773C5.45455 5.89318 5.41875 5.78409 5.34886 5.71136ZM11.8909 0H11.0182C10.9585 0 10.9091 0.0613636 10.9091 0.136364V11.8636C10.9091 11.9386 10.9585 12 11.0182 12H11.8909C11.9506 12 12 11.9386 12 11.8636V0.136364C12 0.0613636 11.9506 0 11.8909 0Z"
                                  fill="#E8E8E8"
                                />
                              </svg>
                            </div>
                          )
                        )}
                        <p>{post.caption}</p>
                        <div style={{ display: "flex" }}>
                          <div
                            className="likes-and-count"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <button onClick={() => handleLikePost(post._id)}>
                              {post.likers.includes(userId) ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                    fill="#FD7972"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                    fill="#151517"
                                    stroke="white"
                                    stroke-width="1"
                                  />
                                </svg>
                              )}
                            </button>
                            {post.likers.length > 0 && (
                              <div className="count">{post.likers.length}</div>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              setCommentPost(post);
                              setShowAllComments(!showAllComments);
                            }}
                            className="comments-and-count"
                          >
                            {post.comments.length > 0 && (
                              <svg
                                style={{
                                  marginBottom: "3px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.75225 6.25225C3.91376 6.09074 4.13281 6 4.36122 6H15.6398C15.8682 6 16.0872 6.09074 16.2488 6.25225C16.4103 6.41376 16.501 6.63281 16.501 6.86122V14.9173C16.501 15.1457 16.4103 15.3648 16.2488 15.5263C16.0872 15.6878 15.8682 15.7786 15.6398 15.7786H5.97245C5.77353 15.7786 5.58277 15.8576 5.44212 15.9982L3.5 17.9403V6.86122C3.5 6.63281 3.59074 6.41376 3.75225 6.25225ZM4.36122 4.5C3.73499 4.5 3.1344 4.74877 2.69159 5.19159C2.24877 5.6344 2 6.23499 2 6.86122V19.751C2 20.0544 2.18273 20.3278 2.46299 20.4439C2.74324 20.56 3.06583 20.4958 3.28033 20.2813L6.28311 17.2786H15.6398C16.266 17.2786 16.8666 17.0298 17.3094 16.587C17.7522 16.1442 18.001 15.5436 18.001 14.9173V6.86122C18.001 6.23499 17.7522 5.6344 17.3094 5.19159C16.8666 4.74877 16.266 4.5 15.6398 4.5H4.36122Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                            {post.comments.length > 0 && (
                              <div className="count">
                                {post.comments.length}
                              </div>
                            )}
                          </div>
                        </div>
                        {isRecordingId !== post._id &&
                          isPreviewingId !== post._id && (
                            <div>
                              {console.log(post._id, "verify post")}
                              <div>
                                <textarea
                                  className="caption-area"
                                  id={`story-${post._id}`}
                                  name={`story-${post._id}`}
                                  rows="4"
                                  cols="33"
                                  placeholder="Write Something..."
                                  value={comment[post._id] || ""}
                                  onClick={() => {
                                    if (!comment[post._id]) {
                                      handleCommentChange(post._id, ""); // Initialize comment state if not exists
                                    }
                                  }}
                                  onChange={(e) =>
                                    handleCommentChange(
                                      post._id,
                                      e.target.value
                                    )
                                  }
                                  maxLength="250"
                                ></textarea>
                                <button
                                  onClick={() => handleCommentPost(post._id)}
                                  className="comment-btn"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                      fill="#3B3B3B"
                                    />
                                  </svg>
                                </button>
                                <div
                                  style={{
                                    position: "absolute",
                                    backgroundColor: "#333333",
                                    marginLeft: "8px",
                                    borderRadius: "5px",
                                    display: "flex",
                                    width: "38px",
                                    height: "38px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "-90px",
                                    zIndex: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEmojiPostId(post._id);
                                    setShowPicker((val) => !val);
                                  }}
                                >
                                  <BsEmojiSmile className="smiley1" />
                                </div>
                              </div>
                              {emojiPostId === post._id && showPicker && (
                                <div
                                  className="emoji-picker-container"
                                  ref={pickerRef}
                                >
                                  <Picker onEmojiClick={onEmojiClick2} />
                                </div>
                              )}
                              <div
                                onClick={() => {
                                  setIsRecordingId(post._id);
                                  startRecording();
                                }}
                                className="mic-chat"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="22"
                                  viewBox="0 0 16 22"
                                  fill="none"
                                >
                                  <path
                                    d="M16 9.85204C16 9.74653 15.9127 9.6602 15.8061 9.6602H14.3515C14.2448 9.6602 14.1576 9.74653 14.1576 9.85204C14.1576 13.2164 11.4012 15.9429 8 15.9429C4.59879 15.9429 1.84242 13.2164 1.84242 9.85204C1.84242 9.74653 1.75515 9.6602 1.64848 9.6602H0.193939C0.0872727 9.6602 0 9.74653 0 9.85204C0 13.8974 3.06909 17.2354 7.0303 17.7078V20.1633H3.50788C3.17576 20.1633 2.90909 20.5062 2.90909 20.9306V21.7939C2.90909 21.8994 2.97697 21.9857 3.05939 21.9857H12.9406C13.023 21.9857 13.0909 21.8994 13.0909 21.7939V20.9306C13.0909 20.5062 12.8242 20.1633 12.4921 20.1633H8.87273V17.7197C12.88 17.2881 16 13.931 16 9.85204ZM8 13.9286C10.2764 13.9286 12.1212 12.1253 12.1212 9.9V4.52857C12.1212 2.30327 10.2764 0.5 8 0.5C5.72364 0.5 3.87879 2.30327 3.87879 4.52857V9.9C3.87879 12.1253 5.72364 13.9286 8 13.9286ZM5.72121 4.52857C5.72121 3.3152 6.73697 2.32245 8 2.32245C9.26303 2.32245 10.2788 3.3152 10.2788 4.52857V9.9C10.2788 11.1134 9.26303 12.1061 8 12.1061C6.73697 12.1061 5.72121 11.1134 5.72121 9.9V4.52857Z"
                                    fill="#EEB9F8"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}

                        {isRecording && isRecordingId === post._id && (
                          <div className="mic-utils">
                            <div style={{ marginLeft: "20px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                                fill="none"
                              >
                                <path
                                  d="M16 9.85204C16 9.74653 15.9127 9.66020 15.8061 9.66020H14.3515C14.2448 9.66020 14.1576 9.74653 14.1576 9.85204C14.1576 13.2164 11.4012 15.9429 8 15.9429C4.59879 15.9429 1.84242 13.2164 1.84242 9.85204C1.84242 9.74653 1.75515 9.66020 1.64848 9.66020H0.193939C0.0872727 9.66020 0 9.74653 0 9.85204C0 13.8974 3.06909 17.2354 7.0303 17.7078V20.1633H3.50788C3.17576 20.1633 2.90909 20.5062 2.90909 20.9306V21.7939C2.90909 21.8994 2.97697 21.9857 3.05939 21.9857H12.9406C13.0230 21.9857 13.0909 21.8994 13.0909 21.7939V20.9306C13.0909 20.5062 12.8242 20.1633 12.4921 20.1633H8.87273V17.7197C12.88 17.2881 16 13.9310 16 9.85204ZM8 13.9286C10.2764 13.9286 12.1212 12.1253 12.1212 9.9V4.52857C12.1212 2.30327 10.2764 0.5 8 0.5C5.72364 0.5 3.87879 2.30327 3.87879 4.52857V9.9C3.87879 12.1253 5.72364 13.9286 8 13.9286ZM5.72121 4.52857C5.72121 3.31520 6.73697 2.32245 8 2.32245C9.26303 2.32245 10.2788 3.31520 10.2788 4.52857V9.9C10.2788 11.1134 9.26303 12.1061 8 12.1061C6.73697 12.1061 5.72121 11.1134 5.72121 9.9V4.52857Z"
                                  fill="red"
                                />
                              </svg>
                            </div>

                            <div
                              style={{
                                fontSize: "17px",
                                marginLeft: "17px",
                                width: "46px",
                              }}
                            >
                              {timer}s
                            </div>
                            <button
                              onClick={async () => {
                                await setIsRecordingId(null);
                                await cancelRecording();
                              }}
                              className="cancel-rec"
                            >
                              Cancel
                            </button>

                            <div
                              onClick={async () => {
                                await setMicPostId(post._id);
                                await setIsRecordingId(null);
                                await setIsPreviewingId(post._id);
                                await stopRecording();
                              }}
                              className="stop-rec"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="38"
                                height="38"
                                viewBox="0 0 48 48"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_351_61603)">
                                  <path
                                    d="M48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.4697 45.4714 30.3652 48 24 48C17.6348 48 11.5303 45.4714 7.02944 40.9706C2.52856 36.4697 0 30.3652 0 24C0 17.6348 2.52856 11.5303 7.02944 7.02944C11.5303 2.52856 17.6348 0 24 0C30.3652 0 36.4697 2.52856 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24ZM19.5 15C18.3065 15 17.1619 15.4741 16.318 16.318C15.4741 17.1619 15 18.3065 15 19.5V28.5C15 29.6935 15.4741 30.8381 16.318 31.682C17.1619 32.5259 18.3065 33 19.5 33H28.5C29.6935 33 30.8381 32.5259 31.682 31.682C32.5259 30.8381 33 29.6935 33 28.5V19.5C33 18.3065 32.5259 17.1619 31.682 16.318C30.8381 15.4741 29.6935 15 28.5 15H19.5Z"
                                    fill="#edb9f8"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_351_61603">
                                    <rect width="48" height="48" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        )}

                        {previewVn && isPreviewingId === post._id && (
                          <div className="mic-utils">
                            <div
                              onClick={async () => {
                                await setMicPostId(post._id);
                                await setIsPreviewingId(null);
                                await cancelRecording();
                              }}
                              style={{ marginLeft: "20px", cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.04999 3.70503C8.18127 3.57375 8.35932 3.5 8.54497 3.5H11.445C11.6306 3.5 11.8087 3.57375 11.9399 3.70503C12.0712 3.8363 12.145 4.01435 12.145 4.2V4.9L7.84497 4.89999V4.2C7.84497 4.01435 7.91872 3.8363 8.04999 3.70503ZM6.34497 4.89999V4.2C6.34497 3.61652 6.57675 3.05694 6.98933 2.64437C7.40191 2.23179 7.96149 2 8.54497 2H11.445C12.0284 2 12.588 2.23179 13.0006 2.64437C13.4132 3.05694 13.645 3.61652 13.645 4.2V4.9L16.52 4.89999C16.9342 4.89999 17.27 5.23578 17.27 5.64999C17.27 6.06421 16.9342 6.39999 16.52 6.39999H15.82V15.8C15.82 16.3835 15.5882 16.9431 15.1756 17.3556C14.763 17.7682 14.2034 18 13.62 18H6.36997C5.78649 18 5.22691 17.7682 4.81433 17.3556C4.40175 16.9431 4.16997 16.3835 4.16997 15.8V6.39999H3.46997C3.05576 6.39999 2.71997 6.06421 2.71997 5.64999C2.71997 5.23578 3.05576 4.89999 3.46997 4.89999H6.34497ZM5.66997 15.8V6.4H14.32V15.8C14.32 15.9857 14.2462 16.1637 14.1149 16.295C13.9837 16.4263 13.8056 16.5 13.62 16.5H6.36997C6.18432 16.5 6.00627 16.4263 5.87499 16.295C5.74372 16.1637 5.66997 15.9857 5.66997 15.8ZM8.54498 8.52502C8.9592 8.52502 9.29498 8.86081 9.29498 9.27502V13.625C9.29498 14.0392 8.9592 14.375 8.54498 14.375C8.13077 14.375 7.79498 14.0392 7.79498 13.625V9.27502C7.79498 8.86081 8.13077 8.52502 8.54498 8.52502ZM12.1949 9.27502C12.1949 8.86081 11.8592 8.52502 11.4449 8.52502C11.0307 8.52502 10.6949 8.86081 10.6949 9.27502V13.625C10.6949 14.0392 11.0307 14.375 11.4449 14.375C11.8592 14.375 12.1949 14.0392 12.1949 13.625V9.27502Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <audio
                              style={{
                                position: "relative",
                                width: "200px",
                                height: "25px",
                                marginLeft: "70px",
                              }}
                              controls
                              src={URL.createObjectURL(previewVn)}
                            />
                            <button
                              onClick={() => {
                                setMicPostId(post._id);
                                setIsSendingId(post._id);
                                setIsPreviewingId(null);
                                setSendRecordBtn(true);
                              }}
                              className="comment-btn2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                  fill="#3B3B3B"
                                />
                              </svg>
                            </button>
                          </div>
                        )}

                        {isSending && isSendingId === post._id && (
                          <LinearProgress
                            sx={{
                              backgroundColor: "#ffb74d",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#ba68c8",
                              },
                            }}
                            style={{
                              width: "300px",
                              height: "10px",
                              marginTop: "-20px",
                            }}
                          />
                        )}

                        {post.comments && (
                          <div style={{ marginTop: "50px" }}>
                            {post.comments
                              .slice(-2)
                              .reverse()
                              .map((comment) => (
                                <div
                                  key={comment._id}
                                  className="mk-flex"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div
                                    className="usertimeline"
                                    style={{ marginTop: "4px" }}
                                  >
                                    <div className="name-spot">
                                      {comment.commentorsName
                                        ? comment.commentorsName[0].toUpperCase()
                                        : "Z"}
                                    </div>
                                  </div>
                                  <div className="top-id">
                                    <div style={{ display: "flex" }}>
                                      <div className="authors-name2">
                                        {comment.commentorsName
                                          ? comment.commentorsName
                                          : "Testing User"}
                                      </div>
                                      <div className="timer">
                                        {getTimeAgo(comment.createdDate)}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="comment-text">
                                        {(comment.audio && (
                                          <audio
                                            controls
                                            style={{
                                              color: "#edb9f8",
                                              height: "26px",
                                              width: "230px",
                                              marginTop: "4px",
                                              marginBottom: "6px",
                                            }}
                                          >
                                            <source
                                              src={comment.audio}
                                              type="audio/mp3"
                                            />
                                          </audio>
                                        )) ||
                                          comment.text}
                                      </div>
                                      <button
                                        style={{
                                          display: "flex",
                                          marginTop: "5px",
                                        }}
                                        onClick={() => {
                                          handleLikeComment(
                                            post._id,
                                            comment._id
                                          );
                                        }}
                                      >
                                        {comment.likers.includes(userId) ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                              fill="#FD7972"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                              fill="#151517"
                                              stroke="white"
                                              stroke-width="1"
                                            />
                                          </svg>
                                        )}
                                        {comment.likers.length > 0 && (
                                          <div className="count">
                                            {comment.likers.length}
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <button
                                        className="toggle-reply"
                                        onClick={() =>
                                          toggleReplyTextarea(comment._id)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="25"
                                          height="25"
                                          viewBox="0 0 20 21"
                                          fill="none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.68621 3.72413C7.91455 3.50221 8.25537 3.43843 8.54757 3.56275C8.83937 3.68705 9.03103 3.97391 9.03103 4.29282V6.71481C14.0189 6.96189 18 11.0967 18 16.1447C18 16.5069 17.7548 16.8231 17.4038 16.913C17.3365 16.9303 17.2705 16.9375 17.2072 16.9375C16.9218 16.9375 16.6534 16.7832 16.512 16.5252L16.499 16.5016C15.1171 14.0058 12.4836 12.453 9.62715 12.453H9.03103V14.8634C9.03103 15.1823 8.8395 15.4691 8.5477 15.5934L8.54669 15.5938C8.25705 15.716 7.91516 15.6563 7.6857 15.4316L2.24142 10.1474C2.08689 9.99801 2 9.79235 2 9.5781C2 9.36386 2.08656 9.15852 2.24109 9.00911L7.68621 3.72413ZM3.93117 9.5781L7.44544 12.9892V11.6602C7.44544 11.2225 7.80053 10.8674 8.23824 10.8674H9.62715C11.9492 10.8674 14.1485 11.7209 15.8431 13.2023C14.6761 10.3237 11.8498 8.28881 8.55856 8.28881H8.23824C7.80053 8.28881 7.44544 7.93373 7.44544 7.49602V6.16705L3.93117 9.5781Z"
                                            fill="white"
                                          />
                                        </svg>
                                      </button>
                                      {showReplyTextarea === comment._id && (
                                        <div style={{ marginBottom: "15px" }}>
                                          <textarea
                                            className="reply-caption-area"
                                            value={reply[comment._id] || ""}
                                            onChange={(e) =>
                                              handleReplyChange(
                                                comment._id,
                                                e.target.value
                                              )
                                            }
                                            maxLength="200"
                                          ></textarea>
                                          <button
                                            className="reply-comment-button"
                                            onClick={() =>
                                              handleReplyPost(
                                                post._id,
                                                comment._id
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                                fill="#3B3B3B"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}

                                      {comment.replies.length > 0 && (
                                        <div
                                          className="reply-count"
                                          onClick={() => {
                                            setCommentReplyPost(comment);
                                            setShowAllCommentReplies(
                                              !showAllCommentReplies
                                            );
                                          }}
                                        >
                                          View replies ({comment.replies.length}
                                          )
                                        </div>
                                      )}

                                      {commentReplyPost === comment &&
                                        showAllCommentReplies === true && (
                                          <div>
                                            {comment.replies
                                              .reverse()
                                              .map((eachReply) => (
                                                <div
                                                  key={eachReply._id}
                                                  className="reply-mk-flex"
                                                  style={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <div
                                                    className="usertimeline"
                                                    style={{ marginTop: "4px" }}
                                                  >
                                                    <div className="name-spot">
                                                      {eachReply.repliersName
                                                        ? eachReply.repliersName[0].toUpperCase()
                                                        : "Z"}
                                                    </div>
                                                  </div>
                                                  <div className="top-id">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <div className="authors-name2">
                                                        {eachReply.repliersName
                                                          ? eachReply.repliersName
                                                          : "Testing User"}
                                                      </div>
                                                      <div className="timer">
                                                        {getTimeAgo(
                                                          eachReply.createdDate
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div className="reply-text">
                                                        {(eachReply.audio && (
                                                          <audio
                                                            controls
                                                            style={{
                                                              color: "#edb9f8",
                                                              height: "26px",
                                                              width: "230px",
                                                              marginTop: "4px",
                                                              marginBottom:
                                                                "6px",
                                                            }}
                                                          >
                                                            <source
                                                              src={
                                                                eachReply.audio
                                                              }
                                                              type="audio/mp3"
                                                            />
                                                          </audio>
                                                        )) ||
                                                          eachReply.text}
                                                      </div>
                                                      {/*<button
                                                        style={{
                                                          display: "flex",
                                                          marginTop: "5px",
                                                        }}
                                                        onClick={() => {}}
                                                      >
                                                        {eachReply.likers.includes(
                                                          userId
                                                        ) ? (
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 21"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                              fill="#FD7972"
                                                            />
                                                          </svg>
                                                        ) : (
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 21"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                              fill="#151517"
                                                              stroke="white"
                                                              stroke-width="1"
                                                            />
                                                          </svg>
                                                        )}
                                                        {eachReply.likers
                                                          .length > 0 && (
                                                          <div className="count">
                                                            {
                                                              eachReply.likers
                                                                .length
                                                            }
                                                          </div>
                                                        )}
                                                          </button>*/}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        {post.comments.length > 2 && (
                          <button
                            style={{
                              position: "relative",
                              bottom: "0",
                              marginTop: "16px",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setCommentPost(post);
                              setShowAllComments(!showAllComments);
                            }}
                          >
                            View all {post.comments.length} comments
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className="inner-day"
                  style={{
                    marginTop: "30px",
                    width: "150px",
                    position: "relative",
                    right: "24px",
                  }}
                >
                  Yesterday
                </div>
                <div className="post-container2">
                  {posts.yesterday.map((post, index) => (
                    <div key={post._id} className="post-item">
                      <div className={`post ${index % 2 === 1 ? "post2" : ""}`}>
                        <div className="mk-flex">
                          <div className="usertimeline">
                            <div className="name-spot">
                              {post.creatorsName
                                ? post.creatorsName[0].toUpperCase()
                                : "Z"}
                            </div>
                          </div>
                          <div className="top-id">
                            <div className="authors-name">
                              {post.creatorsName
                                ? post.creatorsName
                                : "Testing User"}
                            </div>
                            <div className="post-date">
                              {formatDateToAbbreviatedMonth(post.createdDate)}
                            </div>
                          </div>
                        </div>
                        <div
                          className={index % 2 === 1 ? "circle3" : "square3"}
                        ></div>
                        {post.video ? (
                          <video src={post.video} controls alt="post" />
                        ) : post.image ? (
                          <img src={post.image} alt="post" />
                        ) : post.pdf ? (
                          <iframe src={post.pdf} title="post" />
                        ) : (
                          post.audio && (
                            <div className="post-audio-div">
                              <audio
                                style={{
                                  position: "relative",
                                  width: "140px",
                                  height: "30px",
                                  margin: "10px 0px 20px  0px",
                                  display: "none",
                                }}
                                ref={audioRef}
                                controls
                                src={post.audio}
                              />

                              <svg
                                onClick={() => {
                                  audioRef.current.currentTime -= 2;
                                }}
                                className="rewind"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M6.09545 0.579545L1.19659 5.71136C1.16377 5.74568 1.13724 5.7895 1.11899 5.8395C1.10074 5.88951 1.09126 5.94439 1.09126 6C1.09126 6.05561 1.10074 6.11049 1.11899 6.1605C1.13724 6.2105 1.16377 6.25432 1.19659 6.28864L6.09545 11.4205C6.27784 11.6114 6.54545 11.4386 6.54545 11.1318V0.868182C6.54545 0.561364 6.27784 0.388636 6.09545 0.579545ZM11.55 0.579545L6.65114 5.71136C6.61832 5.74568 6.59178 5.7895 6.57353 5.8395C6.55528 5.88951 6.5458 5.94439 6.5458 6C6.5458 6.05561 6.55528 6.11049 6.57353 6.1605C6.59178 6.2105 6.61832 6.25432 6.65114 6.28864L11.55 11.4205C11.7324 11.6114 12 11.4386 12 11.1318V0.868182C12 0.561364 11.7324 0.388636 11.55 0.579545ZM0.981818 0H0.109091C0.0494317 0 0 0.0613636 0 0.136364V11.8636C0 11.9386 0.0494317 12 0.109091 12H0.981818C1.04148 12 1.09091 11.9386 1.09091 11.8636V0.136364C1.09091 0.0613636 1.04148 0 0.981818 0Z"
                                  fill="#E8E8E8"
                                />
                              </svg>

                              {notPlaying ||
                              (notPlaying === false &&
                                audioRef.current.src !== post.audio) ? (
                                <svg
                                  onClick={async () => {
                                    await setNotPlaying(true);
                                    if (audioRef.current.src !== post.audio) {
                                      audioRef.current.src = post.audio;
                                    } // Set the audio source
                                    if (audioRef.current.paused) {
                                      if (audioRef.current.src === post.audio) {
                                        await setNotPlaying(false);
                                        await audioRef.current.play();
                                        console.log(audioRef.current.src);
                                      }
                                    } else {
                                      await audioRef.current.pause();
                                      await setNotPlaying(true);
                                    }
                                  }}
                                  className="play-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM6.79 5.093C6.71524 5.03977 6.62726 5.00814 6.53572 5.00159C6.44418 4.99503 6.35259 5.01379 6.27101 5.05583C6.18942 5.09786 6.12098 5.16154 6.07317 5.23988C6.02537 5.31823 6.00006 5.40822 6 5.5V10.5C6.00006 10.5918 6.02537 10.6818 6.07317 10.7601C6.12098 10.8385 6.18942 10.9021 6.27101 10.9442C6.35259 10.9862 6.44418 11.005 6.53572 10.9984C6.62726 10.9919 6.71524 10.9602 6.79 10.907L10.29 8.407C10.3548 8.36075 10.4076 8.29968 10.4441 8.22889C10.4805 8.1581 10.4996 8.07963 10.4996 8C10.4996 7.92037 10.4805 7.8419 10.4441 7.77111C10.4076 7.70031 10.3548 7.63925 10.29 7.593L6.79 5.093Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                notPlaying === false &&
                                audioRef.current.src === post.audio && (
                                  <svg
                                    onClick={async () => {
                                      if (audioRef.current.paused) {
                                        audioRef.current.play();
                                        setNotPlaying(false);
                                      } else {
                                        audioRef.current.pause();
                                        setNotPlaying(true);
                                      }
                                    }}
                                    className="pause-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="57"
                                    height="57"
                                    viewBox="0 0 57 57"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_351_61455)">
                                      <path
                                        d="M57 28.5C57 36.0587 53.9973 43.3078 48.6525 48.6525C43.3078 53.9973 36.0587 57 28.5 57C20.9413 57 13.6922 53.9973 8.34746 48.6525C3.00267 43.3078 0 36.0587 0 28.5C0 20.9413 3.00267 13.6922 8.34746 8.34746C13.6922 3.00267 20.9413 0 28.5 0C36.0587 0 43.3078 3.00267 48.6525 8.34746C53.9973 13.6922 57 20.9413 57 28.5ZM22.2656 17.8125C19.8075 17.8125 17.8125 19.8075 17.8125 22.2656V34.7344C17.8125 35.9154 18.2817 37.0481 19.1168 37.8832C19.9519 38.7183 21.0846 39.1875 22.2656 39.1875C23.4467 39.1875 24.5793 38.7183 25.4145 37.8832C26.2496 37.0481 26.7188 35.9154 26.7188 34.7344V22.2656C26.7188 19.8075 24.7237 17.8125 22.2656 17.8125ZM34.7344 17.8125C32.2762 17.8125 30.2812 19.8075 30.2812 22.2656V34.7344C30.2812 35.9154 30.7504 37.0481 31.5855 37.8832C32.4207 38.7183 33.5533 39.1875 34.7344 39.1875C35.9154 39.1875 37.0481 38.7183 37.8832 37.8832C38.7183 37.0481 39.1875 35.9154 39.1875 34.7344V22.2656C39.1875 19.8075 37.1925 17.8125 34.7344 17.8125Z"
                                        fill="#FFF"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_351_61455">
                                        <rect
                                          width="57"
                                          height="57"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                )
                              )}

                              <svg
                                onClick={() => {
                                  audioRef.current.currentTime += 2;
                                }}
                                className="fastfoward"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M10.8034 5.71136L5.90455 0.579545C5.72216 0.388636 5.45455 0.561364 5.45455 0.868182V11.1318C5.45455 11.4386 5.72216 11.6114 5.90455 11.4205L10.8034 6.28864C10.8362 6.25432 10.8628 6.2105 10.881 6.1605C10.8993 6.11049 10.9087 6.05561 10.9087 6C10.9087 5.94439 10.8993 5.88951 10.881 5.8395C10.8628 5.7895 10.8362 5.74568 10.8034 5.71136ZM5.34886 5.71136L0.45 0.579545C0.267614 0.388636 0 0.561364 0 0.868182V11.1295C0 11.4364 0.267614 11.6091 0.45 11.4182L5.34886 6.28636C5.41875 6.21364 5.45455 6.10455 5.45455 5.99773C5.45455 5.89318 5.41875 5.78409 5.34886 5.71136ZM11.8909 0H11.0182C10.9585 0 10.9091 0.0613636 10.9091 0.136364V11.8636C10.9091 11.9386 10.9585 12 11.0182 12H11.8909C11.9506 12 12 11.9386 12 11.8636V0.136364C12 0.0613636 11.9506 0 11.8909 0Z"
                                  fill="#E8E8E8"
                                />
                              </svg>
                            </div>
                          )
                        )}
                        <p>{post.caption}</p>
                        <div style={{ display: "flex" }}>
                          <div
                            className="likes-and-count"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <button onClick={() => handleLikePost(post._id)}>
                              {post.likers.includes(userId) ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                    fill="#FD7972"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                    fill="#151517"
                                    stroke="white"
                                    stroke-width="1"
                                  />
                                </svg>
                              )}
                            </button>
                            {post.likers.length > 0 && (
                              <div className="count">{post.likers.length}</div>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              setCommentPost(post);
                              setShowAllComments(!showAllComments);
                            }}
                            className="comments-and-count"
                          >
                            {post.comments.length > 0 && (
                              <svg
                                style={{
                                  marginBottom: "3px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.75225 6.25225C3.91376 6.09074 4.13281 6 4.36122 6H15.6398C15.8682 6 16.0872 6.09074 16.2488 6.25225C16.4103 6.41376 16.501 6.63281 16.501 6.86122V14.9173C16.501 15.1457 16.4103 15.3648 16.2488 15.5263C16.0872 15.6878 15.8682 15.7786 15.6398 15.7786H5.97245C5.77353 15.7786 5.58277 15.8576 5.44212 15.9982L3.5 17.9403V6.86122C3.5 6.63281 3.59074 6.41376 3.75225 6.25225ZM4.36122 4.5C3.73499 4.5 3.1344 4.74877 2.69159 5.19159C2.24877 5.6344 2 6.23499 2 6.86122V19.751C2 20.0544 2.18273 20.3278 2.46299 20.4439C2.74324 20.56 3.06583 20.4958 3.28033 20.2813L6.28311 17.2786H15.6398C16.266 17.2786 16.8666 17.0298 17.3094 16.587C17.7522 16.1442 18.001 15.5436 18.001 14.9173V6.86122C18.001 6.23499 17.7522 5.6344 17.3094 5.19159C16.8666 4.74877 16.266 4.5 15.6398 4.5H4.36122Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                            {post.comments.length > 0 && (
                              <div className="count">
                                {post.comments.length}
                              </div>
                            )}
                          </div>
                        </div>
                        {isRecordingId !== post._id &&
                          isPreviewingId !== post._id && (
                            <div>
                              {console.log(post._id, "verify post")}
                              <div>
                                <textarea
                                  className="caption-area"
                                  id={`story-${post._id}`}
                                  name={`story-${post._id}`}
                                  rows="4"
                                  cols="33"
                                  placeholder="Write Something..."
                                  value={comment[post._id] || ""}
                                  onClick={() => {
                                    if (!comment[post._id]) {
                                      handleCommentChange(post._id, ""); // Initialize comment state if not exists
                                    }
                                  }}
                                  onChange={(e) =>
                                    handleCommentChange(
                                      post._id,
                                      e.target.value
                                    )
                                  }
                                  maxLength="250"
                                ></textarea>
                                <button
                                  onClick={() => handleCommentPost(post._id)}
                                  className="comment-btn"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                      fill="#3B3B3B"
                                    />
                                  </svg>
                                </button>
                                <div
                                  style={{
                                    position: "absolute",
                                    backgroundColor: "#333333",
                                    marginLeft: "8px",
                                    borderRadius: "5px",
                                    display: "flex",
                                    width: "38px",
                                    height: "38px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "-90px",
                                    zIndex: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEmojiPostId(post._id);
                                    setShowPicker((val) => !val);
                                  }}
                                >
                                  <BsEmojiSmile className="smiley1" />
                                </div>
                              </div>
                              {emojiPostId === post._id && showPicker && (
                                <div
                                  className="emoji-picker-container"
                                  ref={pickerRef}
                                >
                                  <Picker onEmojiClick={onEmojiClick2} />
                                </div>
                              )}
                              <div
                                onClick={() => {
                                  setIsRecordingId(post._id);
                                  startRecording();
                                }}
                                className="mic-chat"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="22"
                                  viewBox="0 0 16 22"
                                  fill="none"
                                >
                                  <path
                                    d="M16 9.85204C16 9.74653 15.9127 9.6602 15.8061 9.6602H14.3515C14.2448 9.6602 14.1576 9.74653 14.1576 9.85204C14.1576 13.2164 11.4012 15.9429 8 15.9429C4.59879 15.9429 1.84242 13.2164 1.84242 9.85204C1.84242 9.74653 1.75515 9.6602 1.64848 9.6602H0.193939C0.0872727 9.6602 0 9.74653 0 9.85204C0 13.8974 3.06909 17.2354 7.0303 17.7078V20.1633H3.50788C3.17576 20.1633 2.90909 20.5062 2.90909 20.9306V21.7939C2.90909 21.8994 2.97697 21.9857 3.05939 21.9857H12.9406C13.023 21.9857 13.0909 21.8994 13.0909 21.7939V20.9306C13.0909 20.5062 12.8242 20.1633 12.4921 20.1633H8.87273V17.7197C12.88 17.2881 16 13.931 16 9.85204ZM8 13.9286C10.2764 13.9286 12.1212 12.1253 12.1212 9.9V4.52857C12.1212 2.30327 10.2764 0.5 8 0.5C5.72364 0.5 3.87879 2.30327 3.87879 4.52857V9.9C3.87879 12.1253 5.72364 13.9286 8 13.9286ZM5.72121 4.52857C5.72121 3.3152 6.73697 2.32245 8 2.32245C9.26303 2.32245 10.2788 3.3152 10.2788 4.52857V9.9C10.2788 11.1134 9.26303 12.1061 8 12.1061C6.73697 12.1061 5.72121 11.1134 5.72121 9.9V4.52857Z"
                                    fill="#EEB9F8"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}

                        {isRecording && isRecordingId === post._id && (
                          <div className="mic-utils">
                            <div style={{ marginLeft: "20px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                                fill="none"
                              >
                                <path
                                  d="M16 9.85204C16 9.74653 15.9127 9.66020 15.8061 9.66020H14.3515C14.2448 9.66020 14.1576 9.74653 14.1576 9.85204C14.1576 13.2164 11.4012 15.9429 8 15.9429C4.59879 15.9429 1.84242 13.2164 1.84242 9.85204C1.84242 9.74653 1.75515 9.66020 1.64848 9.66020H0.193939C0.0872727 9.66020 0 9.74653 0 9.85204C0 13.8974 3.06909 17.2354 7.0303 17.7078V20.1633H3.50788C3.17576 20.1633 2.90909 20.5062 2.90909 20.9306V21.7939C2.90909 21.8994 2.97697 21.9857 3.05939 21.9857H12.9406C13.0230 21.9857 13.0909 21.8994 13.0909 21.7939V20.9306C13.0909 20.5062 12.8242 20.1633 12.4921 20.1633H8.87273V17.7197C12.88 17.2881 16 13.9310 16 9.85204ZM8 13.9286C10.2764 13.9286 12.1212 12.1253 12.1212 9.9V4.52857C12.1212 2.30327 10.2764 0.5 8 0.5C5.72364 0.5 3.87879 2.30327 3.87879 4.52857V9.9C3.87879 12.1253 5.72364 13.9286 8 13.9286ZM5.72121 4.52857C5.72121 3.31520 6.73697 2.32245 8 2.32245C9.26303 2.32245 10.2788 3.31520 10.2788 4.52857V9.9C10.2788 11.1134 9.26303 12.1061 8 12.1061C6.73697 12.1061 5.72121 11.1134 5.72121 9.9V4.52857Z"
                                  fill="red"
                                />
                              </svg>
                            </div>

                            <div
                              style={{
                                fontSize: "17px",
                                marginLeft: "17px",
                                width: "46px",
                              }}
                            >
                              {timer}s
                            </div>
                            <button
                              onClick={async () => {
                                await setIsRecordingId(null);
                                await cancelRecording();
                              }}
                              className="cancel-rec"
                            >
                              Cancel
                            </button>

                            <div
                              onClick={async () => {
                                await setMicPostId(post._id);
                                await setIsRecordingId(null);
                                await setIsPreviewingId(post._id);
                                await stopRecording();
                              }}
                              className="stop-rec"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="38"
                                height="38"
                                viewBox="0 0 48 48"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_351_61603)">
                                  <path
                                    d="M48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.4697 45.4714 30.3652 48 24 48C17.6348 48 11.5303 45.4714 7.02944 40.9706C2.52856 36.4697 0 30.3652 0 24C0 17.6348 2.52856 11.5303 7.02944 7.02944C11.5303 2.52856 17.6348 0 24 0C30.3652 0 36.4697 2.52856 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24ZM19.5 15C18.3065 15 17.1619 15.4741 16.318 16.318C15.4741 17.1619 15 18.3065 15 19.5V28.5C15 29.6935 15.4741 30.8381 16.318 31.682C17.1619 32.5259 18.3065 33 19.5 33H28.5C29.6935 33 30.8381 32.5259 31.682 31.682C32.5259 30.8381 33 29.6935 33 28.5V19.5C33 18.3065 32.5259 17.1619 31.682 16.318C30.8381 15.4741 29.6935 15 28.5 15H19.5Z"
                                    fill="#edb9f8"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_351_61603">
                                    <rect width="48" height="48" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        )}

                        {previewVn && isPreviewingId === post._id && (
                          <div className="mic-utils">
                            <div
                              onClick={async () => {
                                await setMicPostId(post._id);
                                await setIsPreviewingId(null);
                                await cancelRecording();
                              }}
                              style={{ marginLeft: "20px", cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.04999 3.70503C8.18127 3.57375 8.35932 3.5 8.54497 3.5H11.445C11.6306 3.5 11.8087 3.57375 11.9399 3.70503C12.0712 3.8363 12.145 4.01435 12.145 4.2V4.9L7.84497 4.89999V4.2C7.84497 4.01435 7.91872 3.8363 8.04999 3.70503ZM6.34497 4.89999V4.2C6.34497 3.61652 6.57675 3.05694 6.98933 2.64437C7.40191 2.23179 7.96149 2 8.54497 2H11.445C12.0284 2 12.588 2.23179 13.0006 2.64437C13.4132 3.05694 13.645 3.61652 13.645 4.2V4.9L16.52 4.89999C16.9342 4.89999 17.27 5.23578 17.27 5.64999C17.27 6.06421 16.9342 6.39999 16.52 6.39999H15.82V15.8C15.82 16.3835 15.5882 16.9431 15.1756 17.3556C14.763 17.7682 14.2034 18 13.62 18H6.36997C5.78649 18 5.22691 17.7682 4.81433 17.3556C4.40175 16.9431 4.16997 16.3835 4.16997 15.8V6.39999H3.46997C3.05576 6.39999 2.71997 6.06421 2.71997 5.64999C2.71997 5.23578 3.05576 4.89999 3.46997 4.89999H6.34497ZM5.66997 15.8V6.4H14.32V15.8C14.32 15.9857 14.2462 16.1637 14.1149 16.295C13.9837 16.4263 13.8056 16.5 13.62 16.5H6.36997C6.18432 16.5 6.00627 16.4263 5.87499 16.295C5.74372 16.1637 5.66997 15.9857 5.66997 15.8ZM8.54498 8.52502C8.9592 8.52502 9.29498 8.86081 9.29498 9.27502V13.625C9.29498 14.0392 8.9592 14.375 8.54498 14.375C8.13077 14.375 7.79498 14.0392 7.79498 13.625V9.27502C7.79498 8.86081 8.13077 8.52502 8.54498 8.52502ZM12.1949 9.27502C12.1949 8.86081 11.8592 8.52502 11.4449 8.52502C11.0307 8.52502 10.6949 8.86081 10.6949 9.27502V13.625C10.6949 14.0392 11.0307 14.375 11.4449 14.375C11.8592 14.375 12.1949 14.0392 12.1949 13.625V9.27502Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <audio
                              style={{
                                position: "relative",
                                width: "200px",
                                height: "25px",
                                marginLeft: "70px",
                              }}
                              controls
                              src={URL.createObjectURL(previewVn)}
                            />
                            <button
                              onClick={() => {
                                setMicPostId(post._id);
                                setIsSendingId(post._id);
                                setIsPreviewingId(null);
                                setSendRecordBtn(true);
                              }}
                              className="comment-btn2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                  fill="#3B3B3B"
                                />
                              </svg>
                            </button>
                          </div>
                        )}

                        {isSending && isSendingId === post._id && (
                          <LinearProgress
                            sx={{
                              backgroundColor: "#ffb74d",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#ba68c8",
                              },
                            }}
                            style={{
                              width: "300px",
                              height: "10px",
                              marginTop: "-20px",
                            }}
                          />
                        )}

                        {post.comments && (
                          <div style={{ marginTop: "50px" }}>
                            {post.comments
                              .slice(-2)
                              .reverse()
                              .map((comment) => (
                                <div
                                  key={comment._id}
                                  className="mk-flex"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div
                                    className="usertimeline"
                                    style={{ marginTop: "4px" }}
                                  >
                                    <div className="name-spot">
                                      {comment.commentorsName
                                        ? comment.commentorsName[0].toUpperCase()
                                        : "Z"}
                                    </div>
                                  </div>
                                  <div className="top-id">
                                    <div style={{ display: "flex" }}>
                                      <div className="authors-name2">
                                        {comment.commentorsName
                                          ? comment.commentorsName
                                          : "Testing User"}
                                      </div>
                                      <div className="timer">
                                        {getTimeAgo(comment.createdDate)}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="comment-text">
                                        {(comment.audio && (
                                          <audio
                                            controls
                                            style={{
                                              color: "#edb9f8",
                                              height: "26px",
                                              width: "230px",
                                              marginTop: "4px",
                                              marginBottom: "6px",
                                            }}
                                          >
                                            <source
                                              src={comment.audio}
                                              type="audio/mp3"
                                            />
                                          </audio>
                                        )) ||
                                          comment.text}
                                      </div>
                                      <button
                                        style={{
                                          display: "flex",
                                          marginTop: "5px",
                                        }}
                                        onClick={() => {
                                          handleLikeComment(
                                            post._id,
                                            comment._id
                                          );
                                        }}
                                      >
                                        {comment.likers.includes(userId) ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                              fill="#FD7972"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                              fill="#151517"
                                              stroke="white"
                                              stroke-width="1"
                                            />
                                          </svg>
                                        )}
                                        {comment.likers.length > 0 && (
                                          <div className="count">
                                            {comment.likers.length}
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <button
                                        className="toggle-reply"
                                        onClick={() =>
                                          toggleReplyTextarea(comment._id)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="25"
                                          height="25"
                                          viewBox="0 0 20 21"
                                          fill="none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.68621 3.72413C7.91455 3.50221 8.25537 3.43843 8.54757 3.56275C8.83937 3.68705 9.03103 3.97391 9.03103 4.29282V6.71481C14.0189 6.96189 18 11.0967 18 16.1447C18 16.5069 17.7548 16.8231 17.4038 16.913C17.3365 16.9303 17.2705 16.9375 17.2072 16.9375C16.9218 16.9375 16.6534 16.7832 16.512 16.5252L16.499 16.5016C15.1171 14.0058 12.4836 12.453 9.62715 12.453H9.03103V14.8634C9.03103 15.1823 8.8395 15.4691 8.5477 15.5934L8.54669 15.5938C8.25705 15.716 7.91516 15.6563 7.6857 15.4316L2.24142 10.1474C2.08689 9.99801 2 9.79235 2 9.5781C2 9.36386 2.08656 9.15852 2.24109 9.00911L7.68621 3.72413ZM3.93117 9.5781L7.44544 12.9892V11.6602C7.44544 11.2225 7.80053 10.8674 8.23824 10.8674H9.62715C11.9492 10.8674 14.1485 11.7209 15.8431 13.2023C14.6761 10.3237 11.8498 8.28881 8.55856 8.28881H8.23824C7.80053 8.28881 7.44544 7.93373 7.44544 7.49602V6.16705L3.93117 9.5781Z"
                                            fill="white"
                                          />
                                        </svg>
                                      </button>
                                      {showReplyTextarea === comment._id && (
                                        <div style={{ marginBottom: "15px" }}>
                                          <textarea
                                            className="reply-caption-area"
                                            value={reply[comment._id] || ""}
                                            onChange={(e) =>
                                              handleReplyChange(
                                                comment._id,
                                                e.target.value
                                              )
                                            }
                                            maxLength="200"
                                          ></textarea>
                                          <button
                                            className="reply-comment-button"
                                            onClick={() =>
                                              handleReplyPost(
                                                post._id,
                                                comment._id
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                                fill="#3B3B3B"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}

                                      {comment.replies.length > 0 && (
                                        <div
                                          className="reply-count"
                                          onClick={() => {
                                            setCommentReplyPost(comment);
                                            setShowAllCommentReplies(
                                              !showAllCommentReplies
                                            );
                                          }}
                                        >
                                          View replies ({comment.replies.length}
                                          )
                                        </div>
                                      )}

                                      {commentReplyPost === comment &&
                                        showAllCommentReplies === true && (
                                          <div>
                                            {comment.replies
                                              .reverse()
                                              .map((eachReply) => (
                                                <div
                                                  key={eachReply._id}
                                                  className="reply-mk-flex"
                                                  style={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <div
                                                    className="usertimeline"
                                                    style={{ marginTop: "4px" }}
                                                  >
                                                    <div className="name-spot">
                                                      {eachReply.repliersName
                                                        ? eachReply.repliersName[0].toUpperCase()
                                                        : "Z"}
                                                    </div>
                                                  </div>
                                                  <div className="top-id">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <div className="authors-name2">
                                                        {eachReply.repliersName
                                                          ? eachReply.repliersName
                                                          : "Testing User"}
                                                      </div>
                                                      <div className="timer">
                                                        {getTimeAgo(
                                                          eachReply.createdDate
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div className="reply-text">
                                                        {(eachReply.audio && (
                                                          <audio
                                                            controls
                                                            style={{
                                                              color: "#edb9f8",
                                                              height: "26px",
                                                              width: "230px",
                                                              marginTop: "4px",
                                                              marginBottom:
                                                                "6px",
                                                            }}
                                                          >
                                                            <source
                                                              src={
                                                                eachReply.audio
                                                              }
                                                              type="audio/mp3"
                                                            />
                                                          </audio>
                                                        )) ||
                                                          eachReply.text}
                                                      </div>
                                                      {/*<button
                                                        style={{
                                                          display: "flex",
                                                          marginTop: "5px",
                                                        }}
                                                        onClick={() => {}}
                                                      >
                                                        {eachReply.likers.includes(
                                                          userId
                                                        ) ? (
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 21"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                              fill="#FD7972"
                                                            />
                                                          </svg>
                                                        ) : (
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 21"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                              fill="#151517"
                                                              stroke="white"
                                                              stroke-width="1"
                                                            />
                                                          </svg>
                                                        )}
                                                        {eachReply.likers
                                                          .length > 0 && (
                                                          <div className="count">
                                                            {
                                                              eachReply.likers
                                                                .length
                                                            }
                                                          </div>
                                                        )}
                                                          </button>*/}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        {post.comments.length > 2 && (
                          <button
                            style={{
                              position: "relative",
                              bottom: "0",
                              marginTop: "16px",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setCommentPost(post);
                              setShowAllComments(!showAllComments);
                            }}
                          >
                            View all {post.comments.length} comments
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="inner-day" style={{ marginTop: "70px" }}>
                  Older
                </div>
                <div className="post-container3">
                  {posts.older.map((post, index) => (
                    <div key={post._id} className="post-item">
                      <div
                        className={`post ${
                          index % 2 === 1 ? "raised-post3" : ""
                        }`}
                      >
                        <div className="mk-flex">
                          <div className="usertimeline">
                            <div className="name-spot">
                              {post.creatorsName
                                ? post.creatorsName[0].toUpperCase()
                                : "Z"}
                            </div>
                          </div>
                          <div className="top-id">
                            <div className="authors-name">
                              {post.creatorsName
                                ? post.creatorsName
                                : "Testing User"}
                            </div>
                            <div className="post-date">
                              {formatDateToAbbreviatedMonth(post.createdDate)}
                            </div>
                          </div>
                        </div>
                        <div
                          className={index % 2 === 1 ? "circle3" : "square3"}
                        ></div>
                        {post.video ? (
                          <video src={post.video} controls alt="post" />
                        ) : post.image ? (
                          <img src={post.image} alt="post" />
                        ) : post.pdf ? (
                          <iframe src={post.pdf} title="post" />
                        ) : (
                          post.audio && (
                            <div className="post-audio-div">
                              <audio
                                style={{
                                  position: "relative",
                                  width: "140px",
                                  height: "30px",
                                  margin: "10px 0px 20px  0px",
                                  display: "none",
                                }}
                                ref={audioRef}
                                controls
                                src={post.audio}
                              />

                              <svg
                                onClick={() => {
                                  audioRef.current.currentTime -= 2;
                                }}
                                className="rewind"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M6.09545 0.579545L1.19659 5.71136C1.16377 5.74568 1.13724 5.7895 1.11899 5.8395C1.10074 5.88951 1.09126 5.94439 1.09126 6C1.09126 6.05561 1.10074 6.11049 1.11899 6.1605C1.13724 6.2105 1.16377 6.25432 1.19659 6.28864L6.09545 11.4205C6.27784 11.6114 6.54545 11.4386 6.54545 11.1318V0.868182C6.54545 0.561364 6.27784 0.388636 6.09545 0.579545ZM11.55 0.579545L6.65114 5.71136C6.61832 5.74568 6.59178 5.7895 6.57353 5.8395C6.55528 5.88951 6.5458 5.94439 6.5458 6C6.5458 6.05561 6.55528 6.11049 6.57353 6.1605C6.59178 6.2105 6.61832 6.25432 6.65114 6.28864L11.55 11.4205C11.7324 11.6114 12 11.4386 12 11.1318V0.868182C12 0.561364 11.7324 0.388636 11.55 0.579545ZM0.981818 0H0.109091C0.0494317 0 0 0.0613636 0 0.136364V11.8636C0 11.9386 0.0494317 12 0.109091 12H0.981818C1.04148 12 1.09091 11.9386 1.09091 11.8636V0.136364C1.09091 0.0613636 1.04148 0 0.981818 0Z"
                                  fill="#E8E8E8"
                                />
                              </svg>

                              {notPlaying ||
                              (notPlaying === false &&
                                audioRef.current.src !== post.audio) ? (
                                <svg
                                  onClick={async () => {
                                    await setNotPlaying(true);
                                    if (audioRef.current.src !== post.audio) {
                                      audioRef.current.src = post.audio;
                                    } // Set the audio source
                                    if (audioRef.current.paused) {
                                      if (audioRef.current.src === post.audio) {
                                        await setNotPlaying(false);
                                        await audioRef.current.play();
                                        console.log(audioRef.current.src);
                                      }
                                    } else {
                                      await audioRef.current.pause();
                                      await setNotPlaying(true);
                                    }
                                  }}
                                  className="play-svg"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM6.79 5.093C6.71524 5.03977 6.62726 5.00814 6.53572 5.00159C6.44418 4.99503 6.35259 5.01379 6.27101 5.05583C6.18942 5.09786 6.12098 5.16154 6.07317 5.23988C6.02537 5.31823 6.00006 5.40822 6 5.5V10.5C6.00006 10.5918 6.02537 10.6818 6.07317 10.7601C6.12098 10.8385 6.18942 10.9021 6.27101 10.9442C6.35259 10.9862 6.44418 11.005 6.53572 10.9984C6.62726 10.9919 6.71524 10.9602 6.79 10.907L10.29 8.407C10.3548 8.36075 10.4076 8.29968 10.4441 8.22889C10.4805 8.1581 10.4996 8.07963 10.4996 8C10.4996 7.92037 10.4805 7.8419 10.4441 7.77111C10.4076 7.70031 10.3548 7.63925 10.29 7.593L6.79 5.093Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                notPlaying === false &&
                                audioRef.current.src === post.audio && (
                                  <svg
                                    onClick={async () => {
                                      if (audioRef.current.paused) {
                                        audioRef.current.play();
                                        setNotPlaying(false);
                                      } else {
                                        audioRef.current.pause();
                                        setNotPlaying(true);
                                      }
                                    }}
                                    className="pause-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="57"
                                    height="57"
                                    viewBox="0 0 57 57"
                                    fill="none"
                                  >
                                    <g clip-path="url(#clip0_351_61455)">
                                      <path
                                        d="M57 28.5C57 36.0587 53.9973 43.3078 48.6525 48.6525C43.3078 53.9973 36.0587 57 28.5 57C20.9413 57 13.6922 53.9973 8.34746 48.6525C3.00267 43.3078 0 36.0587 0 28.5C0 20.9413 3.00267 13.6922 8.34746 8.34746C13.6922 3.00267 20.9413 0 28.5 0C36.0587 0 43.3078 3.00267 48.6525 8.34746C53.9973 13.6922 57 20.9413 57 28.5ZM22.2656 17.8125C19.8075 17.8125 17.8125 19.8075 17.8125 22.2656V34.7344C17.8125 35.9154 18.2817 37.0481 19.1168 37.8832C19.9519 38.7183 21.0846 39.1875 22.2656 39.1875C23.4467 39.1875 24.5793 38.7183 25.4145 37.8832C26.2496 37.0481 26.7188 35.9154 26.7188 34.7344V22.2656C26.7188 19.8075 24.7237 17.8125 22.2656 17.8125ZM34.7344 17.8125C32.2762 17.8125 30.2812 19.8075 30.2812 22.2656V34.7344C30.2812 35.9154 30.7504 37.0481 31.5855 37.8832C32.4207 38.7183 33.5533 39.1875 34.7344 39.1875C35.9154 39.1875 37.0481 38.7183 37.8832 37.8832C38.7183 37.0481 39.1875 35.9154 39.1875 34.7344V22.2656C39.1875 19.8075 37.1925 17.8125 34.7344 17.8125Z"
                                        fill="#FFF"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_351_61455">
                                        <rect
                                          width="57"
                                          height="57"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                )
                              )}

                              <svg
                                onClick={() => {
                                  audioRef.current.currentTime += 2;
                                }}
                                className="fastfoward"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  d="M10.8034 5.71136L5.90455 0.579545C5.72216 0.388636 5.45455 0.561364 5.45455 0.868182V11.1318C5.45455 11.4386 5.72216 11.6114 5.90455 11.4205L10.8034 6.28864C10.8362 6.25432 10.8628 6.2105 10.881 6.1605C10.8993 6.11049 10.9087 6.05561 10.9087 6C10.9087 5.94439 10.8993 5.88951 10.881 5.8395C10.8628 5.7895 10.8362 5.74568 10.8034 5.71136ZM5.34886 5.71136L0.45 0.579545C0.267614 0.388636 0 0.561364 0 0.868182V11.1295C0 11.4364 0.267614 11.6091 0.45 11.4182L5.34886 6.28636C5.41875 6.21364 5.45455 6.10455 5.45455 5.99773C5.45455 5.89318 5.41875 5.78409 5.34886 5.71136ZM11.8909 0H11.0182C10.9585 0 10.9091 0.0613636 10.9091 0.136364V11.8636C10.9091 11.9386 10.9585 12 11.0182 12H11.8909C11.9506 12 12 11.9386 12 11.8636V0.136364C12 0.0613636 11.9506 0 11.8909 0Z"
                                  fill="#E8E8E8"
                                />
                              </svg>
                            </div>
                          )
                        )}
                        <p>{post.caption}</p>
                        <div style={{ display: "flex" }}>
                          <div
                            className="likes-and-count"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <button onClick={() => handleLikePost(post._id)}>
                              {post.likers.includes(userId) ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                    fill="#FD7972"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                    fill="#151517"
                                    stroke="white"
                                    stroke-width="1"
                                  />
                                </svg>
                              )}
                            </button>
                            {post.likers.length > 0 && (
                              <div className="count">{post.likers.length}</div>
                            )}
                          </div>
                          <div
                            onClick={() => {
                              setCommentPost(post);
                              setShowAllComments(!showAllComments);
                            }}
                            className="comments-and-count"
                          >
                            {post.comments.length > 0 && (
                              <svg
                                style={{
                                  marginBottom: "3px",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="18"
                                viewBox="0 0 20 21"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.75225 6.25225C3.91376 6.09074 4.13281 6 4.36122 6H15.6398C15.8682 6 16.0872 6.09074 16.2488 6.25225C16.4103 6.41376 16.501 6.63281 16.501 6.86122V14.9173C16.501 15.1457 16.4103 15.3648 16.2488 15.5263C16.0872 15.6878 15.8682 15.7786 15.6398 15.7786H5.97245C5.77353 15.7786 5.58277 15.8576 5.44212 15.9982L3.5 17.9403V6.86122C3.5 6.63281 3.59074 6.41376 3.75225 6.25225ZM4.36122 4.5C3.73499 4.5 3.1344 4.74877 2.69159 5.19159C2.24877 5.6344 2 6.23499 2 6.86122V19.751C2 20.0544 2.18273 20.3278 2.46299 20.4439C2.74324 20.56 3.06583 20.4958 3.28033 20.2813L6.28311 17.2786H15.6398C16.266 17.2786 16.8666 17.0298 17.3094 16.587C17.7522 16.1442 18.001 15.5436 18.001 14.9173V6.86122C18.001 6.23499 17.7522 5.6344 17.3094 5.19159C16.8666 4.74877 16.266 4.5 15.6398 4.5H4.36122Z"
                                  fill="white"
                                />
                              </svg>
                            )}
                            {post.comments.length > 0 && (
                              <div className="count">
                                {post.comments.length}
                              </div>
                            )}
                          </div>
                        </div>
                        {isRecordingId !== post._id &&
                          isPreviewingId !== post._id && (
                            <div>
                              {console.log(post._id, "verify post")}
                              <div>
                                <textarea
                                  className="caption-area"
                                  id={`story-${post._id}`}
                                  name={`story-${post._id}`}
                                  rows="4"
                                  cols="33"
                                  placeholder="Write Something..."
                                  value={comment[post._id] || ""}
                                  onClick={() => {
                                    if (!comment[post._id]) {
                                      handleCommentChange(post._id, ""); // Initialize comment state if not exists
                                    }
                                  }}
                                  onChange={(e) =>
                                    handleCommentChange(
                                      post._id,
                                      e.target.value
                                    )
                                  }
                                  maxLength="250"
                                ></textarea>
                                <button
                                  onClick={() => handleCommentPost(post._id)}
                                  className="comment-btn"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                  >
                                    <path
                                      d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                      fill="#3B3B3B"
                                    />
                                  </svg>
                                </button>
                                <div
                                  style={{
                                    position: "absolute",
                                    backgroundColor: "#333333",
                                    marginLeft: "8px",
                                    borderRadius: "5px",
                                    display: "flex",
                                    width: "38px",
                                    height: "38px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "-90px",
                                    zIndex: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setEmojiPostId(post._id);
                                    setShowPicker((val) => !val);
                                  }}
                                >
                                  <BsEmojiSmile className="smiley1" />
                                </div>
                              </div>
                              {emojiPostId === post._id && showPicker && (
                                <div
                                  className="emoji-picker-container"
                                  ref={pickerRef}
                                >
                                  <Picker onEmojiClick={onEmojiClick2} />
                                </div>
                              )}
                              <div
                                onClick={() => {
                                  setIsRecordingId(post._id);
                                  startRecording();
                                }}
                                className="mic-chat"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="22"
                                  viewBox="0 0 16 22"
                                  fill="none"
                                >
                                  <path
                                    d="M16 9.85204C16 9.74653 15.9127 9.6602 15.8061 9.6602H14.3515C14.2448 9.6602 14.1576 9.74653 14.1576 9.85204C14.1576 13.2164 11.4012 15.9429 8 15.9429C4.59879 15.9429 1.84242 13.2164 1.84242 9.85204C1.84242 9.74653 1.75515 9.6602 1.64848 9.6602H0.193939C0.0872727 9.6602 0 9.74653 0 9.85204C0 13.8974 3.06909 17.2354 7.0303 17.7078V20.1633H3.50788C3.17576 20.1633 2.90909 20.5062 2.90909 20.9306V21.7939C2.90909 21.8994 2.97697 21.9857 3.05939 21.9857H12.9406C13.023 21.9857 13.0909 21.8994 13.0909 21.7939V20.9306C13.0909 20.5062 12.8242 20.1633 12.4921 20.1633H8.87273V17.7197C12.88 17.2881 16 13.931 16 9.85204ZM8 13.9286C10.2764 13.9286 12.1212 12.1253 12.1212 9.9V4.52857C12.1212 2.30327 10.2764 0.5 8 0.5C5.72364 0.5 3.87879 2.30327 3.87879 4.52857V9.9C3.87879 12.1253 5.72364 13.9286 8 13.9286ZM5.72121 4.52857C5.72121 3.3152 6.73697 2.32245 8 2.32245C9.26303 2.32245 10.2788 3.3152 10.2788 4.52857V9.9C10.2788 11.1134 9.26303 12.1061 8 12.1061C6.73697 12.1061 5.72121 11.1134 5.72121 9.9V4.52857Z"
                                    fill="#EEB9F8"
                                  />
                                </svg>
                              </div>
                            </div>
                          )}

                        {isRecording && isRecordingId === post._id && (
                          <div className="mic-utils">
                            <div style={{ marginLeft: "20px" }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="22"
                                viewBox="0 0 16 22"
                                fill="none"
                              >
                                <path
                                  d="M16 9.85204C16 9.74653 15.9127 9.66020 15.8061 9.66020H14.3515C14.2448 9.66020 14.1576 9.74653 14.1576 9.85204C14.1576 13.2164 11.4012 15.9429 8 15.9429C4.59879 15.9429 1.84242 13.2164 1.84242 9.85204C1.84242 9.74653 1.75515 9.66020 1.64848 9.66020H0.193939C0.0872727 9.66020 0 9.74653 0 9.85204C0 13.8974 3.06909 17.2354 7.0303 17.7078V20.1633H3.50788C3.17576 20.1633 2.90909 20.5062 2.90909 20.9306V21.7939C2.90909 21.8994 2.97697 21.9857 3.05939 21.9857H12.9406C13.0230 21.9857 13.0909 21.8994 13.0909 21.7939V20.9306C13.0909 20.5062 12.8242 20.1633 12.4921 20.1633H8.87273V17.7197C12.88 17.2881 16 13.9310 16 9.85204ZM8 13.9286C10.2764 13.9286 12.1212 12.1253 12.1212 9.9V4.52857C12.1212 2.30327 10.2764 0.5 8 0.5C5.72364 0.5 3.87879 2.30327 3.87879 4.52857V9.9C3.87879 12.1253 5.72364 13.9286 8 13.9286ZM5.72121 4.52857C5.72121 3.31520 6.73697 2.32245 8 2.32245C9.26303 2.32245 10.2788 3.31520 10.2788 4.52857V9.9C10.2788 11.1134 9.26303 12.1061 8 12.1061C6.73697 12.1061 5.72121 11.1134 5.72121 9.9V4.52857Z"
                                  fill="red"
                                />
                              </svg>
                            </div>

                            <div
                              style={{
                                fontSize: "17px",
                                marginLeft: "17px",
                                width: "46px",
                              }}
                            >
                              {timer}s
                            </div>
                            <button
                              onClick={async () => {
                                await setIsRecordingId(null);
                                await cancelRecording();
                              }}
                              className="cancel-rec"
                            >
                              Cancel
                            </button>

                            <div
                              onClick={async () => {
                                await setMicPostId(post._id);
                                await setIsRecordingId(null);
                                await setIsPreviewingId(post._id);
                                await stopRecording();
                              }}
                              className="stop-rec"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="38"
                                height="38"
                                viewBox="0 0 48 48"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_351_61603)">
                                  <path
                                    d="M48 24C48 30.3652 45.4714 36.4697 40.9706 40.9706C36.4697 45.4714 30.3652 48 24 48C17.6348 48 11.5303 45.4714 7.02944 40.9706C2.52856 36.4697 0 30.3652 0 24C0 17.6348 2.52856 11.5303 7.02944 7.02944C11.5303 2.52856 17.6348 0 24 0C30.3652 0 36.4697 2.52856 40.9706 7.02944C45.4714 11.5303 48 17.6348 48 24ZM19.5 15C18.3065 15 17.1619 15.4741 16.318 16.318C15.4741 17.1619 15 18.3065 15 19.5V28.5C15 29.6935 15.4741 30.8381 16.318 31.682C17.1619 32.5259 18.3065 33 19.5 33H28.5C29.6935 33 30.8381 32.5259 31.682 31.682C32.5259 30.8381 33 29.6935 33 28.5V19.5C33 18.3065 32.5259 17.1619 31.682 16.318C30.8381 15.4741 29.6935 15 28.5 15H19.5Z"
                                    fill="#edb9f8"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_351_61603">
                                    <rect width="48" height="48" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        )}

                        {previewVn && isPreviewingId === post._id && (
                          <div className="mic-utils">
                            <div
                              onClick={async () => {
                                await setMicPostId(post._id);
                                await setIsPreviewingId(null);
                                await cancelRecording();
                              }}
                              style={{ marginLeft: "20px", cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.04999 3.70503C8.18127 3.57375 8.35932 3.5 8.54497 3.5H11.445C11.6306 3.5 11.8087 3.57375 11.9399 3.70503C12.0712 3.8363 12.145 4.01435 12.145 4.2V4.9L7.84497 4.89999V4.2C7.84497 4.01435 7.91872 3.8363 8.04999 3.70503ZM6.34497 4.89999V4.2C6.34497 3.61652 6.57675 3.05694 6.98933 2.64437C7.40191 2.23179 7.96149 2 8.54497 2H11.445C12.0284 2 12.588 2.23179 13.0006 2.64437C13.4132 3.05694 13.645 3.61652 13.645 4.2V4.9L16.52 4.89999C16.9342 4.89999 17.27 5.23578 17.27 5.64999C17.27 6.06421 16.9342 6.39999 16.52 6.39999H15.82V15.8C15.82 16.3835 15.5882 16.9431 15.1756 17.3556C14.763 17.7682 14.2034 18 13.62 18H6.36997C5.78649 18 5.22691 17.7682 4.81433 17.3556C4.40175 16.9431 4.16997 16.3835 4.16997 15.8V6.39999H3.46997C3.05576 6.39999 2.71997 6.06421 2.71997 5.64999C2.71997 5.23578 3.05576 4.89999 3.46997 4.89999H6.34497ZM5.66997 15.8V6.4H14.32V15.8C14.32 15.9857 14.2462 16.1637 14.1149 16.295C13.9837 16.4263 13.8056 16.5 13.62 16.5H6.36997C6.18432 16.5 6.00627 16.4263 5.87499 16.295C5.74372 16.1637 5.66997 15.9857 5.66997 15.8ZM8.54498 8.52502C8.9592 8.52502 9.29498 8.86081 9.29498 9.27502V13.625C9.29498 14.0392 8.9592 14.375 8.54498 14.375C8.13077 14.375 7.79498 14.0392 7.79498 13.625V9.27502C7.79498 8.86081 8.13077 8.52502 8.54498 8.52502ZM12.1949 9.27502C12.1949 8.86081 11.8592 8.52502 11.4449 8.52502C11.0307 8.52502 10.6949 8.86081 10.6949 9.27502V13.625C10.6949 14.0392 11.0307 14.375 11.4449 14.375C11.8592 14.375 12.1949 14.0392 12.1949 13.625V9.27502Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <audio
                              style={{
                                position: "relative",
                                width: "200px",
                                height: "25px",
                                marginLeft: "70px",
                              }}
                              controls
                              src={URL.createObjectURL(previewVn)}
                            />
                            <button
                              onClick={() => {
                                setMicPostId(post._id);
                                setIsSendingId(post._id);
                                setIsPreviewingId(null);
                                setSendRecordBtn(true);
                              }}
                              className="comment-btn2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                  fill="#3B3B3B"
                                />
                              </svg>
                            </button>
                          </div>
                        )}

                        {isSending && isSendingId === post._id && (
                          <LinearProgress
                            sx={{
                              backgroundColor: "#ffb74d",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#ba68c8",
                              },
                            }}
                            style={{
                              width: "300px",
                              height: "10px",
                              marginTop: "-20px",
                            }}
                          />
                        )}

                        {post.comments && (
                          <div style={{ marginTop: "50px" }}>
                            {post.comments
                              .slice(-2)
                              .reverse()
                              .map((comment) => (
                                <div
                                  key={comment._id}
                                  className="mk-flex"
                                  style={{ marginBottom: "20px" }}
                                >
                                  <div
                                    className="usertimeline"
                                    style={{ marginTop: "4px" }}
                                  >
                                    <div className="name-spot">
                                      {comment.commentorsName
                                        ? comment.commentorsName[0].toUpperCase()
                                        : "Z"}
                                    </div>
                                  </div>
                                  <div className="top-id">
                                    <div style={{ display: "flex" }}>
                                      <div className="authors-name2">
                                        {comment.commentorsName
                                          ? comment.commentorsName
                                          : "Testing User"}
                                      </div>
                                      <div className="timer">
                                        {getTimeAgo(comment.createdDate)}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div className="comment-text">
                                        {(comment.audio && (
                                          <audio
                                            controls
                                            style={{
                                              color: "#edb9f8",
                                              height: "26px",
                                              width: "230px",
                                              marginTop: "4px",
                                              marginBottom: "6px",
                                            }}
                                          >
                                            <source
                                              src={comment.audio}
                                              type="audio/mp3"
                                            />
                                          </audio>
                                        )) ||
                                          comment.text}
                                      </div>
                                      <button
                                        style={{
                                          display: "flex",
                                          marginTop: "5px",
                                        }}
                                        onClick={() => {
                                          handleLikeComment(
                                            post._id,
                                            comment._id
                                          );
                                        }}
                                      >
                                        {comment.likers.includes(userId) ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                              fill="#FD7972"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                          >
                                            <path
                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                              fill="#151517"
                                              stroke="white"
                                              stroke-width="1"
                                            />
                                          </svg>
                                        )}
                                        {comment.likers.length > 0 && (
                                          <div className="count">
                                            {comment.likers.length}
                                          </div>
                                        )}
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <button
                                        className="toggle-reply"
                                        onClick={() =>
                                          toggleReplyTextarea(comment._id)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="25"
                                          height="25"
                                          viewBox="0 0 20 21"
                                          fill="none"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7.68621 3.72413C7.91455 3.50221 8.25537 3.43843 8.54757 3.56275C8.83937 3.68705 9.03103 3.97391 9.03103 4.29282V6.71481C14.0189 6.96189 18 11.0967 18 16.1447C18 16.5069 17.7548 16.8231 17.4038 16.913C17.3365 16.9303 17.2705 16.9375 17.2072 16.9375C16.9218 16.9375 16.6534 16.7832 16.512 16.5252L16.499 16.5016C15.1171 14.0058 12.4836 12.453 9.62715 12.453H9.03103V14.8634C9.03103 15.1823 8.8395 15.4691 8.5477 15.5934L8.54669 15.5938C8.25705 15.716 7.91516 15.6563 7.6857 15.4316L2.24142 10.1474C2.08689 9.99801 2 9.79235 2 9.5781C2 9.36386 2.08656 9.15852 2.24109 9.00911L7.68621 3.72413ZM3.93117 9.5781L7.44544 12.9892V11.6602C7.44544 11.2225 7.80053 10.8674 8.23824 10.8674H9.62715C11.9492 10.8674 14.1485 11.7209 15.8431 13.2023C14.6761 10.3237 11.8498 8.28881 8.55856 8.28881H8.23824C7.80053 8.28881 7.44544 7.93373 7.44544 7.49602V6.16705L3.93117 9.5781Z"
                                            fill="white"
                                          />
                                        </svg>
                                      </button>
                                      {showReplyTextarea === comment._id && (
                                        <div style={{ marginBottom: "15px" }}>
                                          <textarea
                                            className="reply-caption-area"
                                            value={reply[comment._id] || ""}
                                            onChange={(e) =>
                                              handleReplyChange(
                                                comment._id,
                                                e.target.value
                                              )
                                            }
                                            maxLength="200"
                                          ></textarea>
                                          <button
                                            className="reply-comment-button"
                                            onClick={() =>
                                              handleReplyPost(
                                                post._id,
                                                comment._id
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                                fill="#3B3B3B"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                      )}

                                      {comment.replies.length > 0 && (
                                        <div
                                          className="reply-count"
                                          onClick={() => {
                                            setCommentReplyPost(comment);
                                            setShowAllCommentReplies(
                                              !showAllCommentReplies
                                            );
                                          }}
                                        >
                                          View replies ({comment.replies.length}
                                          )
                                        </div>
                                      )}

                                      {commentReplyPost === comment &&
                                        showAllCommentReplies === true && (
                                          <div>
                                            {comment.replies
                                              .reverse()
                                              .map((eachReply) => (
                                                <div
                                                  key={eachReply._id}
                                                  className="reply-mk-flex"
                                                  style={{
                                                    marginBottom: "20px",
                                                  }}
                                                >
                                                  <div
                                                    className="usertimeline"
                                                    style={{ marginTop: "4px" }}
                                                  >
                                                    <div className="name-spot">
                                                      {eachReply.repliersName
                                                        ? eachReply.repliersName[0].toUpperCase()
                                                        : "Z"}
                                                    </div>
                                                  </div>
                                                  <div className="top-id">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <div className="authors-name2">
                                                        {eachReply.repliersName
                                                          ? eachReply.repliersName
                                                          : "Testing User"}
                                                      </div>
                                                      <div className="timer">
                                                        {getTimeAgo(
                                                          eachReply.createdDate
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <div className="reply-text">
                                                        {(eachReply.audio && (
                                                          <audio
                                                            controls
                                                            style={{
                                                              color: "#edb9f8",
                                                              height: "26px",
                                                              width: "230px",
                                                              marginTop: "4px",
                                                              marginBottom:
                                                                "6px",
                                                            }}
                                                          >
                                                            <source
                                                              src={
                                                                eachReply.audio
                                                              }
                                                              type="audio/mp3"
                                                            />
                                                          </audio>
                                                        )) ||
                                                          eachReply.text}
                                                      </div>
                                                      {/*<button
                                                        style={{
                                                          display: "flex",
                                                          marginTop: "5px",
                                                        }}
                                                        onClick={() => {}}
                                                      >
                                                        {eachReply.likers.includes(
                                                          userId
                                                        ) ? (
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 21"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                              fill="#FD7972"
                                                            />
                                                          </svg>
                                                        ) : (
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 21"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                              fill="#151517"
                                                              stroke="white"
                                                              stroke-width="1"
                                                            />
                                                          </svg>
                                                        )}
                                                        {eachReply.likers
                                                          .length > 0 && (
                                                          <div className="count">
                                                            {
                                                              eachReply.likers
                                                                .length
                                                            }
                                                          </div>
                                                        )}
                                                          </button>*/}
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                        {post.comments.length > 2 && (
                          <button
                            style={{
                              position: "relative",
                              bottom: "0",
                              marginTop: "16px",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setCommentPost(post);
                              setShowAllComments(!showAllComments);
                            }}
                          >
                            View all {post.comments.length} comments
                          </button>
                        )}
                      </div>
                    </div>
                  ))}

                  {showAllComments && (
                    <div className="comments-overlay">
                      <div className="comments-container">
                        {console.log(commentPost, "commentPost is this")}
                        {commentPost.comments.map((comment) => (
                          <div key={comment._id} className="comments-mk-flex">
                            <div className="comments-usertimeline">
                              <div className="name-spot">
                                {comment.commentorsName
                                  ? comment.commentorsName[0].toUpperCase()
                                  : "Z"}
                              </div>
                            </div>
                            <div className="top-id">
                              <div style={{ display: "flex" }}>
                                <div className="authors-name2">
                                  {comment.commentorsName
                                    ? comment.commentorsName
                                    : "Testing User"}
                                </div>
                                <div className="timer">
                                  {getTimeAgo(comment.createdDate)}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className="comment-text"
                                  style={{ width: "280px" }}
                                >
                                  {(comment.audio && (
                                    <audio
                                      controls
                                      style={{
                                        color: "#edb9f8",
                                        height: "26px",
                                        width: "230px",
                                        marginTop: "4px",
                                        marginBottom: "6px",
                                      }}
                                    >
                                      <source
                                        src={comment.audio}
                                        type="audio/mp3"
                                      />
                                    </audio>
                                  )) ||
                                    comment.text}
                                </div>
                                <button
                                  style={{ display: "flex", marginTop: "5px" }}
                                  onClick={() => {
                                    handleLikeComment(
                                      commentPost._id,
                                      comment._id
                                    );
                                    console.log(comment.likers);
                                  }}
                                >
                                  {comment.likers.includes(userId) ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 21"
                                      fill="none"
                                    >
                                      <path
                                        d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                        fill="#FD7972"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 21"
                                      fill="none"
                                    >
                                      <path
                                        d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                        fill="#151517"
                                        stroke="white"
                                        stroke-width="1"
                                      />
                                    </svg>
                                  )}
                                  {comment.likers.length > 0 && (
                                    <div className="count">
                                      {comment.likers.length}
                                    </div>
                                  )}
                                </button>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <button
                                  className="toggle-reply"
                                  onClick={() =>
                                    toggleReplyTextarea(comment._id)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.68621 3.72413C7.91455 3.50221 8.25537 3.43843 8.54757 3.56275C8.83937 3.68705 9.03103 3.97391 9.03103 4.29282V6.71481C14.0189 6.96189 18 11.0967 18 16.1447C18 16.5069 17.7548 16.8231 17.4038 16.913C17.3365 16.9303 17.2705 16.9375 17.2072 16.9375C16.9218 16.9375 16.6534 16.7832 16.512 16.5252L16.499 16.5016C15.1171 14.0058 12.4836 12.453 9.62715 12.453H9.03103V14.8634C9.03103 15.1823 8.8395 15.4691 8.5477 15.5934L8.54669 15.5938C8.25705 15.716 7.91516 15.6563 7.6857 15.4316L2.24142 10.1474C2.08689 9.99801 2 9.79235 2 9.5781C2 9.36386 2.08656 9.15852 2.24109 9.00911L7.68621 3.72413ZM3.93117 9.5781L7.44544 12.9892V11.6602C7.44544 11.2225 7.80053 10.8674 8.23824 10.8674H9.62715C11.9492 10.8674 14.1485 11.7209 15.8431 13.2023C14.6761 10.3237 11.8498 8.28881 8.55856 8.28881H8.23824C7.80053 8.28881 7.44544 7.93373 7.44544 7.49602V6.16705L3.93117 9.5781Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>

                                {showReplyTextarea === comment._id && (
                                  <div style={{ marginBottom: "15px" }}>
                                    <textarea
                                      style={{ width: "200px" }}
                                      className="reply-caption-area"
                                      value={reply[comment._id] || ""}
                                      onChange={(e) =>
                                        handleReplyChange(
                                          comment._id,
                                          e.target.value
                                        )
                                      }
                                      maxLength="200"
                                    ></textarea>
                                    <button
                                      style={{ left: "200px" }}
                                      className="reply-comment-button"
                                      onClick={() =>
                                        handleReplyPost(
                                          commentPost._id,
                                          comment._id
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M15.862 2.12108L2.95415 7.45832C2.41034 7.68315 2.04632 8.1875 2.00407 8.77441C1.96187 9.36137 2.24997 9.91261 2.75597 10.213L6.45534 12.2884C7.02155 11.7222 11.3904 8.60964 11.3904 8.60964C11.3904 8.60964 8.27785 12.9785 7.71165 13.5447L9.78701 17.244C10.0874 17.75 10.6386 18.0382 11.2256 17.9959C11.8137 17.9536 12.3172 17.5886 12.5417 17.0458L17.8789 4.13795C18.1219 3.55026 17.9935 2.90584 17.5438 2.45616C17.0942 2.00647 16.4497 1.87803 15.862 2.12108Z"
                                          fill="#3B3B3B"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                )}

                                {comment.replies.length > 0 && (
                                  <div
                                    className="reply-count"
                                    onClick={() => {
                                      setCommentReplyPost(comment);
                                      setShowAllCommentReplies(
                                        !showAllCommentReplies
                                      );
                                    }}
                                  >
                                    View replies ({comment.replies.length})
                                  </div>
                                )}

                                {commentReplyPost === comment &&
                                  showAllCommentReplies === true && (
                                    <div>
                                      {comment.replies
                                        .reverse()
                                        .map((eachReply) => (
                                          <div
                                            key={eachReply._id}
                                            className="reply-mk-flex"
                                            style={{
                                              marginBottom: "20px",
                                            }}
                                          >
                                            <div
                                              className="usertimeline"
                                              style={{ marginTop: "4px" }}
                                            >
                                              <div className="name-spot">
                                                {eachReply.repliersName
                                                  ? eachReply.repliersName[0].toUpperCase()
                                                  : "Z"}
                                              </div>
                                            </div>
                                            <div className="top-id">
                                              <div
                                                style={{
                                                  display: "flex",
                                                }}
                                              >
                                                <div className="authors-name2">
                                                  {eachReply.repliersName
                                                    ? eachReply.repliersName
                                                    : "Testing User"}
                                                </div>
                                                <div className="timer">
                                                  {getTimeAgo(
                                                    eachReply.createdDate
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div className="reply-text">
                                                  {(eachReply.audio && (
                                                    <audio
                                                      controls
                                                      style={{
                                                        color: "#edb9f8",
                                                        height: "26px",
                                                        width: "230px",
                                                        marginTop: "4px",
                                                        marginBottom: "6px",
                                                      }}
                                                    >
                                                      <source
                                                        src={eachReply.audio}
                                                        type="audio/mp3"
                                                      />
                                                    </audio>
                                                  )) ||
                                                    eachReply.text}
                                                </div>
                                                {/*<button
                                                  style={{
                                                    display: "flex",
                                                    marginTop: "5px",
                                                  }}
                                                  onClick={() => {}}
                                                >
                                                  {eachReply.likers.includes(
                                                    userId
                                                  ) ? (
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="20"
                                                      height="20"
                                                      viewBox="0 0 20 21"
                                                      fill="none"
                                                    >
                                                      <path
                                                        d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                        fill="#FD7972"
                                                      />
                                                    </svg>
                                                  ) : (
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="20"
                                                      height="20"
                                                      viewBox="0 0 20 21"
                                                      fill="none"
                                                    >
                                                      <path
                                                        d="M16.7663 4.7377C16.3753 4.34531 15.9111 4.03404 15.4002 3.82168C14.8893 3.60931 14.3417 3.5 13.7887 3.5C13.2357 3.5 12.6881 3.60931 12.1772 3.82168C11.6663 4.03404 11.2021 4.34531 10.8112 4.7377L9.9998 5.55166L9.18843 4.7377C8.39874 3.94548 7.32768 3.50041 6.21089 3.50041C5.09409 3.50041 4.02303 3.94548 3.23334 4.7377C2.44365 5.52993 2 6.60441 2 7.72479C2 8.84516 2.44365 9.91965 3.23334 10.7119L4.0447 11.5258L9.50403 17.0026C9.77758 17.2771 10.222 17.2771 10.4956 17.0026L15.9549 11.5258L16.7663 10.7119C17.1574 10.3197 17.4677 9.854 17.6794 9.34146C17.891 8.82893 18 8.27958 18 7.72479C18 7.17 17.891 6.62064 17.6794 6.10811C17.4677 5.59557 17.1574 5.1299 16.7663 4.7377Z"
                                                        fill="#151517"
                                                        stroke="white"
                                                        stroke-width="1"
                                                      />
                                                    </svg>
                                                  )}
                                                  {eachReply.likers.length >
                                                    0 && (
                                                    <div className="count">
                                                      {eachReply.likers.length}
                                                    </div>
                                                  )}
                                                    </button>*/}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <button
                          className="popup-btn"
                          onClick={() => setShowAllComments(!showAllComments)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TimelinePage;
