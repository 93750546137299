import { useState } from "react";
import "./AddProjectModal.css";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";
import { getAllMembers, handleFile } from "../../../util.js";
import { useEffect } from "react";

const AddProjectModal = (props) => {
  const { auth, currentOrganization, members } = useAuth();
  const [open, setOpen] = useState(false);
  const [orgMembers, setOrgMembers] = useState(null);
  // console.log('**',orgMembers)
  useEffect(() => {
    (async () => {
      const data = await getAllMembers(currentOrganization?.id);
      setOrgMembers(data);
    })();
  }, [setOrgMembers, getAllMembers]);

  const [formData, setFormData] = useState({
    coverImg: "",
    name: "",
    artistName: "",
    projectType: "none",
    description: "",
    startDate: "",
    endDate: "",
    projectMembers: "",
    budget: "",
    add_file: null,
  });

  const handleFile = (event) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);

        setFormData({ ...formData, ["add_file"]: reader.result });
      };
      reader.onerror = (error) => reject(error);
    });
  };
  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "projectMembers") {
      let newMem = value;

      if (/\s/g.test(value)) {
        newMem = value.replace(/\s/g, "").split(",");
      } else {
        newMem = value.split();
      }

      setFormData({ ...formData, [name]: newMem });
      return;
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData["projectType"] === "none") {
      toast.error("Select a project type!", { duration: 3500 });
      return;
    }
    setOpen(true);

    const {
      name,
      artistName,
      projectType,
      startDate,
      endDate,
      description,
      projectMembers,
      budget,
    } = formData;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        "/project",
        JSON.stringify({
          name,
          artistName,
          projectType,
          startDate,
          endDate,
          organization: currentOrganization?.id,
          description,
          projectMembers: projectMembers !== "" ? projectMembers : [],
          budget,
        }),
        config
      );

      if (props.getProjects) {
        await props.getProjects();
      }
      if (props.rr) {
        props.rr();
      }
      props.onClose();

      setTimeout(() => {
        setOpen(false);
        setFormData({
          coverImg: "",
          name: "",
          artistName: "",
          projectType: "none",
          description: "",
          startDate: "",
          endDate: "",
          projectMembers: "",
          budget: "",
        });
      }, response);

      toast.success(`Project Created`, {
        duration: 4000,
      });
    } catch (error) {
      setOpen(false);
      console.log(error);

      if (!error.response) {
        toast.error("Check your internet connection!");
      } else {
        toast.error("Failed!");
      }
    }
  };

  return (
    <div
      className={`add__product__modal ${
        props.show ? "show project_modal" : ""
      }`}
      onClick={props.onClose}
    >
      <form
        className="add__product__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="contact__modal--content-header">
          <h4> New Project</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add__product__inputs">
          {/* Add Image input */}
          <div className="add_file_container">
            {formData.add_file ? (
              <label htmlFor="add_file" className=" cursor-pointer pb-4">
                <img
                  src={formData.add_file}
                  alt="Org image"
                  className=" object-cover h-44 w-44 rounded-md"
                />
              </label>
            ) : (
              <label htmlFor="add_file" className="add_file">
                +
              </label>
            )}
            <input
              type="file"
              accept="image/*"
              id="add_file"
              className="hidden"
              placeholder="Team Logo"
              name="add_file"
              onChange={handleFile}
            />
          </div>

          <label htmlFor="name">Project Name</label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            required
            value={formData["name"]}
            autoComplete="off"
          />

          <label htmlFor="artistName">Artist Name</label>
          <input
            type="text"
            name="artistName"
            id="artistName"
            onChange={handleChange}
            required
            autoComplete="off"
            value={formData["artistName"]}
          />

          <label htmlFor="projectType">Project Type</label>

          <select
            name="projectType"
            onChange={handleChange}
            value={formData["projectType"]}
            required
          >
            <option value="none">Select Project Type</option>

            <option value="music">Music</option>
            <option value="podcast">Podcast</option>
            <option value="audio_books">AudioBooks</option>
            <option value="other">Other</option>
          </select>

          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="4"
            onChange={handleChange}
            value={formData["description"]}
            placeholder="Add Description"
            required
          />
          <div className="project__duration">
            <div>
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                onChange={handleChange}
                value={formData["startDate"]}
                required
                autoComplete="off"
              />
            </div>
            <div>
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                min={formData["startDate"]}
                onChange={handleChange}
                value={formData["endDate"]}
                required
                autoComplete="off"
              />
            </div>
          </div>

          <label htmlFor="budget">Budget</label>
          <input
            type="number"
            name="budget"
            id="budget"
            onChange={handleChange}
            required
            min={0}
            autoComplete="off"
            value={formData["budget"]}
          />
        </div>
        <button type="submit" className="submit-tag">
          Create Project
        </button>
      </form>
    </div>
  );
};

export default AddProjectModal;
