import { useEffect, useState } from "react";
import "./UserPage.css";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import {
  BsClipboard,
  BsClipboardPlus,
  BsClipboardData,
  BsClipboardCheck,
} from "react-icons/bs";
const UserPage = () => {
  const { auth, userProjects } = useAuth();
  const [projects, setProjects] = useState([]);
  const [activity, setActivity] = useState();

  // useEffect(() => {

  //   const getDetails = async () => {
  //     try {
  //       const config = {
  //         headers: {
  //           Authorization: `Bearer ${auth?.authToken}`,
  //         },
  //       };
  //       const response = await axios.get(`/project`, config);
  //       setProjects(response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   // getActivity();
  //   getDetails();
  // }, []);

  // console.log(projects);

  return (
    <div className="user__profile">
      <div className="profile__container">
        <div className="user__header">
          <div className="user__icon">{auth.firstName?.charAt(0)}</div>

          <div className="user__name text-center">
            {auth.firstName} <span>{auth.lastName}</span>
          </div>

          <div className="user__position">{auth.role}</div>
        </div>
        <hr size="1" width="auto" color="#353535" />

        <div className="user__project__details">
          <div className="total__projects">
            <div
              style={{
                padding: "10px 12px",
                backgroundColor: "#39D1E5",
                // opacity: "0.1",
                borderRadius: "8px",
                color: "black",
              }}
            >
              <BsClipboard size={20} />
            </div>
            <div className="amount">
              {userProjects ? userProjects.length : 0}
            </div>
            <div className="project__type">Total Projects</div>
          </div>
          <div className="new__projects">
            <div
              style={{
                padding: "10px 12px",
                backgroundColor: "#FEB077",
                // opacity: "0.1",
                borderRadius: "8px",
                color: "black",
              }}
            >
              <BsClipboardPlus size={20} />
            </div>
            <div className="amount">
              {userProjects
                ? userProjects.filter(
                    (item) => item.status.toLowerCase() === "in progress"
                  ).length
                : 0}
            </div>
            <div className="project__type">New Projects</div>
          </div>
          <div className="in__progress__projects">
            <div
              style={{
                padding: "10px 12px",
                backgroundColor: "#FFDB6E",
                // opacity: "0.1",
                borderRadius: "8px",
                color: "black",
              }}
            >
              <BsClipboardData size={20} />
            </div>
            <div className="amount">
              {userProjects
                ? userProjects.filter(
                    (item) =>
                      item.status.toLowerCase() === "in_progress" ||
                      item.status.toLowerCase() === "in progress"
                  ).length
                : 0}
            </div>
            <div className="project__type">In Progress</div>
          </div>
          <div className="completed__projects">
            <div
              style={{
                padding: "10px 12px",
                backgroundColor: "#51D187",
                // opacity: "0.1",
                borderRadius: "8px",
                color: "black",
              }}
            >
              <BsClipboardCheck size={20} />
            </div>
            <div className="amount">
              {userProjects
                ? userProjects.filter(
                    (item) => item.status.toLowerCase() === "completed"
                  ).length
                : userProjects}
            </div>
            <div className="project__type">Completed</div>
          </div>
        </div>

        <hr size="1" width="auto" color="#353535" />

        <div className="user_activities">
          <div className="activity__header">Recent Activity</div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
