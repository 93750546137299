import React from "react";
import { Routes, Route } from "react-router-dom";
import Security from "./Security";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Activity from "./Activity";
import Follow from "./Follow";
import LogOut from "./LogOut";
import PlansBillingPage from "./Billing";

import SettingsLayout from "./SettingsLayout";

const Settings = () => {
  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route path="/" element={<Security />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="activity-log" element={<Activity />} />
        <Route path="follow-us" element={<Follow />} />
        <Route path="logout" element={<LogOut />} />
        <Route path="billing" element={<PlansBillingPage />} />
      </Route>
    </Routes>
  );
};

export default Settings;
