import React, { useEffect } from "react";
import "./FramePage.css";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Routes, Route, useLocation } from "react-router-dom";

// PAGE COMPONENTS
import {
  BudgetPage,
  UserProfile,
  TaskPage,
  ProjectPage,
  Layout,
  Missing,
  Header,
  AdminProfile,
  Calendar,
  Settings,
  ProjectList,
  TaskLabelView,
  ReportView,
  TimeLineView,
  Storage,
  Note,
  ChatPage,
  BudgetDetails,
  CartPage,
  Padallock,
  ChatPageApi,
  TaskListView,
} from "../../components";
import TimelinePage from "../TimelinePage/TimelinePage";
import RequireAuth from "../RequireAuth";
import Cookies from "js-cookie";

const FramePage = () => {
  const { auth, logOut } = useAuth();
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!Cookies.get("auth"))
      return navigate("/home", { state: { from: location } }); // Redirects user back to the home page, if the user hasn't signed in yet.
  }, [Cookies.get("auth")]);

  if (!auth) {
    return;
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Protected Routes */}
        <Route element={<RequireAuth />}>
          <Route path="profile-admin" element={<AdminProfile />} />
          <Route path="task_calendar" element={<Calendar />}></Route>
          <Route path="projects_list" element={<ProjectList />}></Route>
          <Route path="task_list" element={<TaskListView />}></Route>
          <Route path="view-task-label" element={<TaskLabelView />}></Route>
          <Route path="report" element={<ReportView />}></Route>
          {/* <Route path="timeline" element={<TimeLineView />}></Route> */}
          <Route path="timeline-page" element={<TimelinePage />}></Route>
          <Route path="user-profile" element={<UserProfile />}></Route>
          <Route path="storage" element={<Storage />}></Route>
          <Route path="projects" element={<ProjectPage />}></Route>
          <Route path="tasks" element={<TaskPage />}></Route>
          <Route path="budget" element={<BudgetPage />}></Route>
          <Route path="budget-details/:id" element={<BudgetDetails />}></Route>
          <Route path="chat" element={<ChatPageApi />}></Route>
          <Route path="/security" element={<Padallock />} />
          <Route path="settings/*" element={<Settings />} />
          {/* <Route path="flows" element={<Note />}></Route> */}

          {/* <Route path="chat_page" element={<ChatPage />}></Route> */}
          {/* <Route path="cart" element={<CartPage />}></Route> */}
        </Route>

        {/* Catch all (e.g: 404 pages) */}
        <Route path="*" element={<Missing />}></Route>
      </Route>
    </Routes>
  );
};

export default FramePage;
