import React, { useState } from "react";
import "./editPasswordModal.css";
import CloseIcon from "@mui/icons-material/Close";
import UpdateProfileModal from "../updateProfileModal/UpdateProfileModal";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import BackDrop from "../../BackDrop";

const EditPasswordModal = ({ handleClick, show, onClose, type }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    newpassword: "",
    confirm_pwd: "",
  });
  const [showStyle, setShowStyle] = useState(type ? "hide" : "");
  const [pStyle, setPStyle] = useState(type ? "show" : "");
  const [values, setValues] = useState({
    password: false,
    confirm: false,
  });
  const [mydisable, setMyDisable] = useState(false);
  const [nextStep, setnextStep] = useState(false);
  const [open, setOpen] = useState(false); // Handles the state of the backdrop
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const PWD_URL = "change_password/";
  const handleChange = (event) => {
    const { value, name } = event.target;
    // setFormData(formData.countryCode === ctryCode)
    if (name === "email") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const AuthStr = "Bearer ".concat(auth?.authToken);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpen(true);
    setMyDisable(true);
    // handleClick(val)
    // onClose(false)
    //setnextStep(true)
    try {
      const response = await axios.patch(
        PWD_URL,
        JSON.stringify({
          newPassword: formData["newpassword"],
          confirmPassword: formData["confirmpassword"],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          withCredentials: true,
        }
      );

      setTimeout(() => {
        setOpen(false);
      }, response);
      if (type) {
        toast.success("Reset successful, please log in", { duration: 4000 });

        navigate("/home");
      } else {
        toast.success("Password reset successful");
        setnextStep(true);
      }
    } catch (error) {
      setOpen(false);

      // onClose(false)
      if (!error?.response) {
        toast.error("Check your internet connection!");
      } else if (error.response?.status === 401) {
        toast.error("Unauthorized!");
      } else if (error.response?.status === 400) {
        toast.error("Error");
      } else {
        toast.error("Failed!");
      }
      console.log(error);
    }
  };
  const SelectedOption = (e) => {
    if (e === "yes") {
      setPStyle("show");
      setShowStyle("hide");
    } else {
      setShowStyle("keep");
      setnextStep(true);
    }
  };
  return (
    <div className={`intro__modal ${show ? "show" : ""}`} id="login=div">
      <div style={{ display: showStyle === "hide" ? "none" : "block" }}>
        <p>Please Update Your Password</p>
        <button onClick={() => SelectedOption("yes")} className="sign-in-tag">
          YES
        </button>
        <button onClick={() => SelectedOption("no")} className="sign-in-tag">
          NO
        </button>
      </div>
      <form
        style={{ display: pStyle === "show" ? "block" : "none" }}
        className="intro__modal--content"
        onSubmit={handleSubmit}
      >
        <div className="intro__modal--content-header">
          <h4>Change Password</h4>
          {/* <CloseIcon className="intro__close--icon"  onClick={onClose}  /> */}
        </div>
        {open && <BackDrop open={open} />}
        <div className="intro__inputs">
          <div className="inputarea">
            <input
              type={values.password ? "text" : "password"}
              name="newpassword"
              onChange={handleChange}
              placeholder="New Password"
              autoCorrect="off"
              required
              className="inputfield"
            />
            <div
              onClick={() =>
                setValues({ ...values, password: !values.password })
              }
              onMouseDown={handleMouseDownPassword}
              className="iconlogin"
            >
              {values.password ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          </div>

          <div className="inputarea">
            <input
              type={values.confirm ? "text" : "password"}
              name="confirmpassword"
              onChange={handleChange}
              placeholder="Confirm Password"
              autoCorrect="off"
              required
            />

            <div
              onClick={() => setValues({ ...values, confirm: !values.confirm })}
              onMouseDown={handleMouseDownPassword}
              className="iconlogin"
            >
              {values.confirm ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </div>
          </div>
        </div>

        <input
          disabled={mydisable ? true : false}
          value="submit"
          type="submit"
          className="sign-in-tag"
        ></input>
      </form>
      <UpdateProfileModal onClose={() => setnextStep(false)} show={nextStep} />
    </div>
  );
};

export default EditPasswordModal;
