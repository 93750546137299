import React, { useState } from 'react'
import Navbar from '../navbar/Navbar'
import './profilepage.css'
import avatar from '../../assets/profile-avatar.jpg'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { BsArrowRightShort } from 'react-icons/bs'
import { MdArrowForwardIos } from 'react-icons/md'
import { IoIosPerson } from 'react-icons/io'
import { BsFillCreditCard2BackFill } from 'react-icons/bs'
import { CgMore } from 'react-icons/cg'
import { Link } from 'react-router-dom'

const ProfilePage = () => {
  const [editState, setEditState] = useState({
    account: false,
    billing: false,
    card: false
  })

  const [openState, setOpenState] = useState({
    personal: true,
    subscription: false,
    additional: false
  })

  return (
    <div className='hos__profilepage'>
      <Navbar />
      <div className="hos__profile container">
        <div className="hos__profile--title">
          <h2>Account Settings</h2>
          <div className="hos__profile--navbar">
            <a href="#personal" onClick={(e) => {setOpenState({personal:true}) 
              e.preventDefault()}}><MdArrowForwardIos className='hos__navdiv' /><IoIosPerson className='profile__navicon' /> Personal Info</a>
            <a href="#subscription" onClick={(e) => {setOpenState({subscription:true})
          e.preventDefault()}}><MdArrowForwardIos className='hos__navdiv' /><BsFillCreditCard2BackFill className='profile__navicon' />Subscription</a>
            <a href="#additional" onClick={(e) => {setOpenState({additional:true})
          e.preventDefault()}}><MdArrowForwardIos className='hos__navdiv' /><CgMore className='profile__navicon' />Additional Info</a>
          </div>
        </div>

        
    <div className="hos__profile--sections">
      {/* PERSONAL DETAILS SECTION */}
        {openState.personal
         ? <div className='spacer'>
          <div className="profile__image">
          <div className="pfp">
           <img src={avatar} alt="avatar" />
           <HiOutlinePencilAlt className='update__img--icon' />
          </div>
          <div className="pfp__basic--details">
            <h4>John Doe</h4>
            <p>PREMIUM</p>
          </div>
        </div>

        <div className="profile__account profile--container" id='personal'>
          <div className="profile__account--title">
            <h3>Personal Details</h3>
            {!editState.account
            ?<a href="#" onClick={(e) => {
              setEditState({account:true})
              e.preventDefault()}}>Edit</a>
            : null
            }
            
          </div>
          < div className='profile__divider' />
          <form className='account__form'>
            <label htmlFor="fname">Name:</label><br/>
            {editState.account
              ?<input type="text" id="fname" name="fname" placeholder='John Doe'/>
              :<p>John Doe</p>
            }
            <label htmlFor="email">Email Address:</label><br/>
            {editState.account
              ?<input type="email" id="email" name="email" placeholder='thoseapp@gmail.com'/>
              :<p>thoseapp@gmail.com</p>
            }
            <label htmlFor="summary">Account Summary:</label>
            {editState.account
              ?<textarea name="summary" id="summary" cols="30" rows="5" placeholder='Account Summary'>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic, quidem veritatis odit dolor deserunt nobis beatae inventore architecto est aliquam.
              </textarea>
              :<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium doloremque earum esse consequatur ab saepe exercitationem eaque repellendus recusandae voluptate.</p>
            }          
            <label htmlFor="password">Password:</label><br/>
            {editState.account
              ?<input type="password" id="password" name="password" placeholder='**********'/>
              :<p>********</p>
            }  
          </form>
          {editState.account
           ?<button className='profile__submit--button' onClick={() => setEditState({account:false})}>Update</button>
           :null
          }
        </div>
        </div>
        : null }
        {/* END OF PERSONAL DETAILS SECTION */}


        {/* SUBSCRIPTION SECTION */}
        {openState.subscription
         ? <div className='spacer'>
        <div className="profile__subscription profile--container" id='subscription'>
          <div className="sub__plan">
            <span><h3>Subscription Plan</h3><p>PREMIUM</p></span>
            <button>Upgrade</button>
          </div>
          <div className="upgrade__subscription">
            <a className='cancel__link'>Cancel Subscription</a>
          </div>
        </div>
        {/* END OF SUBSCRIPTION SECTION */}


        {/* BILLING INFO SECTION */}
        <div className="billing__info profile--container">
              <h3>Billing Info</h3>
              <div className='billing__divider' />
              <div className="billing__info--main">
                <div className="billing__info--main-title">
                  <h3>Billing Address</h3>
                  {!editState.billing
                  ? <a href="#" onClick={(e) => {
                    setEditState({billing:true})
                    e.preventDefault()}}>Edit</a>
                  : null
                  }
                </div>
                <form className='billing__form'>
                  <label htmlFor="fname">Full Name:</label><br/>
                  {editState.billing
                    ?<input type="text" id="fname" name="fname" placeholder='John Doe'/>
                    :<p>John Doe</p>
                  }
                  <label htmlFor="addr1">Address 1:</label><br/>
                  {editState.billing
                    ?<input type="text" id="addr1" name="addr1" placeholder='Ikeja, Lagos'/>
                    :<p>Ikeja, Lagos</p>
                  }
                  <label htmlFor="addr2">Address 2:</label><br/>
                  {editState.billing
                    ?<input type="text" id="addr2" name="addr2" placeholder='Ikeja, Lagos'/>
                    :<p>Ikeja, Lagos</p>
                  }
                </form>
                {editState.billing
                  ?<button className='billing__submit--button' onClick={() => setEditState({billing:false})}>Update</button>
                  :null
                }
              </div>
              
              <div className='billing__divider' />

              <div className="billing__card--details">
              <div className="billing__card--main-title">
                  <h3>Card Info</h3>
                  {!editState.card
                  ?<a href="#" onClick={(e) => {
                    setEditState({card:true})
                    e.preventDefault()}}>Edit</a>
                  : null
                  }
                </div>
                <form className='card__form'>
                  <label htmlFor="fname">Name on Card:</label><br/>
                  {editState.card
                    ?<input type="text" id="fname" name="fname" placeholder='John Doe'/>
                    :<p>John Doe</p>
                  }
                  <label htmlFor="cardnum">Card Number:</label><br/>
                  {editState.card
                    ?<input type="text" id="cardnum" name="cardnum" placeholder='0000 0000 0000 0000'/>
                    :<p>5466 **** **** **34</p>
                  }
                  <div className="card__num--details">
                    <div>
                      <label htmlFor="cvv">CVV</label><br/>
                      {editState.card
                        ?<input type="number" id="cvv" name="cvv" placeholder='***'/>
                        :<p>999</p>
                      }
                    </div>
                    <div>
                      <label htmlFor="month">Expiration</label><br/>
                      {editState.card
                        ?<input type="month" min="1977-03" id="month" name="month" placeholder='MM'/>
                        :<p>03/24</p>
                      }
                    </div>
                  </div>
                </form>
                {editState.card
                  ?<button className='card__submit--button' onClick={() => setEditState({card:false})}>Update</button>
                  : null
                }
                
              </div>
        </div>

        </div>
        : null }
        {/* END OF BILLING SECTION */}


        {/* ADDITIONAL INFO SECTION */}
        {openState.additional
          ?
        <div className="profile__sub profile--container spacer" id='additional'>
          <div className="profile__sub--title">
            <h3>Additional Info</h3>
          </div>
          <div className='profile__divider' />
          <div className="profile__sub--details">
            <h4>Active Projects</h4><p>2</p>
            <h4>Storage Usage</h4><p>2.48/5GB</p>
            <div className="organisations">
              <h4>Organizations</h4>
              <p>THOSE</p>
              <p>Mavin Records</p>
            </div>
          </div>
        </div>
        
        : null}
        {/* END OF ADDITIONAL INFO SECTION */}
        </div>
      </div>
    </div>
  )
}

export default ProfilePage