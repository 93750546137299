import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { gapi } from "gapi-script";
import toast from "react-hot-toast";
import "./StorageContent.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import IconButton from "@mui/joy/IconButton";
import CloseRounded from "@mui/icons-material/CloseRounded";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";

const App = () => {
  const [DropboxFiles, setDropboxFiles] = useState([]);
  const [isSignedToDropbox, setisSignedToDropbox] = useState(false);
  const [dropboxAccessToken, setdropboxAccessToken] = useState(
    "sl.BnDgKngxPC_lHb-JtlEJ9MNBWFb07M6sx22mLQkyjGSLvLxT6TNtifiNH0SkOD7w5afP3hvRTC4iXsIWterxk-E2ewXi95tgXIj02nQMFUJk6iL-GF-tnVvhDfb2w7Vxkgp1Gns0cYYtRbvis5Dc"
  );

  const [storageUsage, setStorageUsage] = useState(null);
  const [storageLimit, setStorageLimit] = useState(null);

  const [DropboxDocumentFiles, setDropboxDocumentFiles] = useState([]);
  const [DropboxImageFiles, setDropboxImageFiles] = useState([]);
  const [DropboxMediaFiles, setDropboxMediaFiles] = useState([]);
  const [DropboxOtherFiles, setDropboxOtherFiles] = useState([]);
  const [DropboxUnknownFiles, setDropboxUnknownFiles] = useState([]);

  const uploadInputRef = useRef(null);

  const [value, setValue] = React.useState("1");

  const [selectedValue, setSelectedValue] = React.useState(""); // Changed 'value' to 'selectedValue'
  const action = React.useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTotalSize = (files) => {
    return files.reduce((total, file) => total + Number(file.size), 0);
  };

  const formatSize = (sizeInBytes) => {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let size = Number(sizeInBytes);
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    let formattedSize = size.toFixed(1);

    if (Number.isInteger(size)) {
      formattedSize = size.toFixed(0);
    } else if (formattedSize.endsWith("00")) {
      formattedSize = size.toFixed(0);
    }

    return `${formattedSize} ${units[unitIndex]}`;
  };

  const emptyDropboxCategorizedFiles = () => {
    setDropboxDocumentFiles([]);
    setDropboxImageFiles([]);
    setDropboxMediaFiles([]);
    setDropboxOtherFiles([]);
    setDropboxUnknownFiles([]);
  };

  const emptyGDriveCategorizedFiles = () => {
    setGDriveDocumentFiles([]);
    setGDriveImageFiles([]);
    setGDriveMediaFiles([]);
    setGDriveOtherFiles([]);
    setGDriveUnknownFiles([]);
  };

  useEffect(() => {
    if (isSignedToDropbox) {
      const dropboxStorageInfo = async () => {
        try {
          const response = await axios.post(
            "https://api.dropboxapi.com/2/users/get_space_usage",
            null,
            {
              headers: {
                Authorization: `Bearer ${dropboxAccessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            setStorageUsage(response.data.used);
            setStorageLimit(response.data.allocation.allocated);
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        } catch (error) {
          console.error(error);
        }
      };

      dropboxStorageInfo(); // Fetch storage usage and limit

      listDropboxFiles();
    }
  }, [isSignedToDropbox, dropboxAccessToken]);

  const handleConnectDropbox = async () => {
    if (isSignedToDropbox) {
      setisSignedToDropbox(false);
      await emptyDropboxCategorizedFiles();
      await setDropboxFiles([]);
      await listGDriveFiles();
    } else {
      await emptyDropboxCategorizedFiles();
      await setDropboxFiles([]);
      await localStorage.setItem("redirectURL", window.location.href);
      window.location.href = `https://www.dropbox.com/oauth2/authorize?response_type=token&client_id=178a5p7so2ht8pi&redirect_uri=https://beatlab.app/frame/storage`;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const dropboxAccessTokenParam = urlParams.get("access_token");

    if (dropboxAccessTokenParam) {
      setisSignedToDropbox(true);
      setdropboxAccessToken(dropboxAccessTokenParam);
    }
  }, []);

  const listDropboxFiles = async () => {
    try {
      const response = await axios.post(
        "https://api.dropboxapi.com/2/files/list_folder",
        {
          path: "",
        },
        {
          headers: {
            Authorization: `Bearer ${dropboxAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const fetchedFiles = response.data.entries;

        console.log(fetchedFiles);

        const fetchedDocuments = [];
        const fetchedImages = [];
        const fetchedMediaFiles = [];
        const fetchedOtherFiles = [];
        const fetchedUnknownFiles = [];
        const fetchedFilesWithLinksandDate = [];

        await Promise.all(
          fetchedFiles.map(async (file) => {
            const fileName = file.name.toLowerCase();
            const response = await axios.post(
              "https://api.dropboxapi.com/2/files/get_temporary_link",
              {
                path: file.path_display,
              },
              {
                headers: {
                  Authorization: `Bearer ${dropboxAccessToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            const fileLink = response.data.link;
            fetchedFilesWithLinksandDate.push({
              ...file,
              link: fileLink,
              server_modified: file.server_modified,
            });

            if (fileName.endsWith(".pdf") || fileName.endsWith(".doc")) {
              fetchedDocuments.push(file);
            } else if (fileName.endsWith(".jpg") || fileName.endsWith(".png")) {
              fetchedImages.push(file);
            } else if (
              fileName.endsWith(".mp4") ||
              fileName.endsWith(".mp3") ||
              fileName.endsWith(".wav")
            ) {
              fetchedMediaFiles.push(file);
            } else if (
              fileName.endsWith(".txt") ||
              fileName.endsWith(".csv") ||
              fileName.endsWith(".xlsx")
            ) {
              fetchedOtherFiles.push(file);
            } else {
              fetchedUnknownFiles.push(file);
            }
          })
        );

        // Sort fetchedFilesWithLinksandDate by server_modified (latest to oldest)
        fetchedFilesWithLinksandDate.sort(
          (a, b) => new Date(b.server_modified) - new Date(a.server_modified)
        );

        setDropboxDocumentFiles(fetchedDocuments);
        setDropboxImageFiles(fetchedImages);
        setDropboxMediaFiles(fetchedMediaFiles);
        setDropboxOtherFiles(fetchedOtherFiles);
        setDropboxUnknownFiles(fetchedUnknownFiles);
        setDropboxFiles(fetchedFilesWithLinksandDate);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(DropboxFiles, "WITH LINKS YH");
  }, [DropboxFiles]);

  //GDrive
  const [isSignedToGDrive, setisSignedToGDrive] = useState(false);
  const [GDriveFiles, setGDriveFiles] = useState([]);
  const [usageG, setUsageG] = useState("");
  const [limitG, setLimitG] = useState("");

  const [GDriveDocumentFiles, setGDriveDocumentFiles] = useState([]);
  const [GDriveImageFiles, setGDriveImageFiles] = useState([]);
  const [GDriveMediaFiles, setGDriveMediaFiles] = useState([]);
  const [GDriveOtherFiles, setGDriveOtherFiles] = useState([]);
  const [GDriveUnknownFiles, setGDriveUnknownFiles] = useState([]);

  const [GoFilterGDriveDocuments, setGoFilterGDriveDocuments] = useState(false);
  const [GoFilterGDriveImages, setGoFilterGDriveImages] = useState(false);
  const [GoFilterGDriveMediaFiles, setGoFilterGDriveMediaFiles] = useState(
    false
  );
  const [GoFilterGDriveOtherFiles, setGoFilterGDriveOtherFiles] = useState(
    false
  );
  const [GoFilterGDriveUnknownFiles, setGoFilterGDriveUnknownFiles] = useState(
    false
  );

  const [GoResetGDriveFilters, setGoResetGDriveFilters] = useState(false);

  // Google Drive credentials below
  const CAVE_URL = "/cave";
  const SECRET = "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";
  const CLIENT_ID =
    "883218663357-9jv30o75872dro4j6g79n6ndiog26nts.apps.googleusercontent.com";
  const API_KEY = "AIzaSyBUbf-JzYMppLY-YiWHWYJRo-wK0nmyhD4";
  const DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
  ];
  const SCOPES = "https://www.googleapis.com/auth/drive";

  const initializeGoogleDrive = async () => {
    console.log("loaded init gdrive");

    try {
      console.log("started init");

      await gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      // Set up event listener for signed-in status
      await console.log("starting auth2");
      await gapi.auth2.getAuthInstance().isSignedIn.listen(updateSignInStatus);
      console.log("passed gapi auth2");
      await updateSignInStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    } catch (error) {
      console.log(error);
    }
  };

  //This checks if a google account has been signed in
  const updateSignInStatus = async (isSignedIn) => {
    console.log("updated sign in status");
    if (isSignedIn) {
      await setisSignedToGDrive(true);
      await listGDriveFiles();
      await showQuota();
    } else {
      await gapi.auth2.getAuthInstance().signIn();
    }
  };

  const handleConnectToDrive = async () => {
    if (isSignedToGDrive) {
      handleSignOutFromDrive();
    } else {
      await setGDriveFiles([]);
      await gapi.load("client:auth2", initializeGoogleDrive);
      await console.log("loaded clientload");
    }
  };

  const handleSignOutFromDrive = async (event) => {
    try {
      await setisSignedToGDrive(false);
      await emptyGDriveCategorizedFiles();
      setGDriveFiles([]);
      await gapi.auth2.getAuthInstance().disconnect();
      await setisSignedToGDrive(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isSignedToGDrive) {
      listGDriveFiles();
    }
    if (isSignedToDropbox) {
      listDropboxFiles();
    }
  }, [isSignedToGDrive, isSignedToDropbox]);

  const listGDriveFiles = async () => {
    const fetchedDocuments = [];
    const fetchedImages = [];
    const fetchedMediaFiles = [];
    const fetchedOtherFiles = [];
    const fetchedUnknownFiles = [];

    try {
      const response = await gapi.client.drive.files.list({
        fields:
          "nextPageToken, files(id, name, mimeType, modifiedTime, webViewLink, size)",
        q: null,
      });

      console.log(response);

      if (response.status === 200) {
        const fetchedFiles = JSON.parse(response.body);
        await setGDriveFiles(fetchedFiles.files);

        fetchedFiles.files.forEach((file) => {
          const fileName = file.name.toLowerCase();

          if (fileName.endsWith(".pdf") || fileName.endsWith(".doc")) {
            fetchedDocuments.push(file);
          } else if (fileName.endsWith(".jpg") || fileName.endsWith(".png")) {
            fetchedImages.push(file);
          } else if (
            fileName.endsWith(".mp4") ||
            fileName.endsWith(".mp3") ||
            fileName.endsWith(".wav")
          ) {
            fetchedMediaFiles.push(file);
          } else if (
            fileName.endsWith(".txt") ||
            fileName.endsWith(".csv") ||
            fileName.endsWith(".xlsx")
          ) {
            fetchedOtherFiles.push(file);
          } else {
            fetchedUnknownFiles.push(file);
          }
        });

        setGDriveDocumentFiles(fetchedDocuments);
        setGDriveImageFiles(fetchedImages);
        setGDriveMediaFiles(fetchedMediaFiles);
        setGDriveOtherFiles(fetchedOtherFiles);
        setGDriveUnknownFiles(fetchedUnknownFiles);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Rest of the logic for processing files...
    } catch (error) {
      console.error(error);
    }
  };

  const showQuota = async () => {
    try {
      const response = await gapi.client.drive.about.get({
        fields: "storageQuota",
      });

      const storageQuota = response.result.storageQuota;
      if (storageQuota.usage !== "") {
        await setUsageG(storageQuota.usage);
        console.log(storageQuota.usage);
      }
      if (storageQuota.limit !== "") {
        setLimitG(storageQuota.limit);
      }
    } catch (error) {
      console.error(error);
      toast.error("Quota not found");
    }
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];

    if (isSignedToDropbox && selectedVal === "Dropbox") {
      // Handle Dropbox upload
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          "https://content.dropboxapi.com/2/files/upload",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${dropboxAccessToken}`,
              "Dropbox-API-Arg": JSON.stringify({
                path: `/${file.name}`,
                mode: "add",
                autorename: true,
                mute: false,
              }),
              "Content-Type": "application/octet-stream",
            },
            body: formData,
          }
        );

        console.log("File uploaded successfully!");
        listDropboxFiles(); // Refresh the file list
      } catch (error) {
        console.error(error);
      }
      e.target.value = "";
    } else if (isSignedToGDrive && selectedVal === "Google Drive") {
      // Handle Google Drive upload
      const metadata = {
        name: file.name,
        mimeType: file.type,
      };
      const GDriveAccessToken = gapi.auth.getToken().access_token;

      const form = new FormData();
      form.append(
        "metadata",
        new Blob([JSON.stringify(metadata)], { type: "application/json" })
      );
      form.append("file", file);

      try {
        const response = await fetch(
          "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
          {
            method: "POST",
            headers: new Headers({
              Authorization: `Bearer ${GDriveAccessToken}`,
            }),
            body: form,
          }
        );

        const data = await response.json();

        if (response.ok) {
          listGDriveFiles();
          toast.success("File uploaded successfully");
          // Optionally, you can update the file list here.
        } else {
          console.error(data.error);
          toast.error("Error uploading file");
        }
      } catch (error) {
        console.error(error);
        toast.error("Error uploading file");
      }
      e.target.value = "";
    } else {
      // Handle connecting to Drive
      await toast.error("Not Signed In To A Storage Account");
      await handleConnectToDrive();
      e.target.value = "";
    }
  };

  //GDrive filters
  const filterGDriveDocuments = async () => {
    await listGDriveFiles();

    setGoFilterGDriveDocuments(true);
  };

  const filterGDriveImages = async () => {
    await listGDriveFiles();

    setGoFilterGDriveImages(true);
  };

  const filterGDriveMediaFiles = async () => {
    await listGDriveFiles();

    setGoFilterGDriveMediaFiles(true);
  };

  const filterGDriveOthers = async () => {
    await listGDriveFiles();

    setGoFilterGDriveOtherFiles(true);
  };

  const filterGDriveUnknown = async () => {
    await listGDriveFiles();

    setGoFilterGDriveUnknownFiles(true);
  };

  //using useEffect to filter files bcos listGDriveFiles function takes a long time to update GDrivefiles state
  useEffect(() => {
    if (GoFilterGDriveDocuments && GDriveFiles) {
      const filteredFiles = GDriveFiles.filter((file) => {
        const fileName = file.name.toLowerCase();

        return fileName.endsWith(".pdf") || fileName.endsWith(".txt");
      });

      setGoFilterGDriveDocuments(false);
      setGDriveFiles(filteredFiles);
    }

    if (GoFilterGDriveImages && GDriveFiles) {
      const filteredFiles = GDriveFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith(".jpg") ||
          fileName.endsWith(".png") ||
          fileName.endsWith(".word")
        );
      });

      setGoFilterGDriveImages(false);
      setGDriveFiles(filteredFiles);
    }

    if (GoFilterGDriveMediaFiles && GDriveFiles) {
      const filteredFiles = GDriveFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith(".mp4") ||
          fileName.endsWith(".mkv") ||
          fileName.endsWith(".mp3")
        );
      });

      setGoFilterGDriveMediaFiles(false);
      setGDriveFiles(filteredFiles);
    }

    if (GoFilterGDriveOtherFiles && GDriveFiles) {
      const filteredFiles = GDriveFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return fileName.endsWith(".xlsx") || fileName.endsWith(".csv");
      });

      setGoFilterGDriveOtherFiles(false);
      setGDriveFiles(filteredFiles);
    }

    if (GoFilterGDriveUnknownFiles && GDriveFiles) {
      const filteredFiles = GDriveFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          !fileName.endsWith(".pdf") &&
          !fileName.endsWith(".txt") &&
          !fileName.endsWith(".jpg") &&
          !fileName.endsWith(".png") &&
          !fileName.endsWith(".word") &&
          !fileName.endsWith(".mp4") &&
          !fileName.endsWith(".mkv") &&
          !fileName.endsWith(".mp3") &&
          !fileName.endsWith(".xlsx") &&
          !fileName.endsWith(".csv")
        );
      });

      setGoFilterGDriveUnknownFiles(false);
      setGDriveFiles(filteredFiles);
    }
  }, [
    GoFilterGDriveDocuments,
    GoFilterGDriveImages,
    GoFilterGDriveMediaFiles,
    GoFilterGDriveOtherFiles,
    GoFilterGDriveUnknownFiles,
    GDriveFiles,
  ]);

  const resetGDriveFilters = async () => {
    setGDriveFiles([]);
    await listGDriveFiles(); // This will fetch the original list of files again
  };

  const [CombinedFiles, setCombinedFiles] = useState([]);

  const [DisplayGDriveFilter, setDisplayGDriveFilter] = useState(false);
  const [DisplayDropboxFilter, setDisplayDropboxFilter] = useState(false);
  const [DisplayCombinedFilesFilter, setDisplayCombinedFilesFilter] = useState(
    false
  );

  //this handles which filter should be displayed when accounts get authenticated
  useEffect(() => {
    if (isSignedToGDrive && isSignedToDropbox) {
      setDisplayGDriveFilter(true);
      setDisplayDropboxFilter(false);
      setDisplayCombinedFilesFilter(false);
      setSelectedValue("");
    } else if (isSignedToGDrive && !isSignedToDropbox) {
      setDisplayGDriveFilter(true);
      setDisplayDropboxFilter(false);
      setDisplayCombinedFilesFilter(false);
      setSelectedValue("");
    } else if (isSignedToDropbox && !isSignedToGDrive) {
      setDisplayDropboxFilter(true);
      setDisplayGDriveFilter(false);
      setDisplayCombinedFilesFilter(false);
      setSelectedValue("");
    }
  }, [isSignedToGDrive, isSignedToDropbox]);

  const listCombinedFiles = () => {
    const combinedFiles = [...GDriveFiles, ...DropboxFiles];

    // Sort combined files by modified time
    const sortedFiles = combinedFiles.sort((a, b) => {
      const dateA = new Date(a.modifiedTime || a.server_modified); // Use server_modified for Dropbox
      const dateB = new Date(b.modifiedTime || b.server_modified); // Use server_modified for Dropbox
      return dateB - dateA;
    });

    setCombinedFiles(sortedFiles);
    console.log(DropboxFiles, "dropbox files links");
    console.log(sortedFiles, "sorted files");
  };

  useEffect(() => {
    if (isSignedToDropbox && isSignedToGDrive) {
      listCombinedFiles();
    }
  }, [isSignedToDropbox, isSignedToGDrive, DropboxFiles, GDriveFiles]);

  const [GoFilterDropboxDocuments, setGoFilterDropboxDocuments] = useState(
    false
  );
  const [GoFilterDropboxImages, setGoFilterDropboxImages] = useState(false);
  const [GoFilterDropboxMediaFiles, setGoFilterDropboxMediaFiles] = useState(
    false
  );
  const [GoFilterDropboxOtherFiles, setGoFilterDropboxOtherFiles] = useState(
    false
  );
  const [
    GoFilterDropboxUnknownFiles,
    setGoFilterDropboxUnknownFiles,
  ] = useState(false);

  const [GoResetDropboxFilters, setGoResetDropboxFilters] = useState(false);

  //Dropbox filters
  const filterDropboxDocuments = async () => {
    await listDropboxFiles();

    setGoFilterDropboxDocuments(true);
  };

  const filterDropboxImages = async () => {
    await listDropboxFiles();

    setGoFilterDropboxImages(true);
  };

  const filterDropboxMediaFiles = async () => {
    await listDropboxFiles();

    setGoFilterDropboxMediaFiles(true);
  };

  const filterDropboxOthers = async () => {
    await listDropboxFiles();

    setGoFilterDropboxOtherFiles(true);
  };

  const filterDropboxUnknown = async () => {
    await listDropboxFiles();

    setGoFilterDropboxUnknownFiles(true);
  };

  //using useEffect to filter files bcos listDropboxFiles function takes a long time to update Dropboxfiles state
  useEffect(() => {
    if (GoFilterDropboxDocuments && DropboxFiles) {
      const filteredFiles = DropboxFiles.filter((file) => {
        const fileName = file.name.toLowerCase();

        return fileName.endsWith(".pdf") || fileName.endsWith(".txt");
      });

      setGoFilterDropboxDocuments(false);
      console.log(filteredFiles, "filtered");
      setDropboxFiles(filteredFiles);
    }

    if (GoFilterDropboxImages && DropboxFiles) {
      const filteredFiles = DropboxFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith(".jpg") ||
          fileName.endsWith(".png") ||
          fileName.endsWith(".word")
        );
      });

      setGoFilterDropboxImages(false);
      setDropboxFiles(filteredFiles);
    }

    if (GoFilterDropboxMediaFiles && DropboxFiles) {
      const filteredFiles = DropboxFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith(".mp4") ||
          fileName.endsWith(".mkv") ||
          fileName.endsWith(".mp3")
        );
      });

      setGoFilterDropboxMediaFiles(false);
      setDropboxFiles(filteredFiles);
    }

    if (GoFilterDropboxOtherFiles && DropboxFiles) {
      const filteredFiles = DropboxFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return fileName.endsWith(".xlsx") || fileName.endsWith(".csv");
      });

      setGoFilterDropboxOtherFiles(false);
      setDropboxFiles(filteredFiles);
    }

    if (GoFilterDropboxUnknownFiles && DropboxFiles) {
      const filteredFiles = DropboxFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          !fileName.endsWith(".pdf") &&
          !fileName.endsWith(".txt") &&
          !fileName.endsWith(".jpg") &&
          !fileName.endsWith(".png") &&
          !fileName.endsWith(".word") &&
          !fileName.endsWith(".mp4") &&
          !fileName.endsWith(".mkv") &&
          !fileName.endsWith(".mp3") &&
          !fileName.endsWith(".xlsx") &&
          !fileName.endsWith(".csv")
        );
      });

      setGoFilterDropboxUnknownFiles(false);
      setDropboxFiles(filteredFiles);
    }
  }, [
    GoFilterDropboxDocuments,
    GoFilterDropboxImages,
    GoFilterDropboxMediaFiles,
    GoFilterDropboxOtherFiles,
    GoFilterDropboxUnknownFiles,
    DropboxFiles,
  ]);

  const resetDropboxFilters = async () => {
    setDropboxFiles([]);
    await listDropboxFiles(); // This will fetch the original list of files again
  };

  console.log(DropboxFiles);

  //combined files
  const [
    GoFilterCombinedFilesDocuments,
    setGoFilterCombinedFilesDocuments,
  ] = useState(false);
  const [
    GoFilterCombinedFilesImages,
    setGoFilterCombinedFilesImages,
  ] = useState(false);
  const [
    GoFilterCombinedFilesMediaFiles,
    setGoFilterCombinedFilesMediaFiles,
  ] = useState(false);
  const [
    GoFilterCombinedFilesOtherFiles,
    setGoFilterCombinedFilesOtherFiles,
  ] = useState(false);
  const [
    GoFilterCombinedFilesUnknownFiles,
    setGoFilterCombinedFilesUnknownFiles,
  ] = useState(false);

  const [
    GoResetCombinedFilesFilters,
    setGoResetCombinedFilesFilters,
  ] = useState(false);

  //CombinedFiles filters
  const filterCombinedFilesDocuments = async () => {
    await listCombinedFiles();

    setGoFilterCombinedFilesDocuments(true);
  };

  const filterCombinedFilesImages = async () => {
    await listCombinedFiles();

    setGoFilterCombinedFilesImages(true);
  };

  const filterCombinedFilesMediaFiles = async () => {
    await listCombinedFiles();

    setGoFilterCombinedFilesMediaFiles(true);
  };

  const filterCombinedFilesOthers = async () => {
    await listCombinedFiles();

    setGoFilterCombinedFilesOtherFiles(true);
  };

  const filterCombinedFilesUnknown = async () => {
    await listCombinedFiles();

    setGoFilterCombinedFilesUnknownFiles(true);
  };

  //using useEffect to filter files bcos listCombinedFiles function takes a long time to update CombinedFiles state
  useEffect(() => {
    if (GoFilterCombinedFilesDocuments && CombinedFiles) {
      const filteredFiles = CombinedFiles.filter((file) => {
        const fileName = file.name.toLowerCase();

        return fileName.endsWith(".pdf") || fileName.endsWith(".txt");
      });

      setGoFilterCombinedFilesDocuments(false);
      console.log(filteredFiles, "filtered");
      setCombinedFiles(filteredFiles);
    }

    if (GoFilterCombinedFilesImages && CombinedFiles) {
      const filteredFiles = CombinedFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith(".jpg") ||
          fileName.endsWith(".png") ||
          fileName.endsWith(".word")
        );
      });

      setGoFilterCombinedFilesImages(false);
      setCombinedFiles(filteredFiles);
    }

    if (GoFilterCombinedFilesMediaFiles && CombinedFiles) {
      const filteredFiles = CombinedFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          fileName.endsWith(".mp4") ||
          fileName.endsWith(".mkv") ||
          fileName.endsWith(".mp3")
        );
      });

      setGoFilterCombinedFilesMediaFiles(false);
      setCombinedFiles(filteredFiles);
    }

    if (GoFilterCombinedFilesOtherFiles && CombinedFiles) {
      const filteredFiles = CombinedFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return fileName.endsWith(".xlsx") || fileName.endsWith(".csv");
      });

      setGoFilterCombinedFilesOtherFiles(false);
      setCombinedFiles(filteredFiles);
    }

    if (GoFilterCombinedFilesUnknownFiles && CombinedFiles) {
      const filteredFiles = CombinedFiles.filter((file) => {
        const fileName = file.name.toLowerCase();
        return (
          !fileName.endsWith(".pdf") &&
          !fileName.endsWith(".txt") &&
          !fileName.endsWith(".jpg") &&
          !fileName.endsWith(".png") &&
          !fileName.endsWith(".word") &&
          !fileName.endsWith(".mp4") &&
          !fileName.endsWith(".mkv") &&
          !fileName.endsWith(".mp3") &&
          !fileName.endsWith(".xlsx") &&
          !fileName.endsWith(".csv")
        );
      });

      setGoFilterCombinedFilesUnknownFiles(false);
      setCombinedFiles(filteredFiles);
    }
  }, [
    GoFilterCombinedFilesDocuments,
    GoFilterCombinedFilesImages,
    GoFilterCombinedFilesMediaFiles,
    GoFilterCombinedFilesOtherFiles,
    GoFilterCombinedFilesUnknownFiles,
    CombinedFiles,
  ]);

  const [open, setOpen] = React.useState(false);
  const [val, setVal] = React.useState("");

  // Conditionally define the emails array based on isSignedToGoogleDrive and isSignedToDropbox

  const [emails, setEmails] = useState([]);
  useEffect(() => {
    if (isSignedToGDrive && isSignedToDropbox) {
      setEmails(["Google Drive", "Dropbox"]);
    } else if (isSignedToGDrive) {
      setEmails(["Google Drive"]);
    } else if (isSignedToDropbox) {
      setEmails(["Dropbox"]);
    }
  }, [isSignedToGDrive, isSignedToDropbox]);

  const [selectedVal, setSelectedVal] = React.useState(emails[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedVal(value);
  };

  const handleListItemClick = (value) => {
    handleClose(value);
  };

  return (
    <div className="storage_main">
      <div className="storage_sub">
        <div className="details-view">
          <div className="details-view-content">
            <div className="search-and-upload-div">
              <input
                type="text"
                placeholder="Search..."
                className="search-div"
              />
              <input
                type="file"
                onChange={handleUpload}
                ref={uploadInputRef}
                style={{ display: "none" }}
              />
              <Dialog onClose={() => handleClose(selectedVal)} open={open}>
                {console.log(selectedVal, "selected val")}
                <DialogTitle>Select uploading account</DialogTitle>
                <List
                  sx={{ pt: 0 }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "black",
                    fontSize: "20px",
                  }}
                >
                  {emails.map((email) => (
                    <ListItem disableGutters key={email}>
                      <ListItemButton
                        onClick={() => {
                          handleListItemClick(email);
                          uploadInputRef.current.click();
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                            {email === "Google Drive" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 36 32"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_351_63860)">
                                  <path
                                    d="M11.7148 0.0424805H24.2305L36.0008 20.3347H23.457L11.7148 0.0424805Z"
                                    fill="url(#paint0_linear_351_63860)"
                                  />
                                  <path
                                    d="M36.0004 20.335L29.7285 31.1771H6.24414L12.5301 20.335H36.0004Z"
                                    fill="url(#paint1_linear_351_63860)"
                                  />
                                  <path
                                    d="M6.24375 31.1769L0 20.3347L11.7141 0.0424805L18 10.9128L6.24375 31.1769Z"
                                    fill="url(#paint2_linear_351_63860)"
                                  />
                                  <path
                                    opacity="0.1"
                                    d="M6.24414 31.1769L17.9301 20.3347H12.5301L6.24414 31.1769ZM36.0004 20.3347H23.4707L20.7145 15.5815L36.0004 20.3347ZM11.7145 0.0424805L15.3285 15.5112L18.0004 10.9128L11.7145 0.0424805Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_351_63860"
                                    x1="11.7148"
                                    y1="0.0424805"
                                    x2="31.6839"
                                    y2="23.9417"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#F6C338" />
                                    <stop offset="0.523" stop-color="#FFD351" />
                                    <stop offset="1" stop-color="#F6C338" />
                                  </linearGradient>
                                  <linearGradient
                                    id="paint1_linear_351_63860"
                                    x1="36.0004"
                                    y1="20.335"
                                    x2="29.0254"
                                    y2="39.4778"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#286EE6" />
                                    <stop offset="0.521" stop-color="#4286FB" />
                                    <stop offset="1" stop-color="#286EE6" />
                                  </linearGradient>
                                  <linearGradient
                                    id="paint2_linear_351_63860"
                                    x1="11.752"
                                    y1="0.0424808"
                                    x2="-1.878"
                                    y2="26.9424"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stop-color="#069B5A" />
                                    <stop offset="0.531" stop-color="#11AA62" />
                                    <stop offset="1" stop-color="#069B5A" />
                                  </linearGradient>
                                  <clipPath id="clip0_351_63860">
                                    <rect
                                      width="36"
                                      height="31.2192"
                                      fill="white"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            ) : (
                              email === "Dropbox" && (
                                <svg
                                  style={{ border: "1px solid #bbdefb" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 48 48"
                                  fill="none"
                                >
                                  <path
                                    d="M15 9L6 14.8393L15 20.5928L24 14.8393L15 9ZM33 9L24 14.8393L33 20.5928L42 14.8393L33 9ZM6 26.4321L15 32.2715L24 26.4321L15 20.5928L6 26.4321ZM33 20.5928L24 26.4321L33 32.2715L42 26.4321L33 20.5928ZM15 34.1607L24 40L33 34.1607L24 28.4072L15 34.1607Z"
                                    fill="#1A71FF"
                                  />
                                  <rect
                                    x="0.5"
                                    y="0.5"
                                    width="47"
                                    height="47"
                                    rx="7.5"
                                    stroke="#242424"
                                  />
                                </svg>
                              )
                            )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={email} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Dialog>
              <div className="upload-div" onClick={handleClickOpen}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.285 2.55604C10.1971 2.51992 10.1009 2.5 10 2.5C9.80129 2.5 9.62063 2.57728 9.48643 2.70342C9.48011 2.70936 9.4739 2.71541 9.46779 2.72156L5.4419 6.74745C5.14901 7.04034 5.14901 7.51521 5.4419 7.80811C5.73479 8.101 6.20967 8.101 6.50256 7.80811L9.25 5.06067V12.9167C9.25 13.3309 9.58579 13.6667 10 13.6667C10.4142 13.6667 10.75 13.3309 10.75 12.9167V5.06065L13.4975 7.80811C13.7903 8.101 14.2652 8.101 14.5581 7.80811C14.851 7.51521 14.851 7.04034 14.5581 6.74745L10.5303 2.71967C10.4579 2.64719 10.3742 2.59265 10.285 2.55604ZM2.75 12.1667C3.16421 12.1667 3.5 12.5024 3.5 12.9167V16.1389C3.5 16.3673 3.59072 16.5863 3.75221 16.7478C3.9137 16.9093 4.13273 17 4.36111 17H15.6389C15.8673 17 16.0863 16.9093 16.2478 16.7478C16.4093 16.5863 16.5 16.3673 16.5 16.1389V12.9167C16.5 12.5024 16.8358 12.1667 17.25 12.1667C17.6642 12.1667 18 12.5024 18 12.9167V16.1389C18 16.7651 17.7512 17.3656 17.3084 17.8084C16.8657 18.2512 16.2651 18.5 15.6389 18.5H4.36111C3.73491 18.5 3.13435 18.2512 2.69155 17.8084C2.24876 17.3656 2 16.7651 2 16.1389V12.9167C2 12.5024 2.33579 12.1667 2.75 12.1667Z"
                    fill="white"
                  />
                </svg>{" "}
                <div className="upload-text">upload</div>
              </div>
            </div>
            <div className="accounts-div">
              <div className="three-account-div">
                <div className="inner-three-accounts-div">
                  <div className="first-inner-section">
                    <div className="drive-logo">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 36 32"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_351_63860)">
                          <path
                            d="M11.7148 0.0424805H24.2305L36.0008 20.3347H23.457L11.7148 0.0424805Z"
                            fill="url(#paint0_linear_351_63860)"
                          />
                          <path
                            d="M36.0004 20.335L29.7285 31.1771H6.24414L12.5301 20.335H36.0004Z"
                            fill="url(#paint1_linear_351_63860)"
                          />
                          <path
                            d="M6.24375 31.1769L0 20.3347L11.7141 0.0424805L18 10.9128L6.24375 31.1769Z"
                            fill="url(#paint2_linear_351_63860)"
                          />
                          <path
                            opacity="0.1"
                            d="M6.24414 31.1769L17.9301 20.3347H12.5301L6.24414 31.1769ZM36.0004 20.3347H23.4707L20.7145 15.5815L36.0004 20.3347ZM11.7145 0.0424805L15.3285 15.5112L18.0004 10.9128L11.7145 0.0424805Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <linearGradient
                            id="paint0_linear_351_63860"
                            x1="11.7148"
                            y1="0.0424805"
                            x2="31.6839"
                            y2="23.9417"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#F6C338" />
                            <stop offset="0.523" stop-color="#FFD351" />
                            <stop offset="1" stop-color="#F6C338" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_351_63860"
                            x1="36.0004"
                            y1="20.335"
                            x2="29.0254"
                            y2="39.4778"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#286EE6" />
                            <stop offset="0.521" stop-color="#4286FB" />
                            <stop offset="1" stop-color="#286EE6" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_351_63860"
                            x1="11.752"
                            y1="0.0424808"
                            x2="-1.878"
                            y2="26.9424"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#069B5A" />
                            <stop offset="0.531" stop-color="#11AA62" />
                            <stop offset="1" stop-color="#069B5A" />
                          </linearGradient>
                          <clipPath id="clip0_351_63860">
                            <rect width="36" height="31.2192" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                    <div className="drive-more">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.0008 18.85C14.0777 18.85 14.9508 19.723 14.9508 20.8C14.9508 21.877 14.0777 22.75 13.0008 22.75C11.9238 22.75 11.0508 21.877 11.0508 20.8C11.0508 19.723 11.9238 18.85 13.0008 18.85ZM13.0008 11.05C14.0777 11.05 14.9508 11.923 14.9508 13C14.9508 14.077 14.0777 14.95 13.0008 14.95C11.9238 14.95 11.0508 14.077 11.0508 13C11.0508 11.923 11.9238 11.05 13.0008 11.05ZM14.9508 5.2C14.9508 4.12304 14.0777 3.25 13.0008 3.25C11.9238 3.25 11.0508 4.12304 11.0508 5.2C11.0508 6.27696 11.9238 7.15 13.0008 7.15C14.0777 7.15 14.9508 6.27696 14.9508 5.2Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="second-inner-section">
                    {isSignedToGDrive ? (
                      <button onClick={handleSignOutFromDrive}>
                        Disconnect from Drive
                      </button>
                    ) : (
                      <button onClick={handleConnectToDrive}>
                        Connect to Google Drive
                      </button>
                    )}
                  </div>

                  <div className="third-inner-section">
                    <div className="storage-text">Storage</div>
                    <div className="storage-size">
                      {isSignedToGDrive ? (
                        <div>
                          {formatSize(usageG)}/{formatSize(limitG)}
                        </div>
                      ) : (
                        <div>N/A</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="three-account-div">
                <div className="inner-three-accounts-div">
                  <div className="first-inner-section">
                    <div className="dropbox-logo">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <path
                          d="M15 9L6 14.8393L15 20.5928L24 14.8393L15 9ZM33 9L24 14.8393L33 20.5928L42 14.8393L33 9ZM6 26.4321L15 32.2715L24 26.4321L15 20.5928L6 26.4321ZM33 20.5928L24 26.4321L33 32.2715L42 26.4321L33 20.5928ZM15 34.1607L24 40L33 34.1607L24 28.4072L15 34.1607Z"
                          fill="#1A71FF"
                        />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="47"
                          height="47"
                          rx="7.5"
                          stroke="#242424"
                        />
                      </svg>
                    </div>
                    <div className="dropbox-more">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.0008 18.85C14.0777 18.85 14.9508 19.723 14.9508 20.8C14.9508 21.877 14.0777 22.75 13.0008 22.75C11.9238 22.75 11.0508 21.877 11.0508 20.8C11.0508 19.723 11.9238 18.85 13.0008 18.85ZM13.0008 11.05C14.0777 11.05 14.9508 11.923 14.9508 13C14.9508 14.077 14.0777 14.95 13.0008 14.95C11.9238 14.95 11.0508 14.077 11.0508 13C11.0508 11.923 11.9238 11.05 13.0008 11.05ZM14.9508 5.2C14.9508 4.12304 14.0777 3.25 13.0008 3.25C11.9238 3.25 11.0508 4.12304 11.0508 5.2C11.0508 6.27696 11.9238 7.15 13.0008 7.15C14.0777 7.15 14.9508 6.27696 14.9508 5.2Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="second-inner-section">
                    {isSignedToDropbox ? (
                      <button onClick={handleConnectDropbox}>
                        Disconnect from Dropbox
                      </button>
                    ) : (
                      <button onClick={handleConnectDropbox}>
                        Connect to Dropbox
                      </button>
                    )}
                  </div>
                  <div className="third-inner-section">
                    <div className="storage-text">Storage</div>
                    <div className="storage-size">
                      {isSignedToDropbox ? (
                        <div>
                          {formatSize(storageUsage)}/{formatSize(storageLimit)}
                        </div>
                      ) : (
                        <div>N/A</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="three-account-div">
                <div className="inner-three-accounts-div">
                  <div className="first-inner-section">
                    <div className="one-drive-logo">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="24"
                        viewBox="0 0 36 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_351_63834)">
                          <path
                            d="M13.9211 7.141V7.1399L21.34 11.5838L25.7607 9.72294C26.6592 9.33483 27.6279 9.13524 28.6066 9.13654C28.7701 9.13654 28.9313 9.14427 29.0914 9.15421C28.5601 7.08164 27.4382 5.20804 25.8621 3.76114C24.2859 2.31423 22.3234 1.35634 20.213 1.00389C18.1027 0.651449 15.9354 0.919627 13.9746 1.77583C12.0138 2.63204 10.3439 4.03939 9.16797 5.82684L9.28061 5.82353C10.92 5.82138 12.5274 6.27772 13.9211 7.141Z"
                            fill="#0371D1"
                          />
                          <path
                            d="M13.9204 7.1406C12.5263 6.27751 10.9186 5.82155 9.27889 5.82423L9.16624 5.82754C7.56165 5.84762 5.99284 6.30451 4.62839 7.14914C3.26393 7.99377 2.1554 9.19421 1.4219 10.6215C0.68839 12.0488 0.357634 13.6489 0.465165 15.25C0.572696 16.8511 1.11445 18.3927 2.03222 19.7091L8.57211 16.956L11.4809 15.7324L17.9557 13.0068L21.3372 11.5845L13.9204 7.1406Z"
                            fill="#0087EE"
                          />
                          <path
                            d="M29.0911 9.15439C28.9297 9.1431 28.768 9.13721 28.6063 9.13672C27.6278 9.13592 26.6596 9.33588 25.7615 9.72423L21.3398 11.5839L22.622 12.3514L26.8239 14.8682L28.6571 15.9659L34.9263 19.7207C35.4959 18.6633 35.7911 17.4798 35.7849 16.2788C35.7786 15.0777 35.4711 13.8974 34.8904 12.846C34.3098 11.7946 33.4746 10.9057 32.4614 10.2608C31.4482 9.61582 30.2894 9.23541 29.0911 9.15439Z"
                            fill="#219EEB"
                          />
                          <path
                            d="M28.6568 15.966L26.8236 14.8683L22.6216 12.3504L21.3406 11.584L17.958 13.0064L11.4833 15.7319L8.57335 16.9554L2.03125 19.7085C2.8444 20.8775 3.92843 21.8323 5.19074 22.4914C6.45305 23.1504 7.85613 23.4941 9.28013 23.4931H28.606C29.902 23.4936 31.1738 23.1431 32.2866 22.4789C33.3994 21.8147 34.3115 20.8616 34.9262 19.7207L28.6568 15.966Z"
                            fill="#3FB3EC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_351_63834">
                            <rect width="36" height="23.5385" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="one-drive-more">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.0008 18.85C14.0777 18.85 14.9508 19.723 14.9508 20.8C14.9508 21.877 14.0777 22.75 13.0008 22.75C11.9238 22.75 11.0508 21.877 11.0508 20.8C11.0508 19.723 11.9238 18.85 13.0008 18.85ZM13.0008 11.05C14.0777 11.05 14.9508 11.923 14.9508 13C14.9508 14.077 14.0777 14.95 13.0008 14.95C11.9238 14.95 11.0508 14.077 11.0508 13C11.0508 11.923 11.9238 11.05 13.0008 11.05ZM14.9508 5.2C14.9508 4.12304 14.0777 3.25 13.0008 3.25C11.9238 3.25 11.0508 4.12304 11.0508 5.2C11.0508 6.27696 11.9238 7.15 13.0008 7.15C14.0777 7.15 14.9508 6.27696 14.9508 5.2Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="second-inner-section">
                    <button>Connect to One Drive</button>
                  </div>
                  <div className="third-inner-section">
                    <div className="storage-text">Storage</div>
                    <div className="storage-size">
                      <div>N/A</div>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
            {/*<div className="recent-files-and-view-all-div">
              <div className="recent-files">Recent Files</div>
              <div className="view-all">View All</div>
            </div>*/}
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    {isSignedToGDrive && (
                      <Tab
                        label="Google Drive Files"
                        value={
                          ((isSignedToGDrive === true &&
                            isSignedToDropbox === false) ||
                            (isSignedToDropbox && isSignedToGDrive)) &&
                          "1"
                        }
                        tabIndex={
                          isSignedToGDrive === true &&
                          isSignedToDropbox === false
                            ? "0"
                            : "-1"
                        }
                        onClick={() => {
                          setDisplayGDriveFilter(true);
                          setDisplayDropboxFilter(false);
                          setDisplayCombinedFilesFilter(false);
                          setSelectedValue("");
                          listGDriveFiles();
                        }}
                        aria-selected="true"
                      />
                    )}

                    {isSignedToDropbox && (
                      <Tab
                        label="Dropbox Files"
                        value={
                          isSignedToGDrive === false &&
                          isSignedToDropbox === true
                            ? "1"
                            : isSignedToDropbox && isSignedToGDrive && "2"
                        }
                        tabIndex={
                          isSignedToGDrive === false &&
                          isSignedToDropbox === true
                            ? "0"
                            : "-1"
                        }
                        onClick={() => {
                          setDisplayGDriveFilter(false);
                          setDisplayDropboxFilter(true);
                          setDisplayCombinedFilesFilter(false);
                          setSelectedValue("");
                          listDropboxFiles();
                        }}
                      />
                    )}

                    {isSignedToDropbox && isSignedToGDrive && (
                      <Tab
                        label="View All"
                        value={isSignedToDropbox && isSignedToGDrive && "3"}
                        style={{ marginLeft: "356px" }}
                        tabIndex={
                          isSignedToGDrive && isSignedToDropbox ? "0" : "-1"
                        }
                        onClick={() => {
                          setDisplayGDriveFilter(false);
                          setDisplayDropboxFilter(false);
                          setDisplayCombinedFilesFilter(true);
                          setSelectedValue("");
                        }}
                      />
                    )}
                  </TabList>
                </Box>

                <div style={{ marginTop: "20px", width: "180px" }}>
                  {DisplayGDriveFilter && (
                    <Select
                      action={action}
                      value={selectedValue} // Changed 'value' to 'selectedValue'
                      placeholder="FILTER BY FILE TYPE"
                      onChange={(e, newValue) => setSelectedValue(newValue)} // Changed 'value' to 'selectedValue'
                      {...(selectedValue && {
                        // Changed 'value' to 'selectedValue'
                        // display the button and remove select indicator
                        // when user has selected a value
                        endDecorator: (
                          <IconButton
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onMouseDown={(event) => {
                              // don't open the popup when clicking on this button
                              event.stopPropagation();
                            }}
                            onClick={() => {
                              setSelectedValue(null); // Changed 'value' to 'selectedValue'
                              action.current?.focusVisible();
                              listGDriveFiles();
                            }}
                          >
                            <CloseRounded style={{ background: "#151517" }} />
                          </IconButton>
                        ),
                        indicator: null,
                      })}
                      sx={{ minWidth: 160 }}
                      style={{
                        background: "#151517",
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      <Box>
                        <Option
                          value="documents"
                          onClick={filterGDriveDocuments}
                        >
                          Documents
                        </Option>
                        <Option value="images" onClick={filterGDriveImages}>
                          Images
                        </Option>
                        <Option
                          value="mediafiles"
                          onClick={filterGDriveMediaFiles}
                        >
                          Media Files
                        </Option>
                        <Option value="otherfiles" onClick={filterGDriveOthers}>
                          Other Files
                        </Option>
                        <Option value="unknown" onClick={filterGDriveUnknown}>
                          Unknown
                        </Option>
                      </Box>
                    </Select>
                  )}
                  {DisplayDropboxFilter && (
                    <Select
                      action={action}
                      value={selectedValue} // Changed 'value' to 'selectedValue'
                      placeholder="FILTER BY FILE TYPE"
                      onChange={(e, newValue) => setSelectedValue(newValue)} // Changed 'value' to 'selectedValue'
                      {...(selectedValue && {
                        // Changed 'value' to 'selectedValue'
                        // display the button and remove select indicator
                        // when user has selected a value
                        endDecorator: (
                          <IconButton
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onMouseDown={(event) => {
                              // don't open the popup when clicking on this button
                              event.stopPropagation();
                            }}
                            onClick={() => {
                              setSelectedValue(null); // Changed 'value' to 'selectedValue'
                              action.current?.focusVisible();
                              listDropboxFiles();
                            }}
                          >
                            <CloseRounded style={{ background: "#151517" }} />
                          </IconButton>
                        ),
                        indicator: null,
                      })}
                      sx={{ minWidth: 160 }}
                      style={{
                        background: "#151517",
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      <Box>
                        <Option
                          value="documents"
                          onClick={filterDropboxDocuments}
                        >
                          Documents
                        </Option>
                        <Option value="images" onClick={filterDropboxImages}>
                          Images
                        </Option>
                        <Option
                          value="mediafiles"
                          onClick={filterDropboxMediaFiles}
                        >
                          Media Files
                        </Option>
                        <Option
                          value="otherfiles"
                          onClick={filterDropboxOthers}
                        >
                          Other Files
                        </Option>
                        <Option value="unknown" onClick={filterDropboxUnknown}>
                          Unknown
                        </Option>
                      </Box>
                    </Select>
                  )}
                  {DisplayCombinedFilesFilter && (
                    <Select
                      action={action}
                      value={selectedValue} // Changed 'value' to 'selectedValue'
                      placeholder="FILTER BY FILE TYPE"
                      onChange={(e, newValue) => setSelectedValue(newValue)} // Changed 'value' to 'selectedValue'
                      {...(selectedValue && {
                        // Changed 'value' to 'selectedValue'
                        // display the button and remove select indicator
                        // when user has selected a value
                        endDecorator: (
                          <IconButton
                            size="sm"
                            variant="plain"
                            color="neutral"
                            onMouseDown={(event) => {
                              // don't open the popup when clicking on this button
                              event.stopPropagation();
                            }}
                            onClick={() => {
                              setSelectedValue(null); // Changed 'value' to 'selectedValue'
                              action.current?.focusVisible();
                              listCombinedFiles();
                            }}
                          >
                            <CloseRounded style={{ background: "#151517" }} />
                          </IconButton>
                        ),
                        indicator: null,
                      })}
                      sx={{ minWidth: 160 }}
                      style={{
                        background: "#151517",
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      <Box>
                        <Option
                          value="documents"
                          onClick={filterCombinedFilesDocuments}
                        >
                          Documents
                        </Option>
                        <Option
                          value="images"
                          onClick={filterCombinedFilesImages}
                        >
                          Images
                        </Option>
                        <Option
                          value="mediafiles"
                          onClick={filterCombinedFilesMediaFiles}
                        >
                          Media Files
                        </Option>
                        <Option
                          value="otherfiles"
                          onClick={filterCombinedFilesOthers}
                        >
                          Other Files
                        </Option>
                        <Option
                          value="unknown"
                          onClick={filterCombinedFilesUnknown}
                        >
                          Unknown
                        </Option>
                      </Box>
                    </Select>
                  )}
                </div>

                <TabPanel
                  value={
                    isSignedToGDrive === true && isSignedToDropbox === false
                      ? "1"
                      : isSignedToDropbox && isSignedToGDrive && "1"
                  }
                  style={{ padding: "0", marginTop: "20px" }}
                >
                  {isSignedToGDrive && (
                    <div className="fetched-files-div">
                      {GDriveFiles.map((file) => (
                        <div
                          key={file.id}
                          className="fileicon-and-filename-div"
                        >
                          <div className="fileicon">
                            {file.name.endsWith(".jpg") ||
                            file.name.endsWith(".png") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 29 38"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#61A9DD"
                                />
                                <path
                                  d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M4.67773 28.5L11.2261 17.1L15.81 23.8059L19.0842 19.7823L24.323 28.5H4.67773Z"
                                  fill="#61A9DD"
                                />
                                <ellipse
                                  cx="7.4842"
                                  cy="13.3"
                                  rx="2.80646"
                                  ry="2.85"
                                  fill="#FFDB6E"
                                />
                              </svg>
                            ) : file.name.endsWith(".mp3") ||
                              file.name.endsWith(".mp4") ||
                              file.name.endsWith(".wav") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 34 44"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#FD7972"
                                />
                                <path
                                  d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                                  fill="#FD7972"
                                  stroke="#FD7972"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            ) : file.name.endsWith(".png") ||
                              file.name.endsWith(".jpg") ||
                              file.name.endsWith(".jpeg") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 34 44"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#61A9DD"
                                />
                                <path
                                  d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M5.48438 33L13.1618 19.8L18.536 27.5647L22.3748 22.9059L28.5167 33H5.48438Z"
                                  fill="#61A9DD"
                                />
                                <ellipse
                                  cx="8.77471"
                                  cy="15.4"
                                  rx="3.29034"
                                  ry="3.3"
                                  fill="#FFDB6E"
                                />
                              </svg>
                            ) : file.name.endsWith(".pdf") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 29 38"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#FD7972"
                                />
                                <path
                                  d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M8.75845 28.5C8.54689 28.5 8.34385 28.435 8.17059 28.3124C7.53773 27.863 7.45262 27.363 7.49275 27.0223C7.60339 26.0856 8.82715 25.1051 11.1312 24.1062C12.0455 22.2096 12.9155 19.8729 13.434 17.9205C12.8273 16.6707 12.2376 15.0492 12.6674 14.0981C12.8182 13.7649 13.006 13.5094 13.3568 13.399C13.4954 13.3552 13.8456 13.3 13.9745 13.3C14.2809 13.3 14.5502 13.6734 14.7411 13.9036C14.9204 14.1199 15.3271 14.5785 14.5143 17.8175C15.3338 19.4195 16.4949 21.0513 17.6075 22.1688C18.4044 22.0324 19.0902 21.9628 19.6489 21.9628C20.6009 21.9628 21.1778 22.1728 21.4131 22.6055C21.6076 22.9634 21.528 23.3817 21.176 23.8484C20.8374 24.2966 20.3705 24.5337 19.8264 24.5337C19.0872 24.5337 18.2263 24.0918 17.2664 23.2189C15.5417 23.5601 13.5276 24.1689 11.8996 24.8427C11.3914 25.8635 10.9044 26.6857 10.4509 27.2888C9.8278 28.1145 9.29039 28.5 8.75845 28.5ZM10.3768 25.5504C9.07762 26.2415 8.54811 26.8094 8.50981 27.1294C8.50373 27.1823 8.48732 27.3216 8.77183 27.5275C8.86241 27.5005 9.39131 27.2721 10.3768 25.5504ZM18.6671 22.9945C19.1625 23.3553 19.2835 23.5377 19.6075 23.5377C19.7498 23.5377 20.1553 23.5319 20.3431 23.2839C20.4337 23.1636 20.469 23.0865 20.483 23.0451C20.4082 23.0077 20.3091 22.9318 19.7686 22.9318C19.4616 22.9323 19.0756 22.945 18.6671 22.9945ZM14.1258 19.2071C13.6912 20.6307 13.1173 22.1676 12.5003 23.5595C13.7708 23.0929 15.152 22.6855 16.4494 22.3972C15.6286 21.495 14.8086 20.3683 14.1258 19.2071ZM13.7568 14.338C13.6973 14.357 12.9483 15.349 13.8152 16.1885C14.3921 14.9716 13.783 14.33 13.7568 14.338Z"
                                  fill="#FD7972"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 34 44"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#FEB077"
                                />
                                <path
                                  d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                  stroke="#FEB077"
                                />
                                <path
                                  d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                  stroke="#FEB077"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.5169 23.1C11.5169 24.0113 10.7803 24.75 9.87173 24.75C8.96313 24.75 8.22656 24.0113 8.22656 23.1C8.22656 22.1887 8.96313 21.45 9.87173 21.45C10.7803 21.45 11.5169 22.1887 11.5169 23.1ZM18.0976 23.1C18.0976 24.0113 17.361 24.75 16.4524 24.75C15.5438 24.75 14.8072 24.0113 14.8072 23.1C14.8072 22.1887 15.5438 21.45 16.4524 21.45C17.361 21.45 18.0976 22.1887 18.0976 23.1ZM23.0331 24.75C23.9417 24.75 24.6782 24.0113 24.6782 23.1C24.6782 22.1887 23.9417 21.45 23.0331 21.45C22.1245 21.45 21.3879 22.1887 21.3879 23.1C21.3879 24.0113 22.1245 24.75 23.0331 24.75Z"
                                  fill="#FEB077"
                                />
                              </svg>
                            )}
                          </div>
                          <a
                            href={file.webViewLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.name}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                </TabPanel>
                <TabPanel
                  value={
                    isSignedToGDrive === false && isSignedToDropbox === true
                      ? "1"
                      : isSignedToDropbox && isSignedToGDrive && "2"
                  }
                  style={{ padding: "0", marginTop: "20px" }}
                >
                  {isSignedToDropbox && (
                    <div className="fetched-files-div">
                      <div className="fetched-files-div">
                        {DropboxFiles.map((file, index) => (
                          <div
                            key={file.id}
                            className="fileicon-and-filename-div"
                          >
                            <div className="fileicon">
                              {file.name.endsWith(".jpg") ||
                              file.name.endsWith(".png") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="38"
                                  viewBox="0 0 29 38"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                    fill="#0D0D0D"
                                  />
                                  <path
                                    opacity="0.05"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                    fill="#61A9DD"
                                  />
                                  <path
                                    d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                    stroke="#61A9DD"
                                  />
                                  <path
                                    d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                    stroke="#61A9DD"
                                  />
                                  <path
                                    d="M4.67773 28.5L11.2261 17.1L15.81 23.8059L19.0842 19.7823L24.323 28.5H4.67773Z"
                                    fill="#61A9DD"
                                  />
                                  <ellipse
                                    cx="7.4842"
                                    cy="13.3"
                                    rx="2.80646"
                                    ry="2.85"
                                    fill="#FFDB6E"
                                  />
                                </svg>
                              ) : file.name.endsWith(".mp3") ||
                                file.name.endsWith(".mp4") ||
                                file.name.endsWith(".wav") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="38"
                                  viewBox="0 0 34 44"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                    fill="#0D0D0D"
                                  />
                                  <path
                                    opacity="0.05"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                    fill="#FD7972"
                                  />
                                  <path
                                    d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                    stroke="#FD7972"
                                  />
                                  <path
                                    d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                    stroke="#FD7972"
                                  />
                                  <path
                                    d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                                    fill="#FD7972"
                                    stroke="#FD7972"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              ) : file.name.endsWith(".png") ||
                                file.name.endsWith(".jpg") ||
                                file.name.endsWith(".jpeg") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="38"
                                  viewBox="0 0 34 44"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                    fill="#0D0D0D"
                                  />
                                  <path
                                    opacity="0.05"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                    fill="#61A9DD"
                                  />
                                  <path
                                    d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                    stroke="#61A9DD"
                                  />
                                  <path
                                    d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                    stroke="#61A9DD"
                                  />
                                  <path
                                    d="M5.48438 33L13.1618 19.8L18.536 27.5647L22.3748 22.9059L28.5167 33H5.48438Z"
                                    fill="#61A9DD"
                                  />
                                  <ellipse
                                    cx="8.77471"
                                    cy="15.4"
                                    rx="3.29034"
                                    ry="3.3"
                                    fill="#FFDB6E"
                                  />
                                </svg>
                              ) : file.name.endsWith(".pdf") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="38"
                                  viewBox="0 0 29 38"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                    fill="#0D0D0D"
                                  />
                                  <path
                                    opacity="0.05"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                    fill="#FD7972"
                                  />
                                  <path
                                    d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                    stroke="#FD7972"
                                  />
                                  <path
                                    d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                    stroke="#FD7972"
                                  />
                                  <path
                                    d="M8.75845 28.5C8.54689 28.5 8.34385 28.435 8.17059 28.3124C7.53773 27.863 7.45262 27.363 7.49275 27.0223C7.60339 26.0856 8.82715 25.1051 11.1312 24.1062C12.0455 22.2096 12.9155 19.8729 13.434 17.9205C12.8273 16.6707 12.2376 15.0492 12.6674 14.0981C12.8182 13.7649 13.006 13.5094 13.3568 13.399C13.4954 13.3552 13.8456 13.3 13.9745 13.3C14.2809 13.3 14.5502 13.6734 14.7411 13.9036C14.9204 14.1199 15.3271 14.5785 14.5143 17.8175C15.3338 19.4195 16.4949 21.0513 17.6075 22.1688C18.4044 22.0324 19.0902 21.9628 19.6489 21.9628C20.6009 21.9628 21.1778 22.1728 21.4131 22.6055C21.6076 22.9634 21.528 23.3817 21.176 23.8484C20.8374 24.2966 20.3705 24.5337 19.8264 24.5337C19.0872 24.5337 18.2263 24.0918 17.2664 23.2189C15.5417 23.5601 13.5276 24.1689 11.8996 24.8427C11.3914 25.8635 10.9044 26.6857 10.4509 27.2888C9.8278 28.1145 9.29039 28.5 8.75845 28.5ZM10.3768 25.5504C9.07762 26.2415 8.54811 26.8094 8.50981 27.1294C8.50373 27.1823 8.48732 27.3216 8.77183 27.5275C8.86241 27.5005 9.39131 27.2721 10.3768 25.5504ZM18.6671 22.9945C19.1625 23.3553 19.2835 23.5377 19.6075 23.5377C19.7498 23.5377 20.1553 23.5319 20.3431 23.2839C20.4337 23.1636 20.469 23.0865 20.483 23.0451C20.4082 23.0077 20.3091 22.9318 19.7686 22.9318C19.4616 22.9323 19.0756 22.945 18.6671 22.9945ZM14.1258 19.2071C13.6912 20.6307 13.1173 22.1676 12.5003 23.5595C13.7708 23.0929 15.152 22.6855 16.4494 22.3972C15.6286 21.495 14.8086 20.3683 14.1258 19.2071ZM13.7568 14.338C13.6973 14.357 12.9483 15.349 13.8152 16.1885C14.3921 14.9716 13.783 14.33 13.7568 14.338Z"
                                    fill="#FD7972"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="38"
                                  viewBox="0 0 34 44"
                                  fill="none"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                    fill="#0D0D0D"
                                  />
                                  <path
                                    opacity="0.05"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                    fill="#FEB077"
                                  />
                                  <path
                                    d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                    stroke="#FEB077"
                                  />
                                  <path
                                    d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                    stroke="#FEB077"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M11.5169 23.1C11.5169 24.0113 10.7803 24.75 9.87173 24.75C8.96313 24.75 8.22656 24.0113 8.22656 23.1C8.22656 22.1887 8.96313 21.45 9.87173 21.45C10.7803 21.45 11.5169 22.1887 11.5169 23.1ZM18.0976 23.1C18.0976 24.0113 17.361 24.75 16.4524 24.75C15.5438 24.75 14.8072 24.0113 14.8072 23.1C14.8072 22.1887 15.5438 21.45 16.4524 21.45C17.361 21.45 18.0976 22.1887 18.0976 23.1ZM23.0331 24.75C23.9417 24.75 24.6782 24.0113 24.6782 23.1C24.6782 22.1887 23.9417 21.45 23.0331 21.45C22.1245 21.45 21.3879 22.1887 21.3879 23.1C21.3879 24.0113 22.1245 24.75 23.0331 24.75Z"
                                    fill="#FEB077"
                                  />
                                </svg>
                              )}
                            </div>
                            <a
                              href={file.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {file.name}
                              {console.log(file.server_modified)}
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel
                  value={isSignedToDropbox && isSignedToGDrive && "3"}
                  style={{ padding: "0", marginTop: "20px" }}
                >
                  {isSignedToDropbox && isSignedToGDrive && (
                    <div className="fetched-files-div">
                      {CombinedFiles.map((file) => (
                        <div
                          key={file.id}
                          className="fileicon-and-filename-div"
                        >
                          <div className="fileicon">
                            {file.name.endsWith(".jpg") ||
                            file.name.endsWith(".png") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 29 38"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#61A9DD"
                                />
                                <path
                                  d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M4.67773 28.5L11.2261 17.1L15.81 23.8059L19.0842 19.7823L24.323 28.5H4.67773Z"
                                  fill="#61A9DD"
                                />
                                <ellipse
                                  cx="7.4842"
                                  cy="13.3"
                                  rx="2.80646"
                                  ry="2.85"
                                  fill="#FFDB6E"
                                />
                              </svg>
                            ) : file.name.endsWith(".mp3") ||
                              file.name.endsWith(".mp4") ||
                              file.name.endsWith(".wav") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 34 44"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#FD7972"
                                />
                                <path
                                  d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                                  fill="#FD7972"
                                  stroke="#FD7972"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            ) : file.name.endsWith(".png") ||
                              file.name.endsWith(".jpg") ||
                              file.name.endsWith(".jpeg") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 34 44"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#61A9DD"
                                />
                                <path
                                  d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                  stroke="#61A9DD"
                                />
                                <path
                                  d="M5.48438 33L13.1618 19.8L18.536 27.5647L22.3748 22.9059L28.5167 33H5.48438Z"
                                  fill="#61A9DD"
                                />
                                <ellipse
                                  cx="8.77471"
                                  cy="15.4"
                                  rx="3.29034"
                                  ry="3.3"
                                  fill="#FFDB6E"
                                />
                              </svg>
                            ) : file.name.endsWith(".pdf") ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 29 38"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                  fill="#FD7972"
                                />
                                <path
                                  d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                  stroke="#FD7972"
                                />
                                <path
                                  d="M8.75845 28.5C8.54689 28.5 8.34385 28.435 8.17059 28.3124C7.53773 27.863 7.45262 27.363 7.49275 27.0223C7.60339 26.0856 8.82715 25.1051 11.1312 24.1062C12.0455 22.2096 12.9155 19.8729 13.434 17.9205C12.8273 16.6707 12.2376 15.0492 12.6674 14.0981C12.8182 13.7649 13.006 13.5094 13.3568 13.399C13.4954 13.3552 13.8456 13.3 13.9745 13.3C14.2809 13.3 14.5502 13.6734 14.7411 13.9036C14.9204 14.1199 15.3271 14.5785 14.5143 17.8175C15.3338 19.4195 16.4949 21.0513 17.6075 22.1688C18.4044 22.0324 19.0902 21.9628 19.6489 21.9628C20.6009 21.9628 21.1778 22.1728 21.4131 22.6055C21.6076 22.9634 21.528 23.3817 21.176 23.8484C20.8374 24.2966 20.3705 24.5337 19.8264 24.5337C19.0872 24.5337 18.2263 24.0918 17.2664 23.2189C15.5417 23.5601 13.5276 24.1689 11.8996 24.8427C11.3914 25.8635 10.9044 26.6857 10.4509 27.2888C9.8278 28.1145 9.29039 28.5 8.75845 28.5ZM10.3768 25.5504C9.07762 26.2415 8.54811 26.8094 8.50981 27.1294C8.50373 27.1823 8.48732 27.3216 8.77183 27.5275C8.86241 27.5005 9.39131 27.2721 10.3768 25.5504ZM18.6671 22.9945C19.1625 23.3553 19.2835 23.5377 19.6075 23.5377C19.7498 23.5377 20.1553 23.5319 20.3431 23.2839C20.4337 23.1636 20.469 23.0865 20.483 23.0451C20.4082 23.0077 20.3091 22.9318 19.7686 22.9318C19.4616 22.9323 19.0756 22.945 18.6671 22.9945ZM14.1258 19.2071C13.6912 20.6307 13.1173 22.1676 12.5003 23.5595C13.7708 23.0929 15.152 22.6855 16.4494 22.3972C15.6286 21.495 14.8086 20.3683 14.1258 19.2071ZM13.7568 14.338C13.6973 14.357 12.9483 15.349 13.8152 16.1885C14.3921 14.9716 13.783 14.33 13.7568 14.338Z"
                                  fill="#FD7972"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="38"
                                viewBox="0 0 34 44"
                                fill="none"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#0D0D0D"
                                />
                                <path
                                  opacity="0.05"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                  fill="#FEB077"
                                />
                                <path
                                  d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                  stroke="#FEB077"
                                />
                                <path
                                  d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                  stroke="#FEB077"
                                />
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.5169 23.1C11.5169 24.0113 10.7803 24.75 9.87173 24.75C8.96313 24.75 8.22656 24.0113 8.22656 23.1C8.22656 22.1887 8.96313 21.45 9.87173 21.45C10.7803 21.45 11.5169 22.1887 11.5169 23.1ZM18.0976 23.1C18.0976 24.0113 17.361 24.75 16.4524 24.75C15.5438 24.75 14.8072 24.0113 14.8072 23.1C14.8072 22.1887 15.5438 21.45 16.4524 21.45C17.361 21.45 18.0976 22.1887 18.0976 23.1ZM23.0331 24.75C23.9417 24.75 24.6782 24.0113 24.6782 23.1C24.6782 22.1887 23.9417 21.45 23.0331 21.45C22.1245 21.45 21.3879 22.1887 21.3879 23.1C21.3879 24.0113 22.1245 24.75 23.0331 24.75Z"
                                  fill="#FEB077"
                                />
                              </svg>
                            )}
                          </div>
                          <a
                            href={file.webViewLink || file.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.name}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
        <div className="summary-view">
          <div className="storage-view-content">
            <div className="storage-words">Storage summary</div>
            <div className="storage-flex">
              <div className="circle-chart-div">
                <div className="circle-chart">
                  <div className="circle-chart2">
                    {isSignedToGDrive && DisplayGDriveFilter ? (
                      <div>
                        <div className="storage-usage">
                          {formatSize(usageG)}
                        </div>
                        <div className="storage-limit">
                          of {formatSize(limitG)}
                        </div>
                      </div>
                    ) : isSignedToDropbox && DisplayDropboxFilter ? (
                      <div>
                        <div className="storage-usage">
                          {formatSize(storageUsage)}
                        </div>
                        <div className="storage-limit">
                          of {formatSize(storageLimit)}
                        </div>
                      </div>
                    ) : DisplayCombinedFilesFilter ? (
                      <div>
                        <div className="storage-usage">
                          {formatSize(Number(storageUsage) + Number(usageG))}
                        </div>
                        <div className="storage-limit">
                          of {formatSize(Number(storageLimit) + Number(limitG))}
                        </div>
                      </div>
                    ) : (
                      <div>N/A</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="categorized-files-div">
                <div className="categorized-files-each-border">
                  <div className="caticon-and-catdesc">
                    <div className="caticon">
                      <svg
                        width="34"
                        height="44"
                        viewBox="0 0 31 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                          fill="#111519"
                        />
                        <path
                          opacity="0.05"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                          fill="#409FFF"
                        />
                        <path
                          d="M2.49998 0.5H21.5348L30.4999 9.4228V37.5C30.4999 38.6049 29.604 39.5 28.4999 39.5H2.49998C1.39594 39.5 0.5 38.6049 0.5 37.5V2.49998C0.5 1.39508 1.39604 0.5 2.49998 0.5Z"
                          stroke="#409FFF"
                        />
                        <path
                          d="M30.5001 9.5H23.7294C22.4987 9.5 21.5 8.50231 21.5 7.27286V0.5"
                          stroke="#409FFF"
                        />
                        <path
                          d="M8 15C8 14.4477 8.44772 14 9 14H22C22.5523 14 23 14.4477 23 15V29C23 29.5523 22.5523 30 22 30H9C8.44772 30 8 29.5523 8 29V15Z"
                          fill="#409FFF"
                        />
                        <path
                          d="M18.8097 19.4083C18.8611 19.275 18.9382 19.175 19.0409 19.1083C19.1494 19.0361 19.2693 19 19.4006 19C19.5661 19 19.706 19.05 19.8202 19.15C19.9401 19.25 20 19.3861 20 19.5583C20 19.6417 19.9829 19.7333 19.9486 19.8333L18.1675 24.575C18.1161 24.7083 18.0247 24.8139 17.8934 24.8917C17.7678 24.9639 17.628 25 17.4738 25C17.3197 25 17.1798 24.9639 17.0542 24.8917C16.9286 24.8139 16.8402 24.7083 16.7888 24.575L15.5128 21.0667L14.2027 24.575C14.1513 24.7083 14.0628 24.8139 13.9372 24.8917C13.8116 24.9639 13.6717 25 13.5176 25C13.3692 25 13.2293 24.9639 13.098 24.8917C12.9724 24.8139 12.8839 24.7083 12.8325 24.575L11.0514 19.8333C11.0171 19.7389 11 19.65 11 19.5667C11 19.3944 11.0628 19.2583 11.1884 19.1583C11.314 19.0528 11.4624 19 11.6337 19C11.7707 19 11.8934 19.0361 12.0019 19.1083C12.1161 19.175 12.1989 19.275 12.2502 19.4083L13.569 23.1L14.9391 19.425C14.9905 19.2917 15.0704 19.1889 15.1789 19.1167C15.2931 19.0444 15.4129 19.0083 15.5385 19.0083C15.6698 19.0083 15.7897 19.0444 15.8982 19.1167C16.0067 19.1889 16.0866 19.2944 16.138 19.4333L17.4481 23.1667L18.8097 19.4083Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="catdesc">
                      <div className="catname">Documents</div>
                      <div className="catlength">
                        {DisplayGDriveFilter
                          ? GDriveDocumentFiles.length
                          : DisplayDropboxFilter
                          ? DropboxDocumentFiles.length
                          : DisplayCombinedFilesFilter &&
                            GDriveDocumentFiles.length +
                              DropboxDocumentFiles.length}{" "}
                        files
                      </div>
                    </div>
                  </div>
                  <div className="catsize">
                    {DisplayGDriveFilter
                      ? formatSize(getTotalSize(GDriveDocumentFiles))
                      : DisplayDropboxFilter
                      ? formatSize(getTotalSize(DropboxDocumentFiles))
                      : DisplayCombinedFilesFilter &&
                        formatSize(
                          getTotalSize(GDriveDocumentFiles) +
                            getTotalSize(DropboxDocumentFiles)
                        )}
                  </div>
                </div>
                <div className="categorized-files-each-border">
                  <div className="caticon-and-catdesc">
                    <div className="caticon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="44"
                        viewBox="0 0 34 44"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#0D0D0D"
                        />
                        <path
                          opacity="0.05"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#61A9DD"
                        />
                        <path
                          d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                          stroke="#61A9DD"
                        />
                        <path
                          d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                          stroke="#61A9DD"
                        />
                        <path
                          d="M5.48438 33L13.1618 19.8L18.536 27.5647L22.3748 22.9059L28.5167 33H5.48438Z"
                          fill="#61A9DD"
                        />
                        <ellipse
                          cx="8.77471"
                          cy="15.4"
                          rx="3.29034"
                          ry="3.3"
                          fill="#FFDB6E"
                        />
                      </svg>
                    </div>
                    <div className="catdesc">
                      <div className="catname">Images</div>
                      <div className="catlength">
                        {DisplayGDriveFilter
                          ? GDriveImageFiles.length
                          : DisplayDropboxFilter
                          ? DropboxImageFiles.length
                          : DisplayCombinedFilesFilter &&
                            GDriveImageFiles.length +
                              DropboxImageFiles.length}{" "}
                        files
                      </div>
                    </div>
                  </div>
                  <div className="catsize">
                    {DisplayGDriveFilter
                      ? formatSize(getTotalSize(GDriveImageFiles))
                      : DisplayDropboxFilter
                      ? formatSize(getTotalSize(DropboxImageFiles))
                      : DisplayCombinedFilesFilter &&
                        formatSize(
                          getTotalSize(GDriveImageFiles) +
                            getTotalSize(DropboxImageFiles)
                        )}
                  </div>
                </div>
                <div className="categorized-files-each-border">
                  <div className="caticon-and-catdesc">
                    <div className="caticon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="44"
                        viewBox="0 0 34 44"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#0D0D0D"
                        />
                        <path
                          opacity="0.05"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#FD7972"
                        />
                        <path
                          d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                          stroke="#FD7972"
                        />
                        <path
                          d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                          stroke="#FD7972"
                        />
                        <path
                          d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                          fill="#FD7972"
                          stroke="#FD7972"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="catdesc">
                      <div className="catname">Media Files</div>
                      <div className="catlength">
                        {DisplayGDriveFilter
                          ? GDriveMediaFiles.length
                          : DisplayDropboxFilter
                          ? DropboxMediaFiles.length
                          : DisplayCombinedFilesFilter &&
                            GDriveMediaFiles.length +
                              DropboxMediaFiles.length}{" "}
                        files
                      </div>
                    </div>
                  </div>
                  <div className="catsize">
                    {DisplayGDriveFilter
                      ? formatSize(getTotalSize(GDriveMediaFiles))
                      : DisplayDropboxFilter
                      ? formatSize(getTotalSize(DropboxMediaFiles))
                      : DisplayCombinedFilesFilter &&
                        formatSize(
                          getTotalSize(GDriveMediaFiles) +
                            getTotalSize(DropboxMediaFiles)
                        )}
                  </div>
                </div>
                <div className="categorized-files-each-border">
                  <div className="caticon-and-catdesc">
                    <div className="caticon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="44"
                        viewBox="0 0 34 44"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#0D0D0D"
                        />
                        <path
                          opacity="0.05"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#FEB077"
                        />
                        <path
                          d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                          stroke="#FEB077"
                        />
                        <path
                          d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                          stroke="#FEB077"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.5169 23.1C11.5169 24.0113 10.7803 24.75 9.87173 24.75C8.96313 24.75 8.22656 24.0113 8.22656 23.1C8.22656 22.1887 8.96313 21.45 9.87173 21.45C10.7803 21.45 11.5169 22.1887 11.5169 23.1ZM18.0976 23.1C18.0976 24.0113 17.361 24.75 16.4524 24.75C15.5438 24.75 14.8072 24.0113 14.8072 23.1C14.8072 22.1887 15.5438 21.45 16.4524 21.45C17.361 21.45 18.0976 22.1887 18.0976 23.1ZM23.0331 24.75C23.9417 24.75 24.6782 24.0113 24.6782 23.1C24.6782 22.1887 23.9417 21.45 23.0331 21.45C22.1245 21.45 21.3879 22.1887 21.3879 23.1C21.3879 24.0113 22.1245 24.75 23.0331 24.75Z"
                          fill="#FEB077"
                        />
                      </svg>
                    </div>
                    <div className="catdesc">
                      <div className="catname">Other Files</div>
                      <div className="catlength">
                        {DisplayGDriveFilter
                          ? GDriveOtherFiles.length
                          : DisplayDropboxFilter
                          ? DropboxOtherFiles.length
                          : DisplayCombinedFilesFilter &&
                            GDriveOtherFiles.length +
                              DropboxOtherFiles.length}{" "}
                        files
                      </div>
                    </div>
                  </div>
                  <div className="catsize">
                    {DisplayGDriveFilter
                      ? formatSize(getTotalSize(GDriveOtherFiles))
                      : DisplayDropboxFilter
                      ? formatSize(getTotalSize(DropboxOtherFiles))
                      : DisplayCombinedFilesFilter &&
                        formatSize(
                          getTotalSize(GDriveOtherFiles) +
                            getTotalSize(DropboxOtherFiles)
                        )}
                  </div>
                </div>
                <div className="categorized-files-each-border">
                  <div className="caticon-and-catdesc">
                    <div className="caticon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="34"
                        height="44"
                        viewBox="0 0 34 44"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#0D0D0D"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                          fill="#404040"
                          fill-opacity="0.05"
                        />
                        <path
                          d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                          stroke="#404040"
                        />
                        <path
                          d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                          stroke="#404040"
                        />
                        <path
                          d="M16.4288 27.5245C16.0791 27.5245 15.8009 27.4022 15.5943 27.1578C15.4035 26.9134 15.3082 26.5874 15.3082 26.18C15.3082 25.3489 15.4512 24.6563 15.7374 24.1022C16.0394 23.5482 16.4765 22.9371 17.0487 22.2689C17.4779 21.7637 17.7879 21.3482 17.9786 21.0222C18.1852 20.68 18.2886 20.2971 18.2886 19.8734C18.2886 19.4171 18.1217 19.0585 17.7879 18.7978C17.4699 18.5208 17.0328 18.3822 16.4765 18.3822C15.9678 18.3822 15.491 18.4882 15.0459 18.7C14.6167 18.9119 14.0366 19.2378 13.3054 19.6778C12.8444 19.9385 12.4629 20.0689 12.1609 20.0689C11.8271 20.0689 11.541 19.9222 11.3026 19.6289C11.08 19.3193 10.9688 18.9526 10.9688 18.5289C10.9688 18.2356 11.0164 17.9911 11.1118 17.7956C11.2231 17.5837 11.39 17.3882 11.6125 17.2089C12.3119 16.6385 13.1146 16.1985 14.0207 15.8889C14.9426 15.563 15.8645 15.4 16.7864 15.4C17.772 15.4 18.6541 15.5793 19.433 15.9378C20.2278 16.2963 20.8398 16.7934 21.2689 17.4289C21.714 18.0482 21.9365 18.7489 21.9365 19.5311C21.9365 20.1341 21.8173 20.6882 21.5789 21.1934C21.3564 21.6822 21.0861 22.1141 20.7682 22.4889C20.4503 22.8474 20.0211 23.2956 19.4807 23.8334C18.8767 24.4037 18.4237 24.8845 18.1217 25.2756C17.8196 25.6504 17.6289 26.0741 17.5494 26.5467C17.4858 26.8563 17.3507 27.1008 17.1441 27.28C16.9533 27.443 16.7149 27.5245 16.4288 27.5245ZM16.4765 33C15.8884 33 15.4035 32.8045 15.0221 32.4134C14.6406 32.006 14.4498 31.5008 14.4498 30.8978C14.4498 30.2948 14.6406 29.7978 15.0221 29.4067C15.4035 28.9993 15.8884 28.7956 16.4765 28.7956C17.0487 28.7956 17.5256 28.9993 17.9071 29.4067C18.2886 29.7978 18.4793 30.2948 18.4793 30.8978C18.4793 31.5008 18.2886 32.006 17.9071 32.4134C17.5256 32.8045 17.0487 33 16.4765 33Z"
                          fill="#404040"
                        />
                      </svg>
                    </div>
                    <div className="catdesc">
                      <div className="catname">Unknown Files</div>
                      <div className="catlength">
                        {DisplayGDriveFilter
                          ? GDriveUnknownFiles.length
                          : DisplayDropboxFilter
                          ? DropboxUnknownFiles.length
                          : DisplayCombinedFilesFilter &&
                            GDriveUnknownFiles.length +
                              DropboxUnknownFiles.length}{" "}
                        files
                      </div>
                    </div>
                  </div>
                  <div className="catsize">
                    {DisplayGDriveFilter
                      ? formatSize(getTotalSize(GDriveUnknownFiles))
                      : DisplayDropboxFilter
                      ? formatSize(getTotalSize(DropboxUnknownFiles))
                      : DisplayCombinedFilesFilter &&
                        formatSize(
                          getTotalSize(GDriveUnknownFiles) +
                            getTotalSize(DropboxUnknownFiles)
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
