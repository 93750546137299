import React from "react";
import { Bar } from "react-chartjs-2";
import { timeLeft } from "../../util.js";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { LeakRemoveTwoTone } from "@mui/icons-material";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
const TeamMembersPerformance = ({ teamMembers, userTasks, allMembers }) => {
  let labels = [];
  let excellent = [];
  let poor = [];
  let average = [];
  let rate = [];

  for (const key in teamMembers) {
    const findItem = allMembers.find((item) => item.id === key);
    if (findItem) labels.push(`${findItem.firstName} ${findItem.lastName} `);

    if (teamMembers.hasOwnProperty(key)) {
      const value = new Set(teamMembers[key]);

      value.forEach(async (item) => {
        excellent.push(20);
        poor.push(40);
        average.push(40);

        // All the tasks for their respective projects
        let allProjectTasks = userTasks.filter(
          (task) => task.projectId === item.id
        );

        // Time elapsed and the Time allocated inxs hours
        let projectElapseTime = timeLeft(item?.startDate, new Date()).days;
        let timeAllocated = timeLeft(item?.startDate, item?.endDate).days;
        let tasksCompleted = allProjectTasks.filter(
          (task) => task.status.toLowerCase() === "completed"
        );

        let calculateRate =
          projectElapseTime / timeAllocated +
          tasksCompleted.length / allProjectTasks.length;

        if (tasksCompleted.length === 0) {
          rate.push(1);
          return;
        } else if (tasksCompleted.length === allProjectTasks.length) {
          rate.push(99);
          return;
        } else if (calculateRate > 1 && calculateRate < 2) {
          rate.push((2 - calculateRate) * 100);
        } else if (Math.round(calculateRate) === 1 && projectElapseTime === 0) {
          rate.push(100);
        } else if (calculateRate < 1) {
          rate.push((1 - calculateRate) * 100);
        } else if (calculateRate === 1 && timeAllocated > 0) {
          rate.push(50);
        }
      });
    }
  }

  const optionsBar = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: false,
        text: "Team Members Efficiency",
      },
    },
    responsive: true,
    legend: {
      labels: {
        boxWidth: 10,
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false,
        gridLines: {
          display: true,
          drawBorder: false,
          borderDash: [3, 3],
          zeroLineColor: "transparent",
        },
        categoryPercentage: 0.7,
        barPercentage: 0.9,
        ticks: {
          beginAtZero: true,
        },
      },
      yAxes: {
        stacked: true,
        gridLines: {
          display: false,
          zeroLineColor: "transparent",
        },
        ticks: {
          // beginAtZero: true,
          max: 100,
          min: 0,
          // suggestedMax: 100,
        },
      },
    },
  };

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Poor",
        data: poor,
        backgroundColor: "#59ACFF",
        barThickness: 10,
        order: 2,
      },
      {
        label: "Rating",

        data: rate
          .slice(rate.length - 2)
          .map((item) => [item.toFixed(2) - 1, item.toFixed(2) + 1]),
        xAxisID: "x-axis-target",
        order: 1,
        backgroundColor: "#ED6F69",
        barThickness: 15,
      },
      {
        label: "Average",
        // data: getValArr[2],
        data: average,

        backgroundColor: "#FEB077",
        barThickness: 10,
        order: 2,
      },
      {
        label: "Excellent",
        // data: getValArr[3],
        data: excellent,

        backgroundColor: "#51D187",
        barThickness: 10,
        order: 2,
      },
    ],
  };

  return <Bar options={optionsBar} data={dataBar} />;
};

export default TeamMembersPerformance;
