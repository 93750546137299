import "./ReviewModal.css";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";

const ReviewModal = (props) => {
  const { data } = props;
  const { auth } = useAuth();
  const { cd } = props.data;
  const [msg, setMsg] = useState(null);

  return (
    <div
      className={`set__deadline__modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div
        className={`set__deadline__modal--content `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="set__deadline__modal__header">
          <div></div>
          <h4>{cd.name}</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        <div className="review__details">
          <p className="task__name">{data.title}</p>
          <p className="task__desc">{data.description}</p>
          {/*Input div */}
          <TextField
            id="standard-basic"
            label="Message..."
            color="secondary"
            variant="standard"
            autoComplete="off"
            onChange={(e) => setMsg(e.target.value)}
          />

          <div className="team__members">
            <p>Team member(s)</p>
            <br />

            <div className="members">
              {props.taskMembers &&
                props.taskMembers.map((item, index) =>
                  item === "[]" ? null : (
                    <p key={index} className="single">
                      {item.firstName.charAt(0)}
                    </p>
                  )
                )}
            </div>
          </div>
        </div>

        <a href="#" className="submit-tag" onClick={() => props.setToReview()}>
          Submit Task For Review
        </a>
      </div>
    </div>
  );
};

export default ReviewModal;
