import React from 'react'
import './contactmodal.css'
import CloseIcon from '@mui/icons-material/Close';

const ContactModal = (props) => {
  return ( 
    <div className={`contact__modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
      <div className="contact__modal--content" onClick={e => e.stopPropagation()}>
        <div className='contact__modal--content-header'>
          <h4>Contact Us</h4>
          <CloseIcon className='contact__close--icon' onClick={props.onClose} />
        </div>
        <div className="contact__inputs">
        <input type="text" placeholder='Name' />
        <input type="email" placeholder='Email Address' />
        <input type="text" placeholder='Subject'/>
        <textarea name="Message" id="nessage" cols="30" rows="4" placeholder='Message'/>
        </div>
        <a href="#" className='submit-tag' onClick={props.onClose}>Submit</a>
      </div>
    </div>
  )
}

export default ContactModal