import React, { useState, useEffect, useCallback, useRef } from "react";
import "./ChartAnalysis.css";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { BsDownload } from "react-icons/bs";
import toast from "react-hot-toast";
import LineChart from "./lineChart";
import TableReport from "./tableReport";
import ExportModal from "../TheModal/ExportModal";
import ExportProjectModal from "../TheModal/ExportProjectModal";
import LoadingScreen from "../preLoader";
import SpendSummary from "./spendSummary/spendSummary";
import { sortResponse, timeLeft } from "../../util.js";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { RiStackLine } from "react-icons/ri";
import { BsCheck2 } from "react-icons/bs";
import { AiOutlineDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ProjectPerformance } from "../charts";

const ChartAnalysis = () => {
  const { auth, currentOrganization, userProjects, config } = useAuth();
  const navigate = useNavigate();
  const [projectSum, setProjectSum] = useState(userProjects);
  const [show, setShow] = useState(false);
  const [showR, setShowR] = useState(false);
  const [dataTask, setDatatask] = useState([]);
  const [dropProjs, setDropProjs] = useState(false);
  const [curProj, setCurProj] = useState();
  const [singleP, setSingleP] = useState();
  const [sumT, setSumT] = useState([]);
  const [filter, setFilter] = useState("");
  const [r, setR] = useState(false);
  const [move, setMove] = useState();
  const [loading, setLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [j, setJ] = useState(false);
  const [adminUsersTasks, setAdminUsersTasks] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [categorizeUserByProjects, setCategorizeUserByProjects] = useState({});
  const [categorizeUserByTasks, setCategorizeUserByTasks] = useState({});
  const [initializePrint, setInitializePrint] = useState(false);
  const printableRef = useRef(null);

  const handlePrintClick = () => {
    setInitializePrint(true);

    // Clone the printable content to manipulate without affecting the original
    const printContents = printableRef.current.cloneNode(true);

    // Remove the divs with ids "remove--print"
    const removeDivs = printContents.querySelectorAll("#remove--print");
    removeDivs.forEach((removeDiv) => {
      removeDiv.parentNode.removeChild(removeDiv);
    });

    // const performanceChartDiv = printContents.querySelector(
    //   "#performance--chart"
    // );
    // if (performanceChartDiv) {
    //   performanceChartDiv.parentNode.removeChild(performanceChartDiv);
    // }

    // Add the following lines to set the background color to black
    const printContentsWithBlackBackground = `
    <html>
      <head>
        <style>
          body {
            background-color: black;
          }
        </style>
      </head>
      <body>${printContents.innerHTML}</body>
    </html>
  `;

    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContentsWithBlackBackground;
    window.print();
    document.body.innerHTML = originalContents;

    setInitializePrint(false);
  };

  // Get project details
  const getProjetDetails = async (id) => {
    try {
      const resp = await axios.get(`/project/${id}`, config);
      return resp.data;
    } catch (error) {
      console.log("Error getting project information", error);
    }
  };

  // Get all tasks of a project
  const getProjectTasks = async (id) => {
    try {
      const resp = await axios.get(`/task/all/${id}`, config);

      let previousLi = [];

      // Loop through the response data of tasks and append "projectId" to each element of the list
      resp.data.forEach(function (element) {
        element.projectId = id;

        previousLi.push(element);
      });

      return previousLi;
    } catch (error) {
      console.log("Error while getting a project's tasks", error);
      return;
    }
  };

  // Categorize tasks by users
  const getCategorizeUserTasks = async (user, projects) => {
    for (const project of projects) {
      let allProjectTasks = await getProjectTasks(project.id);
      setCategorizeUserByTasks((prevCategorizeUserByTasks) => {
        if (prevCategorizeUserByTasks[user]) {
          const newDataToAppend = [
            ...prevCategorizeUserByTasks[user],
            allProjectTasks,
          ];
          return {
            ...prevCategorizeUserByTasks,
            [user]: newDataToAppend,
          };
        } else {
          return {
            ...prevCategorizeUserByTasks,
            [user]: [allProjectTasks],
          };
        }
      });
    }

    console.log("Category of tasks", categorizeUserByTasks);
  };

  // Categorize projects by users
  const getCategorizeUserProjects = async (projects) => {
    for (const project of projects) {
      let projectDetails = await getProjetDetails(project.id);
      if (projectDetails.projectMembers.length > 0) {
        projectDetails.projectMembers.forEach((member) => {
          if (member.firstName && member.lastName) {
            let categoryKey = member.firstName + " " + member.lastName;
            setCategorizeUserByProjects((prevCategorizeUserByProjects) => {
              if (prevCategorizeUserByProjects[categoryKey]) {
                const newDataToAppend = [
                  // remove duplicates using the set constructor
                  ...new Set([
                    ...prevCategorizeUserByProjects[categoryKey],
                    project,
                  ]),
                ];
                return {
                  ...prevCategorizeUserByProjects,
                  [categoryKey]: newDataToAppend,
                };
              } else {
                return {
                  ...prevCategorizeUserByProjects,
                  [categoryKey]: [project],
                };
              }
            });
          }
        });
      } else {
        return;
      }
    }

    return categorizeUserByProjects;
  };

  // Used for a admin project efficiency
  const getUserProjectsAdmin = useCallback(
    async (projects) => {
      projects.forEach(async (item, index) => {
        const all = await getUserTasksForAdmin(item.id);
        if (index + 1 === projects.length) {
          setJ(true);
        }
      });
    },
    [projectSum]
  );

  // Used for an admin team members efficiency
  const getUserTasksForAdmin = useCallback(
    async (projectId) => {
      let previousLi = dataTask;
      try {
        const resp = await axios.get(`/task/all/${projectId}`, config);
        if (resp && resp.data) {
          resp.data.forEach(function (element, index) {
            element.projectId = projectId;

            previousLi.push(element);
          });

          setAdminUsersTasks(previousLi);
          return previousLi;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [projectSum]
  );

  useEffect(() => {
    if (userProjects.length > 0) {
      getUsersEfficiencies(userProjects);
      getTasks(sortResponse(userProjects)[0].id);
      getExpenses(sortResponse(userProjects)[0].id);
      setCurProj(userProjects[0]);
      // getUserProjectsAdmin(userProjects);
    }
  }, []);

  // Calculates the accurate rating for the project efficiency chart

  const getRates = (userProjects, userTasks) => {
    let rate = [];
    userProjects.forEach((item) => {
      // All the tasks for their respective projects

      let allProjectTasks = userTasks.filter(
        (task) => task.projectId === item.id
      );

      // * Get all the subtasks
      let subTasks = []; // Z(2)

      for (let s = 0; s < allProjectTasks.length; s++) {
        const task = allProjectTasks[s];

        if (task.subTasks && task.subTasks.length > 0) {
          task.subTasks.forEach((sub) => {
            subTasks.push(sub);
          });
        }
      }

      // Time elapsed and the Time allocated inxs hours
      let projectElapseTime = timeLeft(item.startDate, new Date()).days * 24; // Y(1)
      let timeAllocated = timeLeft(item.startDate, item.endDate).days * 24; // Y(2)

      let subTasksCompleted = subTasks.filter(
        (task) => task.status && task.status.toLowerCase() === "completed"
      ); // Z(1)

      let finalTime =
        projectElapseTime / (timeAllocated === 0 ? 1 : timeAllocated); // Y
      let finalSubtasks =
        subTasksCompleted.length /
        (subTasks.length === 0 ? 1 : subTasks.length); // Z

      // let calculateRate =
      //   subTasksCompleted.length /
      //   (subTasks.length === 0 ? 1 : subTasks.length);

      let teamMemberEffInDecimal = finalTime + finalSubtasks; // X(1)

      if (subTasksCompleted.length === 0 || subTasks.length === 0) {
        rate.push(1);
      } else if (teamMemberEffInDecimal > 2) {
        rate.push(1);
      } else if (subTasksCompleted.length === subTasks.length) {
        rate.push(99);
      } else if (teamMemberEffInDecimal === 1 && projectElapseTime > 0) {
        rate.push(50);
      } else if (teamMemberEffInDecimal > 1 && teamMemberEffInDecimal < 2) {
        if (finalTime > finalSubtasks) {
          let calVal = (teamMemberEffInDecimal - 1) * 100;

          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalSubtasks > finalTime) {
          let calVal = (2 - teamMemberEffInDecimal) * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalSubtasks > 0.5 && finalTime > 0.5) {
          let calVal = (teamMemberEffInDecimal / 2) * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        }
      } else if (teamMemberEffInDecimal === 1 && projectElapseTime === 0) {
        rate.push(99);
      } else if (teamMemberEffInDecimal < 1) {
        if (finalTime < 0.5 && finalSubtasks < 0.5) {
          let calVal = teamMemberEffInDecimal * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalTime > 0.5 && finalSubtasks < 0.5) {
          let calVal = (1 - teamMemberEffInDecimal) * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalTime < 0.5 && finalSubtasks > 0.5) {
          let calVal = teamMemberEffInDecimal * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else {
          rate.push(20);
        }
      } else {
        rate.push(1);
      }
    });
    //  return array

    // console.log("Various rating", rate);
    return rate;
  };

  useEffect(() => {
    const ratingArray = getRates(projectSum, adminUsersTasks);
    setRatings(ratingArray);
  }, [adminUsersTasks, projectSum, j]);

  const selectProject = async (item) => {
    setCurProj(item);
    setDropProjs(false);
    setLoading(false);
    getTasks(item.id);
    getExpenses(item.id);
  };

  const searchProj = (event) => {
    const { value } = event.target;

    const compare = projectSum.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilter(compare);

    if (compare.length === 0) {
      // setR(!r);
      setFilter("Not Found");
    }

    if (value !== "" && compare.length !== 0) {
      setProjectSum(compare);
    } else {
      setFilter("");
      setR(!r);
    }
  };

  const getExpenses = useCallback(async (id) => {
    try {
      const response = await axios.get(`/budgetexpense/expenses/${id}`, config);
      setExpenses(sortResponse(response.data));
    } catch (error) {
      toast.error("Failed!");
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (curProj) {
      getExpenses(curProj.id);
    }
  }, [curProj]);

  const getTasks = useCallback(
    async (id) => {
      try {
        const activeProject = projectSum.find((item) => item.id === id);
        activeProject ? setCurProj(activeProject) : setCurProj(projectSum[0]);
        const response = await axios.get(`/task/all/${id}`, config);

        setDatatask(response.data);
        const resp = await axios.get(`/project/${id}`, config);
        setSingleP(resp.data?.projectMembers);
        const checkComplete = response?.data.filter(
          (useMe) => useMe.status.toLowerCase() === "completed"
        );

        setMove(checkComplete);
        setLoading(true);
      } catch (error) {
        if (!error.response) {
          toast.error("Check your internet connection!");
        }
        console.log(error);
      }
    },
    [dataTask, r]
  );

  const getUsersEfficiencies = useCallback(
    async (projects) => {
      // First categories projects by team memebers
      // TODO: Then calculate the efficiency of that categoried projects using getRates function
      // TODO: Then find each cumulative averages

      const getDetails = await getCategorizeUserProjects(projects);
      // Object.entries(categorizeUserByProjects).forEach(async ([key, value]) => {
      //   await getCategorizeUserTasks(key, value);
      //   console.log(key);
      // });
    },
    [projectSum]
  );

  console.log("Categorized Users by projrcts", categorizeUserByProjects);

  return (
    <div className="project__page">
      <div className="project__header">
        <div className="chart__header">
          <ClickAwayListener
            onClickAway={() => {
              setDropProjs(false);
            }}
          >
            <div
              className="header__dropdown"
              onClick={() => setDropProjs(!dropProjs)}
            >
              <h2 className="text-lg">
                <span className="font-semibold">
                  {!curProj ? projectSum[0]?.name : curProj?.name}{" "}
                </span>
                Progress Report
              </h2>

              <AiOutlineDown fontSize={13} />
            </div>
          </ClickAwayListener>
          <div
            className={`projects__dropdown ${dropProjs && "show"}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="top__part">
              <article className="text-xl px-3 py-2 font-semibold">
                Projects
              </article>

              <input
                type="text"
                placeholder="Search Project..."
                onChange={searchProj}
              />
            </div>
            <div className="dropdown__list">
              {filter === "Not Found" ? (
                <p>Not Found</p>
              ) : (
                projectSum.map((item, index) => (
                  <div
                    className="dropdown__item"
                    key={index}
                    onClick={() => selectProject(item)}
                  >
                    <div className="icon__name">
                      <RiStackLine />
                      <div>{item.name}</div>
                    </div>
                    {index === 0 && !curProj && filter === "" ? (
                      <BsCheck2
                        color="#51D187"
                        className="check__icon"
                        size={20}
                      />
                    ) : null}
                    {curProj === item ? (
                      <BsCheck2
                        color="#51D187"
                        className="check__icon"
                        size={20}
                      />
                    ) : null}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        {loading && dataTask.length !== 0 && (
          <div className="project__header__buttons">
            <ExportProjectModal
              passProj={curProj}
              passData={dataTask}
              dataForTableData={dataTask}
              budget={expenses}
              orgaName={currentOrganization}
              projectMembers={singleP}
              getMembers={sumT}
              holdComp={move}
              handlePrintClick={handlePrintClick}
              footText="Export PDF"
              xlcText="Export EXCEL"
              title="Export Progress Report"
              onClose={() => setShow(false)}
              show={show}
            />
            <div className="flex justify-end mb-4">
              <div></div>
              <div
                className="border-solid border-2 cursor-pointer hover:scale-105 duration-200 ease-in border-orange-400 flex rounded-md p-3"
                onClick={() => setShow(true)}
              >
                <BsDownload className="mr-2 my-1" />
                Export
              </div>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <div className="projects" ref={printableRef}>
          <div>
            {dataTask.length !== 0 && (
              <div className="line__chart__arrange" id="remove--print">
                <LineChart teamMembers={dataTask} />
              </div>
            )}

            {dataTask.length !== 0 && (
              <div className="report_text" id="remove--print">
                <div className="report_text_space">
                  <div style={{ display: "flex" }}>
                    <h4>COMPLETED</h4>
                    <div
                      style={{
                        marginLeft: "0.5rem",
                        marginTop: "0.2rem",
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "4rem",
                        background: "#0059ACFF",
                      }}
                    ></div>
                  </div>
                  {dataTask
                    .filter(
                      (useMe) => useMe.status.toLowerCase() === "completed"
                    )
                    .map((useMe) => (
                      <p>{useMe.title}</p>
                    ))}
                </div>
                <div className="report_text_space">
                  <div style={{ display: "flex" }}>
                    <h4>IN PROGRESS</h4>
                    <div
                      style={{
                        marginLeft: "0.5rem",
                        marginTop: "0.2rem",
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "4rem",
                        background: "#3e444a",
                      }}
                    ></div>
                  </div>
                  {dataTask
                    .filter(
                      (useMe) =>
                        useMe.status.toLowerCase() === "in progress" ||
                        useMe.status.toLowerCase() === "in_progress"
                    )
                    .map((useMe) => (
                      <p>{useMe.title}</p>
                    ))}
                </div>
                <div className="report_text_space">
                  <div style={{ display: "flex" }}>
                    <h4>IN REVIEW</h4>
                    <div
                      style={{
                        marginLeft: "0.5rem",
                        marginTop: "0.2rem",
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "4rem",
                        background: "rgb(255 141 31)",
                      }}
                    ></div>
                  </div>
                  {dataTask
                    .filter(
                      (useMe) =>
                        useMe.status.toLowerCase() === "review" ||
                        useMe.status.toLowerCase() === "in review"
                    )
                    .map((useMe) => (
                      <p>{useMe.title}</p>
                    ))}
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <h4>TO-DO</h4>
                    <div
                      style={{
                        marginLeft: "0.5rem",
                        marginTop: "0.2rem",
                        width: "1rem",
                        height: "1rem",
                        borderRadius: "4rem",
                        background: "#51D187",
                      }}
                    ></div>
                  </div>
                  {dataTask
                    .filter((useMe) => useMe.status.toLowerCase() === "todo")
                    .map((useMe) => (
                      <div>
                        <p>{useMe.title}</p>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {dataTask.length !== 0 && (
              <div className="chart_spending px-[1rem]">
                <div
                  className="flex justify-between items-center"
                  id="remove--print"
                >
                  <h4
                    style={{
                      fontWeight: "500",
                      fontSize: "1.5rem",
                    }}
                  >
                    Project Efficiency
                  </h4>

                  <div
                    className="border-solid border-2 cursor-pointer hover:scale-105 duration-200 ease-in border-orange-400 flex rounded-md p-3"
                    onClick={() => setShowR(true)}
                  >
                    <BsDownload className="my-1" />
                    <p className="px-2">Export</p>
                  </div>
                </div>

                <div className="flex flex-row py-6 items-start space-x-5">
                  <div
                    className="w-[65%] border border-[#242424] rounded-2xl p-4"
                    id="remove--print"
                  >
                    {auth?.role === "SUPER_ADMIN" ? (
                      <ProjectPerformance
                        userProjects={projectSum}
                        userTasks={adminUsersTasks}
                        ratings={ratings}
                      />
                    ) : (
                      <div>Member Project Efficiency</div>
                    )}
                  </div>
                  <div className="w-[35%]">
                    <SpendSummary
                      data={dataTask}
                      rowsPerPage={7}
                      projId={
                        curProj
                          ? curProj?.id
                          : projectSum.length > 0 && projectSum[0].id
                      }
                    />
                  </div>
                </div>

                <ExportModal
                  getTask={dataTask}
                  saveProj={curProj}
                  budget={expenses}
                  passData={dataTask}
                  orgaName={currentOrganization}
                  footText="Export PDF"
                  xlcText="Export EXCEL"
                  title="Export Budget Summary"
                  onClose={() => setShowR(false)}
                  showR={showR}
                  projId={
                    curProj
                      ? curProj?.id
                      : projectSum.length > 0 && projectSum[0].id
                  }
                />
              </div>
            )}

            <div
              style={{
                marginLeft: "0.5rem",
                marginBottom: "0.5rem",
                border: "1px solid #242424",
                padding: "1rem",
                borderRadius: "1rem",
                width: "98%",
              }}
            >
              <h3 className="text-xl">
                Team members summary based on{" "}
                <span
                  className="text-[#ba68c8] cursor-pointer"
                  onClick={() => navigate("/frame/tasks")}
                >
                  tasks
                </span>{" "}
              </h3>

              {dataTask.length === 0 ? (
                <p className="text-lg p-5">No tasks on this project</p>
              ) : (
                <TableReport data={dataTask} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <LoadingScreen loading />
      )}
    </div>
  );
};

export default ChartAnalysis;
