import React, { useState } from "react";
import "./privacyPolicyPage.css";
import Navbar from "../navbar/Navbar";

const PrivacyPolicyPage = () => {
    return (
        <>
        <Navbar />
        <div>
        <div className="privacy__modal--content-header">
            <div className="privacy__flex">
                <h4>Privacy Policy</h4>
            </div>
          
            <div className="privacy__text">
                <h5><strong>Privacy and Safety Policy</strong></h5>
                <p>At Beatlab, we value your privacy and dedicated to maintaing the highest level of data protection and user safety. This outlines our commitment to safeguarding your information and ensuring a secure experience while using our platform</p>
                <br/>
                <p>Data Usage and Google Drive Integration</p>
                <br />
                <p>Beatlab operates in conjuction with Google Drive to provide you with seamless access and collaboration capabilities. As part of this integration, you grant our application rights to access, modify, create and delete specific Google Drive files that you use with our app
                    We will only interact with the files directly related to yor BeatLab activities
                </p>
                <br />
                <p>Data Proctection and Privacy</p>
                <br />
                <p>We employ industry standard security measeures to safeguard your personal information. We do not share your data with third parties unless explicitly authorized by you or when required by law. Your information is securely stored and accessible only to authorized personnel</p>
                <br />
                <p>User Safety</p>
                <br />
                <p>Your safety is of utmost importance to us. To ensure a secure user experince, we have implemented stringent measures, including:</p>
                <br />
                <ul>
                    <li>Verifying user identities to prevent fraudlent accounts</li>
                    <li>Monitoring user activity to detect and prevent any malicious behavior</li>
                    <li>Providing tools to report and block any users who violate our terms of services</li>
                </ul>
                <br/>
                <p>Providing reporting and blocking tools to address violations of our terms of service</p>
                <br />
                <p>We have also taken steps to protect minors on our platforms. Users under the age of 13 are prohibited from creating accounts, and we have implemented measures to prevent access to inappropriate content.</p>
                <br />
               <p>Privacy Updates</p>
               <br />
               <p>Our Privacy and Safety Policy may be updated periodically. We will notify you of any changes and provide an opportunity to review the updated policy before continuing to use our platform.</p>
               <br />
               <p>Contact Us</p>
               <br />
               <p>If you have any questions or concerns regarding our Privacy and Safety Policy, please reach out to us at session@beatlab.app. We are committed to providing a safe and secure experience for all users.</p>
               <br />
               <p>Thank you for choosing BeatLab. Your privacy and safety are our top priorites</p>
               <br />
               <h5><strong>Terms and Conditions</strong></h5>
               <p>Welcome to BeatLab. The collaboration and productivity tools for music and audio prodiction is designed to help you work more efficiently with your team. By using BeatLab, you agree to the following terms and conditions. Please
                read them carefully before using our service
               </p>
               <br />
               <ol>
                <li>Ownership and Theft Prevention
                    <p>You own the content yo create on BeatLab, but you are responsible for ensuring that any material you upload does not violate copyright or other intellectual property laws. BeatLab reserves the right to remove any material that
                        we believe infringes on someone else's intellectual property. Additionally, BeatLab does not tolerate any form of theft, piracy or illegal distribution of audio or music files. Any user found engaging in succ activities will be
                        immediately banned from the platform
                    </p>
                </li>
                <li>Prohibited Use
                    <p>Users are prohibited from using the BeatLab app for any illegal, unauthorized or inappropriate purposes. Inapppropriate use includes, but is not limite to</p>
                    <ul>
                        <li>Harrassment or discrimination of other users</li>
                        <li>Posting inappropriate or offensive content</li>
                        <li>Using BeatLab to distribute malicious software or engage in hacking activites</li>
                        <li>Engaging in any activites that violate the privacy of other users</li>
                    </ul>
                </li>
                <li>User Conduct
                    <p>Any user found to be engaging in any form of misconduct on the BeatLab app will be immediately suspended and may be reported to the appropriate authorities. Misconduct includes, but is not
                        limited to:
                        <li>Bullying, harrassing or threatening other users</li>
                        <li>Creating multiple accounts to engage in fraudlent activities</li>
                        <li>Disrupting the BeatLab community through spam or other malicious activities</li>
                    </p>
                </li>
                <li>Privacy and Data Protection
                    <p>BeatLab values your privacy and is commited to protecting your personal information. We collect only the data we need to provide you with the best experience possible, and we never sell or share your information with
                        third parties. We use industry standard encryption to protect your data, and we regularly review and update our security measures to ensure the highest level of protection. Please refere to the 
                        <span>Privacy and safety section</span> for more information
                    </p>
                </li>
                <li>Termination
                    <p>BeatLab reserves the right to terminate your account at any time with or without notice, if we believe that you have violated any of these terms and conditions.</p>
                </li>
               </ol>
               <br />
               <p>Thank you for using BeatLab. We hope you enjoy collaborating and creating with our platform</p>
               <br/>
               <h5><strong>Activity Log</strong></h5>
               <p>The 10 most recent actions the user has done on the app should be shown here</p>
               <br />
               <h5><strong>Follow us</strong></h5>
               <p>IG: @thehouseofsounds</p>
               <p>TWITTER: @THOSEAPP</p>
            </div>
        </div>
        </div>
        </>
    )
}

export default PrivacyPolicyPage;
