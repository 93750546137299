import React, { useState, useCallback, useEffect } from "react";
import "./BudgetPage.css";
import { AddProjectModal } from "../Modals";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { BudgetCard, ProjectCard } from "../Cards";
import { FilterModal } from "../Modals";
import Drawer from "@mui/material/Drawer";
import useAuth from "../../hooks/useAuth";
import LoadingScreen from "../preLoader";
import { getOrgProjects, sortResponse } from "../../util.js";
import filterIcon from "../../assets/Filter.png";
import { Tooltip } from "@mui/material";

const BudgetPage = () => {
  const {
    auth,
    currentOrganization,
    userProjects,
    UpdateProjectsGlobalState,
    config,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showaddModal, setAddModal] = useState(false);
  const [value, setValue] = useState("1");
  const [state, setState] = useState({
    right: false,
  });
  const [projects, setProjects] = useState(userProjects);
  const [hold, setHold] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [progress, setProgress] = useState([]);

  const getProjects = useCallback(async () => {
    try {
      const response = await getOrgProjects(
        currentOrganization?.id,
        auth?.id,
        auth?.role,
        config
      );
      UpdateProjectsGlobalState(response.data); // This will update the global state storing the user's projects

      setProjects(sortResponse(response.data));
      setLoading(true);
      getStatus(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [currentOrganization?.id, auth?.id, auth?.role]);

  const getStatus = (res) => {
    setHold([]);
    setProgress([]);
    setCompleted([]);

    setHold(res.filter((item) => item.status.toLowerCase() === "on_hold"));
    setProgress(
      res.filter(
        (item) =>
          item.status.toLowerCase() === "in_progress" ||
          item.status.toLowerCase() === "in progress"
      )
    );
    setCompleted(
      res.filter((item) => item.status.toLowerCase() === "completed")
    );
  };

  useEffect(() => {
    setLoading(true);
    getStatus(userProjects);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let categories = [
    {
      value: "1",
      label: "all",
      content: "All  ",
      type: projects,
    },

    {
      value: "2",

      label: "in progress",
      content: "In Progress ",
      type: progress || [],
    },
    {
      value: "3",

      label: "on hold",
      content: "On Hold ",
      type: hold || [],
    },
    {
      value: "4",

      label: "completed",
      content: "Completed ",
      type: completed || [],
    },
  ];

  return (
    <div className="project__page">
      <div className="project__header">
        <p>Projects</p>
        <div className="project__header__buttons">
          <Tooltip title="Filter" placement="top">
            <div className="equalizer" onClick={toggleDrawer("right", true)}>
              <img src={filterIcon} alt="icon" fontSize={20} />
            </div>
          </Tooltip>
          <div
            className="add__project__button"
            onClick={() => {
              setAddModal(true);
            }}
          >
            + Add Project
          </div>

          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            <FilterModal right="right" />
          </Drawer>

          <AddProjectModal
            onClose={() => {
              setAddModal(false);
            }}
            show={showaddModal}
            getProjects={getProjects}
          />
        </div>
      </div>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab"
            className="border-b border-b-[#353535]"
          >
            {categories.map((item, index) => (
              <Tab
                label={`${
                  item.label.toUpperCase() + " [" + item.type.length + "]"
                } `}
                value={item.value}
                key={index}
              />
            ))}
          </TabList>
        </Box>

        {/*ALL*/}
        <TabPanel p={8} value={"1"}>
          {loading ? (
            <div className="project__items">
              {projects.length !== 0 ? (
                projects?.map((item, index) => (
                  <BudgetCard
                    key={index}
                    data={item}
                    rr={() => {
                      getProjects();
                    }}
                  />
                ))
              ) : (
                <ProjectCard />
              )}
            </div>
          ) : (
            <LoadingScreen loading />
          )}
        </TabPanel>

        {/* IN PROGRESS*/}
        <TabPanel p={8} value={"2"}>
          <div className="project__items">
            {progress.length !== 0 ? (
              progress.map((item, index) => (
                <BudgetCard
                  key={index}
                  data={item}
                  rr={() => {
                    getProjects();
                  }}
                />
              ))
            ) : (
              <p className="empty__tab"></p>
            )}
          </div>
        </TabPanel>

        {/* ON HOLD*/}
        <TabPanel p={8} value={"3"}>
          <div className="project__items">
            {hold.length !== 0 ? (
              hold.map((item, index) => (
                <BudgetCard
                  key={index}
                  data={item}
                  rr={() => {
                    getProjects();
                  }}
                />
              ))
            ) : (
              <p className="empty__tab"></p>
            )}
          </div>
        </TabPanel>

        {/* COMPLETED*/}
        <TabPanel p={8} value={"4"}>
          <div className="project__items">
            {completed.map((item, index) => (
              <BudgetCard
                key={index}
                data={item}
                rr={() => {
                  getProjects();
                }}
              />
            ))}
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default BudgetPage;
