import axios from "axios";

const dataMuse = axios.create({
  baseURL: "https://api.datamuse.com",
});

const firstVersion = axios.create({
  baseURL: "https://www.api.beatlab.app/api/v1/", //FOR PRODUCTION
  // baseURL: "https://www.api-test.beatlab.app/api/", //FOR STAGING
});

const backEndApi = axios.create({
  baseURL: "https://www.api.beatlab.app/api/v1/auth/", //FOR PRODUCTION
  // baseURL: "https://www.api-test.beatlab.app/api/auth/", //FOR STAGING
});

const mockSever = axios.create({
  // baseURL: "http://localhost:4000/api/", //DEVELOPMENT URL
  baseURL: "https://kens-those-server-z0bk.onrender.com/api/", //PRODUCTION URL
});

export default backEndApi;
export { dataMuse, firstVersion, mockSever };
