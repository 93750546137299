import React, { useState, useEffect, useCallback } from "react";
import "./CalendarPage.css";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { DatePicker } from "./Datepicker";
import { AiOutlinePlus } from "react-icons/ai";
import ProgressBar from "./ProgressBar";
import { MenuData } from "./MenuData";
import SubMenu from "./SubMenu";
import { RiEqualizerLine } from "react-icons/ri";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { sortResponse, getOrgProjects } from "../../util.js";

import { AddProjectModal } from "../Modals";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { RiStackLine, RiUserAddLine } from "react-icons/ri";
import { BsCheck2, BsGrid1X2Fill } from "react-icons/bs";
import { AiOutlineDown } from "react-icons/ai";
import { HiViewGrid } from "react-icons/hi";

import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
//import axios from "axios";
import toast from "react-hot-toast";
const Button6 = ({ onClick, title }) => (
  <div
    style={{
      height: " 65px",
      // left: 0,
      // position: "absolute",
      // width: "297px",
      background: "#0D0D0D",
      // border: "1px solid #242424",
      fontSize: "1.2rem",
      color: "#59ACFF",
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
    }}
  >
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <AiOutlinePlus />
      </div>
      <div style={{ marginLeft: "0.7rem" }} onClick={onClick}>
        {" "}
        {title}
      </div>
    </div>
  </div>
);
const handleClick = () => {
  alert("process add list..");
};

const CalendarPage = ({ className }) => {
  const { auth, currentOrganization } = useAuth();
  const [completed, setCompleted] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [valueState, setValueState] = useState(null);
  const [value, setValue] = useState(0);
  const [toggle, setToggle] = useState(1);
  const [showaddModal, setAddModal] = useState(false);

  const [dataTask, setDatatask] = React.useState([]);
  const [projectData, setProjectdata] = useState([]);
  const [dropProjs, setDropProjs] = useState(false);
  const [curProj, setCurProj] = useState();
  const [filter, setFilter] = useState("");
  const [r, setR] = useState(false);
  const [projId, setprojId] = useState();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownItemTitle, setDropdownItemTitle] = useState("");
  useEffect(() => {
    setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1), 2000);
  }, []);

  const dateFormat = "MMMM-yyyy";
  const viewsubVal = (subNavData) => {
    setValueState(subNavData.subnav);
    setDropdownItemTitle(subNavData.title);
  };
  const AuthStr = "Bearer ".concat(auth?.authToken);
  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getOrgProjects(
          currentOrganization?.id,
          auth?.id,
          auth?.role
        );
        setProjects(sortResponse(response.data));
        setProjectdata(response.data);
        setLoading(false);

        if (response.data.length > 0) {
          setLoading(true);
          await getTasks(response.data[0].id);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [r]);
 
  const onDateClick = (day) => {
    setSelectedDate(day);
  };
  //console.log(valueState)
  var day1 = new Date(dataTask[0]?.startDate);
  var curDate = new Date().toISOString().slice(0, 10);
  var useDate = new Date(curDate);
  var day2 = new Date(dataTask[0]?.endDate);
  // var diffTime = day2.getTime() - day1.getTime();
  var curDateTime = day2.getTime() - useDate.getTime();
  //  var diffDays = diffTime / (1000 * 3600 * 24);
  var curDays = curDateTime / (1000 * 3600 * 24);

  const selectProject = async (item) => {
   
    setCurProj(item);
    setDropProjs(false);
    setprojId(item.id);
    await getTasks(item.id);
   // setLoading(false);
   
  };
  // console.log(useData)
  const searchProj = (event) => {
    const { value } = event.target;

    const compare = projectData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilter(compare);

    if (compare.length === 0) {
      // setR(!r);
      setFilter("Not Found");
    }

    if (value !== "" && compare !== []) {
      setProjectdata(compare);
    } else {
      setFilter("");
      setR(!r);
    }
  };

  const getTasks = useCallback(
    async (id) => {
   
      try {
        const activeProject = projects.find((item) => item.id === id);
        activeProject ? setCurProj(activeProject) : setCurProj(projects[0]);
        const response = await axios.get(`/task/all/${id}`, config);
       // console.log(dataTask)
       
        setDatatask(response.data);
       
      setLoading(true);
      } catch (error) {
        if (!error.response) {
          toast.error("Check your internet connection!");
        }
        console.log(error);
      }
    },
     [projects]
  );
  //removing shortly...
//   var planWidth,
//     sWidth,
//     mixMidth,
//     prepWidth,
//     volWidth,
//     compWidth,
//     eWidth,
//     spaceWidth,
//     vaWidth,
//     trackWidth,
//     mastWidth;
//   var motionComp,
//     motionE,
//     motionVol,
//     motionPlan,
//     motionMix,
//     motionPrep,
//     motionS,
//     motionSpace,
//     motionVA,
//     motionT,
//     motionMast;
//   if (useDate > day1) {
//     if (curDays >= 20) {
//       planWidth = "995px";
//       sWidth = "995px";
//       mixMidth = "995px";
//       prepWidth = "995px";
//       volWidth = "995px";
//       compWidth = "995px";
//       eWidth = "995px";
//       spaceWidth = "995px";
//       vaWidth = "995px";
//       trackWidth = "995px";
//       mastWidth = "995px";
//     } else if (curDays === 19) {
//       planWidth = "980px";
//       sWidth = "980px";
//       mixMidth = "980px";
//       prepWidth = "980px";
//       volWidth = "980px";
//       compWidth = "980px";
//       eWidth = "980px";
//       spaceWidth = "980px";
//       vaWidth = "980px";
//       trackWidth = "980px";
//       mastWidth = "980px";
//     }
//  else {
//       planWidth = "80px";
//       sWidth = "80px";
//       mixMidth = "80px";
//       prepWidth = "80px";
//       volWidth = "80px";
//       compWidth = "80px";
//       eWidth = "80px";
//       spaceWidth = "80px";
//       vaWidth = "80px";
//       trackWidth = "80px";
//       mastWidth = "80px";
//     }
//   } else {
//     if (useDate.getDate() + 1 === day1.getDate()) {
//       motionPlan = 80;
//       motionComp = 80;
//       motionE = 80;
//       motionVol = 80;
//       motionPlan = 80;
//       motionMix = 80;
//       motionPrep = 80;
//       motionS = 80;
//       motionSpace = 80;
//       motionVA = 80;
//       motionT = 80;
//       motionMast = 80;
//       sWidth = "880px";
//       planWidth = "880px";
//       mixMidth = "880px";
//       prepWidth = "880px";
//       volWidth = "880px";
//       compWidth = "880px";
//       eWidth = "880px";
//       spaceWidth = "880px";
//       vaWidth = "880px";
//       trackWidth = "880px";
//       mastWidth = "880px";
//     } else if (useDate.getDate() + 2 === day1.getDate()) {
//       motionPlan = 130;
//       motionComp = 130;
//       motionE = 80;
//       motionVol = 130;
//       motionPlan = 130;
//       motionMix = 130;
//       motionPrep = 130;
//       motionS = 130;
//       motionSpace = 130;
//       motionVA = 130;
//       motionT = 130;
//       motionMast = 130;
//       sWidth = "830px";
//       planWidth = "830px";
//       mixMidth = "830px";
//       prepWidth = "830px";
//       volWidth = "830px";
//       compWidth = "830px";
//       eWidth = "830px";
//       spaceWidth = "830px";
//       vaWidth = "830px";
//       trackWidth = "830px";
//       mastWidth = "830px";
//     } else if (useDate.getDate() + 3 === day1.getDate()) {
//       motionPlan = 180;
//       motionComp = 180;
//       motionE = 180;
//       motionVol = 180;
//       motionPlan = 180;
//       motionMix = 180;
//       motionPrep = 180;
//       motionS = 180;
//       motionSpace = 180;
//       motionVA = 180;
//       motionT = 180;
//       motionMast = 180;
//       sWidth = "780px";
//       planWidth = "780px";
//       mixMidth = "780px";
//       prepWidth = "780px";
//       volWidth = "780px";
//       compWidth = "780px";
//       eWidth = "780px";
//       spaceWidth = "780px";
//       vaWidth = "780px";
//       trackWidth = "780px";
//       mastWidth = "780px";
//     } else if (useDate.getDate() + 4 === day1.getDate()) {
//       motionPlan = 230;
//       motionComp = 230;
//       motionE = 230;
//       motionVol = 230;
//       motionPlan = 230;
//       motionMix = 230;
//       motionPrep = 230;
//       motionS = 230;
//       motionSpace = 230;
//       motionVA = 230;
//       motionT = 230;
//       motionMast = 230;
//       sWidth = "730px";
//       planWidth = "730px";
//       mixMidth = "730px";
//       prepWidth = "730px";
//       volWidth = "730px";
//       compWidth = "730px";
//       eWidth = "730px";
//       spaceWidth = "730px";
//       vaWidth = "730px";
//       trackWidth = "730px";
//       mastWidth = "730px";
//     } else if (useDate.getDate() + 5 === day1.getDate()) {
//       motionPlan = 280;
//       motionComp = 280;
//       motionE = 280;
//       motionVol = 280;
//       motionPlan = 280;
//       motionMix = 280;
//       motionPrep = 280;
//       motionS = 280;
//       motionSpace = 280;
//       motionVA = 280;
//       motionT = 280;
//       motionMast = 280;
//       sWidth = "680px";
//       planWidth = "680px";
//       mixMidth = "680px";
//       prepWidth = "680px";
//       volWidth = "680px";
//       compWidth = "680px";
//       eWidth = "680px";
//       spaceWidth = "680px";
//       vaWidth = "680px";
//       trackWidth = "680px";
//       mastWidth = "680px";
//     } else if (useDate.getDate() + 6 === day1.getDate()) {
//       motionPlan = 330;
//       motionComp = 330;
//       motionE = 330;
//       motionVol = 330;
//       motionPlan = 330;
//       motionMix = 330;
//       motionPrep = 330;
//       motionS = 330;
//       motionSpace = 330;
//       motionVA = 330;
//       motionT = 330;
//       motionMast = 330;
//       sWidth = "630px";
//       planWidth = "630px";
//       mixMidth = "630px";
//       prepWidth = "630px";
//       volWidth = "630px";
//       compWidth = "630px";
//       eWidth = "630px";
//       spaceWidth = "630px";
//       vaWidth = "630px";
//       trackWidth = "630px";
//       mastWidth = "630px";
//     } else if (useDate.getDate() + 6 === day1.getDate()) {
//       motionPlan = 380;
//       motionComp = 380;
//       motionE = 380;
//       motionVol = 380;
//       motionPlan = 380;
//       motionMix = 380;
//       motionPrep = 380;
//       motionS = 380;
//       motionSpace = 380;
//       motionVA = 380;
//       motionT = 380;
//       motionMast = 380;
//       sWidth = "580px";
//       planWidth = "580px";
//       mixMidth = "580px";
//       prepWidth = "580px";
//       volWidth = "580px";
//       compWidth = "580px";
//       eWidth = "580px";
//       spaceWidth = "580px";
//       vaWidth = "580px";
//       trackWidth = "580px";
//       mastWidth = "580px";
//     } else if (useDate.getDate() + 7 === day1.getDate()) {
//       motionPlan = 430;
//       motionComp = 430;
//       motionE = 430;
//       motionVol = 430;
//       motionPlan = 430;
//       motionMix = 430;
//       motionPrep = 430;
//       motionS = 430;
//       motionSpace = 430;
//       motionVA = 430;
//       motionT = 430;
//       motionMast = 430;
//       sWidth = "530px";
//       planWidth = "530px";
//       mixMidth = "530px";
//       prepWidth = "530px";
//       volWidth = "530px";
//       compWidth = "530px";
//       eWidth = "530px";
//       spaceWidth = "530px";
//       vaWidth = "530px";
//       trackWidth = "530px";
//       mastWidth = "530px";
//     } else if (useDate.getDate() + 8 === day1.getDate()) {
//       motionPlan = 480;
//       motionComp = 480;
//       motionE = 480;
//       motionVol = 480;
//       motionPlan = 480;
//       motionMix = 480;
//       motionPrep = 480;
//       motionS = 480;
//       motionSpace = 480;
//       motionVA = 480;
//       motionT = 480;
//       motionMast = 480;
//       sWidth = "480px";
//       planWidth = "480px";
//       mixMidth = "480px";
//       prepWidth = "480px";
//       volWidth = "480px";
//       compWidth = "480px";
//       eWidth = "480px";
//       spaceWidth = "480px";
//       vaWidth = "480px";
//       trackWidth = "480px";
//       mastWidth = "480px";
//     } else if (useDate.getDate() + 9 === day1.getDate()) {
//       motionPlan = 530;
//       motionComp = 530;
//       motionE = 530;
//       motionVol = 530;
//       motionPlan = 530;
//       motionMix = 530;
//       motionPrep = 530;
//       motionS = 530;
//       motionSpace = 530;
//       motionVA = 530;
//       motionT = 530;
//       motionMast = 530;
//       sWidth = "430px";
//       planWidth = "430px";
//       mixMidth = "430px";
//       prepWidth = "430px";
//       volWidth = "430px";
//       compWidth = "430px";
//       eWidth = "430px";
//       spaceWidth = "430px";
//       vaWidth = "430px";
//       trackWidth = "430px";
//       mastWidth = "430px";
//     } else if (useDate.getDate() + 10 === day1.getDate()) {
//       motionPlan = 580;
//       motionComp = 580;
//       motionE = 580;
//       motionVol = 580;
//       motionPlan = 580;
//       motionMix = 580;
//       motionPrep = 580;
//       motionS = 580;
//       motionSpace = 580;
//       motionVA = 580;
//       motionT = 580;
//       motionMast = 580;
//       sWidth = "380px";
//       planWidth = "380px";
//       mixMidth = "380px";
//       prepWidth = "380px";
//       volWidth = "380px";
//       compWidth = "380px";
//       eWidth = "380px";
//       spaceWidth = "380px";
//       vaWidth = "380px";
//       trackWidth = "380px";
//       mastWidth = "380px";
//     } else if (useDate.getDate() + 11 === day1.getDate()) {
//       motionPlan = 630;
//       motionComp = 630;
//       motionE = 630;
//       motionVol = 630;
//       motionPlan = 630;
//       motionMix = 630;
//       motionPrep = 630;
//       motionS = 630;
//       motionSpace = 630;
//       motionVA = 630;
//       motionT = 630;
//       motionMast = 630;
//       sWidth = "330px";
//       planWidth = "330px";
//       mixMidth = "330px";
//       prepWidth = "330px";
//       volWidth = "330px";
//       compWidth = "330px";
//       eWidth = "330px";
//       spaceWidth = "330px";
//       vaWidth = "330px";
//       trackWidth = "330px";
//       mastWidth = "330px";
//     } else if (useDate.getDate() + 12 === day1.getDate()) {
//       motionPlan = 680;
//       motionComp = 680;
//       motionE = 680;
//       motionVol = 680;
//       motionPlan = 680;
//       motionMix = 680;
//       motionPrep = 680;
//       motionS = 680;
//       motionSpace = 680;
//       motionVA = 680;
//       motionT = 680;
//       motionMast = 680;
//       sWidth = "280px";
//       planWidth = "280px";
//       mixMidth = "280px";
//       prepWidth = "280px";
//       volWidth = "280px";
//       compWidth = "280px";
//       eWidth = "280px";
//       spaceWidth = "280px";
//       vaWidth = "280px";
//       trackWidth = "280px";
//       mastWidth = "280px";
//     } else if (useDate.getDate() + 13 === day1.getDate()) {
//       motionPlan = 730;
//       motionComp = 730;
//       motionE = 730;
//       motionVol = 730;
//       motionPlan = 730;
//       motionMix = 730;
//       motionPrep = 730;
//       motionS = 730;
//       motionSpace = 730;
//       motionVA = 730;
//       motionT = 730;
//       motionMast = 730;
//       sWidth = "230px";
//       planWidth = "230px";
//       mixMidth = "230px";
//       prepWidth = "230px";
//       volWidth = "230px";
//       compWidth = "230px";
//       eWidth = "230px";
//       spaceWidth = "230px";
//       vaWidth = "230px";
//       trackWidth = "230px";
//       mastWidth = "230px";
//     } else if (useDate.getDate() + 14 === day1.getDate()) {
//       motionPlan = 780;
//       motionComp = 780;
//       motionE = 780;
//       motionVol = 780;
//       motionPlan = 780;
//       motionMix = 780;
//       motionPrep = 780;
//       motionS = 780;
//       motionSpace = 780;
//       motionVA = 780;
//       motionT = 780;
//       motionMast = 780;
//       sWidth = "180px";
//       planWidth = "180px";
//       mixMidth = "180px";
//       prepWidth = "180px";
//       volWidth = "180px";
//       compWidth = "180px";
//       eWidth = "180px";
//       spaceWidth = "180px";
//       vaWidth = "180px";
//       trackWidth = "180px";
//       mastWidth = "180px";
//     } else if (useDate.getDate() + 15 === day1.getDate()) {
//       motionPlan = 830;
//       motionComp = 830;
//       motionE = 830;
//       motionVol = 830;
//       motionPlan = 830;
//       motionMix = 830;
//       motionPrep = 830;
//       motionS = 830;
//       motionSpace = 830;
//       motionVA = 830;
//       motionT = 830;
//       motionMast = 830;
//       sWidth = "130px";
//       planWidth = "130px";
//       mixMidth = "130px";
//       prepWidth = "130px";
//       volWidth = "130px";
//       compWidth = "130px";
//       eWidth = "130px";
//       spaceWidth = "130px";
//       vaWidth = "130px";
//       trackWidth = "130px";
//       mastWidth = "130px";
//     }
//   }
  return (
    <div className="project__page" style={{ background: "#0D0D0D" }}>
      {loading ?
      (
        <div>
      <div className="project__header">
        <div className="taskcal__header">
          <ClickAwayListener
            onClickAway={() => {
              setDropProjs(false);
            }}
          >
            <div
              className="header__dropdown"
              onClick={() => setDropProjs(!dropProjs)}
            >
              <p className="welcome__header">Welcome {auth.firstName}</p>
              {/* <p>{!curProj ? projectData[0]?.name : curProj?.name}  .</p> */}

              <AiOutlineDown fontSize={13} />
            </div>
          </ClickAwayListener>
          {projectData.length !== 0 && (
            <div
              className={`projects__dropdown ${dropProjs && "show"}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="top__part">
                <p>projects</p>

                <input
                  type="text"
                  placeholder="Search Project..."
                  onChange={searchProj}
                />
              </div>
              <div className="dropdown__list">
                {filter === "Not Found" ? (
                  <p>Not Found</p>
                ) : (
                  projectData.map((item, index) => (
                    <div
                      className="dropdown__item"
                      key={index}
                      onClick={() => selectProject(item)}
                    >
                      <div className="icon__name">
                        <RiStackLine />
                        <div>{item.name}</div>
                      </div>
                      {index === 0 && !curProj && filter === "" ? (
                        <BsCheck2
                          color="#51D187"
                          className="check__icon"
                          size={20}
                        />
                      ) : null}
                      {curProj === item ? (
                        <BsCheck2
                          color="#51D187"
                          className="check__icon"
                          size={20}
                        />
                      ) : null}
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
        <div className="header row flex-middle">
          <div style={{ marginLeft: "12rem", fontSize: "26px" }}>
            <span>{format(currentDate, dateFormat).toUpperCase()}</span>
          </div>
        </div>
        <div className="project__header__buttons">
          <Link className="equalizer" to="/frame/tasks">
            <HiViewGrid fontSize={15} />
          </Link>
          <div
            onClick={() => {
              setAddModal(true);
            }}
            className="add__project__button"
          >
            + Add Project
          </div>
        </div>

        <AddProjectModal
          onClose={() => {
            // rrender();
            setAddModal(false);
          }}
          // refresh={rrender}
          show={showaddModal}
          rr={() => {
            setR(!r);
          }}
        />
      </div>
      <div
        style={{
          alignItems: "center",
          background: "#0D0D0D",
          display: "flex",
          marginRight: "-27px",
          marginTop: "9px",
          width: "100%",
          border: "1px solid #242424",
          height: "66px",
        }}
      >
        <div style={{ width: "20%", padding: "1rem", fontSize: "1.2rem" }}>
          {curProj?.name} project
        </div>
        <div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            height: "66px",
          }}
        >
          <DatePicker lastDate={20} />
        </div>
      </div>
      <div
        style={{
          //  alignItems: "flex-start",
          display: "flex",
          flexDirection: " row",
          //  minHeight: " 470px",
          height: "100vh",
          position: "relative",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "20%",
            position: "relative",
          }}
        >
          {
            dataTask?.map((value,i)=>{
              return <SubMenu subVal={viewsubVal} item={value} key={i} />;
            })
          }
          {/* {MenuData.map((item, index) => {
            return <SubMenu subVal={viewsubVal} item={item} key={index} />;
          })} */}
          <div>
            <Button6 onClick={handleClick} title="Add list" />
          </div>
        </div>

        <div
          style={{
            alignItems: "flex-start",
            display: " flex",
            flexDirection: "column",
            position: "relative",
            // marginLeft: "18.6rem",
            // bottom: "1.2rem",
            width: "80%",
            position: "relative",
          }}
          className="gridImage"
        >
         
             <div style={{ position: "relative"}}>
             {  dataTask?.map((value, i)=>{
                // const getMarginTop = (index) => {
                //   if (index === 0) return '0rem'; 
                //   if (index === 1) return '9rem'; 
                //   if (index === 2) return '3rem'; 
                //   return '-3rem'; 
                // };
                const getDisplayTop = (index) => {
                  const dropdownIndex = dataTask?.findIndex((task) => task?.title === dropdownItemTitle);
                //  console.log(dropdownIndex)
                if (index <= dropdownIndex) return 'block';
                return 'none';
                };
                
                const getMarginSubTop = (index) => {
                  if (index === 0) return '3rem'; // First element: 0rem
                  if (index === 1) return '6rem'; // Second element: 9rem
                  if (index === 2) return '9rem'; // Third element: 3rem
                  return '-3rem'; // Rest of the elements: -3rem
                };
                

               var dayfirst = new Date(value?.startDate);
               var curDate = new Date().toISOString().slice(0, 10);
               var useDate = new Date(curDate);
               var daycatch = new Date(value?.endDate);
               // var diffTime = day2.getTime() - day1.getTime();
               var curDateTime = daycatch.getTime() - useDate.getTime();
               //  var diffDays = diffTime / (1000 * 3600 * 24);
               var curDays = curDateTime / (1000 * 3600 * 24);
               var widthProgress;
               var widthPerc;
               var usePerc;
               if(value?.status.toLowerCase() === "completed"){
                widthPerc=100
                usePerc=100
               }
               else if(value?.status.toLowerCase() === "review"){
                widthPerc=75
                usePerc=75
               }
               else if(value?.status.toLowerCase() === "in progress"){
                widthPerc=50
                usePerc=50
               }
               else if(value?.status.toLowerCase() === "todo"){
                widthPerc=30
                usePerc=30
               }
               else{
                widthPerc=0
               }
               
               
              
            if (useDate > dayfirst) {
            if (curDays >= 20) {  widthProgress = "1020px";
             }
            else if (curDays === 19) {
              widthProgress = "1020px";
            }
            else if (curDays === 18) {
              widthProgress = "930px";
            }
            else if (curDays === 17) {
              widthProgress = "1020px";
            }
            else if (curDays === 16) {
              widthProgress = "1020px";
            }
            else if (curDays === 15) {
              widthProgress = "1020px";
            }
            else if (curDays === 14) {
              widthProgress = "1020px";
            }
            else if (curDays === 13) {
              widthProgress = "990px";
            }
            else if (curDays === 12) {
              widthProgress = "910px";
            }
            else if (curDays === 11) {
              widthProgress = "850px";
            }
            else if (curDays === 10) {
              widthProgress = "770px";
            }
            else if (curDays === 9) {
              widthProgress = "700px";
            }
            else if (curDays === 8) {
              widthProgress = "630px";
            }
            else if (curDays === 7) {
              widthProgress = "550px";
            }
            else if (curDays === 6) {
              widthProgress = "500px";
            }
            else if (curDays === 5) {
              widthProgress = "410px";
            }
            else if (curDays === 4) {
              widthProgress = "350px";
            }
            else if (curDays === 3) {
              widthProgress = "250px";
            }
            else if (curDays === 2) {
              widthProgress = "180px";
            }
            else if (curDays < 0) {
              widthProgress = "0px";

            }
            else {
              widthProgress = "130px";
            }
          }
             return(
            //   <motion.p
            //   animate={{ x: value + "px", opacity: toggle }}
            //   drag="x"
            //   dragConstraints={{ left: motionPlan, right: 100 }}
            // >
                <div
                  key={i}
                  style={{
                    alignItems: "flex-start",
                    borderRadius: "4px",
                    display: "flex",
                    marginTop: !valueState?`${i*1}rem`:`${i*2}rem`,
                    minWidth: " 200px",
                   display: !valueState?'block':getDisplayTop(i),
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#39D1E5",
                      // backgroundColor: "rgba(81, 209, 135, 1)",
                      borderRadius: "4px",
                      height: "33px",
                      width: widthProgress,
                    }}
                  >
                    <div style={{ width: widthPerc + '%', height: '33px', backgroundColor: "#226B74", borderTopRightRadius:'5px', borderBottomRightRadius:'5px', position: 'relative'}}>
                    <div style={{textAlign:'center',color:'#fff' }}>
                    {
                      curDays < 0
                      ?'':                          
                      `${usePerc}%`
                      }
                      </div>
                    </div>
                    {/* <ProgressBar
                      min={60}
                      max={100}
                      onChange={({ min, max }) => `${max} - ${min}`}
                      // color={"#2D6B47"}
                      color={"#226B74"}
                    /> */}
                    <div
                      style={{
                        zIndex:'10',
                        marginLeft: "0.7rem",
                       position: "relative",
                        top:'-1.5rem',
                        color: "#fff",
                        bottom: "0.4rem",
                        whiteSpace: "normal", 
                        wordBreak: "break-word",
                      }}
                    >
                      {
                      curDays < 0
                      ?'':                          
                      value?.title
                      }
                    </div>
                  </div>
                  {
                    
                  value?.subTasks?.map((item,index)=>{
                    var subPerc;
                    var usesubPerc;
                    if(item?.status?.toLowerCase() === "completed"){
                      subPerc=100
                      usesubPerc=100
                    }
                    else if(item?.status?.toLowerCase() === "review"){
                      subPerc=75
                      usesubPerc=75
                    }
                    else if(item?.status?.toLowerCase() === "in progress"){
                      subPerc=50
                      usesubPerc=50
                    }
                    else if(item?.status?.toLowerCase() === "todo"){
                      subPerc=30
                      usesubPerc=30
                    }
                    else{
                      usesubPerc=10
                      subPerc=20
                    }
                    return (
                      <>
                 {valueState && dropdownItemTitle === value?.title &&
                  (
                    <div
                    key={index}
                    style={{
                      alignItems: "flex-start",
                      position: "absolute",
                      borderRadius: "4px",
                      display: "flex",
                      marginTop: getMarginSubTop(index),
                      minWidth: " 361px",
                    }}
                  >
                <div
                      style={{
                        backgroundColor: "#FFDB6E",
                        borderRadius: "4px",
                        height: "33px",
                        width: widthProgress,
                      }}
                    >
                       <div style={{ width: subPerc + '%', height: '33px', backgroundColor: "#80703B", borderTopRightRadius:'5px', borderBottomRightRadius:'5px', position: 'relative'}}>
                    <div style={{textAlign:'center',color:'#fff' }}>
                    {
                      curDays < 0
                      ?'':                          
                      `${usesubPerc}%`
                      }
                      </div>
                    </div>
                      {/* <ProgressBar
                        min={30}
                        max={100}
                        onChange={({ min, max }) => `${max} - ${min}`}
                        color={"#80703B"}
                      /> */}
                      <div
                        style={{
                          marginLeft: "0.7rem",
                          position: "absolute",
                          color: "#fff",
                          bottom: "0.4rem",
                        }}
                      >
                        {item?.name}
                      </div>
                   </div>
                 </div>
                  ) }  
                  </>)        
                       } )}
                </div>
                // </motion.p>
            
              )
            }
            
            ) }
            </div>
         
          <div
            style={{
              position: "absolute",
              bottom: 20,
              right: 15,
              display: "flex",
              gap: "10px",
            }}
          >
            <div className="todayButton">Today</div>
            <div className="daysButton">
              <div style={{ paddingBottom: "10px" }}>
                <img src="/Minus.svg" />
              </div>
              <div>Days</div>
              <div style={{ paddingTop: "5px" }}>
                <img src="/Plus.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      ):
      (
        <div></div>
      )
    }
      


    </div>
  );
};

export default CalendarPage;
