import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import InputBase from "@mui/material/InputBase";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import { visuallyHidden } from "@mui/utils";
import { AddProjectModal } from "../Modals";
import { HiOutlineDotsVertical, HiViewGrid } from "react-icons/hi";
import { BsDownload, BsClock, BsGrid1X2Fill } from "react-icons/bs";
import ProgressData from "./ProgressData";
import LowProgressData from "./lowProgressData";
import AverageProgressData from "./averageProgressData";
import MiddleProgressData from "./middleProgressData";
import HighProgressData from "./highProgressData";
import BiggerProgressData from "./biggerProgressData";
import EmptyProgressData from "./emptyProgressData";
import { CSVLink } from "react-csv";
import "./Table.css";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { sortResponse, getOrgProjects } from "../../util.js";
import LoadingScreen from "../preLoader";
//images
import RedHeadPhones from "../../assets/icon/fluent_headphones-sound-wave-28-red.png";
import HeadPhones from "../../assets/icon/fluent_headphones-sound-wave-28-green.png";
import BlueHeadPhones from "../../assets/icon/fluent_headphones-sound-wave-blue-regular.png";
import YellowHeadPhones from "../../assets/icon/fluent_headphones-sound-wave-yellow-regular.png";
import ViewFilter from "../TheModal/ViewFilter";
import { Tooltip } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D1F20",
    color: "white",
    borderBottom: "1px solid #242424",
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#1D1F20",
    color: "white",
    borderBottom: "1px solid #242424",
  },
}));

const imageDisplay = [
  HeadPhones,
  BlueHeadPhones,
  YellowHeadPhones,
  RedHeadPhones,
];

const ArtistLetter = ({ color, letter, txtcolor }) => (
  <div
    style={{
      fontSize: "small",
      padding: "0.5rem 0.5rem",
      textAlign: "center",
      borderRadius: "14px",
      border: "1px solid #fead77",
      color: txtcolor,
      backgroundColor: "#7a480b",
      textTransform: "uppercase",
    }}
  >
    {letter}
  </div>
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "projectname",
    numeric: false,
    disablePadding: false,
    label: "PROJECT NAME",
  },
  {
    id: "createdby",
    numeric: false,
    disablePadding: false,
    label: "CREATED BY",
  },
  {
    id: "progress",
    numeric: false,
    disablePadding: false,
    label: "PROGRESS",
  },
  {
    id: "deadline",
    numeric: false,
    disablePadding: false,
    label: "DEADLINE",
  },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  backgroundColor: " #242424",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "90%",

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "white",

    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            style={{
              borderRadius: "4px",
              height: "16px",
              width: "16px",
              border: "1px solid #242424",
              color: " #171717",
              background: "#1D1F20",
            }}
            color="secondary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              style={{ color: "white" }}
              IconComponent={() => <ArrowDropDownIcon />}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({ numSelected, takeSearch }) => {
  // const  numSelected = props.numSelected;
  const [value, setValue] = React.useState(true);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "3rem",
            marginBottom: "2rem",
            fontFamily: "poppins",
          }}
        >
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search project…"
              inputProps={{ "aria-label": "search" }}
              onChange={takeSearch}
            />
          </Search>
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const { auth, currentOrganization } = useAuth();
  const [rows, setRows] = React.useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("projectname");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [value, setValue] = useState("1");
  const [showaddModal, setAddModal] = useState(false);
  const [show, setShow] = React.useState(false);
  const [valrow, setVal] = React.useState();
  const [viewFilter, setViewFilter] = React.useState([]);
  const [searchInput, setSearchInput] = React.useState("");
  const [r, setR] = useState(false);
  const [projects, setProjects] = useState([]);
  const [hold, setHold] = useState([]);
  const [progress, setProgress] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [loading, setLoading] = useState(false);
  const [storehold, setStorehold] = useState([]);
  const [storeprogress, setStoreprogress] = useState([]);
  const [storecompleted, setStorecompleted] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const AuthStr = "Bearer ".concat(auth?.authToken);
  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
      "Content-Type": "application/json",
    },
  };
  const getStatus = (res) => {
    // console.log("started running the get tasks func ", res);
    setHold([]);
    setProgress([]);
    setCompleted([]);
    setStoreprogress([]);
    setStorecompleted([]);
    setStorehold([]);
    for (let index = 0; index < res.length; index++) {
      const element = res[index];

      const status = element.status.toLowerCase();

      if (status === "on hold" || status === "on_hold") {
        setHold((prev) => [...prev, element]);
        // table data filter
        setStorehold((prev) => [...prev, element]);
      } else if (status === "in progress" || status === "in_progress") {
        setProgress((prev) => [...prev, element]);
        // table data filter
        setStoreprogress((prev) => [...prev, element]);
      } else if (status === "completed") {
        setCompleted((prev) => [...prev, element]);
        // table data filter
        setStorecompleted((prev) => [...prev, element]);
      }
    }
  };

  // api call usage
  const getProjects = useCallback(async () => {
    try {
      const response = await getOrgProjects(currentOrganization.id, auth?.id);
      // getAllTasks(response.data);

      setRows(response.data);
      setProjects(sortResponse(response.data));
      setLoading(true);
      getStatus(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [projects, r]);
  const loadData = async (data) => {
    const promises = data.map((row) => loadProject(row));
    const results = await Promise.all(promises);
  };
  const loadProject = async (e) => {
    try {
      const response = await axios.get(`/task/all/${e?.id}`, config);

      const data = response.data;

      // Snippet
      let subTasks = [];
      for (let s = 0; s < data.length; s++) {
        const task = data[s];
        if (task.subTasks && task.subTasks.length > 0) {
          task.subTasks.forEach((sub) => {
            subTasks.push(sub);
          });
        }
      }
      const completedSubtasks = subTasks.filter(
        (item) => item.status && item.status.toLowerCase() === "completed"
      );
      let v = 0;
      if (subTasks.length > 0) {
        v = completedSubtasks.length / subTasks.length;
      }
      // End of Snippet
      return v;
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (projects.length > 0) {
      loadData(projects);
    }
  }, [projects]);
  React.useEffect(() => {
    getProjects();
  }, [r]);
  React.useEffect(() => {
    if (projects.length > 0) {
      const promises = projects.map((row) => loadProject(row));
      Promise.all(promises).then((results) => {
        const updatedTableData = projects.map((row, index) => ({
          ...row,
          roundedResult: Math.round(results[index] * 100),
        }));
        setTableData(updatedTableData);
      });
    }
  }, [projects]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const takeSearch = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (searchInput !== "") {
      const filteredData = rows.filter((item) => {
        return Object.values(item)
          .join("")
          .toLocaleLowerCase()
          .includes(searchInput.toLowerCase());
      });
      console.log(viewFilter);
      setViewFilter(filteredData);
    } else {
      setViewFilter(rows);
    }
    // setViewFilter(e)
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage]
  );
  let categories = [
    {
      value: "1",
      label: "all",
      content: "All  ",
      type: projects,
    },

    {
      value: "2",

      label: "in progress",
      content: "In Progress ",
      type: progress,
    },
    {
      value: "3",

      label: "on hold",
      content: "On Hold ",
      type: hold,
    },
    {
      value: "4",

      label: "completed",
      content: "Completed ",
      type: completed,
    },
  ];

  const projectheaders = [
    { label: "Artist Name", key: "artistName" },
    { label: "Description", key: "description" },
    { label: "Budget", key: "budget" },
    { label: "Project Created", key: "createdAt" },
    { label: "Deadline Of Project", key: "endDate" },
    { label: "Project Team", key: "projectMembers" },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <div className="project__header">
        <p>Projects</p>
        <div className="project__header__buttons">
          <Link
            to="/frame/projects"
            style={{
              color: "white",
              borderRadius: "0.3rem",
              padding: "0.4rem",
              border: "1px solid rgb(80, 77, 77)",
            }}
          >
            <HiViewGrid />
          </Link>
          {projects?.length !== 0 ? (
            <Tooltip title="Download CSV" placement="top">
              <div
                style={{
                  borderRadius: "5px",
                  border: "1px solid rgb(80, 77, 77)",
                  padding: "0.4rem 0.4rem",
                  color: "rgb(160, 149, 149)",
                }}
              >
                <CSVLink
                  style={{ color: "white" }}
                  filename={"ProjectTable.csv"}
                  data={rows}
                  headers={projectheaders}
                >
                  <BsDownload />
                </CSVLink>
              </div>
            </Tooltip>
          ) : null}
          <div
            className="add__project__button"
            onClick={() => {
              setAddModal(true);
            }}
          >
            + Add Project
          </div>

          <AddProjectModal
            onClose={() => {
              // rrender();
              setAddModal(false);
            }}
            // refresh={rrender}
            show={showaddModal}
            rr={() => {
              setR(!r);
            }}
          />
        </div>
      </div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab"
            className="border-b border-b-[#353535]"
          >
            {categories.map((item, index) => (
              <Tab
                label={`${
                  item.label.toUpperCase() + " [" + item.type.length + "]"
                } `}
                value={item.value}
                key={index}
              />
            ))}
          </TabList>
        </Box>
        {categories.map((item, index) => (
          <TabPanel p={8} key={index} value={item.value}>
            {loading ? (
              <div className="project__items">
                <Paper
                  style={{
                    color: "white",
                    background: "#1D1F20",
                    border: "1px solid #242424",
                  }}
                  sx={{ width: "100%", mb: 2 }}
                >
                  <EnhancedTableToolbar
                    takeSearch={takeSearch}
                    numSelected={selected.length}
                  />
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
                        {stableSort(
                          searchInput.length > 1
                            ? viewFilter
                            : value == "3"
                            ? storehold
                            : value == "2"
                            ? storeprogress
                            : value == "4"
                            ? storecompleted
                            : rows,
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            const setColor = (start, end) => {
                              const startTimestamp = new Date(start).getTime();
                              const endTimestamp = new Date(end).getTime();

                              const secondsLeft =
                                (endTimestamp - startTimestamp) / 1000;

                              const minutesLeft = Math.floor(secondsLeft / 60);
                              const hoursLeft = Math.floor(minutesLeft / 60);
                              const daysLeft = Math.floor(hoursLeft / 24);
                              if (daysLeft === 0) {
                                return "#af4a4a";
                              } else if (daysLeft <= 17) {
                                return "#FFA06E";
                              } else if (daysLeft <= 45) {
                                return "#242424";
                              } else {
                                return "#242424";
                              }
                            };

                            //updated time code
                            const timeLeft = (start, end) => {
                              const startTimestamp = new Date(start).getTime();
                              const endTimestamp = new Date(end).getTime();

                              const secondsLeft =
                                (endTimestamp - startTimestamp) / 1000;

                              const minutesLeft = Math.floor(secondsLeft / 60);
                              const hoursLeft = Math.floor(minutesLeft / 60);
                              const daysLeft = Math.floor(hoursLeft / 24);

                              const timeLeft = {
                                days: daysLeft + " " + "days left",
                                hours: (hoursLeft % 24) + " " + "hours left",
                                minutes:
                                  (minutesLeft % 60) + " " + "minutes left",
                                seconds:
                                  Math.floor(secondsLeft % 60) +
                                  " " +
                                  "seconds left",
                              };
                              return timeLeft;
                            };
                            //updated time code
                            //  loadProject(row).then((result) => {
                            //   console.log(result);
                            //   const roundedResult = Math.round(result * 100);

                            //   console.log(roundedResult)
                            // });
                            const tableRowData = tableData.find(
                              (data) => data.id === row.id
                            );
                            const roundedResult = tableRowData
                              ? tableRowData.roundedResult
                              : 0;
                            // console.log(roundedResult)
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <StyledTableCell padding="checkbox">
                                  <Checkbox
                                    style={{
                                      background: "#0D0D0D",
                                      borderRadius: "4px",
                                      height: "16px",
                                      width: "16px",
                                      border: "1px solid #242424",
                                      color: " #171717",
                                    }}
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        marginRight: "0.4rem",
                                        borderRadius: "0.3rem",
                                        padding: "0.5rem 0.5rem",
                                        border: "1px solid #242424",
                                      }}
                                    >
                                      <img
                                        className="img__headset"
                                        src={
                                          imageDisplay[
                                            Math.floor(Math.random() * 4)
                                          ]
                                        }
                                      />
                                    </div>
                                    <div>
                                      <div>{row.artistName}</div>
                                      <div>{row.name}</div>
                                    </div>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {" "}
                                  <div style={{ display: "flex" }}>
                                    <div style={{ width: "15%" }}>
                                      <ArtistLetter
                                        txtcolor="#fead77"
                                        letter={auth.firstName.charAt(0)}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "0.5rem",
                                        width: "85%",
                                      }}
                                    >
                                      <div>
                                        {auth.firstName + " " + auth.lastName}
                                      </div>
                                      <div>{auth.role}</div>
                                    </div>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* {(() => {
                                     
                                      return (
                                        <div style={{ display: "contents" }}>
                                          <ProgressData />
                                            {roundedResult}%
                                        </div>
                                     ) 
                                    })()} */}
                                    {(() => {
                                      if (
                                        roundedResult >= 95 &&
                                        roundedResult <= 100
                                      ) {
                                        return (
                                          <div style={{ display: "contents" }}>
                                            <ProgressData />
                                            <span
                                              style={{ marginLeft: "0.5rem" }}
                                            >
                                              {roundedResult}%
                                            </span>
                                          </div>
                                        );
                                      } else if (
                                        roundedResult >= 80 &&
                                        roundedResult <= 94
                                      ) {
                                        return (
                                          <div style={{ display: "contents" }}>
                                            <BiggerProgressData />
                                            <span
                                              style={{ marginLeft: "0.5rem" }}
                                            >
                                              {roundedResult}%
                                            </span>
                                          </div>
                                        );
                                      } else if (
                                        roundedResult >= 60 &&
                                        roundedResult <= 79
                                      ) {
                                        return (
                                          <div style={{ display: "contents" }}>
                                            <HighProgressData />
                                            <span
                                              style={{ marginLeft: "0.5rem" }}
                                            >
                                              {roundedResult}%
                                            </span>
                                          </div>
                                        );
                                      } else if (
                                        roundedResult >= 50 &&
                                        roundedResult <= 59
                                      ) {
                                        return (
                                          <div style={{ display: "contents" }}>
                                            <MiddleProgressData />
                                            <span
                                              style={{ marginLeft: "0.5rem" }}
                                            >
                                              {roundedResult}%
                                            </span>
                                          </div>
                                        );
                                      } else if (
                                        roundedResult >= 40 &&
                                        roundedResult <= 49
                                      ) {
                                        return (
                                          <div style={{ display: "contents" }}>
                                            <AverageProgressData />
                                            <span
                                              style={{ marginLeft: "0.5rem" }}
                                            >
                                              {roundedResult}%
                                            </span>
                                          </div>
                                        );
                                      } else if (
                                        roundedResult >= 10 &&
                                        roundedResult <= 39
                                      ) {
                                        return (
                                          <div style={{ display: "contents" }}>
                                            <LowProgressData />
                                            <span
                                              style={{ marginLeft: "0.5rem" }}
                                            >
                                              {roundedResult}%
                                            </span>
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div style={{ display: "contents" }}>
                                            <EmptyProgressData />{" "}
                                            <span
                                              style={{ marginLeft: "0.5rem" }}
                                            >
                                              {roundedResult}%
                                            </span>
                                          </div>
                                        );
                                      }
                                    })()}
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  <div className="tableparent__style">
                                    <HiOutlineDotsVertical
                                      fontSize={20}
                                      onClick={() => {
                                        setVal(row);
                                        setShow(true);
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      background: `${setColor(
                                        row.startDate,
                                        row.endDate
                                      )}`,
                                      // color: `${colorTxt}`,
                                      padding: "0.4rem",
                                      width: "50%",
                                      borderRadius: "0.4rem",
                                    }}
                                  >
                                    <div>
                                      <BsClock fontSize="20" />
                                    </div>
                                    <div className="timetable__style">
                                      {
                                        timeLeft(row.startDate, row.endDate)
                                          .days
                                      }
                                    </div>
                                  </div>
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <StyledTableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    style={{ color: "#ba68c8", background: "#151517" }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            ) : (
              <LoadingScreen loading />
            )}
          </TabPanel>
        ))}
      </TabContext>
      <ViewFilter data={valrow} onClose={() => setShow(false)} show={show} />
    </Box>
  );
}
