import React, { useState } from "react";
import "./TaskCat.css";
import { TaskCatCard } from "../Cards";
import { AddTaskModal } from "../Modals";
import { Tooltip } from "@mui/material";

const TaskCat = (props) => {
  const { data, type } = props;
  const [modal, setModal] = useState(false);

  return (
    <div className="task__cart">
      <div className="task__cart__contents">
        {/* Header Section */}
        <div className="task__cart__header">
          <div className="cat__type">
            <h4 className="cat__type__name">{type.toUpperCase()}</h4>
            <p className="cat__type__amount">{Object.keys(data).length}</p>
          </div>
        </div>

        {/* Content Section */}
        {data.map((item, index) => (
          <div key={index} className="task__cart__items">
            <TaskCatCard
              {...item}
              ci={props?.ci?.id}
              cd={props.ci}
              getTasks={props.getTasks}
            />
          </div>
        ))}

        {type !== "completed" && (
          <Tooltip title="Add task" placement="top">
            <div className="add__more" onClick={() => setModal(true)}>
              +
            </div>
          </Tooltip>
        )}

        <AddTaskModal
          show={modal}
          onClose={() => setModal(!modal)}
          pi={props.ci}
          availableBudget={props.availableBudget}
          stat={props.type}
          getTasks={props.getTasks}
        />
      </div>
    </div>
  );
};

export default TaskCat;
