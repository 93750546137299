import React, { useCallback, useState } from "react";
import "./TaskCatCard.css";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdAttachFile } from "react-icons/md";
import { FiMessageSquare } from "react-icons/fi";
import { SetDeadline, ReviewModal, AddMemberTask } from "../../Modals";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { TaskDetails } from "../../Modals";
import Moment from "moment";
import { truncate } from "../../../util.js";
import { BsFolder2Open, BsThreeDots } from "react-icons/bs";
import { BiPencil, BiMessageX } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClockCircle, AiOutlineCheck } from "react-icons/ai";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";
import { useEffect } from "react";

const TaskCatCard = (props) => {
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const {
    title,
    description,
    attachments,
    image,
    comments,
    startDate,
    endDate,
  } = props;
  const [showDlModal, setDlModal] = useState(false);
  const [details, setDetails] = useState(false);
  const [showOptions, setOptions] = useState(false);
  const [invite, setInvite] = useState(false);
  const [review, setReview] = useState(false);
  const [singleP, setSingleP] = useState(null);
  const [r, setR] = useState(false);
  const [taskMembers, setTaskMembers] = useState(null);
  const [fileCount, setFileCount] = useState(0);
  const [subs, setSubs] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
      "Content-Type": "application/json",
    },
  };

  // TODO: Use props.cd.id to get the projectMembers by requesting the project endpoint
  const getProjectDetails = useCallback(
    async (id) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth?.authToken}`,
          },
        };
        const response = await axios.get(`/project/${id}`, config);

        setSingleP(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    [r]
  );

  const getTask = useCallback(
    async (id) => {
      const resp = await axios.get(`/task/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
        },
      });

      const data = resp.data;
      setSubs(data?.subTasks);
      setFileCount(data.attachment.filter((item) => item !== "[]").length);
      setTaskMembers(data?.taskMembers);
    },
    [r]
  );

  useEffect(() => {
    getProjectDetails(props?.ci);
    getTask(props.id);

    if (props.getTasks) {
      props.getTasks(props?.ci);
    }
  }, [r]);

  const time = Moment(endDate).format("MMM Do");

  const handleClickAway = () => {
    setOptions(false);
  };

  const handleInvite = () => {
    setInvite(true);
  };
  const handleDl = () => {
    setDlModal(!showDlModal);
    return;
  };

  const handleReview = () => {
    setReview(!review);
  };

  const changeStatus = async (taskId, stats) => {
    setOpen(true);

    try {
      let status;
      if (stats.toLowerCase() === "completed") {
        status = "TODO";
      } else {
        status = "COMPLETED";
      }

      const response = await axios.patch(
        `/task/${taskId}`,
        JSON.stringify({
          status,
        }),
        config
      );

      setTimeout(() => {
        setOpen(false);
      }, response);

      if (props.getTasks) {
        props.getTasks(props?.ci);
      }

      status === "COMPLETED"
        ? toast.success(`${title} has been completed`)
        : toast.success(`${title} has been opened`);
    } catch (error) {
      setOpen(false);

      toast.error("Failed!");
      console.log(error);
    }

    return;
  };

  const changeToReview = async (taskId) => {
    setOpen(true);

    try {
      const status = "REVIEW";

      const response = await axios.patch(
        `/task/${taskId}`,
        JSON.stringify({
          status,
        }),
        config
      );

      setTimeout(() => {
        setOpen(false);
      }, response);

      if (props.getTasks) {
        props.getTasks(props?.ci);
      }
      toast.success("Task submitted for review");
    } catch (error) {
      setOpen(false);

      toast.error("Failed!");
      console.log(error);
    }

    return;
  };

  const delItem = async (id) => {
    setOpen(true);

    try {
      const response = await axios.delete(`/task/${id}`, config);

      setTimeout(() => {
        setOpen(false);
      }, response);
      if (props.getTasks) {
        props.getTasks(props?.ci);
      }

      toast.success(`${title} Has Been Deleted!`);
    } catch (error) {
      setOpen(false);
      toast.error("Failed!");
      console.log(error);
    }
  };

  return (
    <div className="task__cat__card">
      <TaskDetails
        singleP={singleP}
        time={time}
        subs={subs}
        show={details}
        {...props}
        getTask={getTask}
        taskMembers={taskMembers}
        onClose={() => setDetails(!details)}
        getTasks={props.getTasks}
        rr={() => setR(!r)}
      />

      {open && <BackDrop open={open} />}

      <div className="header">
        <div className="colors">
          <div className="orange"></div>
          <div className="green"></div>
        </div>
        <div className="date">
          <AiOutlineCalendar />
          <p>{time}</p>

          <ClickAwayListener onClickAway={handleClickAway}>
            <Tooltip title="Options" placement="top">
              <div
                onClick={() => setOptions(!showOptions)}
                className={`task__cat__dropdown__button`}
              >
                <BsThreeDots fontSize={20} color="#7A7A7A" />
              </div>
            </Tooltip>
          </ClickAwayListener>
        </div>
      </div>
      {/* The TaskCat Options Dropdown */}
      <div className={`task__cat__dropdown  ${showOptions ? "show" : ""} `}>
        <div className="task__cat__dropdown__list">
          <div
            className="dropdown__item"
            onClick={() => {
              handleInvite();
              setOptions(false);
            }}
          >
            <BiPencil />
            <div>Assign Task</div>
          </div>

          <hr width="auto" color="#242424" size="1" />

          <div
            className="dropdown__item"
            onClick={() => {
              handleDl();
              setOptions(false);
            }}
          >
            <AiOutlineClockCircle />
            <div>Set Deadline</div>
          </div>

          {props.status.toLowerCase() !== "review" && (
            <div
              className="dropdown__item"
              onClick={() => {
                handleReview();
                setOptions(false);
              }}
            >
              <BiMessageX />
              <div>Submit For Review</div>
            </div>
          )}

          <div
            className="dropdown__item"
            onClick={() => {
              changeStatus(props?.id, props?.status);
              setOptions(false);
            }}
          >
            {props.status.toLowerCase() === "completed" ? (
              <>
                <BsFolder2Open />
                <div>Open Task</div>
              </>
            ) : (
              <>
                <AiOutlineCheck />
                <div>Complete Task</div>
              </>
            )}
          </div>

          <hr width="auto" color="#242424" size="1" />

          <div
            className="dropdown__item del"
            onClick={() => {
              delItem(props.id);
              setOptions(false);
            }}
          >
            <RiDeleteBinLine />
            <div>Delete Task</div>
          </div>
        </div>
      </div>

      <SetDeadline
        onClose={() => {
          setDlModal(false);
        }}
        rr={() => setR(!r)}
        project={props}
        type="task"
        show={showDlModal}
      />
      <ReviewModal
        data={props}
        onClose={() => {
          setReview(false);
        }}
        show={review}
        taskMembers={taskMembers}
        setToReview={() => changeToReview(props?.id)}
      />
      <AddMemberTask
        data={props}
        taskMembers={taskMembers}
        projectMembers={singleP?.projectMembers}
        onClose={() => setInvite(false)}
        show={invite}
        getTask={getTask}
        rr={() => setR(!r)}
      />

      <div className="content" onClick={() => setDetails(true)}>
        <p
          style={{
            paddingBottom: ".7rem",
          }}
        >
          {title}
        </p>
        {image == "1" ? (
          <img
            className="task__cart__image"
            src={
              "https://images.unsplash.com/photo-1656447553291-377b34781c59?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            }
            alt={"cart image"}
          />
        ) : null}
        <p> {truncate(description)}</p>
      </div>

      <div></div>

      <div className="footer">
        <div className="left__side">
          <div>
            <MdAttachFile />
            <p>{fileCount || "0"}</p>
          </div>
          <div>
            <FiMessageSquare />
            <p>{comments?.length || "0"}</p>
          </div>
        </div>
        <div className="right__side">
          {taskMembers && taskMembers[0] !== "[]"
            ? taskMembers.map((item, index) => (
                <div className="memeber__icon" key={index}>
                  {item.firstName.charAt(0).toUpperCase()}
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default TaskCatCard;
