import React, { useState, useEffect, useCallback } from "react";
import "../adminNav/adminnav.css";
import "./adminprofile.css";
import AdminTable from "../adminTable/AdminTable";
import AdminInfo from "../adminInfo/AdminInfo";
import axios, { firstVersion } from "../../api/axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import LoadingScreen from "../preLoader";
import { AddOrganization } from "../Modals";
import { InviteModal } from "../Modals";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { RiStackLine } from "react-icons/ri";
import { AiOutlineDown } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";
import { CometChat } from "@cometchat-pro/chat";
import useAuth from "../../hooks/useAuth";
import { EditPasswordModal } from "../Modals";
import { getOrgProjects, sortResponse } from "../../util.js";
const AdminProfile = () => {
  const { auth, logOut, setCurrentOrganization, currentOrganization, config } =
    useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [Org, setOrg] = useState([]);
  const [dropProjs, setDropProjs] = useState(false);
  const [filter, setFilter] = useState("");
  const [r, setR] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [members, setMembers] = useState([]);
  const [orgProjects, setOrgProjects] = useState(null);

  useEffect(() => {
    if (auth?.firstName) {
      setShowPassword(false);
    }
  }, []);

  // *Get all members of an organization
  const getMembers = async (orgId) => {
    if (currentOrganization) {
      try {
        // GETS MEMBERS ENDPOINT
        const response = await firstVersion.get(`/users/all/${orgId}/`, config);
        setMembers(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // *Get all organization projects.
  const OrgProjects = useCallback(async () => {
    try {
      const response = await getOrgProjects(
        currentOrganization?.id,
        auth?.id,
        auth?.role,
        config
      );

      setLoading(true);
      setOrgProjects(sortResponse(response.data));
    } catch (error) {
      console.log(error);
    }
  }, [r]);

  useEffect(() => {
    //  THIS IIFE GETS ALL OF THE AVAILABLE ORGANIZATIONS FOR THE LOGGED IN ADMIN
    (async () => {
      try {
        const response = await axios.get(`/organizations`, config);
        let currOrg;
        setOrg(response.data);
        setLoading(false);

        if (response.data.length > 0) {
          if (currentOrganization) {
            const findItem = response.data.find(
              (item) => item.id === currentOrganization?.id
            );
            if (findItem) {
              currOrg = findItem;
              setCurrentOrganization(currOrg);
            } else {
              currOrg = response.data[0];
              setCurrentOrganization(currOrg);
            }
            setOrg(response.data);
          } else {
            currOrg = response.data[0];
            setCurrentOrganization(currOrg);
          }
        }

        getMembers(currOrg.id);
        setLoading(true);
      } catch (error) {
        if (error.response.data.message === "jwt expired") {
          toast.error("Session Expired");
          logOut();
        }
        console.log(error);
      }
    })();

    OrgProjects();
  }, [r]);

  const selectProject = (item) => {
    setLoading(false);
    setCurrentOrganization(item);
    setR(!r);

    setDropProjs(false);
  };

  const searchProj = (event) => {
    const { value } = event.target;

    const compare = Org.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    setFilter(compare);

    if (compare.length === 0) {
      setFilter("Not Found");
    }

    if (value !== "" && compare.length !== 0) {
      setOrg(compare);
    } else {
      setFilter("");
      setR(!r);
    }
  };

  return (
    <div className="hos__admin-profile">
      {/* Admin Nav*/}
      <div className="hos__admin--navbar container">
        <div className="admin__navbar">
          {/* <h2>Manage your Team</h2> */}
          <div className="org__header ">
            <ClickAwayListener
              onClickAway={() => {
                setDropProjs(false);
              }}
            >
              <div
                className="header__dropdown"
                onClick={() =>
                  auth.role.toLowerCase() === "super_admin" &&
                  setDropProjs(!dropProjs)
                }
              >
                <h2 className="text-xl">
                  <span className="font-semibold">
                    {!currentOrganization
                      ? Org[0]?.name
                      : currentOrganization?.name}{" "}
                  </span>
                  Team
                </h2>

                {auth.role.toLowerCase() === "super_admin" && (
                  <AiOutlineDown fontSize={13} />
                )}
              </div>
            </ClickAwayListener>
            {Org.length !== 0 && (
              <div
                className={`projects__dropdown ${
                  Org.length > 3 && " overflow-y-scroll"
                } ${dropProjs && "show"}`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="top__part">
                  <p>Teams</p>

                  <input
                    type="text"
                    placeholder="Search for team..."
                    onChange={searchProj}
                  />
                </div>
                <div className="dropdown__list">
                  {filter === "Not Found" ? (
                    <p>Not Found</p>
                  ) : (
                    Org.map((item, index) => (
                      <div
                        className="dropdown__item"
                        key={index}
                        onClick={() => selectProject(item)}
                      >
                        <div className="icon__name">
                          <RiStackLine />
                          <div>{item.name}</div>
                        </div>

                        {currentOrganization?.id === item.id && (
                          <BsCheck2
                            color="#51D187"
                            className="check__icon"
                            size={20}
                          />
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>

          {auth?.role === "SUPER_ADMIN" ? (
            currentOrganization && (
              <div className="admin__nav--buttons">
                <a
                  href="#"
                  className="btn-1"
                  onClick={() => setShowLog(true)}
                  style={{ cursor: "pointer" }}
                >
                  Add Organization
                </a>
                <a
                  href="#"
                  className="btn-1"
                  onClick={() => setShowInvite(true)}
                  style={{ cursor: "pointer" }}
                >
                  Invite Members
                </a>
                <Link className="btn-2" to="/pricing">
                  {" "}
                  Upgrade
                </Link>
                <AddOrganization
                  onClose={() => setShowLog(false)}
                  show={showLog}
                  rr={() => setR(!r)}
                />
                <InviteModal
                  onClose={() => setShowInvite(false)}
                  show={showInvite}
                  rr={() => setR(!r)}
                />
              </div>
            )
          ) : (
            <div className="admin__nav--buttons">
              <Link className="btn-1" to="/frame/chat">
                {" "}
                Chat with members
              </Link>
            </div>
          )}
        </div>
      </div>

      {loading && orgProjects ? (
        <div className="admin__profile--container container">
          <AdminInfo
            r={r}
            rr={() => setR(!r)}
            allProjects={orgProjects}
            allMembers={members}
          />

          <div className="admin__profile--main">
            {/*
             */}
            {auth?.role === "SUPER_ADMIN"
              ? currentOrganization && (
                  <AdminTable
                    setNew={() => setCurrentOrganization(Org[0])}
                    orgLenght={Org.length}
                    allMembers={members}
                    allProjects={orgProjects}
                    orgdata={currentOrganization}
                    rr={() => setR(!r)}
                  />
                )
              : null}
          </div>
        </div>
      ) : (
        <LoadingScreen loading />
      )}
      <div>
        {!auth.firstName && (
          <EditPasswordModal
            onClose={() => setShowPassword(false)}
            show={showPassword}
          />
        )}
      </div>
    </div>
  );
};

export default AdminProfile;
