import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

const settingsMenu = [
  { name: "Account access", link: "" },
  { name: "Privacy and safety", link: "privacy" },
  { name: "Terms and conditions", link: "terms" },
  // { name: "Activity log", link: "activity-log" },
  // { name: "Follow us", link: "follow-us" },
  { name: "Plan & Billing", link: "billing" },
  { name: "Log out", link: "logout" },
];
const SettingsLayout = () => {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="flex items-start  ">
        <div className="sticky left-0 w-[20%] !overflow-hidden border-r border-r-[#353535] h-screen">
          <div className="fixed text-left w-auto truncate  ">
            <p className="text-xl border-b font-semibold  border-b-[#353535] py-3">
              Settings
            </p>

            <div className="flex space-y-3 w-auto flex-col pt-5  ">
              {settingsMenu.map((item, index) => (
                <div
                  className="py-2 cursor-pointer text-base w-auto lg:text-lg"
                  key={index}
                  onClick={() => navigate(`/frame/settings/${item.link}`)}
                  // to={`/frame/settings/${item.link}`}
                >
                  <p className="!truncate ">{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-[80%] py-4 z-10  px-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
