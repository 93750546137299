import React from 'react'
import './activelisting.css'
import { IoIosCheckmarkCircle } from 'react-icons/io'

const ActiveListing = (props) => {
  return (
    <div className='hos__activelisting'>
        <IoIosCheckmarkCircle className='active__icon' />
        <p>{props.text}</p>
    </div>
  )
}

export default ActiveListing