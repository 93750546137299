import React, { useState, useEffect } from "react";
import "./Rhyme.css";
// import axios from "../../../axios";
import { dataMuse } from "../../../api/axios";
import { BiSearchAlt } from "react-icons/bi";
import { CgCheckR } from "react-icons/cg";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
const Rhyme = () => {
  const [searchInput, setSearchInput] = useState("");
  const [results, setResults] = useState([]);

  // Handling Rhyme Search Input
  const handleSearch = async (e) => {
    const request = await dataMuse.get(`/words?rel_rhy=${searchInput}`);
    setResults(request.data);
  };

  // DUMMY DATA
  let tags = ["Perfect Rhymes", "Near Rhymes", "Phrases"];
  return (
    <div className="body">
      <div className="rhyme__input">
        <input
          type="text"
          placeholder="Rhyme..."
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              document.getElementById("myBtn").click();
            }
          }}
        />

        <div className="search__icon" onClick={handleSearch} id="myBtn">
          <BiSearchAlt size={20} />
        </div>
      </div>
      <br />

      <div className="tags">
        {tags.map((item, index) => (
          <div key={index} className="tag__items">
            <CgCheckR color="#22CCE2" />
            <p>{item}</p>
          </div>
        ))}
      </div>

      <br />

      <div className="rhyme__results">
        <div className="header">
          <p>Word</p>
          <p>Popularity</p>
          <p>Syllables</p>
        </div>
        <hr />

        {results.length === 0 ? (
          <p
            style={{
              textAlign: "center",
              padding: "30px 0px",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            No result
          </p>
        ) : (
          results.map((item, index) => (
            <div className="results__body" key={index}>
              <p className="word">{item.word}</p>
              <div className="right">
                <p className="popularity">{item.score ?? "0"}</p>
                <p className="syllables">{item.numSyllables}</p>
              </div>
            </div>
          ))
        )}
      </div>

      <br />
      <br />
    </div>
  );
};

export default Rhyme;
