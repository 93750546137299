import React from "react";
import "./taskLabel.css";
import { BiEqualizer } from "react-icons/bi";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled, alpha } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ChecklistComponent from "./smallTodoComponent/ChecklistComponent";
import TodoMap from "./smallTodoComponent/TodoMap";
import AttachlistComponent from "./attachTodoComponent/AttachlistComponent";
import AttachMap from "./attachTodoComponent/AttachMap";
import uniqid from "uniqid";
import { FcCheckmark } from "react-icons/fc";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import DatePicker from "./DatePicker";
import moment from "moment";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import TheModal from "../TheModal/Modal";
import TeamModal from "../TheModal/TeamModal";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { CgAttachment } from "react-icons/cg";
import { BsEmojiSmile, BsCardImage } from "react-icons/bs";

import { darkTheme, ThemeProvider } from "./smallTodoComponent/styleMUI.js";

import MixImg1 from "../../assets/icon/mix1.png";
import MixImg2 from "../../assets/icon/mix2.png";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#51D187" : "#308fe8",
  },
}));

const KEY = "taskApp.todos";
const MELT = "attachtaskApp.todos";

const style = {
  position: "absolute",
  top: "50%",
  height: "700px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "var(--bg)",
  boxShadow: 24,
  overflow: "scroll",
};

const styletask = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "#0D0D0D",
  boxShadow: 24,
  p: 4,
  padding: 0,
};
const styleperson = {
  position: "absolute",
  top: "35%",
  left: "48%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "#0D0D0D",
  boxShadow: 24,
  p: 4,
  padding: 0,
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  backgroundColor: " #242424",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",

  [theme.breakpoints.up("sm")]: {},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(0.1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "white",

    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const ArtistChildLetter = ({ color, letter, txtcolor }) => (
  <div
    style={{
      fontSize: "large",
      padding: "0.1rem 0.5rem",
      borderRadius: "0.3rem",
      color: txtcolor,
      backgroundColor: color,
    }}
  >
    {letter}
  </div>
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TaskLabel = () => {
  const [demoopen, setdemoOpen] = React.useState(false);
  const handledemoOpen = () => setdemoOpen(true);
  const handledemoClose = () => setdemoOpen(false);
  const [dateopen, datesetOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleValChange = (event, newValue) => {
    setValue(newValue);
  };

  const [show, setShow] = React.useState(false);

  const [team, setTeam] = React.useState(false);
  const onChange = (ranges) => {
    if (
      moment(ranges.startDate).format("MM-DD-YYYY") !==
      moment(ranges.endDate).format("MM-DD-YYYY")
    ) {
      datesetOpen(false);
    } else if (ranges.startDate === "" && ranges.endDate === "") {
      datesetOpen(false);
    }
  };

  const [taskopen, tasksetOpen] = React.useState(false);
  const taskhandleOpen = () => tasksetOpen(true);
  const taskhandleClose = () => tasksetOpen(false);

  const [personopen, personsetOpen] = React.useState(false);
  const personhandleOpen = () => personsetOpen(true);
  const personhandleClose = () => personsetOpen(false);

  const [todo, setTodo] = React.useState("");
  const [todoList, setTodoList] = React.useState([]);
  const [boleanTodo, setBoleanTodo] = React.useState(false);
  const [todoId, setTodoId] = React.useState("");

  //Attachment
  const [attachtodo, attachsetTodo] = React.useState("");
  const [attachtodoList, attachsetTodoList] = React.useState([]);
  const [attachboleanTodo, attachsetBoleanTodo] = React.useState(false);
  const [attachtodoId, attachsetTodoId] = React.useState("");
  //Attachment

  React.useEffect(() => {
    const storedTodos = JSON.parse(localStorage.getItem(KEY));
    if (storedTodos) {
      setTodoList(storedTodos);
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem(KEY, JSON.stringify(todoList));
  }, [todoList]);

  //checklist
  const addTodo = (e) => {
    // console.log(todoList);
    e.preventDefault();
    if (todo === "") {
      return;
    }
    setTodoList([...todoList, { id: uniqid(), todo, tv: false }]);
    setTodo("");
  };
  const dellTodo = (id) => {
    const newTodos = todoList.filter((item) => item.id !== id);

    setTodoList(newTodos);
  };

  const btnEditTodo = (item) => {
    setBoleanTodo(true);
    setTodoId(item.id);

    setTodo(item.todo);
  };

  const editTodo = (e) => {
    e.preventDefault();

    if (todo === "") {
      return;
    }

    const newTodos = todoList.map((item) =>
      item.id === todoId ? { id: uniqid(), todo, tv: false } : item
    );

    setTodoList(newTodos);
    setBoleanTodo(false);
    setTodoId("");
    setTodo("");
  };

  const cheked = (id, tv, todo) => {
    console.log("those app");

    const newTodos = todoList.map((item) =>
      item.id === id ? { id: uniqid(), todo, tv } : item
    );

    setTodoList(newTodos);
  };

  // end checklist

  React.useEffect(() => {
    const storedattachTodos = JSON.parse(localStorage.getItem(KEY));
    if (storedattachTodos) {
      attachsetTodoList(storedattachTodos);
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem(MELT, JSON.stringify(attachtodoList));
  }, [attachtodoList]);

  //Attachment
  const addAttachTodo = (e) => {
    e.preventDefault();
    if (attachtodo === "") {
      return;
    }
    attachsetTodoList([
      ...attachtodoList,
      { id: uniqid(), attachtodo, tc: false },
    ]);
    attachsetTodo("");
  };
  const dellAttachTodo = (id) => {
    const newAttachTodos = attachtodoList.filter((item) => item.id !== id);

    attachsetTodoList(newAttachTodos);
  };

  const btnAttachEditTodo = (item) => {
    attachsetBoleanTodo(true);
    attachsetTodoId(item.id);

    attachsetTodo(item.attachtodo);
  };

  const editAttachTodo = (e) => {
    e.preventDefault();

    if (attachtodo === "") {
      return;
    }

    const newAttachTodos = attachtodoList.map((item) =>
      item.id === attachtodoId ? { id: uniqid(), attachtodo, tc: false } : item
    );

    attachsetTodoList(newAttachTodos);
    attachsetBoleanTodo(false);
    attachsetTodoId("");
    attachsetTodo("");
  };

  const chekedAttach = (id, tc, attachtodo) => {
    //console.log(id, tc, attachtodo);

    const newAttachTodos = attachtodoList.map((item) =>
      item.id === id ? { id: uniqid(), attachtodo, tc } : item
    );

    attachsetTodoList(newAttachTodos);
  };
  //Attachment

  return (
    <div className="project__page">
      <div className="project__header">
        <h3>Projects</h3>
        <div className="project__header__buttons">
          <div className="equalizer">
            <BiEqualizer fontSize={20} />
          </div>
          <div className="add__project__button">+ Add Project</div>
        </div>
      </div>

      <div className="projects">
        <div className="project__categories">
          <Button onClick={handledemoOpen}>Open modal</Button>
          <Button onClick={() => setShow(true)}>Display label</Button>
          <Button onClick={() => setTeam(true)}>Invite Team</Button>
          <TeamModal
            taskName="#Task1"
            footText="Submit task for review"
            title="Team name"
            onClose={() => setTeam(false)}
            team={team}
          />
          <TheModal
            footText="Done"
            title="Add New Label"
            onClose={() => setShow(false)}
            show={show}
          />

          <Modal
            open={demoopen}
            onClose={handledemoClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", padding: "1rem" }}>
                  <div className="add__tarsk__button">
                    <div style={{ marginRight: "0.5rem" }}>
                      <AiOutlineCheck style={{ color: "white" }} />
                    </div>{" "}
                    Complete{" "}
                  </div>
                  <div style={{ marginLeft: "0.4rem", background: "#242424" }}>
                    <DatePicker
                      onChange={onChange}
                      dateopen={dateopen}
                      datesetOpen={datesetOpen}
                    />
                  </div>
                  <div
                    style={{
                      padding: "8px",
                      width: "32px",
                      height: "32px",
                      borderRadius: "6px",
                      background: "#171717",
                      marginLeft: "17rem",
                    }}
                    onClick={handledemoClose}
                  >
                    <AiOutlineClose />
                  </div>
                </div>
                <div style={{ padding: "0.5rem" }}>
                  <h3 style={{ marginBottom: "0.5rem" }}>Tracking Progress</h3>
                  <div style={{ display: "flex", marginTop: "1.4rem" }}>
                    <div style={{ fontSize: "0.8rem" }}>
                      <p>ASSIGNED TO</p>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginTop: "0.5rem",
                        }}
                      >
                        <div className="rare__">S</div>
                        <div onClick={personhandleOpen} className="bigadd__">
                          +
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "9rem", fontSize: "0.8rem" }}>
                      <p>CREATED BY</p>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginTop: "0.5rem",
                        }}
                      >
                        <div className="created__">D</div>
                        <p
                          style={{ marginLeft: "0.6rem", marginTop: "0.4rem" }}
                        >
                          Don Jazzy
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: "0.5rem", fontSize: "0.8rem" }}>
                  LABELS
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        background: "#51D187",
                        padding: "0.2rem",
                        width: "20%",
                        borderRadius: "0.3rem",
                        textAlign: "center",
                      }}
                    >
                      arranging
                    </div>
                    <div
                      style={{
                        background: "#FEB077",
                        padding: "0.2rem",
                        marginRight: "0.3rem",
                        width: "20%",
                        textAlign: "center",
                        borderRadius: "0.3rem",
                      }}
                    >
                      capturing
                    </div>
                    <div onClick={taskhandleOpen} className="bigadd__">
                      +
                    </div>
                  </div>
                </div>
                <div style={{ padding: "0.5rem", fontSize: "0.8rem" }}>
                  <p>Description</p>
                  <div>
                    We need to develop several options (inbox,template,Chat
                    template, tasks template, Project template) of cool user
                    interface design templates
                  </div>
                </div>
                <div
                  style={{
                    padding: "0.5rem",
                    fontSize: "0.8rem",
                    marginTop: "0.6rem",
                  }}
                >
                  <p>CHECKLIST (75%)</p>
                  <div style={{ width: "90%", marginTop: "0.9rem" }}>
                    <BorderLinearProgress variant="determinate" value={75} />
                  </div>
                  <div style={{ marginTop: "0.5rem" }}>
                    {todoList.length ? (
                      <p className="d-flex justify-content-center mt-1"></p>
                    ) : null}
                    <TodoMap
                      list={todoList}
                      editBtn={btnEditTodo}
                      dell={dellTodo}
                      chek={cheked}
                    />
                    <ChecklistComponent
                      add={addTodo}
                      todo={todo} // setTodo
                      input={setTodo}
                      bolean={boleanTodo}
                      setBtnToEdit={editTodo}
                    />
                  </div>
                </div>
                <div style={{ padding: "0.5rem", fontSize: "0.8rem" }}>
                  <p>ATTACHMENTS</p>
                  <div>
                    {todoList.length ? (
                      <p className="d-flex justify-content-center mt-1"></p>
                    ) : null}
                    <AttachMap
                      list={attachtodoList}
                      editBtn={btnAttachEditTodo}
                      dell={dellAttachTodo}
                      chek={chekedAttach}
                    />
                    <AttachlistComponent
                      add={addAttachTodo}
                      todo={attachtodo} // setTodo
                      input={attachsetTodo}
                      bolean={attachboleanTodo}
                      setBtnToEdit={editAttachTodo}
                    />
                  </div>
                </div>
                <div style={{ padding: "0.5rem" }}>
                  <Box sx={{ width: "100%" }}>
                    <ThemeProvider theme={darkTheme}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleValChange}
                          aria-label="basic tabs example"
                        >
                          <Tab label="COMMENTS" {...a11yProps(0)} />
                          <Tab label="ACTIVITY" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                    </ThemeProvider>
                    <TabPanel value={value} index={0}>
                      <div>
                        <div
                          style={{
                            padding: "0.4rem 0.5rem",
                            borderRadius: "0.3rem",
                            backgroundColor: "var(--accent)",
                            position: "absolute",
                            marginTop: "3.8rem",
                            marginLeft: "1rem",
                            fontSize: "0.6rem",
                            cursor: "pointer",
                            color: "white",
                          }}
                        >
                          Comments
                        </div>
                        <div
                          style={{
                            cursor: "pointer",
                            padding: "0.2rem 0.4rem",
                            background: "#242424",
                            marginTop: "3.6rem",
                            marginLeft: "12rem",
                            borderRadius: "4px",
                            position: "absolute",
                            border: "1px solid #242424",
                          }}
                        >
                          <CgAttachment />
                        </div>
                        <div
                          style={{
                            padding: "0.2rem 0.4rem",
                            background: "#242424",
                            marginTop: "3.6rem",
                            marginLeft: "14.5rem",
                            borderRadius: "4px",
                            position: "absolute",
                            border: "1px solid #242424",
                            cursor: "pointer",
                          }}
                        >
                          <BsEmojiSmile />
                        </div>
                        <div
                          style={{
                            padding: "0.2rem 0.4rem",
                            background: "#242424",
                            marginTop: "3.6rem",
                            marginLeft: "17rem",
                            borderRadius: "4px",
                            position: "absolute",
                            border: "1px solid #242424",
                            cursor: "pointer",
                          }}
                        >
                          <BsCardImage />
                        </div>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={5}
                          placeholder="Add comments.."
                          style={{
                            width: 300,
                            background: "#171717",
                            color: "white",
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            marginTop: "0.5rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <div className="profile__icon">
                            <ArtistChildLetter color="orange" letter="D" />
                            {/* <div className="big__">D</div> */}
                          </div>
                          <div
                            style={{ marginLeft: " 10px", fontSize: "10px" }}
                          >
                            <p style={{ float: "left" }}>Daniel Rodriguez</p>
                            <p
                              style={{
                                fontStyle: "italic",
                                marginLeft: "2rem",
                                display: "inline",
                              }}
                            >
                              9 min. ago
                            </p>
                            <div>
                              <p>Hi Cody, Any progress on the project?</p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "0.5rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          <div className="profile__icon">
                            <ArtistChildLetter color="#39D1E5" letter="J" />
                            {/* <div className="big__">J</div> */}
                          </div>
                          <div
                            style={{ marginLeft: " 10px", fontSize: "10px" }}
                          >
                            <p style={{ float: "left" }}>James Wilson</p>
                            <p
                              style={{
                                fontStyle: "italic",
                                marginLeft: "2rem",
                                display: "inline",
                              }}
                            >
                              4 min. ago
                            </p>
                            <div>
                              <p>
                                Hi Bart, I just finished developing the 'Mix'
                              </p>
                            </div>
                            <div
                              style={{ display: "flex", marginTop: "0.5rem" }}
                            >
                              <img
                                src={MixImg1}
                                alt="mix"
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  marginRight: "0.4rem",
                                  borderRadius: "0.4rem",
                                }}
                              />
                              <img
                                src={MixImg2}
                                alt="mix"
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  marginRight: "0.4rem",
                                  borderRadius: "0.4rem",
                                }}
                              />
                              <div
                                style={{
                                  padding: "0.5rem 0.8rem",
                                  marginLeft: "0.7rem",
                                  background: "#171717",
                                  borderRadius: "0.3rem",
                                }}
                              >
                                +3
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <div>
                        <div style={{ display: "flex", padding: "0.4rem" }}>
                          <div className="profile__icon">
                            <div className="big__">J</div>
                          </div>
                          <div
                            style={{ marginLeft: " 10px", fontSize: "10px" }}
                          >
                            <p style={{ float: "left" }}>James Wilson</p>
                            <p
                              style={{
                                fontStyle: "italic",
                                marginLeft: "2rem",
                                display: "inline",
                              }}
                            >
                              9 min. ago
                            </p>
                            <div>
                              <p>James registered a new client !</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </Box>
          </Modal>

          <Modal
            open={taskopen}
            onClose={taskhandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styletask}>
              <Typography
                style={{ padding: "0.3rem" }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Labels
              </Typography>
              <div style={{ padding: "0.3rem" }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search label…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </div>
              <div style={{ padding: "0.3rem", display: "flex" }}>
                <div
                  style={{
                    background: "yellow",
                    padding: "0.2rem",
                    width: "60%",
                    borderRadius: "0.3rem",
                  }}
                >
                  arranging
                </div>
                <div style={{ marginLeft: "2rem", marginTop: "0.3rem" }}>
                  <FcCheckmark />
                </div>
              </div>
              <div style={{ marginTop: "1rem", padding: "0.3rem" }}>
                <div className="add__tarsk__button">Add New Label </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={personopen}
            onClose={personhandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleperson}>
              <p style={{ padding: "0.5rem" }}>Assign To</p>

              <div style={{ padding: "0.3rem" }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search person…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </div>
              <div style={{ padding: "0.3rem", display: "flex" }}>
                <div className="cvbig__">P</div>Pheels
                <div style={{ marginLeft: "4.5rem", marginTop: "0.3rem" }}>
                  <FcCheckmark />
                </div>
              </div>
              <div style={{ padding: "0.3rem", display: "flex" }}>
                <div className="svbig__">S</div>Savage
                <div style={{ marginLeft: "4.5rem", marginTop: "0.3rem" }}>
                  <FcCheckmark />
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default TaskLabel;
