import { useState, useEffect, useCallback, useRef } from "react";
import "./TaskDetails.css";
import CloseIcon from "@mui/icons-material/Close";
import { AddTaskModal } from "../../Modals";
import useAuth from "../../../hooks/useAuth";
import axios, { mockSever } from "../../../api/axios";
import toast from "react-hot-toast";
import { BsCheck2, BsEye } from "react-icons/bs";
import { AiOutlineCalendar, AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SetDeadline, AddMember, ActivityModal } from "../../Modals";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { BsEmojiSmile, BsImage, BsThreeDotsVertical } from "react-icons/bs";
import Picker from "emoji-picker-react";
import {
  BorderLinearProgress,
  getTimeAgo,
  LessLinearProgress,
  handleDownload,
} from "../../../util.js";
import BackDrop from "../../BackDrop";
import { HiDownload } from "react-icons/hi";
import { BsFolder2Open } from "react-icons/bs";
import Popover from "@mui/material/Popover";
import { MdAttachFile } from "react-icons/md";
import { BsFileEarmarkImage, BsFileEarmarkFill } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { BiMessageSquareEdit } from "react-icons/bi";
import { FiEdit, FiDelete } from "react-icons/fi";
import { Tooltip } from "@mui/material";
import Moment from "moment";

const ProjectDetails = (props) => {
  const { id } = props;
  const { auth } = useAuth();
  const userId = auth.id;
  const commentAttachInputRef = useRef(null);
  const userFullName = `${auth.firstName} ${auth.lastName}`;
  const [showDlModal, setDlModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [value, setValue] = useState("1");
  const [newTask, setNewTask] = useState(false);
  const [open, setOpen] = useState(false);
  const [invite, setInvite] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentObj, setCommentObj] = useState({
    text: "",
    image: null,
    audio: null,
    video: null,
    pdf: null,
    creatorsName: userFullName,
    projectId: id,
    userId,
  });
  const [progress, setProgress] = useState(0);
  const [c, setC] = useState(false);
  const [available, setAvailable] = useState(0);
  const pickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [option, setOption] = useState({
    display: null,
    commentId: "",
  });
  const [commentAttachType, setCommentAttachType] = useState(null);
  const [base64Img, setBase64Img] = useState(null);
  const [loading, setLoading] = useState({
    commentLike: false,
    activity: false,
    commentId: "",
  });
  const [activityModal, setActivityModal] = useState({
    edit: false,
    delete: false,
  });
  const [truncateComments, setTruncateComments] = useState(true);
  const time = Moment(props.endDate).format("MMM Do");
  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
      "Content-Type": "application/json",
    },
  };

  // Gets all comments on this project
  const getComments = async () => {
    try {
      const resp = await mockSever.get(`/project/comments?projectId=${id}`);
      setComments(resp.data.data);
    } catch (error) {
      console.log("Error in fetching comments:", error);
    }
  };

  // Handles the onChange event on the comment text area
  const handleComment = (event) => {
    const { value } = event.target;
    setCommentObj({ ...commentObj, text: value });
  };

  // Adding a new comment
  const submitComment = async () => {
    if (commentObj.text === "") {
      toast.error("Text field is required!");
      return;
    }
    setOpen(true);

    const formData = new FormData();
    formData.append("userId", auth.id);
    formData.append("projectId", id);
    formData.append("creatorsName", userFullName);
    formData.append("text", commentObj.text);
    formData.append("image", commentObj.image);
    formData.append("video", commentObj.video);
    formData.append("pdf", commentObj.pdf);
    formData.append("audio", commentObj.audio);
    try {
      const response = await mockSever.post(
        `/project/comments/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response) {
        console.log(response.data);
        getComments(); // fetch udpdated comments
        setOpen(false);
        if (response.data.status) {
          setCommentObj({
            text: "",
            image: null,
            audio: null,
            video: null,
            pdf: null,
            creatorsName: userFullName,
            projectId: id,
            userId,
          });
          setBase64Img(null);

          toast.success("Comment Sent!");
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      setOpen(false);
      console.log("Error while creating a new comment", error);
    }
  };

  const getAvailableBugdet = useCallback(
    (tasks) => {
      const totalAvailable = tasks.reduce(
        (acc, curr) => acc + Number(curr.budget),
        0
      );
      setAvailable(Number(props?.budget) - totalAvailable);
      return totalAvailable;
    },
    [c]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`/task/all/${id}`, config);
        setTasks(response.data);
        getAvailableBugdet(response.data);

        // * Get all the subtasks
        let subTasks = [];

        for (let s = 0; s < response.data.length; s++) {
          const task = response.data[s];

          if (task.subTasks && task.subTasks.length > 0) {
            task.subTasks.forEach((sub) => {
              subTasks.push(sub);
            });
          }
        }
        const completedSubtasks = subTasks.filter(
          (item) => item.status && item.status.toLowerCase() === "completed"
        );

        setProgress((completedSubtasks.length / subTasks.length) * 100);
      } catch (error) {
        console.log(error);
      }
    })();
    getComments();
  }, [c]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheck = async (event, item) => {
    let { checked } = event.target;

    let status;

    if (checked) {
      // SEND POST REQUEST TO CHANGE THE STATUS OF THIS TASK TO "COMPLETED"
      status = "COMPLETED";
    } else {
      // SEND POST REQUEST TO CHANGE THE STATUS OF THIS TASK TO "IN PROGRESS"
      status = "IN PROGRESS";
    }

    setOpen(true);
    try {
      const response = await axios.patch(
        `/task/${item.id}`,
        JSON.stringify({
          status,
        }),
        config
      );

      setTimeout(() => {
        setOpen(false);
      }, response);
      setC(!c);
      props.rr();
      if (checked) {
        toast.success(`${item.title} Completed`);
      } else {
        toast.success(`${item.title} In Progress`);
      }
    } catch (error) {
      setOpen(false);
      toast.error("Failed!");

      console.log(error);
    }

    if (checked) {
      return;
    } else {
      return;
    }
  };

  const delItem = async (item) => {
    setOpen(true);

    try {
      const response = await axios.delete(`/task/${item.id}`, config);

      setTimeout(() => {
        setOpen(false);
      }, response);
      setC(!c);

      props.rr();

      toast.success(`${item.title} Has Been Deleted!`);
    } catch (error) {
      setOpen(false);
      toast.error("Failed!");
      console.log(error);
    }
  };

  const changeStatus = async (project) => {
    setOpen(true);
    let status;

    if (project.status.toLowerCase() === "completed") {
      status = "IN_PROGRESS";
    } else {
      status = "COMPLETED";
    }

    try {
      const response = await axios.patch(
        `project/${project.id}`,
        JSON.stringify({
          status,
        }),
        config
      );
      setTimeout(() => {
        setOpen(false);
      }, response);

      if (props.getProjects) {
        props.getProjects();
      }
      props.onClose();

      if (project.status.toLowerCase() === "completed") {
        toast.success(`${project.name} Has Been Opened`);
      } else {
        toast.success(`${project.name} Set To Completed`);
      }
    } catch (error) {
      setOpen(false);

      toast.error("Failed!");
      console.log(error);
    }

    return;
  };

  const handleAttach = async (id, e) => {
    setOpen(true);

    // uploadimg(e.target.files[0]);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const response = await axios.patch(
        `/project/upload/${id}`,
        formData,
        config
      );

      setTimeout(() => {
        toast.success("Upload successful");
        props.rr();
        props.onClose();
        setOpen(false);
      }, response);
    } catch (error) {
      setOpen(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed!");
      }
      console.log(error);
    }
  };

  const onEmojiClick2 = (event, emojiObject) => {
    const newComment = (commentObj.text || "") + emojiObject.emoji;
    setCommentObj({ ...commentObj, text: newComment });
    setShowPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        // Clicked outside of the picker, so close it
        setShowPicker(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCommentActivityButtons = async (type) => {
    if (type.toLowerCase() === "edit") {
      await setActivityModal({
        edit: true,
        delete: false,
      });
    } else {
      await setActivityModal({
        edit: false,
        delete: true,
      });
    }
  };

  const handleCommentAttachment = async (type) => {
    await setCommentAttachType("");
    if (type.toLowerCase() === "photo") {
      await setCommentAttachType("image");
    } else {
      await setCommentAttachType("file");
    }

    commentAttachInputRef.current.click();
  };

  const clearMediaFiles = async () => {
    await setCommentObj({
      ...commentObj,
      video: null,
      pdf: null,
      audio: null,
      image: null,
    });
    await setBase64Img(null);
  };

  const handleCommentUpload = async (event) => {
    const file = await event.target.files[0];

    clearMediaFiles(); // clear out media fields

    console.log("File to upload", file);

    if (commentAttachType === "file") {
      // Handles uploads for pdfs, videos, and documents
      if (
        file.type.includes("video") ||
        file.type.includes("mp4") ||
        file.type.includes("mov")
      ) {
        setCommentObj({ ...commentObj, video: file });
      } else if (file.type.includes("pdf")) {
        console.log("PDF file", file.name);
        setCommentObj({ ...commentObj, pdf: file });
      } else if (
        file.type.includes("audio") ||
        file.type.includes("wav") ||
        file.type.includes("mp3")
      ) {
        setCommentObj({ ...commentObj, audio: file });
      }
    } else {
      // Handles uploads for images
      await new Promise((resolve, reject) => {
        // Converts Image to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
          setBase64Img(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      setCommentObj({
        ...commentObj,
        image: file,
        pdf: null,
        video: null,
        audio: null,
      });
    }

    setAnchorEl(null); //Closes popover
  };

  const handleCommentLike = async (commentId) => {
    setLoading({ ...loading, commentLike: true, commentId }); //loading state

    try {
      const resp = await mockSever.post("/project/comment/like", {
        commentId,
        userId,
      });
      if (resp) {
        await getComments(); // fetch udpdated comments
        setLoading({ ...loading, commentLike: false, commentId: "" });
      }
    } catch (error) {
      setLoading({ ...loading, commentLike: false, commentId: "" });
      console.log("Error while liking a comment:", error);
    }
  };

  const deleteComment = async (commentId) => {
    setLoading({ ...loading, activity: true, commentId }); //loading state

    try {
      const resp = await mockSever.delete(
        `/project/comment/delete?commentId=${commentId}`
      );
      if (resp) {
        await getComments(); // fetch udpdated comments
        setLoading({ ...loading, activity: false, commentId }); //loading state
        toast.success("Comment Deleted");
      }
    } catch (error) {
      setLoading({ ...loading, activity: false, commentId }); //loading state

      console.log("Error in deleting comment:", error);
    }
  };

  const updateComment = async (commentId, updatedText) => {
    setLoading({ ...loading, activity: true, commentId }); //loading state

    try {
      const resp = await mockSever.post(`/project/comment/update`, {
        commentId,
        updatedText,
      });
      if (resp) {
        await getComments(); // fetch udpdated comments
        setLoading({ ...loading, activity: false, commentId }); //loading state
        toast.success("Comment Updated");
      }
    } catch (error) {
      setLoading({ ...loading, activity: false, commentId }); //loading state

      console.log("Error in updating comment:", error);
    }
  };

  return (
    <div
      className={`add__product__modal chrome-hide-scrollbar !overflow-y-auto !py-7 hide-scrollbar ${
        props.show ? "show " : ""
      }`}
      onClick={props.onClose}
    >
      <div
        className="add__product__modal--content !absolute"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="contact__modal--content-header">
          <div className="header__but">
            <div
              className={`complete__but ${
                props.status.toLowerCase() === "completed" ? "change" : null
              }`}
            >
              {props.status.toLowerCase() === "completed" ? (
                <BsFolder2Open />
              ) : (
                <BsCheck2 />
              )}
              <p onClick={() => changeStatus(props)}>
                {props.status.toLowerCase() === "completed"
                  ? "Open Project"
                  : "Complete"}
              </p>
            </div>
            <Tooltip title="Set deadline" placement="top">
              <div
                className="time__but"
                onClick={() => setDlModal(!showDlModal)}
              >
                <AiOutlineCalendar />
                <p>{time}</p>
              </div>
            </Tooltip>
            <SetDeadline
              onClose={() => {
                setDlModal(false);
              }}
              show={showDlModal}
              project={props}
              rr={props.rr}
            />
            <AddMember
              rr={props.rr}
              projectMembers={props.singleP?.projectMembers}
              data={props}
              onClose={() => setInvite(false)}
              show={invite}
            />
          </div>
          <Tooltip title="Close" placement="top">
            <CloseIcon className="add__product--icon" onClick={props.onClose} />
          </Tooltip>
        </div>
        {open && <BackDrop open={open} />}

        <div className="add__product__inputs !gap-y-[5px] nice">
          <h3>Tracking Progress</h3>

          <div className="team__details">
            <div className="assigned__to">
              <h5>ASSIGNED TO..</h5>

              <div className="all__assign">
                {props.singleP?.projectMembers !== 0 &&
                props.singleP?.projectMembers !== null
                  ? props.singleP?.projectMembers?.map((item, index) => (
                      <div className="assign_mem" key={index}>
                        {item.firstName.charAt(0).toUpperCase()}
                      </div>
                    ))
                  : ""}

                <Tooltip title="Assign member" placement="top">
                  <div
                    onClick={() => {
                      setInvite(true);
                    }}
                    className="assign__new"
                  >
                    +
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className="created__by">
              <h5> CREATED BY</h5>
              <div className="the__user">
                <p className="ic">{auth.firstName.charAt(0).toUpperCase()}</p>
                <p>{auth.firstName + " " + auth.lastName}</p>
              </div>
            </div>
          </div>

          <div className="description">
            <h5>DESCRIPTION</h5>
            <p
              style={{
                fontSize: "14px",
              }}
            >
              {props.description}
            </p>
          </div>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                TASKS {progress ? Math.round(progress) : 0}%
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                {props.status === "ON_HOLD" || props.status === "on_hold" ? (
                  <LessLinearProgress
                    variant="determinate"
                    value={progress ? Math.round(progress) : 0}
                  />
                ) : (
                  <BorderLinearProgress
                    variant="determinate"
                    value={progress ? Math.round(progress) : 0}
                  />
                )}
              </Box>
              <div className="check__list">
                <div className="check__items">
                  {tasks.length !== 0 ? (
                    tasks.map((item, index) => (
                      <div key={index} className="check__item">
                        <div className="check__input">
                          <input
                            type="checkbox"
                            name={item.title}
                            id={item.title}
                            className="check-with-label"
                            onChange={(e) => handleCheck(e, item)}
                            checked={
                              item.status.toLowerCase() === "completed"
                                ? true
                                : false
                            }
                          />
                          <label
                            className="label-for-check"
                            htmlFor={item.title}
                          >
                            {item.title}
                          </label>
                        </div>

                        <Tooltip title="Delete" placement="top">
                          <span>
                            <RiDeleteBin5Line
                              style={{ cursor: "pointer" }}
                              size={20}
                              onClick={() => delItem(item)}
                            />
                          </span>
                        </Tooltip>
                      </div>
                    ))
                  ) : (
                    <p>No task on this project</p>
                  )}
                </div>

                <div
                  className="add__new__check cursor-pointer"
                  onClick={() => setNewTask(true)}
                >
                  <AiOutlinePlus />
                  <p>Add Task</p>
                </div>

                <AddTaskModal
                  show={newTask}
                  onClose={() => setNewTask(!newTask)}
                  pi={props}
                  rr={props.rr}
                  availableBudget={available}
                  cc={() => setC(!c)}
                  getProjects={props.getProjects}
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>COMMENTS ({comments.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="comment__panel">
                <div className="make__a__comment">
                  <div className="post__something">
                    <textarea
                      id="story"
                      name="story"
                      rows="4"
                      cols="33"
                      value={commentObj.text}
                      onChange={handleComment}
                      placeholder="Add a comment..."
                    ></textarea>
                  </div>

                  {base64Img && (
                    <div className="py-2 bg-[#000]">
                      <div className="relative w-[10vw] h-[13vh]">
                        <img
                          src={base64Img}
                          className=" h-full w-full  object-contain "
                        />

                        <Tooltip title="Delete" placement="top">
                          <span
                            className="absolute cursor-pointer top-1 text-2xl z-10 right-1 text-red-700 hover:scale-125 duration-200 ease-in backdrop-blur-sm rounded-full  flex justify-center items-center"
                            onClick={() => setBase64Img(null)}
                          >
                            <TiDeleteOutline />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  {commentObj.audio || commentObj.video || commentObj.pdf ? (
                    <div className="py-2 bg-[#000]">
                      <div className="relative w-[15vw] p-3  border-[#151517] border-2 rounded-md pr-5">
                        {/* Display media file */}
                        <div className="flex items-center space-x-2">
                          {commentObj.audio && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="38"
                              viewBox="0 0 34 44"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                fill="#0D0D0D"
                              />
                              <path
                                opacity="0.05"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                fill="#FD7972"
                              />
                              <path
                                d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                stroke="#FD7972"
                              />
                              <path
                                d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                stroke="#FD7972"
                              />
                              <path
                                d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                                fill="#FD7972"
                                stroke="#FD7972"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}

                          {commentObj.pdf && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="38"
                              viewBox="0 0 29 38"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                fill="#0D0D0D"
                              />
                              <path
                                opacity="0.05"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                fill="#FD7972"
                              />
                              <path
                                d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                stroke="#FD7972"
                              />
                              <path
                                d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                stroke="#FD7972"
                              />
                              <path
                                d="M8.75845 28.5C8.54689 28.5 8.34385 28.435 8.17059 28.3124C7.53773 27.863 7.45262 27.363 7.49275 27.0223C7.60339 26.0856 8.82715 25.1051 11.1312 24.1062C12.0455 22.2096 12.9155 19.8729 13.434 17.9205C12.8273 16.6707 12.2376 15.0492 12.6674 14.0981C12.8182 13.7649 13.006 13.5094 13.3568 13.399C13.4954 13.3552 13.8456 13.3 13.9745 13.3C14.2809 13.3 14.5502 13.6734 14.7411 13.9036C14.9204 14.1199 15.3271 14.5785 14.5143 17.8175C15.3338 19.4195 16.4949 21.0513 17.6075 22.1688C18.4044 22.0324 19.0902 21.9628 19.6489 21.9628C20.6009 21.9628 21.1778 22.1728 21.4131 22.6055C21.6076 22.9634 21.528 23.3817 21.176 23.8484C20.8374 24.2966 20.3705 24.5337 19.8264 24.5337C19.0872 24.5337 18.2263 24.0918 17.2664 23.2189C15.5417 23.5601 13.5276 24.1689 11.8996 24.8427C11.3914 25.8635 10.9044 26.6857 10.4509 27.2888C9.8278 28.1145 9.29039 28.5 8.75845 28.5ZM10.3768 25.5504C9.07762 26.2415 8.54811 26.8094 8.50981 27.1294C8.50373 27.1823 8.48732 27.3216 8.77183 27.5275C8.86241 27.5005 9.39131 27.2721 10.3768 25.5504ZM18.6671 22.9945C19.1625 23.3553 19.2835 23.5377 19.6075 23.5377C19.7498 23.5377 20.1553 23.5319 20.3431 23.2839C20.4337 23.1636 20.469 23.0865 20.483 23.0451C20.4082 23.0077 20.3091 22.9318 19.7686 22.9318C19.4616 22.9323 19.0756 22.945 18.6671 22.9945ZM14.1258 19.2071C13.6912 20.6307 13.1173 22.1676 12.5003 23.5595C13.7708 23.0929 15.152 22.6855 16.4494 22.3972C15.6286 21.495 14.8086 20.3683 14.1258 19.2071ZM13.7568 14.338C13.6973 14.357 12.9483 15.349 13.8152 16.1885C14.3921 14.9716 13.783 14.33 13.7568 14.338Z"
                                fill="#FD7972"
                              />
                            </svg>
                          )}

                          {commentObj.video && (
                            <svg
                              width="31"
                              height="40"
                              viewBox="0 0 31 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                                fill="white"
                              />
                              <path
                                opacity="0.05"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                                fill="#FD7972"
                              />
                              <path
                                d="M2.49998 0.5H21.5348L30.4999 9.4228V37.5C30.4999 38.6049 29.604 39.5 28.4999 39.5H2.49998C1.39594 39.5 0.5 38.6049 0.5 37.5V2.49998C0.5 1.39508 1.39604 0.5 2.49998 0.5Z"
                                stroke="#FD7972"
                              />
                              <path
                                d="M30.5001 9.5H23.7294C22.4987 9.5 21.5 8.50231 21.5 7.27286V0.5"
                                stroke="#FD7972"
                              />
                              <path
                                d="M12 16L19 21.5L12 27V16Z"
                                fill="#FD7972"
                                stroke="#FD7972"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}

                          <span className=" truncate">
                            {commentObj.audio?.name ||
                              commentObj.video?.name ||
                              commentObj.pdf?.name}
                          </span>
                        </div>
                        <span
                          className="absolute cursor-pointer top-1 text-2xl z-10 right-1 text-red-700 hover:scale-125  duration-200 ease-in backdrop-blur-sm rounded-full  flex justify-center items-center"
                          onClick={() => clearMediaFiles()}
                        >
                          <TiDeleteOutline />
                        </span>
                      </div>
                    </div>
                  ) : null}

                  <div className="button">
                    <div
                      className={`post-tag ${
                        commentObj.text === ""
                          ? "opacity-75 cursor-not-allowed"
                          : ""
                      }`}
                      onClick={() => submitComment()}
                    >
                      Comment
                    </div>
                    <div className="activity__buttons">
                      <div className="relative">
                        <Tooltip title="Emoji" placement="top">
                          <div
                            onClick={() => {
                              setShowPicker(!showPicker);
                            }}
                          >
                            <BsEmojiSmile />
                          </div>
                        </Tooltip>
                        {showPicker && (
                          <div
                            className="absolute left[50%] bottom-[50%]"
                            ref={pickerRef}
                          >
                            <Picker onEmojiClick={onEmojiClick2} />
                          </div>
                        )}
                      </div>
                      <Tooltip title="Media Uploads" placement="top">
                        <div onClick={(e) => setAnchorEl(e.currentTarget)}>
                          <MdAttachFile />
                        </div>
                      </Tooltip>
                      <Popover
                        id={id}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "left",
                          horizontal: "left",
                        }}
                      >
                        <div className="bg-[#000] shadow-lg shadow-[#413f3f] flex flex-col  space-y-1 text-[#fff]">
                          {[
                            {
                              text: "Document",
                              icon: <BsFileEarmarkFill size={20} />,
                            },
                            {
                              text: "Photo",
                              icon: <BsFileEarmarkImage size={20} />,
                            },
                          ].map((item, index) => (
                            <div
                              key={index}
                              className=" hover:bg-[#413f3f] text-sm px-3 cursor-pointer py-2 flex items-center space-x-2 ease-in duration-200 rounded"
                              onClick={() => handleCommentAttachment(item.text)}
                            >
                              <span>{item.icon}</span>
                              <p className=" flex-1">{item.text}</p>
                            </div>
                          ))}
                        </div>
                      </Popover>

                      {/* Initializes the attachment selected */}
                      <input
                        ref={commentAttachInputRef}
                        type="file"
                        accept={
                          commentAttachType === "file"
                            ? "video/*,application/pdf,audio/*"
                            : "image/*"
                        }
                        className="hidden"
                        onChange={handleCommentUpload}
                      />
                    </div>
                  </div>
                </div>

                <div className="all__comments pt-3">
                  {comments.length !== 0 ? (
                    (truncateComments ? comments.slice(0, 3) : comments).map(
                      (item, index) => (
                        <div key={index} className="flex  justify-between">
                          <div className="header">
                            <div className="w-10 h-10 pt-2.5 pb-[9px] text-purple-400 text-sm  bg-purple-400 bg-opacity-20 rounded-[8px] uppercase justify-center items-center inline-flex">
                              {item.creatorsName.charAt(0).toUpperCase()}
                            </div>
                            <div className="name">
                              <p>
                                {item.creatorsName}{" "}
                                <span className="pl-2 text-xs">
                                  {getTimeAgo(item.createdDate)}
                                </span>
                              </p>
                              <p
                                style={{
                                  fontSize: "small",
                                }}
                                className="w-full truncate"
                              >
                                {item.text}
                              </p>

                              {/* DISPLAY MEDIA FILES */}
                              {item.image && (
                                <img
                                  src={item.image}
                                  className="w-[4vw] h-[8vh] object-center object-cover mt-2 rounded-md "
                                  alt={item._id}
                                />
                              )}
                              {item.pdf && (
                                <div className="group relative flex  hover:scale-110 hover:p-2 duration-200 ease-in ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35"
                                    height="40"
                                    viewBox="0 0 29 38"
                                    fill="none"
                                    className="pt-2"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                      fill="#0D0D0D"
                                    />
                                    <path
                                      opacity="0.05"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                      fill="#FD7972"
                                    />
                                    <path
                                      d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                      stroke="#FD7972"
                                    />
                                    <path
                                      d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                      stroke="#FD7972"
                                    />
                                    <path
                                      d="M8.75845 28.5C8.54689 28.5 8.34385 28.435 8.17059 28.3124C7.53773 27.863 7.45262 27.363 7.49275 27.0223C7.60339 26.0856 8.82715 25.1051 11.1312 24.1062C12.0455 22.2096 12.9155 19.8729 13.434 17.9205C12.8273 16.6707 12.2376 15.0492 12.6674 14.0981C12.8182 13.7649 13.006 13.5094 13.3568 13.399C13.4954 13.3552 13.8456 13.3 13.9745 13.3C14.2809 13.3 14.5502 13.6734 14.7411 13.9036C14.9204 14.1199 15.3271 14.5785 14.5143 17.8175C15.3338 19.4195 16.4949 21.0513 17.6075 22.1688C18.4044 22.0324 19.0902 21.9628 19.6489 21.9628C20.6009 21.9628 21.1778 22.1728 21.4131 22.6055C21.6076 22.9634 21.528 23.3817 21.176 23.8484C20.8374 24.2966 20.3705 24.5337 19.8264 24.5337C19.0872 24.5337 18.2263 24.0918 17.2664 23.2189C15.5417 23.5601 13.5276 24.1689 11.8996 24.8427C11.3914 25.8635 10.9044 26.6857 10.4509 27.2888C9.8278 28.1145 9.29039 28.5 8.75845 28.5ZM10.3768 25.5504C9.07762 26.2415 8.54811 26.8094 8.50981 27.1294C8.50373 27.1823 8.48732 27.3216 8.77183 27.5275C8.86241 27.5005 9.39131 27.2721 10.3768 25.5504ZM18.6671 22.9945C19.1625 23.3553 19.2835 23.5377 19.6075 23.5377C19.7498 23.5377 20.1553 23.5319 20.3431 23.2839C20.4337 23.1636 20.469 23.0865 20.483 23.0451C20.4082 23.0077 20.3091 22.9318 19.7686 22.9318C19.4616 22.9323 19.0756 22.945 18.6671 22.9945ZM14.1258 19.2071C13.6912 20.6307 13.1173 22.1676 12.5003 23.5595C13.7708 23.0929 15.152 22.6855 16.4494 22.3972C15.6286 21.495 14.8086 20.3683 14.1258 19.2071ZM13.7568 14.338C13.6973 14.357 12.9483 15.349 13.8152 16.1885C14.3921 14.9716 13.783 14.33 13.7568 14.338Z"
                                      fill="#FD7972"
                                    />
                                  </svg>

                                  <Tooltip title="download" placement="top">
                                    <div
                                      className="hidden hover:scale-110 duration-300 ease-in group-hover:block cursor-pointer  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                                      onClick={() =>
                                        handleDownload(item.pdf, item._id)
                                      }
                                    >
                                      <HiDownload size={20} />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}

                              {item.audio && (
                                <div className="group relative hover:scale-110 hover:p-2 duration-200 ease-in ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="38"
                                    viewBox="0 0 34 44"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                      fill="#0D0D0D"
                                    />
                                    <path
                                      opacity="0.05"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                      fill="#FD7972"
                                    />
                                    <path
                                      d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                      stroke="#FD7972"
                                    />
                                    <path
                                      d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                      stroke="#FD7972"
                                    />
                                    <path
                                      d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                                      fill="#FD7972"
                                      stroke="#FD7972"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>

                                  <Tooltip title="download" placement="top">
                                    <div
                                      className="hidden group-hover:block cursor-pointer  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                                      onClick={() =>
                                        handleDownload(item.audio, item._id)
                                      }
                                    >
                                      <HiDownload size={20} />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}

                              {item.video && (
                                <div className="group relative hover:scale-110 hover:p-2 duration-200 ease-in ">
                                  <svg
                                    width="31"
                                    height="40"
                                    viewBox="0 0 31 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                                      fill="white"
                                    />
                                    <path
                                      opacity="0.05"
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                                      fill="#FD7972"
                                    />
                                    <path
                                      d="M2.49998 0.5H21.5348L30.4999 9.4228V37.5C30.4999 38.6049 29.604 39.5 28.4999 39.5H2.49998C1.39594 39.5 0.5 38.6049 0.5 37.5V2.49998C0.5 1.39508 1.39604 0.5 2.49998 0.5Z"
                                      stroke="#FD7972"
                                    />
                                    <path
                                      d="M30.5001 9.5H23.7294C22.4987 9.5 21.5 8.50231 21.5 7.27286V0.5"
                                      stroke="#FD7972"
                                    />
                                    <path
                                      d="M12 16L19 21.5L12 27V16Z"
                                      fill="#FD7972"
                                      stroke="#FD7972"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                  <Tooltip title="download" placement="top">
                                    <div
                                      className="hidden group-hover:block cursor-pointer  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                                      onClick={() =>
                                        handleDownload(item.video, item._id)
                                      }
                                    >
                                      <HiDownload size={20} />
                                    </div>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex space-x-1 mt-3">
                            {/* Like comment component */}
                            <Tooltip
                              title={
                                item.likers.includes(userId) &&
                                loading.commentId !== item._id
                                  ? "Remove like"
                                  : "Like"
                              }
                              placement="top"
                            >
                              <div
                                onClick={() => handleCommentLike(item._id)}
                                className=" text-lg pr-2  cursor-pointer"
                              >
                                {loading.commentLike &&
                                loading.commentId === item._id ? (
                                  <div className="flex items-center space-x-2">
                                    <svg
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                      }}
                                      aria-hidden="true"
                                      className={`inline w-5 h-5  cursor-progress text-gray-200 animate-spin dark:text-gray-600 fill-red-500 `}
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                    <span className=" text-sm">
                                      {item.likers.length}
                                    </span>
                                  </div>
                                ) : item.likers.includes(userId) ? (
                                  <aside className="flex items-center ">
                                    <FaHeart className="text-red-500" />
                                    <span className="pl-2 text-sm">
                                      {item.likers.length}
                                    </span>
                                  </aside>
                                ) : (
                                  <aside className="flex items-center ">
                                    <FaRegHeart />
                                    <span className="pl-2 text-sm">
                                      {item.likers.length}
                                    </span>
                                  </aside>
                                )}
                              </div>
                            </Tooltip>

                            {/* Options component */}
                            {item.creatorId === userId && (
                              <Tooltip title="Options" placement="top">
                                <div
                                  className=" cursor-pointer"
                                  onClick={(e) =>
                                    setOption({
                                      display: e.currentTarget,
                                      commentId: item._id,
                                    })
                                  }
                                >
                                  <BsThreeDotsVertical size={20} />
                                </div>
                              </Tooltip>
                            )}
                            <Popover
                              id={id}
                              open={Boolean(option.display)}
                              anchorEl={option.display}
                              onClose={() =>
                                setOption({ commentId: "", display: null })
                              }
                              anchorOrigin={{
                                vertical: "left",
                                horizontal: "left",
                              }}
                            >
                              <div className="bg-[#000] shadow-lg shadow-[#413f3f] flex flex-col  space-y-1 text-[#fff]">
                                {[
                                  {
                                    text: "Edit",
                                    icon: <FiEdit size={20} />,
                                  },
                                  {
                                    text: "Delete",
                                    icon: <RiDeleteBin5Line size={20} />,
                                  },
                                ].map((item, index) => (
                                  <div
                                    key={index}
                                    className={` ${
                                      item.text.toLowerCase() === "delete" &&
                                      "text-red-500"
                                    } hover:bg-[#413f3f] pl-3 pr-6 cursor-pointer text-sm py-2 flex items-center space-x-2 ease-in duration-200 rounded`}
                                    onClick={() =>
                                      handleCommentActivityButtons(item.text)
                                    }
                                  >
                                    <span>{item.icon}</span>
                                    <p className=" flex-1">{item.text}</p>
                                  </div>
                                ))}
                              </div>
                            </Popover>
                            {/* Delete modal for a comment */}
                            {activityModal.delete && (
                              <ActivityModal
                                show={activityModal.delete}
                                onClose={() => {
                                  setActivityModal({
                                    ...activityModal,
                                    delete: false,
                                  });
                                  setOption({ commentId: "", display: null });
                                }}
                                loading={loading.activity}
                                type="delete"
                                func={() => deleteComment(option.commentId)}
                                text={
                                  "Are you sure you want to delete this comment?"
                                }
                              />
                            )}

                            {/* Edit modal for a comment */}
                            {activityModal.edit && (
                              <ActivityModal
                                show={activityModal.edit}
                                onClose={() => {
                                  setActivityModal({
                                    ...activityModal,
                                    edit: false,
                                  });
                                  setOption({ commentId: "", display: null });
                                }}
                                loading={loading.activity}
                                type="edit"
                                func={updateComment}
                                text={"Edit comment"}
                                commentId={option.commentId}
                                editData={comments.find(
                                  (item) => item._id === option.commentId
                                )}
                              />
                            )}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <p className=" text-center">No comments</p>
                  )}

                  {comments.length > 3 && (
                    <p
                      className="pt-1 cursor-pointer text-sm"
                      onClick={() => setTruncateComments(!truncateComments)}
                    >
                      {!truncateComments
                        ? "Show less comments"
                        : `View all (${comments.length}) comments.`}
                    </p>
                  )}
                  <br />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>ATTACHMENTS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="attachments">
                <div className="attach__items">
                  {props.singleP?.file && props.singleP?.file.length !== 0 ? (
                    props.singleP?.file !== null ? (
                      props.singleP?.file.map((item, index) =>
                        item === "[]" ? null : (
                          <div key={index} className="attach__item">
                            <div className="attach__left">
                              <img
                                //   src={item.file}
                                src={
                                  item ||
                                  "https://images.unsplash.com/photo-1518098268026-4e89f1a2cd8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"
                                }
                                alt="attach image"
                                className="attach__image"
                              />
                              <div className="attach__details">file</div>
                            </div>
                            <div className="attach__icons">
                              <a
                                href={item}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                <HiDownload size={20} />
                              </a>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <p>No attachment on this project</p>
                    )
                  ) : null}
                </div>

                <label className="add__new__attach" htmlFor="attachment">
                  <AiOutlinePlus />
                  <p>Add Attachment</p>
                </label>

                <input
                  type="file"
                  id="attachment"
                  onChange={(e) => handleAttach(props.id, e)}
                />
              </div>
            </AccordionDetails>
          </Accordion> */}

          {/* TAB for comments and activity */}
          {/* <Box
            sx={{ width: "100%", typography: "body1" }}
            className="buttom-aspect"
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="Comments and activity tab"
                >
                  <Tab label="COMMENTS" value="1" />

                  <Tab label="ACTIVITY" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
          
              </TabPanel>
              
              <TabPanel value="2">Activity Section</TabPanel>
             
            </TabContext>
          </Box> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
