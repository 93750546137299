import React, {useEffect} from "react";
import "./tableFooter.css"

const TableFooter =({range, setPage, page, slice})=>{
    useEffect(()=>{
        if(slice.length <1 && page!== 1){
            setPage(page - 1)
        }
    },[slice, page, setPage]);

    return(
        <div 
        style={{justifyContent:'center',borderRadius:'0.3rem',display:'flex',textAlign:'left',padding:'0.3rem',background:'var(--accent)'}}
        >
            {range.map((el, index)=>(
                <buttton
                className={`${page === el ?'activeButton':'inactiveButton'} cursor-pointer`}
                    key={index}
                    onClick={()=>setPage(el)}
                >{el}</buttton>
            ))}
        </div>
    )
}

export default TableFooter;