import "../../userProfilePage/UserProfilePage.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import toast from "react-hot-toast";
import LoadingScreen from "../../preLoader";
import { FiEdit3 } from "react-icons/fi";
import { BsEye } from "react-icons/bs";

const styles = {
  input:
    "w-full rounded-md p-2 px-4 focus:border focus:border-[#ba68c8] bg-[#353535]",
};
const Security = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { auth, setAuth, config } = useAuth();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const resp = await axios.get(
          `https://www.api.beatlab.app/api/v1/users/`,
          config
        );

        setUser(resp.data);
        setLoading(true);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [config]);

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "fullName") {
      const splitName = value.split(" ");
      setUser({
        ...user,
        firstName: splitName[0],
        lastName: splitName.slice(1).join(" "),
      });
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    setOpen(true);

    try {
      const response = await axios.patch(
        "https://www.api.beatlab.app/api/v1/users/",
        JSON.stringify({
          ...user,
          dateOfBirth: new Date(user["dateOfBirth"])
            .toISOString()
            .substring(0, 10),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(auth?.authToken),
          },
          withCredentials: true,
        }
      );

      setTimeout(() => {
        const newAuth = { ...response.data, authToken: auth?.authToken };
        setAuth(newAuth);
        setOpen(false);
        toast.success("Profile Updated");
      }, response);
    } catch (error) {
      setOpen(false);

      if (!error.response) {
        toast.error("Check your internet connection!");
      } else {
        toast.error("Failed!");
      }
      console.log(error);
    }
  };
  if (!loading) {
    return <LoadingScreen loading />;
  }
  return (
    <div>
      <div className="flex md:gap-0 justify-between items-center text-white ">
        <div className="flex items-center space-x-8 ">
          <div className="group relative">
            <img
              className="w-20 h-20 md:w-40 md:h-40 object-cover rounded-full border-2 border-[#202225] group-hover:opacity-40 ease-in-out duration-500"
              src={
                user["picture"] ||
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtaP-HPoo7LXGeRZRta0zY1vdavRWm-KJfsw&usqp=CAU"
              }
              alt="profile"
            />

            <div className="hidden group-hover:block absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              <div className="flex space-x-3">
                <label
                  htmlFor="profile_img"
                  className="text-center py-2  rounded-lg  bg-[#ba68c8] text-white font-bold text-lg cursor-pointer px-4 "
                  // onClick={() => uploadProfileImg()}
                >
                  <FiEdit3 />
                  <input
                    id="profile_img"
                    type="file"
                    name="image"
                    className="hidden"
                    accept="image/*"
                    onChange={handleChange}
                  />
                </label>
                <p
                  className="text-center py-2 rounded-lg bg-[#ba68c8] text-white font-bold text-lg cursor-pointer px-4 "
                  // onClick={() => setViewImg(true)}
                >
                  <BsEye />
                </p>
              </div>
            </div>
          </div>

          <div className="hidden md:flex flex-col space-y-1">
            <p className="text-xl md:text-3xl font-bold mb-4 text-[#ba68c8]">
              {user["firstName"] + " " + user["lastName"]}
            </p>
            <p className="text-lg mb-4 text-[#8a939b]">
              {/* This hyperlink should redirect the user to the profile page */}
              You're a{" "}
              <span className="text-[#ba68c8]">
                {user.role === "SUPER_ADMIN" ? "Admin" : user.role}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="my-3 shadow-xl rounded-xl p-4 md:p-8">
        <h2 className="test-xl md:text-2xl font-semibold text-[#ba68c8] capitalize">
          Account Information
        </h2>

        <form onSubmit={handleUpdate}>
          <div className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-x-6 text-white">
            <div className="flex flex-col space-y-4 py-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={user["firstName"]}
                  onChange={handleChange}
                  placeholder="FirstName"
                  className={styles.input}
                  required
                />
              </div>

              <div className="flex lg:hidden flex-col space-y-2">
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  value={user["lastName"]}
                  onChange={handleChange}
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  required
                  className={styles.input}
                />
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="location">Address</label>
                <input
                  id="location"
                  type="text"
                  value={user["location"]}
                  name="location"
                  onChange={handleChange}
                  placeholder="e.g San Francisco"
                  className={styles.input}
                  required
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="phoneNumber">Mobile Number</label>
                <input
                  id="phoneNumber"
                  type="tel"
                  onChange={handleChange}
                  value={user["phoneNumber"]}
                  name="phoneNumber"
                  placeholder="Mobile Number"
                  className={styles.input}
                  required
                />
              </div>
            </div>
            <div className="flex flex-col space-y-4 py-4">
              <div className=" hidden lg:flex flex-col space-y-2">
                <label htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  value={user["lastName"]}
                  onChange={handleChange}
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  required
                  className={styles.input}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="dateOfBirth">Date Of Birth</label>
                <input
                  id="dateOfBirth"
                  type="date"
                  onChange={handleChange}
                  value={new Date(user["dateOfBirth"])
                    .toISOString()
                    .substring(0, 10)}
                  name="dateOfBirth"
                  placeholder="dateOfBirth"
                  className={styles.input}
                  required
                />
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="twitter">Twitter</label>
                <input
                  id="twitter"
                  type="url"
                  onChange={handleChange}
                  value={user["twitter"]}
                  name="twitter"
                  placeholder="Twitter Link"
                  className={styles.input}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-4 py-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="linkedin">LinkedIn</label>
                <input
                  id="linkedin"
                  type="url"
                  onChange={handleChange}
                  value={user["linkedin"]}
                  name="linkedin"
                  placeholder="LinkedIn Link"
                  className={styles.input}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="facebook">Facebook</label>
                <input
                  id="facebook"
                  type="url"
                  onChange={handleChange}
                  value={user["facebook"]}
                  name="facebook"
                  placeholder="Facebook Link"
                  className={styles.input}
                />
              </div>

              <div className="flex flex-col space-y-2">
                <label htmlFor="instagram">Instagram </label>
                <input
                  id="instagram"
                  type="url"
                  onChange={handleChange}
                  value={user["instagram"]}
                  name="instagram"
                  placeholder="Instagram Link"
                  className={styles.input}
                />
              </div>
            </div>
          </div>
          <br />

          <button
            type="submit"
            className="bg-[#ba68c8]  hover:opacity-70  rounded-md md:rounded-xl text-sm md:text-base py-2 px-3 md:py-3 md:px-5 ease-in-out duration-200 "
          >
            {open ? (
              <div className="flex items-center space-x-2">
                <svg
                  aria-hidden="true"
                  className="inline w-5 h-5 mr-2 text-[#ba68c8] animate-spin  fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>{" "}
                <p>Updating...</p>
              </div>
            ) : (
              <p>Update</p>
            )}
          </button>
        </form>
      </div>
      {/*
      <ViewImage
        show={viewImg}
        onClose={() => setViewImg(!viewImg)}
        image={user["image"]}
      />
     */}
    </div>
  );
};

export default Security;
