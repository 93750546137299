import React, { useState, useEffect } from "react";
import "./ProfileModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { ImageModal } from "../../Modals";
import { FiHeadphones } from "react-icons/fi";
import { ProjectDetails } from "../../Modals";
import Moment from "moment";

const ProfileModal = (props) => {
  const [view, setView] = useState(false);
  const [source, setSource] = useState(null);

  const [details, setDetails] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [data, setData] = useState();

  const openView = (src) => {
    setView(true);
    setSource(src);
  };
  const CloseView = () => setView(false);

  const handleDetails = (item) => {
    setSelectedProject(item);

    setDetails(!details);
  };

  let postImage =
    "https://images.unsplash.com/photo-1593526613712-7b4b9a707330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80";

  // DUMMY DATA FOR TESTING
  let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 1, 2, 4, 5];
  return (
    <div>
      <div
        className={`add__product__modal ${props.show ? "show" : ""}`}
        onClick={props.onClose}
      >
        <div
          className="profile__modal--content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="contact__modal--content-header">
            <h4
              style={{
                textTransform: "capitalize",
              }}
            >
              {props.present}
            </h4>
            <CloseIcon className="add__product--icon" onClick={props.onClose} />
          </div>

          <div className="profile__modal__details">
            {/* DISPLAY FOLLOWERS */}
            {props.present === "followers" && (
              <div className="followers__section">
                <div className="search__container">
                  <input
                    type="text"
                    placeholder={` Search...`}
                    id="search__bar"
                  />
                  <label htmlFor="search__bar"></label>
                </div>
                <div className="friends__section">
                  {/*
              
                {numbers.map((item, index) => (
                  <div className="friend" key={index}>
                    <div className="user">T</div>
                    <div className="name">Tiwa Savage</div>
                  </div>
                ))}
              */}

                  <p className="nothing">0 followers</p>
                </div>
              </div>
            )}
            {/* DISPLAY MWEDIA FILES */}
            {props.present === "media" && (
              <div className="media__section">
                <div className="followers__section">
                  <div className="search__container">
                    <input
                      type="text"
                      placeholder={` Search...`}
                      id="search__bar"
                    />
                    <label htmlFor="search__bar"></label>
                  </div>
                  <div className="friends__section">
                    {props.details.length !== 0 ? (
                      props.details.map((item, index) => (
                        <div className="media__item" key={index}>
                          <FiHeadphones />
                          <p>{item.name}</p>
                        </div>
                      ))
                    ) : (
                      <p className="nothing">No media</p>
                    )}
                  </div>
                  {/* <ProjectDetails
                    rr={props?.rr}
                    time={time}
                    show={details}
                    {...data}
                    getProjects={props.fetchPosts}
                    onClose={() => setDetails(!details)}
                  /> */}
                </div>
              </div>
            )}

            {/* DISPLAY PROJECTS */}
            {props.present === "projects" && (
              <div className="followers__section">
                <div className="search__container">
                  <input
                    type="text"
                    placeholder={` Search...`}
                    id="search__bar"
                  />
                  <label htmlFor="search__bar"></label>
                </div>
                <div className="flex items-center my-5 flex-wrap !w-full gap-2">
                  {props.details.length !== 0 ? (
                    props.details.map((item, index) => (
                      <div
                        className="py-2 px-4 cursor-pointer duration-150 ease-in hover:border-2 hover:scale-105 w-[12vw] flex space-x-3 items-center rounded border border-[#242424]"
                        key={index}
                        onClick={() => handleDetails(item)}
                      >
                        <span className="p-1 rounded border border-[#242424]">
                          <FiHeadphones size={30} color="rgb(26, 156, 255)" />
                        </span>
                        <p className="truncate">{item.name}</p>
                      </div>
                    ))
                  ) : (
                    <p className="nothing">No media</p>
                  )}
                </div>
              </div>
            )}

            {/* DISPLAY CONTACTS */}
            {props.present === "contacts" && (
              <div className="media__section">
                <div className="followers__section">
                  <div className="search__container">
                    <input
                      type="text"
                      placeholder={` Search...`}
                      id="search__bar"
                    />
                    <label htmlFor="search__bar"></label>
                  </div>
                  <div className="friends__section">
                    <p className="nothing">No contacts</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* PROJECT MODAL DISPLAY FOR A PROJECT */}
      {selectedProject && details && (
        <ProjectDetails
          show={details}
          {...selectedProject}
          getProjects={props.getProjects}
          onClose={() => setDetails(!details)}
        />
      )}
      <ImageModal source={source} open={view} close={CloseView} />
    </div>
  );
};

export default ProfileModal;
