import React, { useState, useEffect, useRef } from "react";
import "./UserProfilePage.css";
import { BiPencil, BiSave, BiChevronDown } from "react-icons/bi";
import { Box, Stack } from "@mui/material";
import { BsEmojiSmile, BsImage, BsStopFill } from "react-icons/bs";
import PostCard from "../Cards/postCard/PostCard";
import { ProfileModal, ProjectDetails } from "../Modals";
import useAuth from "../../hooks/useAuth";
import axios, { firstVersion, mockSever } from "../../api/axios";
import toast from "react-hot-toast";
import { getOrgProjects, sortResponse } from "../../util.js";
import Picker from "emoji-picker-react";

import BackDrop from "../BackDrop";
import LoadingScreen from "../preLoader";
import { MdAttachFile } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";

const URL_PATH = "/users/me";
const UserProfilePage = () => {
  const {
    auth,
    config,
    userProjects,
    currentOrganization,
    UpdateProjectsGlobalState,
  } = useAuth();
  const userId = auth.authToken;
  const commentAttachInputRef = useRef(null);
  const pickerRef = useRef(null);
  const userFullName = `${auth.firstName} ${auth.lastName}`;
  const [currentPopUp, setCurrPopUp] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dateOfBirth: "",
    location: "",
  });
  const [loading, setLoading] = useState({
    posts: false,
    postProgressBar: false,
  });
  const [details, setDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  // Manage state for toggling modals
  const [state, setState] = useState({
    followers: false,
    media: false,
    projects: false,
    contacts: false,
  });
  const [pageData, setPageData] = useState({
    followers: [],
    media: [],
    projects: userProjects,
    contacts: [],
    posts: [],
  });
  const [postObj, setPostObj] = useState({
    caption: "",
    image: null,
    audio: null,
    video: null,
    pdf: null,
    creatorsName: userFullName,
    userId,
  });

  const [picker, setPicker] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [base64Img, setBase64Img] = useState(null);
  const [postAttachType, setPostAttachType] = useState("");
  const postAttachInputRef = useRef(null);
  const [r, setR] = useState(false);

  const fetchData = async (endpoint) => {
    try {
      const response = await axios.get(`/${endpoint}`, config);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //GETING USER'S INFORMATION(FOLLOWERS, CONTACTS, MEDIA FILES)
  const getDetails = async () => {
    try {
      const [media, followers, contacts] = await Promise.all([
        fetchData("cave"), //fetch media files data
        fetchData("followers"), //fetch follwers data
        fetchData("contact"), //fetch contact data
      ]);

      // Update page data
      setPageData({
        ...pageData,
        media,
        followers,
        contacts,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Get all the users posts
  const getPosts = async () => {
    setLoading({ ...loading, posts: true });
    try {
      const resp = await mockSever.get(`/posts?userId=${userId}`);
      // const resp = await mockSever.get(`/legit/userprofile?userId=${userId}`);
      if (resp) {
        const respData = resp.data;

        // await setPageData({
        //   ...pageData,
        //   posts: respData,
        // });
        await setPageData({
          ...pageData,
          posts: [...respData.today, ...respData.older],
        });
        setLoading({ ...loading, posts: false });
      }
    } catch (error) {
      setLoading({ ...loading, posts: false });
      console.log("Error while fetching posts:", error);
    }
  };

  // To update the posts array afet performing an action
  const getPosts2 = async () => {
    try {
      const resp = await mockSever.get(`/posts?userId=${userId}`);
      if (resp) {
        const respData = resp.data;
        setPageData({
          ...pageData,
          posts: [...respData.today, ...respData.older],
        });
      }
    } catch (error) {
      console.log("Error while fetching posts:", error);
    }
  };

  const getProjects = async () => {
    try {
      const response = await getOrgProjects(
        currentOrganization?.id,
        auth?.id,
        auth?.role,
        config
      );
      UpdateProjectsGlobalState(response.data); // This will update the global state storing the user's projects
      setPageData({ ...pageData, projects: sortResponse(response.data) });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPosts();
    // getDetails(); //GETING USER'S INFORMATION(FOLLOWERS, CONTACTS, MEDIA FILES)
  }, []);

  // Handles toggling state to display the appropriate details modal
  useEffect(() => {
    setCurrPopUp(Object.entries(state).find((i) => i[1]));
  }, [state]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        // Clicked outside of the picker, so close it
        setPicker(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCommentAttachment = async (type) => {
    await setPostAttachType("");
    if (type.toLowerCase() === "photo") {
      await setPostAttachType("image");
    } else {
      await setPostAttachType("file");
    }

    commentAttachInputRef.current.click();
  };

  const clearMediaFiles = async () => {
    await setPostObj({
      ...postObj,
      video: null,
      pdf: null,
      audio: null,
      image: null,
    });
    await setBase64Img(null);
  };
  const handleCommentUpload = async (event) => {
    const file = await event.target.files[0];

    clearMediaFiles(); // clear out media fields

    console.log("File to upload", file);

    if (postAttachType === "file") {
      // Handles uploads for pdfs, videos, and documents
      if (
        file.type.includes("video") ||
        file.type.includes("mp4") ||
        file.type.includes("mov")
      ) {
        setPostObj({ ...postObj, video: file });
      } else if (file.type.includes("pdf")) {
        setPostObj({ ...postObj, pdf: file });
      } else if (
        file.type.includes("audio") ||
        file.type.includes("wav") ||
        file.type.includes("mp3")
      ) {
        setPostObj({ ...postObj, audio: file });
      }
    } else {
      // Handles uploads for images
      await new Promise((resolve, reject) => {
        // Converts Image to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
          setBase64Img(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      setPostObj({
        ...postObj,
        image: file,
        pdf: null,
        video: null,
        audio: null,
      });
    }
  };
  const handleCreatePost = async () => {
    if (postObj.caption === "") {
      toast.success("Text field is required", { icon: "❗" });
      return;
    }
    await setOpen(true);
    await setLoading({ ...loading, postProgressBar: true });

    try {
      const formData = new FormData();
      formData.append("userId", postObj.userId);
      formData.append("caption", postObj.caption);
      formData.append("creatorsName", postObj.creatorsName);
      formData.append("image", postObj.image);
      formData.append("video", postObj.video);
      formData.append("pdf", postObj.pdf);
      formData.append("audio", postObj.audio);

      await mockSever.post("/posts/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      await getPosts2();
      await setPostObj({
        caption: "",
        image: null,
        audio: null,
        video: null,
        pdf: null,
        creatorsName: userFullName,
        userId,
      });
      await setOpen(false);
      await setLoading({ ...loading, postProgressBar: false });
    } catch (error) {
      setOpen(false);
      setLoading({ ...loading, postProgressBar: false });
      console.error("Error creating post:", error);
    }
  };

  const checkDetails = (li, str) => {
    const found = li.find((el) => el.title === str);

    return found ? found.data : null;
  };

  const togglePopUp = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;

    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "fullName") {
      const splitName = value.split(" ");
      setFormData({
        ...formData,

        firstName: splitName[0],
        lastName: splitName.slice(1).join(" "),
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    setEdit(!edit);
    setOpen(true);

    const { firstName, lastName, phoneNumber, dateOfBirth, location } =
      formData;

    try {
      const response = await firstVersion.patch(
        "/users",
        JSON.stringify({
          firstName,
          lastName,
          phoneNumber,
          dateOfBirth,
          location,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth?.authToken}`,
          },
          withCredentials: true,
        }
      );

      setTimeout(() => {
        setOpen(false);
      }, response);
      toast.success("Update Successful!");
    } catch (error) {
      setOpen(false);

      if (!error.response) {
        toast.error("Check your internet connection!");
      } else {
        toast.error("Failed!");
        console.log(error);
      }
    }
  };

  const handleInput = (event) => {
    const { value } = event.target;
    setPostObj({ ...postObj, caption: value });
  };

  const handleProjects = async (item) => {
    setDetails(true);
    await setSelectedProject(item);
  };

  let obj = [
    { title: "followers", data: pageData.followers },
    { title: "media", data: pageData.media },
    { title: "projects", data: pageData.projects },
    { title: "contacts", data: pageData.contacts },
  ];

  const onEmojiClick2 = (event, emojiObject) => {
    const newComment = (postObj.caption || "") + emojiObject.emoji;
    setPostObj({ ...postObj, caption: newComment });
    setPicker(false);
  };
  return (
    <div className="user__profile">
      {open && <BackDrop open={open} />}

      <div className="user__content ">
        <div className="cover__photo">
          <img
            src="https://images.unsplash.com/photo-1439405326854-014607f694d7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
            alt="cover"
          />
        </div>

        <div className="body">
          <div className="user__details">
            <div className="user__details__header">
              <div className="left">
                <div className="user__icon">{auth?.firstName.charAt(0)}</div>
                <div
                  className="details"
                  style={{ textTransform: "capitalize" }}
                >
                  {edit ? (
                    <>
                      <p>Full Name</p>
                      <input
                        type="text"
                        name="fullName"
                        defaultValue={
                          auth?.firstName + " " + auth?.lastName ?? "null"
                        }
                        disabled={!edit}
                        onChange={handleChange}
                      />
                      <br />
                      <br />
                    </>
                  ) : (
                    <p
                      style={{
                        fontSize: "17px",
                        fontWeight: "400",
                      }}
                    >
                      {auth?.firstName + " " + auth?.lastName}
                    </p>
                  )}

                  <p>{auth?.role}</p>

                  <br />
                  <p className="socials">Social Media Handles</p>
                </div>
              </div>

              <div className="center">
                <p>EMAIL</p>
                <p style={{ color: "#a2a2a2" }}>{auth?.email}</p>

                <br />

                <p>PHONE</p>
                <input
                  type="tel"
                  name="phoneNumber"
                  // pattern="[0-9]{4}-[0-9]{3}-[0-9]{4}"
                  defaultValue={auth?.phoneNumber ?? ""}
                  placeholder={auth?.phoneNumber ?? "0000-000-0000"}
                  disabled={!edit}
                  onChange={handleChange}
                />
              </div>

              <div className="right">
                <p>BIRTHDAY</p>
                <input
                  type="date"
                  name="dateOfBirth"
                  defaultValue={auth?.dateOfBirth ?? "null"}
                  disabled={!edit}
                  onChange={handleChange}
                />

                <br />
                <br />

                <p>LOCATION</p>
                <input
                  type="text"
                  name="location"
                  defaultValue={auth?.location ?? "null"}
                  disabled={!edit}
                  onChange={handleChange}
                />
              </div>
            </div>
            {!edit ? (
              <div className="edit__button" onClick={() => setEdit(!edit)}>
                <BiPencil />
                <p>Edit</p>
              </div>
            ) : (
              <div
                className="edit__button"
                style={{ color: "green" }}
                onClick={handleUpdate}
              >
                <BiSave />
                <p>Save</p>
              </div>
            )}
          </div>
          {loading.posts ? (
            <LoadingScreen loading />
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{
                overflow: "hidden",
                padding: "20px 41px",
                // position: "relative"
              }}
            >
              <Box flex={1} className="sticky top-0">
                <div className="left__side ">
                  <p
                    style={{
                      fontSize: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    Followers
                  </p>
                  {pageData.followers.length !== 0 ? (
                    (pageData.followers.length > 4
                      ? pageData.followers.slice(0, 3)
                      : pageData.followers
                    ).map((item, index) => (
                      <div className="" key={index}>
                        {/* //TODO: Display  */}
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">0 Followers</p>
                  )}

                  {pageData.followers.length > 4 && (
                    <div
                      className="view__more text-sm"
                      onClick={togglePopUp("followers", true)}
                    >
                      All ({pageData.followers.length}) Followers
                    </div>
                  )}
                </div>

                <br />
                <div className="left__side">
                  <p
                    style={{
                      fontSize: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    Media
                  </p>
                  {pageData.media.length !== 0 ? (
                    <div className="media__items">
                      {(pageData.media.length > 4
                        ? pageData.media.slice(0, 3)
                        : pageData.media
                      ).map((item, index) => (
                        <div className="media__item" key={index}>
                          <img
                            src="https://images.unsplash.com/photo-1593526613712-7b4b9a707330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                            alt="media"
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm">0 Media</p>
                  )}

                  {pageData.media.length > 4 && (
                    <div
                      className="view__more text-sm"
                      onClick={togglePopUp("media", true)}
                    >
                      All Media
                    </div>
                  )}
                </div>
                <br />

                <div className="left__side">
                  <div
                    style={{
                      fontSize: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    Projects
                  </div>
                  {pageData.projects.length !== 0 ? (
                    (pageData.projects.length > 4
                      ? pageData.projects.slice(0, 3)
                      : pageData.projects
                    ).map((item, index) => (
                      <div
                        key={index}
                        className="p-1 px-2 projects__rendered"
                        onClick={() => handleProjects(item)}
                      >
                        <p className="text-sm w-[70%] truncate">{item.name}</p>
                        <span className="w-[30%] flex items-center space-x-1 justify-end">
                          <p className="text-xs">Public</p>
                          <span>
                            <BiChevronDown size={20} />
                          </span>
                        </span>
                      </div>
                    ))
                  ) : (
                    <p className="!text-sm">0 Projects</p>
                  )}

                  {pageData.projects.length > 4 && (
                    <div
                      className="view__more text-sm"
                      onClick={togglePopUp("projects", true)}
                    >
                      All ({pageData.projects.length}) Projects
                    </div>
                  )}
                </div>

                <br />

                <div className="left__side">
                  <p
                    style={{
                      fontSize: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    Contacts
                  </p>

                  <p className="text-sm">0 contacts</p>

                  {pageData.contacts.length > 4 && (
                    <div
                      className="view__more text-sm"
                      onClick={togglePopUp("contacts", true)}
                    >
                      All Contacts
                    </div>
                  )}
                </div>
              </Box>

              <Box flex={4} className="right__side">
                <div className="make__a__post">
                  <div className="post__something">
                    <div className="w-10 h-10 pt-2.5 pb-[9px] text-orange-300 text-sm   bg-orange-300 bg-opacity-20 rounded-[8px] uppercase justify-center items-center inline-flex">
                      {auth?.firstName.charAt(0).toUpperCase()}
                    </div>

                    <textarea
                      id="story"
                      name="story"
                      rows="4"
                      cols="33"
                      placeholder="Write Something..."
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleCreatePost();
                        }
                      }}
                      onChange={(e) => handleInput(e)}
                      value={postObj.caption}
                    ></textarea>
                  </div>

                  {base64Img && (
                    <div className="py-2 ">
                      <div className="relative w-[10vw] h-[13vh]">
                        <img
                          src={base64Img}
                          className=" h-full w-full  object-contain "
                        />

                        <span
                          className="absolute cursor-pointer top-1 text-2xl z-10 right-1 text-red-700 hover:scale-125 duration-200 ease-in backdrop-blur-sm rounded-full  flex justify-center items-center"
                          onClick={() => setBase64Img(null)}
                        >
                          <TiDeleteOutline />
                        </span>
                      </div>
                    </div>
                  )}

                  {postObj.audio || postObj.video || postObj.pdf ? (
                    <div className="py-2">
                      <div className="relative w-[15vw] p-3  border-[#151517] border-2 rounded-md pr-5">
                        {/* Display media file */}
                        <div className="flex items-center space-x-2">
                          {postObj.audio && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="38"
                              viewBox="0 0 34 44"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                fill="#0D0D0D"
                              />
                              <path
                                opacity="0.05"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                                fill="#FD7972"
                              />
                              <path
                                d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                                stroke="#FD7972"
                              />
                              <path
                                d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                                stroke="#FD7972"
                              />
                              <path
                                d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                                fill="#FD7972"
                                stroke="#FD7972"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}

                          {postObj.pdf && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="38"
                              viewBox="0 0 29 38"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                fill="#0D0D0D"
                              />
                              <path
                                opacity="0.05"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                                fill="#FD7972"
                              />
                              <path
                                d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                                stroke="#FD7972"
                              />
                              <path
                                d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                                stroke="#FD7972"
                              />
                              <path
                                d="M8.75845 28.5C8.54689 28.5 8.34385 28.435 8.17059 28.3124C7.53773 27.863 7.45262 27.363 7.49275 27.0223C7.60339 26.0856 8.82715 25.1051 11.1312 24.1062C12.0455 22.2096 12.9155 19.8729 13.434 17.9205C12.8273 16.6707 12.2376 15.0492 12.6674 14.0981C12.8182 13.7649 13.006 13.5094 13.3568 13.399C13.4954 13.3552 13.8456 13.3 13.9745 13.3C14.2809 13.3 14.5502 13.6734 14.7411 13.9036C14.9204 14.1199 15.3271 14.5785 14.5143 17.8175C15.3338 19.4195 16.4949 21.0513 17.6075 22.1688C18.4044 22.0324 19.0902 21.9628 19.6489 21.9628C20.6009 21.9628 21.1778 22.1728 21.4131 22.6055C21.6076 22.9634 21.528 23.3817 21.176 23.8484C20.8374 24.2966 20.3705 24.5337 19.8264 24.5337C19.0872 24.5337 18.2263 24.0918 17.2664 23.2189C15.5417 23.5601 13.5276 24.1689 11.8996 24.8427C11.3914 25.8635 10.9044 26.6857 10.4509 27.2888C9.8278 28.1145 9.29039 28.5 8.75845 28.5ZM10.3768 25.5504C9.07762 26.2415 8.54811 26.8094 8.50981 27.1294C8.50373 27.1823 8.48732 27.3216 8.77183 27.5275C8.86241 27.5005 9.39131 27.2721 10.3768 25.5504ZM18.6671 22.9945C19.1625 23.3553 19.2835 23.5377 19.6075 23.5377C19.7498 23.5377 20.1553 23.5319 20.3431 23.2839C20.4337 23.1636 20.469 23.0865 20.483 23.0451C20.4082 23.0077 20.3091 22.9318 19.7686 22.9318C19.4616 22.9323 19.0756 22.945 18.6671 22.9945ZM14.1258 19.2071C13.6912 20.6307 13.1173 22.1676 12.5003 23.5595C13.7708 23.0929 15.152 22.6855 16.4494 22.3972C15.6286 21.495 14.8086 20.3683 14.1258 19.2071ZM13.7568 14.338C13.6973 14.357 12.9483 15.349 13.8152 16.1885C14.3921 14.9716 13.783 14.33 13.7568 14.338Z"
                                fill="#FD7972"
                              />
                            </svg>
                          )}

                          {postObj.video && (
                            <svg
                              width="31"
                              height="40"
                              viewBox="0 0 31 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                                fill="white"
                              />
                              <path
                                opacity="0.05"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                                fill="#FD7972"
                              />
                              <path
                                d="M2.49998 0.5H21.5348L30.4999 9.4228V37.5C30.4999 38.6049 29.604 39.5 28.4999 39.5H2.49998C1.39594 39.5 0.5 38.6049 0.5 37.5V2.49998C0.5 1.39508 1.39604 0.5 2.49998 0.5Z"
                                stroke="#FD7972"
                              />
                              <path
                                d="M30.5001 9.5H23.7294C22.4987 9.5 21.5 8.50231 21.5 7.27286V0.5"
                                stroke="#FD7972"
                              />
                              <path
                                d="M12 16L19 21.5L12 27V16Z"
                                fill="#FD7972"
                                stroke="#FD7972"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}

                          <span className=" truncate">
                            {postObj.audio?.name ||
                              postObj.video?.name ||
                              postObj.pdf?.name}
                          </span>
                        </div>
                        <span
                          className="absolute cursor-pointer top-1 text-2xl z-10 right-1 text-red-700 hover:scale-125  duration-200 ease-in backdrop-blur-sm rounded-full  flex justify-center items-center"
                          onClick={() => clearMediaFiles()}
                        >
                          <TiDeleteOutline />
                        </span>
                      </div>
                    </div>
                  ) : null}

                  <div className="button">
                    <div className="post-tag" onClick={handleCreatePost}>
                      Post
                    </div>
                    <div className="activity__buttons">
                      <div onClick={() => handleCommentAttachment("file")}>
                        <MdAttachFile />
                      </div>
                      <div className="!relative">
                        <BsEmojiSmile onClick={() => setPicker(!picker)} />
                        {picker && (
                          <div
                            className="absolute z-10 right-[2%] top-[28%]"
                            ref={pickerRef}
                          >
                            <Picker onEmojiClick={onEmojiClick2} />
                          </div>
                        )}
                      </div>
                      <div>
                        <div
                          onClick={() => handleCommentAttachment("photo")}
                          className="image_file"
                        >
                          <BsImage />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="post__cards">
                  {pageData.posts.map((item, index) => (
                    <div key={index} className="post__card">
                      <PostCard {...item} getPosts={getPosts2} />
                    </div>
                  ))}
                </div>
              </Box>
            </Stack>
          )}

          {/* Initializes the attachment typw selected */}
          <input
            ref={commentAttachInputRef}
            type="file"
            accept={
              postAttachType === "file"
                ? "video/*,application/pdf,audio/*"
                : "image/*"
            }
            className="hidden"
            onChange={handleCommentUpload}
          />

          {details && selectedProject && (
            <ProjectDetails
              show={details}
              {...selectedProject}
              getProjects={getProjects}
              onClose={() => setDetails(!details)}
            />
          )}

          {currentPopUp ? (
            <ProfileModal
              show={state[currentPopUp[0]]}
              rr={() => {
                setR(!r);
              }}
              getProjects={getProjects}
              onClose={togglePopUp(currentPopUp[0], false)}
              present={currentPopUp[0]}
              details={checkDetails(obj, currentPopUp[0])}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
