import { useState, useRef } from "react";
import "./organizationmodal.css";
import "../fpModal/fpmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { useEffect } from "react";

// bouncei code
import BackDrop from "../../BackDrop";

const ORGN_URL = "/organizations/add";

const AddOrganization = (props) => {
  const { auth } = useAuth();
  const [formData, setFormData] = useState({
    name: "",

    country: "none",
    city: "",
    address: "",
    mobileNumber: "",
    // role: "none",
    logo: null,
  });
  const [open, setOpen] = useState(false);
  const [displayImg, setDisplayImg] = useState(null);
  const [ctryCode, setCtryCode] = useState("");

  // Handles user credentials inputted in the available fields
  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleFile = (event) => {
    setFormData({ ...formData, ["logo"]: event.target.files[0] });

    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);

        setDisplayImg(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  // Handles submitting of the form
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData["logo"] === null) {
      toast.error("Please add logo");
      return;
    }

    if (formData["country"] === "none") {
      toast.error("Please select a country");
      return;
    }

    setOpen(true);

    try {
      const formDetails = new FormData();
      formDetails.append("file", formData["logo"]);
      formDetails.append("name", formData["name"]);
      formDetails.append("country", formData["country"]);
      formDetails.append("countryCode", ctryCode);
      formDetails.append("city", formData["address"]);
      formDetails.append("owner", auth?.firstName + " " + auth?.lastName);
      formDetails.append("address", formData["address"]);
      formDetails.append("phoneNumber", formData["mobileNumber"]);

      const response = await axios.post(ORGN_URL, formDetails, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer ".concat(auth?.authToken),
        },
      });

      setTimeout(() => {
        setFormData({
          name: "",
          country: "none",
          city: "",
          address: "",
          mobileNumber: "",
          logo: null,
        });
        setDisplayImg(null);
        setOpen(false);
        props.rr();
        props.onClose();
        toast.success("Organization Created");
      }, response);
    } catch (error) {
      setOpen(false);
      if (!error?.response) {
        toast.error("Check your internet connection!");
        console.log(error);
      } else {
        toast.error(error.response.data?.message);
      }
      console.log(error);
    }
  };

  // console.log(props);
  useEffect(() => {
    if (formData.country === "nigeria") {
      setCtryCode("NG");
    } else if (formData.country === "usa") {
      setCtryCode("US");
    } else if (formData.country === "france") {
      setCtryCode("FR");
    } else if (formData.country === "uk") {
      setCtryCode("GB");
    } else if (formData.country === "southafrica") {
      setCtryCode("ZA");
    } else if (formData.country === "ghana") {
      setCtryCode("GH");
    }
  });

  return (
    <div
      className={`organization__modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <form
        className="organization__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="relative w-[459px] py-4 flex items-center  justify-between">
          <p className=" text-white text-[28px] font-semibold">
            Add Organization
          </p>
          <CloseIcon className="cursor-pointer" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add_file_container mb-3">
          {displayImg ? (
            <label htmlFor="add_file" className=" cursor-pointer pb-4">
              <img
                src={displayImg}
                alt="Org image"
                className=" object-cover h-44 w-44 rounded-md"
              />
            </label>
          ) : (
            <label htmlFor="add_file" className="add_file  mb-7 text-white">
              +
            </label>
          )}
          <input
            type="file"
            accept="image/*"
            id="add_file"
            className="hidden"
            placeholder="Team Logo"
            name="logo"
            onChange={handleFile}
          />
        </div>
        <div className="organization__inputs">
          <input
            type="text"
            name="name"
            placeholder="Organization Name"
            autoCorrect="off"
            value={formData["name"]}
            onChange={handleChange}
            required
          />

          <div className="flex flex-col space-y-1 w-full">
            <select
              onChange={handleChange}
              name="country"
              id="country"
              value={formData["country"]}
            >
              <option className="hidden" value="none">
                Select Country
              </option>
              <option value="nigeria">Nigeria</option>
              <option value="usa">USA</option>
              <option value="france">France</option>
              <option value="uk">UK</option>
              <option value="southafrica">South Africa</option>
              <option value="ghana">Ghana</option>
            </select>
          </div>

          <input
            type="text"
            readOnly
            value={ctryCode}
            onChange={handleChange}
            name="countryCode"
            placeholder="Country Code"
            disabled={formData["country"] === "none"}
          />
          <input
            type="text"
            placeholder="City"
            name="city"
            value={formData["city"]}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="address"
            placeholder="Address"
            autoCorrect="off"
            value={formData["address"]}
            onChange={handleChange}
            required
          />
          <div className="flex flex-col space-y-1 w-full">
            <label style={{ color: "white" }} htmlFor="mcode">
              Please Use Country Code
            </label>
            <input
              type="text"
              id="mcode"
              placeholder="Mobile Number"
              name="mobileNumber"
              onChange={handleChange}
              value={formData["mobileNumber"]}
              required
            />
          </div>
        </div>

        <br />

        <button type="submit" href="#" className="sign-in-tag my-3">
          Create
        </button>
      </form>
    </div>
  );
};

export default AddOrganization;
