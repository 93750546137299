import React, { useState, useRef, useEffect } from "react";
import "./PostCard.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { FcLike } from "react-icons/fc";
import { BsReply, BsThreeDotsVertical } from "react-icons/bs";
import { BiComment } from "react-icons/bi";
import { ActivityModal, ImageModal } from "../../Modals";
import toast from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import useAuth from "../../../hooks/useAuth";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SendIcon from "@mui/icons-material/Send";
import { getTimeAgo, handleDownload } from "../../../util.js";
import BackDrop from "../../BackDrop";
import { mockSever } from "../../../api/axios";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Box, Popover, Tooltip } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import Picker from "emoji-picker-react";

const PostCard = (props) => {
  const { auth } = useAuth();
  const [view, setView] = useState(false);
  const [source, setSource] = useState(null);
  const [open, setOpen] = useState(false);
  const [notPlaying, setNotPlaying] = useState(true);
  const userId = auth.authToken;
  const commentAttachInputRef = useRef(null);
  const userFullName = `${auth.firstName} ${auth.lastName}`;
  const { comments } = props;
  const [commentObj, setCommentObj] = useState({
    caption: "",
    image: null,
    audio: null,
    video: null,
    pdf: null,
    creatorsName: userFullName,
    userId,
  });
  const audioRef = useRef(null);
  const pickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [commentAttachType, setCommentAttachType] = useState(null);
  const [base64Img, setBase64Img] = useState(null);
  const [activityModal, setActivityModal] = useState({
    edit: false,
    delete: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [option, setOption] = useState({
    display: null,
    commentId: "",
  });
  const [truncateComments, setTruncateComments] = useState(true);
  const [loading, setLoading] = useState({
    commentLike: false,
    activity: false,
    commentId: "",
    postId: "",
  });
  const handleLikePost = async (postId) => {
    setLoading({ ...loading, postId });
    try {
      await mockSever.post("/posts/like", { postId, userId });
      await props.getPosts(); // fetch udpdated posts
      setLoading({ ...loading, postId: "" }); //manage loading state
    } catch (error) {
      setLoading({ ...loading, postId: "" }); //manage loading state
      console.error("Error liking post:", error);
    }
  };

  const clearMediaFiles = async () => {
    await setCommentObj({
      ...commentObj,
      video: null,
      pdf: null,
      audio: null,
      image: null,
    });
    await setBase64Img(null);
  };

  // Handles the onChange event on the comment text area
  const handleComment = (event) => {
    const { value } = event.target;
    setCommentObj({ ...commentObj, caption: value });
  };

  // Adding a new comment
  const submitComment = async () => {
    if (commentObj.caption === "") {
      toast.error("Text field is required!");
      return;
    }
    setOpen(true);

    // const formData = new FormData();
    // formData.append("commentorId", userId);
    // formData.append("commentorsName", userFullName);
    // formData.append("text", commentObj.caption);
    // formData.append("image", commentObj.image);
    // formData.append("video", commentObj.video);
    // formData.append("pdf", commentObj.pdf);
    // formData.append("audio", commentObj.audio);
    try {
      const response = await mockSever.post(
        `/posts/comment`,
        {
          commentorId: userId,
          postId: props._id,
          text: commentObj.caption,
          commentorsName: userFullName,
        },
        {
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        }
      );

      await props.getPosts(); // fetch updated posts
      setOpen(false);
      setCommentObj({
        caption: "",
        image: null,
        audio: null,
        video: null,
        pdf: null,
        creatorsName: userFullName,
        userId,
      });
      setBase64Img(null);

      toast.success("Comment Sent!");
    } catch (error) {
      setOpen(false);
      console.log("Error while creating a new comment", error);
    }
  };

  const onEmojiClick2 = (event, emojiObject) => {
    const newComment = (commentObj.caption || "") + emojiObject.emoji;
    setCommentObj({ ...commentObj, caption: newComment });
    setShowPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        // Clicked outside of the picker, so close it
        setShowPicker(false);
      }
    };

    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCommentActivityButtons = async (type) => {
    if (type.toLowerCase() === "edit") {
      await setActivityModal({
        edit: true,
        delete: false,
      });
    } else {
      await setActivityModal({
        edit: false,
        delete: true,
      });
    }
  };

  const handleCommentAttachment = async (type) => {
    await setCommentAttachType("");
    if (type.toLowerCase() === "photo") {
      await setCommentAttachType("image");
    } else {
      await setCommentAttachType("file");
    }

    commentAttachInputRef.current.click();
  };

  const handleCommentUpload = async (event) => {
    const file = await event.target.files[0];

    clearMediaFiles(); // clear out media fields

    console.log("File to upload", file);

    if (commentAttachType === "file") {
      // Handles uploads for pdfs, videos, and documents
      if (
        file.type.includes("video") ||
        file.type.includes("mp4") ||
        file.type.includes("mov")
      ) {
        setCommentObj({ ...commentObj, video: file });
      } else if (file.type.includes("pdf")) {
        console.log("PDF file", file.name);
        setCommentObj({ ...commentObj, pdf: file });
      } else if (
        file.type.includes("audio") ||
        file.type.includes("wav") ||
        file.type.includes("mp3")
      ) {
        setCommentObj({ ...commentObj, audio: file });
      }
    } else {
      // Handles uploads for images
      await new Promise((resolve, reject) => {
        // Converts Image to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
          setBase64Img(reader.result);
        };
        reader.onerror = (error) => reject(error);
      });
      setCommentObj({
        ...commentObj,
        image: file,
        pdf: null,
        video: null,
        audio: null,
      });
    }

    setAnchorEl(null); //Closes popover
  };

  const handleCommentLike = async (commentId) => {
    setLoading({ ...loading, commentLike: true, commentId }); //loading state

    try {
      const resp = await mockSever.post("/posts/like-comment", {
        commentId,
        userId,
        postId: props._id,
      });
      if (resp) {
        await props.getPosts(); // fetch udpdated posts
        setLoading({ ...loading, commentLike: false, commentId: "" });
      }
    } catch (error) {
      setLoading({ ...loading, commentLike: false, commentId: "" });
      console.log("Error while liking a comment:", error);
    }
  };

  const deleteComment = async (commentId) => {
    setLoading({ ...loading, activity: true, commentId }); //loading state

    try {
      const resp = await mockSever.delete(
        `/posts/comment/delete?commentId=${commentId}&postId=${props._id}`
      );
      if (resp) {
        await props.getPosts(); // fetch udpdated posts
        setLoading({ ...loading, activity: false, commentId }); //loading state
        toast.success("Comment Deleted");
      }
    } catch (error) {
      setLoading({ ...loading, activity: false, commentId }); //loading state

      console.log("Error in deleting comment:", error);
    }
  };

  const updateComment = async (commentId, updatedText) => {
    setLoading({ ...loading, activity: true, commentId }); //loading state

    try {
      const resp = await mockSever.post(`/posts/comment/update`, {
        commentId,
        updatedText,
        postId: props._id,
      });
      if (resp) {
        await props.getPosts(); // fetch udpdated posts
        setLoading({ ...loading, activity: false, commentId }); //loading state
        toast.success("Comment Updated");
      }
    } catch (error) {
      setLoading({ ...loading, activity: false, commentId }); //loading state

      console.log("Error in updating comment:", error);
    }
  };

  const openView = (src) => {
    setView(true);
    setSource(src);
  };

  return (
    <div className="post__card">
      {open && <BackDrop open={open} />}

      <div className="post__card__details">
        <div className="header">
          <div className="lefter__side">
            <div className="w-10 h-10 pt-2.5 pb-[9px] text-purple-400 text-sm  bg-purple-400 bg-opacity-20 rounded-[8px] uppercase justify-center items-center inline-flex">
              {userFullName.charAt(0).toUpperCase()}
            </div>
            <div className="name ">
              <p>{userFullName}</p>
              <p className=" text-xs pt-1">{getTimeAgo(props.createdDate)}</p>
            </div>
          </div>
          <div
            className="right__side"
            // onClick={() => {
            //   deletePost(props);
            // }}
          >
            {/* <AiOutlineDelete />
            <p>Delete Post</p> */}
          </div>
        </div>
        <div className="details">
          <div className="media">
            {props.image && (
              <img
                src={props.image}
                onClick={() => openView(props.image)}
                alt="post"
              />
            )}
            {props.pdf && <iframe src={props.pdf} title="post" />}

            {props.video && <video src={props.video} controls alt="post" />}
            {props.audio && (
              <div className="post-audio-div">
                <audio
                  style={{
                    position: "relative",
                    width: "140px",
                    height: "30px",
                    margin: "10px 0px 20px  0px",
                    display: "none",
                  }}
                  ref={audioRef}
                  controls
                  src={props.audio}
                />

                <svg
                  onClick={() => {
                    audioRef.current.currentTime -= 2;
                  }}
                  className="rewind"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M6.09545 0.579545L1.19659 5.71136C1.16377 5.74568 1.13724 5.7895 1.11899 5.8395C1.10074 5.88951 1.09126 5.94439 1.09126 6C1.09126 6.05561 1.10074 6.11049 1.11899 6.1605C1.13724 6.2105 1.16377 6.25432 1.19659 6.28864L6.09545 11.4205C6.27784 11.6114 6.54545 11.4386 6.54545 11.1318V0.868182C6.54545 0.561364 6.27784 0.388636 6.09545 0.579545ZM11.55 0.579545L6.65114 5.71136C6.61832 5.74568 6.59178 5.7895 6.57353 5.8395C6.55528 5.88951 6.5458 5.94439 6.5458 6C6.5458 6.05561 6.55528 6.11049 6.57353 6.1605C6.59178 6.2105 6.61832 6.25432 6.65114 6.28864L11.55 11.4205C11.7324 11.6114 12 11.4386 12 11.1318V0.868182C12 0.561364 11.7324 0.388636 11.55 0.579545ZM0.981818 0H0.109091C0.0494317 0 0 0.0613636 0 0.136364V11.8636C0 11.9386 0.0494317 12 0.109091 12H0.981818C1.04148 12 1.09091 11.9386 1.09091 11.8636V0.136364C1.09091 0.0613636 1.04148 0 0.981818 0Z"
                    fill="#E8E8E8"
                  />
                </svg>

                {notPlaying ||
                (notPlaying === false &&
                  audioRef.current.src !== props.audio) ? (
                  <svg
                    onClick={async () => {
                      await setNotPlaying(true);
                      if (audioRef.current.src !== props.audio) {
                        audioRef.current.src = props.audio;
                      } // Set the audio source
                      if (audioRef.current.paused) {
                        if (audioRef.current.src === props.audio) {
                          await setNotPlaying(false);
                          await audioRef.current.play();
                          console.log(audioRef.current.src);
                        }
                      } else {
                        await audioRef.current.pause();
                        await setNotPlaying(true);
                      }
                    }}
                    className="play-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM6.79 5.093C6.71524 5.03977 6.62726 5.00814 6.53572 5.00159C6.44418 4.99503 6.35259 5.01379 6.27101 5.05583C6.18942 5.09786 6.12098 5.16154 6.07317 5.23988C6.02537 5.31823 6.00006 5.40822 6 5.5V10.5C6.00006 10.5918 6.02537 10.6818 6.07317 10.7601C6.12098 10.8385 6.18942 10.9021 6.27101 10.9442C6.35259 10.9862 6.44418 11.005 6.53572 10.9984C6.62726 10.9919 6.71524 10.9602 6.79 10.907L10.29 8.407C10.3548 8.36075 10.4076 8.29968 10.4441 8.22889C10.4805 8.1581 10.4996 8.07963 10.4996 8C10.4996 7.92037 10.4805 7.8419 10.4441 7.77111C10.4076 7.70031 10.3548 7.63925 10.29 7.593L6.79 5.093Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  notPlaying === false &&
                  audioRef.current.src === props.audio && (
                    <svg
                      onClick={async () => {
                        if (audioRef.current.paused) {
                          audioRef.current.play();
                          setNotPlaying(false);
                        } else {
                          audioRef.current.pause();
                          setNotPlaying(true);
                        }
                      }}
                      className="pause-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_351_61455)">
                        <path
                          d="M57 28.5C57 36.0587 53.9973 43.3078 48.6525 48.6525C43.3078 53.9973 36.0587 57 28.5 57C20.9413 57 13.6922 53.9973 8.34746 48.6525C3.00267 43.3078 0 36.0587 0 28.5C0 20.9413 3.00267 13.6922 8.34746 8.34746C13.6922 3.00267 20.9413 0 28.5 0C36.0587 0 43.3078 3.00267 48.6525 8.34746C53.9973 13.6922 57 20.9413 57 28.5ZM22.2656 17.8125C19.8075 17.8125 17.8125 19.8075 17.8125 22.2656V34.7344C17.8125 35.9154 18.2817 37.0481 19.1168 37.8832C19.9519 38.7183 21.0846 39.1875 22.2656 39.1875C23.4467 39.1875 24.5793 38.7183 25.4145 37.8832C26.2496 37.0481 26.7188 35.9154 26.7188 34.7344V22.2656C26.7188 19.8075 24.7237 17.8125 22.2656 17.8125ZM34.7344 17.8125C32.2762 17.8125 30.2812 19.8075 30.2812 22.2656V34.7344C30.2812 35.9154 30.7504 37.0481 31.5855 37.8832C32.4207 38.7183 33.5533 39.1875 34.7344 39.1875C35.9154 39.1875 37.0481 38.7183 37.8832 37.8832C38.7183 37.0481 39.1875 35.9154 39.1875 34.7344V22.2656C39.1875 19.8075 37.1925 17.8125 34.7344 17.8125Z"
                          fill="#FFF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_351_61455">
                          <rect width="57" height="57" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )
                )}

                <svg
                  onClick={() => {
                    audioRef.current.currentTime += 2;
                  }}
                  className="fastfoward"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M10.8034 5.71136L5.90455 0.579545C5.72216 0.388636 5.45455 0.561364 5.45455 0.868182V11.1318C5.45455 11.4386 5.72216 11.6114 5.90455 11.4205L10.8034 6.28864C10.8362 6.25432 10.8628 6.2105 10.881 6.1605C10.8993 6.11049 10.9087 6.05561 10.9087 6C10.9087 5.94439 10.8993 5.88951 10.881 5.8395C10.8628 5.7895 10.8362 5.74568 10.8034 5.71136ZM5.34886 5.71136L0.45 0.579545C0.267614 0.388636 0 0.561364 0 0.868182V11.1295C0 11.4364 0.267614 11.6091 0.45 11.4182L5.34886 6.28636C5.41875 6.21364 5.45455 6.10455 5.45455 5.99773C5.45455 5.89318 5.41875 5.78409 5.34886 5.71136ZM11.8909 0H11.0182C10.9585 0 10.9091 0.0613636 10.9091 0.136364V11.8636C10.9091 11.9386 10.9585 12 11.0182 12H11.8909C11.9506 12 12 11.9386 12 11.8636V0.136364C12 0.0613636 11.9506 0 11.8909 0Z"
                    fill="#E8E8E8"
                  />
                </svg>
              </div>
            )}
          </div>

          <div className="description pt-[8px]">{props.caption}</div>

          <div className="flex items-center pt-3 space-x-5  ">
            <div
              onClick={() => handleLikePost(props._id)}
              className="cursor-pointer"
            >
              {loading.postId === props._id ? (
                <div className="flex items-center space-x-2">
                  <svg
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    aria-hidden="true"
                    className={`inline w-5 h-5  cursor-progress text-gray-200 animate-spin dark:text-gray-600 fill-red-500 `}
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className=" text-sm">{props.likers.length}</span>
                </div>
              ) : props.likers.includes(userId) ? (
                <aside className="flex items-center space-x-2">
                  <FaHeart className="text-red-500" />
                  <span className=" text-sm">{props.likers.length}</span>
                </aside>
              ) : (
                <aside className="flex items-center space-x-2">
                  <FaRegHeart />
                  <span className=" text-sm">{props.likers.length}</span>
                </aside>
              )}
            </div>

            <aside
              className="flex items-center space-x-2 cursor-pointer"
              onClick={(e) => {
                setTruncateComments(!truncateComments);
              }}
            >
              <BiComment />
              <span className=" text-sm">{comments.length || 0}</span>
            </aside>
          </div>
        </div>
        {/* COMMENT INPUT */}
        <div className="comment__input  !relative">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              paddingRight: "10px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#171717",
              //   color: "white",
              width: "100%",
              borderRadius: "8px",
            }}
          >
            <IconButton
              sx={{
                p: "9px 12px",
                margin: "5px",
                color: "white",
                backgroundColor: "#242424",
                borderRadius: "8px",
                "&:hover": {
                  color: "gray",
                  backgroundColor: "black",
                },
              }}
              aria-label="emoji"
              onClick={() => setShowPicker(!showPicker)}
            >
              <SentimentSatisfiedAltIcon />
            </IconButton>
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                color: "white",
              }}
              value={commentObj.caption}
              placeholder="Write a comment..."
              inputProps={{ "aria-label": "write a comment" }}
              onChange={(event) => handleComment(event)}
            />
            <IconButton
              sx={{
                p: "10px",
                color: "#ED89F8",
                "&:hover": {
                  color: "gray",
                },
              }}
              aria-label="voice note"
            >
              <KeyboardVoiceIcon color="#EDB9F8" className=" !text-[#ED89F8]" />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{
                p: "7px 0px",
                paddingLeft: "9px",
                paddingRight: "4px",
                backgroundColor: "#ED89F8",
                color: "black",
                borderRadius: "8px",
                "&:hover": {
                  opacity: "75%",
                  backgroundColor: "#ED89F8",
                },
              }}
              aria-label="share"
              onClick={() => submitComment()}
            >
              <SendIcon
                sx={{
                  transform: "rotate(-34deg)",
                  paddingBottom: "2px",
                }}
              />
            </IconButton>
          </Paper>

          {showPicker && (
            <div
              className="absolute z-40 left-[2%] bottom-[50%]"
              ref={pickerRef}
            >
              <Picker onEmojiClick={onEmojiClick2} />
            </div>
          )}
        </div>

        <div className="all__comments pt-3">
          {comments.length !== 0 ? (
            (truncateComments ? comments.slice(0, 3) : comments).map(
              (item, index) => (
                <div key={index} className="flex  justify-between">
                  <div className="header">
                    <div className="w-10 h-10 pt-2.5 pb-[9px] text-purple-400 text-sm  bg-purple-400 bg-opacity-20 rounded-[8px] uppercase justify-center items-center inline-flex">
                      {item.commentorsName.charAt(0).toUpperCase() || "T"}
                    </div>
                    <div className="name pl-2">
                      <p>
                        {item.commentorsName || "Test User"}
                        <span className="pl-2 text-xs">
                          {getTimeAgo(item.createdDate)}
                        </span>
                      </p>
                      <p
                        style={{
                          fontSize: "small",
                        }}
                        className="w-full pt-1 truncate"
                      >
                        {item.text}
                      </p>

                      {/* DISPLAY MEDIA FILES */}
                      {item.image && (
                        <img
                          src={item.image}
                          className="w-[4vw] h-[8vh] object-center object-cover mt-2 rounded-md "
                          alt={item._id}
                        />
                      )}
                      {item.pdf && (
                        <div className="group relative flex  hover:scale-110 hover:p-2 duration-200 ease-in ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            height="40"
                            viewBox="0 0 29 38"
                            fill="none"
                            className="pt-2"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                              fill="#0D0D0D"
                            />
                            <path
                              opacity="0.05"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.3387 0H20.3386L29 8.75425V35.625C29 36.9371 27.9523 38 26.6613 38H2.3387C1.04769 38 0 36.9371 0 35.625V2.37498C0 1.06283 1.04781 0 2.3387 0Z"
                              fill="#FD7972"
                            />
                            <path
                              d="M2.3387 0.5H20.1299L28.5 8.9598V35.625C28.5 36.6681 27.6691 37.5 26.6613 37.5H2.3387C1.33092 37.5 0.5 36.6681 0.5 35.625V2.37498C0.5 1.33186 1.33101 0.5 2.3387 0.5Z"
                              stroke="#FD7972"
                            />
                            <path
                              d="M28.5327 9.02497H22.1989C21.0475 9.02497 20.1133 8.07717 20.1133 6.90919V0.474976"
                              stroke="#FD7972"
                            />
                            <path
                              d="M8.75845 28.5C8.54689 28.5 8.34385 28.435 8.17059 28.3124C7.53773 27.863 7.45262 27.363 7.49275 27.0223C7.60339 26.0856 8.82715 25.1051 11.1312 24.1062C12.0455 22.2096 12.9155 19.8729 13.434 17.9205C12.8273 16.6707 12.2376 15.0492 12.6674 14.0981C12.8182 13.7649 13.006 13.5094 13.3568 13.399C13.4954 13.3552 13.8456 13.3 13.9745 13.3C14.2809 13.3 14.5502 13.6734 14.7411 13.9036C14.9204 14.1199 15.3271 14.5785 14.5143 17.8175C15.3338 19.4195 16.4949 21.0513 17.6075 22.1688C18.4044 22.0324 19.0902 21.9628 19.6489 21.9628C20.6009 21.9628 21.1778 22.1728 21.4131 22.6055C21.6076 22.9634 21.528 23.3817 21.176 23.8484C20.8374 24.2966 20.3705 24.5337 19.8264 24.5337C19.0872 24.5337 18.2263 24.0918 17.2664 23.2189C15.5417 23.5601 13.5276 24.1689 11.8996 24.8427C11.3914 25.8635 10.9044 26.6857 10.4509 27.2888C9.8278 28.1145 9.29039 28.5 8.75845 28.5ZM10.3768 25.5504C9.07762 26.2415 8.54811 26.8094 8.50981 27.1294C8.50373 27.1823 8.48732 27.3216 8.77183 27.5275C8.86241 27.5005 9.39131 27.2721 10.3768 25.5504ZM18.6671 22.9945C19.1625 23.3553 19.2835 23.5377 19.6075 23.5377C19.7498 23.5377 20.1553 23.5319 20.3431 23.2839C20.4337 23.1636 20.469 23.0865 20.483 23.0451C20.4082 23.0077 20.3091 22.9318 19.7686 22.9318C19.4616 22.9323 19.0756 22.945 18.6671 22.9945ZM14.1258 19.2071C13.6912 20.6307 13.1173 22.1676 12.5003 23.5595C13.7708 23.0929 15.152 22.6855 16.4494 22.3972C15.6286 21.495 14.8086 20.3683 14.1258 19.2071ZM13.7568 14.338C13.6973 14.357 12.9483 15.349 13.8152 16.1885C14.3921 14.9716 13.783 14.33 13.7568 14.338Z"
                              fill="#FD7972"
                            />
                          </svg>

                          <Tooltip title="download" placement="top">
                            <div
                              className="hidden hover:scale-110 duration-300 ease-in group-hover:block cursor-pointer  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                              onClick={() => handleDownload(item.pdf, item._id)}
                            >
                              <HiDownload size={20} />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      {item.audio && (
                        <div className="group relative hover:scale-110 hover:p-2 duration-200 ease-in ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="38"
                            viewBox="0 0 34 44"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                              fill="#0D0D0D"
                            />
                            <path
                              opacity="0.05"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.74192 0H23.8453L34 10.1365V41.25C34 42.7693 32.7717 44 31.2581 44H2.74192C1.22833 44 0 42.7693 0 41.25V2.74998C0 1.23065 1.22847 0 2.74192 0Z"
                              fill="#FD7972"
                            />
                            <path
                              d="M2.74192 0.5H23.6384L33.5 10.3439V41.25C33.5 42.4944 32.4943 43.5 31.2581 43.5H2.74192C1.5057 43.5 0.5 42.4944 0.5 41.25V2.74998C0.5 1.50558 1.50582 0.5 2.74192 0.5Z"
                              stroke="#FD7972"
                            />
                            <path
                              d="M33.4511 10.45H26.0252C24.6754 10.45 23.5801 9.35253 23.5801 8.00013V0.549988"
                              stroke="#FD7972"
                            />
                            <path
                              d="M13.1604 17.6L20.8379 23.65L13.1604 29.7V17.6Z"
                              fill="#FD7972"
                              stroke="#FD7972"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <Tooltip title="download" placement="top">
                            <div
                              className="hidden group-hover:block cursor-pointer  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                              onClick={() =>
                                handleDownload(item.audio, item._id)
                              }
                            >
                              <HiDownload size={20} />
                            </div>
                          </Tooltip>
                        </div>
                      )}

                      {item.video && (
                        <div className="group relative hover:scale-110 hover:p-2 duration-200 ease-in ">
                          <svg
                            width="31"
                            height="40"
                            viewBox="0 0 31 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                              fill="white"
                            />
                            <path
                              opacity="0.05"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.49998 0H21.7412L30.9999 9.215V37.5C30.9999 38.8812 29.88 40 28.4999 40H2.49998C1.11994 40 0 38.8812 0 37.5V2.49998C0 1.11877 1.12007 0 2.49998 0Z"
                              fill="#FD7972"
                            />
                            <path
                              d="M2.49998 0.5H21.5348L30.4999 9.4228V37.5C30.4999 38.6049 29.604 39.5 28.4999 39.5H2.49998C1.39594 39.5 0.5 38.6049 0.5 37.5V2.49998C0.5 1.39508 1.39604 0.5 2.49998 0.5Z"
                              stroke="#FD7972"
                            />
                            <path
                              d="M30.5001 9.5H23.7294C22.4987 9.5 21.5 8.50231 21.5 7.27286V0.5"
                              stroke="#FD7972"
                            />
                            <path
                              d="M12 16L19 21.5L12 27V16Z"
                              fill="#FD7972"
                              stroke="#FD7972"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Tooltip title="download" placement="top">
                            <div
                              className="hidden group-hover:block cursor-pointer  absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                              onClick={() =>
                                handleDownload(item.video, item._id)
                              }
                            >
                              <HiDownload size={20} />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex space-x-1 mt-3">
                    {/* Like comment component */}
                    <div
                      onClick={() => handleCommentLike(item._id)}
                      className=" text-lg pr-2   cursor-pointer"
                    >
                      {loading.commentLike && loading.commentId === item._id ? (
                        <div className="flex space-x-2 items-center">
                          <svg
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            aria-hidden="true"
                            className={`inline w-5 h-5  cursor-progress text-gray-200 animate-spin dark:text-gray-600 fill-red-500 `}
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className=" text-sm">{item.likers.length}</span>
                        </div>
                      ) : item.likers.includes(userId) ? (
                        <aside className="flex items-center ">
                          <FaHeart className="text-red-500" />
                          <span className="pl-2 text-sm">
                            {item.likers.length}
                          </span>
                        </aside>
                      ) : (
                        <aside className="flex items-center ">
                          <FaRegHeart />
                          <span className="pl-2 text-sm">
                            {item.likers.length}
                          </span>
                        </aside>
                      )}
                    </div>

                    {/* Options component */}
                    {props.commentorId === userId && (
                      <Tooltip title="Options" placement="top">
                        <div
                          className=" cursor-pointer"
                          onClick={(e) =>
                            setOption({
                              display: e.currentTarget,
                              commentId: item._id,
                            })
                          }
                        >
                          <BsThreeDotsVertical size={20} />
                        </div>
                      </Tooltip>
                    )}
                    <Popover
                      id={props._id}
                      open={Boolean(option.display)}
                      anchorEl={option.display}
                      onClose={() =>
                        setOption({ commentId: "", display: null })
                      }
                      anchorOrigin={{
                        vertical: "left",
                        horizontal: "left",
                      }}
                    >
                      <div className="bg-[#000] shadow-lg shadow-[#413f3f] flex flex-col  space-y-1 text-[#fff]">
                        {[
                          {
                            text: "Edit",
                            icon: <FiEdit size={20} />,
                          },
                          {
                            text: "Delete",
                            icon: <RiDeleteBin5Line size={20} />,
                          },
                        ].map((item, index) => (
                          <div
                            key={index}
                            className={` ${
                              item.text.toLowerCase() === "delete" &&
                              "text-red-500"
                            } hover:bg-[#413f3f] pl-3 pr-6 cursor-pointer text-sm py-2 flex items-center space-x-2 ease-in duration-200 rounded`}
                            onClick={() =>
                              handleCommentActivityButtons(item.text)
                            }
                          >
                            <span>{item.icon}</span>
                            <p className=" flex-1">{item.text}</p>
                          </div>
                        ))}
                      </div>
                    </Popover>
                    {/* Delete modal for a comment */}
                    {activityModal.delete && (
                      <ActivityModal
                        show={activityModal.delete}
                        onClose={() => {
                          setActivityModal({
                            ...activityModal,
                            delete: false,
                          });
                          setOption({ commentId: "", display: null });
                        }}
                        loading={loading.activity}
                        type="delete"
                        func={() => deleteComment(option.commentId)}
                        text={"Are you sure you want to delete this comment?"}
                      />
                    )}

                    {/* Edit modal for a comment */}
                    {activityModal.edit && (
                      <ActivityModal
                        show={activityModal.edit}
                        onClose={() => {
                          setActivityModal({
                            ...activityModal,
                            edit: false,
                          });
                          setOption({ commentId: "", display: null });
                        }}
                        loading={loading.activity}
                        type="edit"
                        func={updateComment}
                        text={"Edit comment"}
                        commentId={option.commentId}
                        editData={comments.find(
                          (item) => item._id === option.commentId
                        )}
                      />
                    )}
                  </div>
                </div>
              )
            )
          ) : (
            <p className=" text-center">No comments</p>
          )}

          {comments.length > 3 && (
            <p
              className="pt-1 cursor-pointer text-sm"
              onClick={() => setTruncateComments(!truncateComments)}
            >
              {!truncateComments
                ? "Show less comments"
                : `View all (${comments.length}) comments.`}
            </p>
          )}
          <br />
        </div>
      </div>
      <ImageModal source={source} open={view} close={() => setView(false)} />
    </div>
  );
};

export default PostCard;
