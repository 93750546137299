import React from "react";
import { useState } from "react";
import "./AddProjectModal.css";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";

const EditProjectModal = (props) => {
  const { data } = props;
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    coverImg: "",
    name: data?.name,
    artistName: data?.artistName,
    projectType: data?.projectType,
    description: data?.description,
    startDate: data?.startDate,
    endDate: data?.endDate,
    projectMembers: data?.projectMembers,
    budget: data?.budget,
    add_file: null,
  });

  const handleFile = (event) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);

        setFormData({ ...formData, ["add_file"]: reader.result });
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "projectMembers") {
      let newMem = value;

      if (/\s/g.test(value)) {
        newMem = value.replace(/\s/g, "").split(",");
      } else {
        newMem = value.split();
      }

      setFormData({ ...formData, [name]: newMem });
      return;
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpen(true);

    const {
      name,
      artistName,
      projectType,
      startDate,
      endDate,
      description,
      projectMembers,
      budget,
    } = formData;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
          "Content-Type": "application/json",
          // withCredentials: true,
        },
      };
      const response = await axios.patch(
        `/project/${data.id}`,
        JSON.stringify({
          name,
          artistName,
          projectType,
          startDate,
          endDate,
          description,
          projectMembers,
          budget,
        }),
        config
      );
      if (props.getProjects) {
        await props.getProjects();
      }
      props.onClose();

      setTimeout(() => {
        setOpen(false);
      }, response);
      toast.success(`Project Updated`, {
        duration: 4000,
      });
    } catch (error) {
      setOpen(false);

      if (!error.response) {
        toast.error("Check your internet connection!");
      } else {
        toast.error("Failed!");
      }
      console.log(error);
    }
  };

  return (
    <div
      className={`add__product__modal ${props.show ? "show " : ""}`}
      onClick={props.onClose}
    >
      <form
        className="add__product__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="contact__modal--content-header">
          <h4> Edit {data?.name}</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add__product__inputs">
          {/* Add Image input */}
          <div className="add_file_container">
            {formData.add_file ? (
              <label htmlFor="add_file" className=" cursor-pointer pb-4">
                <img
                  src={formData.add_file}
                  alt="Org image"
                  className=" object-cover h-44 w-44 rounded-md"
                />
              </label>
            ) : (
              <label htmlFor="add_file" className="add_file">
                +
              </label>
            )}
            <input
              type="file"
              accept="image/*"
              id="add_file"
              className="hidden"
              placeholder="Team Logo"
              name="add_file"
              onChange={handleFile}
            />
          </div>

          <label htmlFor="name">Project Name</label>
          <input
            type="text"
            name="name"
            id="name"
            defaultValue={data?.name}
            onChange={handleChange}
          />

          <label htmlFor="artistName">Artist Name</label>
          <input
            type="text"
            name="artistName"
            id="artistName"
            defaultValue={data?.artistName}
            onChange={handleChange}
          />

          <label htmlFor="projectType">Project Type</label>
          <select
            name="projectType"
            onChange={handleChange}
            defaultValue={data?.projectType}
            required
          >
            <option value="music">Music</option>
            <option value="podcast">Podcast</option>
            <option value="audio_books">AudioBooks</option>
            <option value="other">Other</option>
          </select>

          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            id="description"
            defaultValue={data?.description}
            cols="30"
            rows="4"
            onChange={handleChange} // placeholder="Message"
          />
          <div className="project__duration">
            <div>
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                name="startDate"
                defaultValue={data?.startDate}
                id="startDate"
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                defaultValue={data?.endDate}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* 
          <label htmlFor="projectMembers">Members</label>
          <input
            type="text"
            name="projectMembers"
            id="projectMembers"
            defaultValue={
              data?.projectMembers
                ? data?.projectMembers.map((item, index) =>
                    index == 0 ? item : " " + item
                  )
                : ""
            }
            onChange={handleChange}
          />
         */}

          <label htmlFor="budget">Budget</label>
          <input
            type="number"
            name="budget"
            id="budget"
            min={0}
            defaultValue={data?.budget}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="submit-tag">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditProjectModal;
