import React from "react";
// icons
import { BiTask, BiUser } from "react-icons/bi";
import { FaLayerGroup, FaCheckSquare, FaMusic } from "react-icons/fa";
import { RiCoinsLine } from "react-icons/ri";
import { HiMailOpen } from "react-icons/hi";
import { MdDashboard } from "react-icons/md";
import { BsChatLeftFill, BsCloudUpload } from "react-icons/bs";
import { GiPadlock } from "react-icons/gi";
import { IoMdHeadset } from "react-icons/io";

export const SidebarData = [
  {
    id: 0,
    menus: [
      {
        title: "Dashboard",
        path: "/profile-admin",
        icon: <MdDashboard />,
        cName: "nav-text",
      },
    ],
  },
  {
    id: 1,
    title: "People",
    menus: [
      {
        title: "Projects",
        path: "/projects",
        icon: <FaLayerGroup />,
        cName: "nav-text",
      },
      {
        title: "Task",
        path: "/tasks",
        icon: <BiTask />,
        cName: "nav-text",
      },
      {
        title: "Bugeting",
        path: "/budget",
        icon: <RiCoinsLine />,
        cName: "nav-text",
      },

      {
        title: "Chat",
        path: "/chat",
        icon: <BsChatLeftFill />,
        cName: "nav-text",
      },
      {
        title: "Report",
        path: "/report",
        // path: "/security",
        icon: <HiMailOpen />,
        // icon2: <GiPadlock />,

        cName: "nav-text",
      },
      {
        title: "Profile",
        path: "/user-profile",
        // path: "/security",

        // icon2: <GiPadlock />,

        icon: <BiUser />,
        cName: "nav-text",
      },
      {
        title: "Timeline",
        // path: "/timeline",
        path: "/timeline-page",

        icon: <FaCheckSquare />,

        cName: "nav-text",
      },
    ],
  },
  {
    id: 2,
    title: "Cave",
    menus: [
      {
        title: "Storage",
        path: "/storage",
        icon: <BsCloudUpload />,
        cName: "nav-text",
      },
    ],
  },
  {
    id: 3,
    title: "Market Place",
    menus: [
      {
        title: "Summary",
        //  path: "/summary",
        path: "/security",
        icon: <img src="/Summary.svg" />,
        cName: "nav-text",
        icon2: <GiPadlock />,
      },
      {
        title: "Sounds",
        //  path: "/sounds",
        path: "/security",
        icon: <IoMdHeadset />,
        cName: "nav-text",
        icon2: <GiPadlock />,
        msg: "A marketplace for beats",
      },
      {
        title: "Samples",
        //  path: "/samples",
        path: "/security",
        icon: <FaMusic />,
        cName: "nav-text",
        icon2: <GiPadlock />,
        msg: "A marketplace for samples",
      },
    ],
  },
  {
    id: 4,
    title: "Dynamic",
    menus: [
      {
        title: "Mastering",
        //   path: "/master_sound",
        path: "/security",
        icon: <img src="/Master.svg" />,
        cName: "nav-text",
        icon2: <GiPadlock />,
        msg: "An e-mastering suite",
      },
    ],
  },
  {
    id: 5,
    title: "Fluid Flow",
    menus: [
      {
        title: "Flows",
        //    path: "/flows",
        path: "/security",
        icon: <img src="/Flows.svg" />,
        cName: "nav-text",
        icon2: <GiPadlock />,
        msg: "An AI powered songwriting tool",
      },
    ],
  },
];
