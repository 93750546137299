import { useState, useEffect, useCallback } from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom";
import filter from "../../assets/Filter.png";
import { AddProjectModal } from "../Modals";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { AiOutlineUnorderedList } from "react-icons/ai";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { ProjectCard } from "../Cards";
import { FilterModal } from "../Modals";
import Drawer from "@mui/material/Drawer";
import useAuth from "../../hooks/useAuth";
import DataProjectCard from "../Cards/projectCard/DataProjectCard";
import LoadingScreen from "../preLoader";
import { sortResponse, getOrgProjects } from "../../util.js";
import Tooltip from "@mui/material/Tooltip";

const ProjectPage = () => {
  const {
    currentOrganization,
    auth,
    userProjects,
    UpdateProjectsGlobalState,
    config,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showaddModal, setAddModal] = useState(false);
  const [value, setValue] = useState("1");
  const [state, setState] = useState({
    right: false,
  });
  const [projects, setProjects] = useState(userProjects);
  const [hold, setHold] = useState(null);
  const [progress, setProgress] = useState(null);
  const [completed, setCompleted] = useState(null);
  const [r, setR] = useState(false);
  const [resultFilter, setresultFilter] = useState("");
  const [viewFilter, setViewFilter] = useState([]);

  const resultCheck = (resultFilter) => {
    setresultFilter(resultFilter);
    if (resultFilter !== "") {
      const filteredData = projects.filter((item) => {
        return Object.values(item)
          .join("")
          .toLocaleLowerCase()
          .includes(resultFilter.toLowerCase());
      });

      setViewFilter(filteredData);
    } else {
      setViewFilter(projects);
    }
  };

  const getProjects = useCallback(async () => {
    try {
      const response = await getOrgProjects(
        currentOrganization?.id,
        auth?.id,
        auth?.role,
        config
      );
      UpdateProjectsGlobalState(response.data); // This will update the global state storing the user's projects

      setProjects(sortResponse(response.data));
      setLoading(true);
      getStatus(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [currentOrganization?.id, auth?.id, auth?.role, r]);

  const getStatus = (res) => {
    setHold([]);
    setProgress([]);
    setCompleted([]);

    setHold(res.filter((item) => item.status.toLowerCase() === "on_hold"));
    setProgress(
      res.filter(
        (item) =>
          item.status.toLowerCase() === "in_progress" ||
          item.status.toLowerCase() === "in progress"
      )
    );
    setCompleted(
      res.filter((item) => item.status.toLowerCase() === "completed")
    );
  };

  useEffect(() => {
    setLoading(true);
    getStatus(userProjects);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  let categories = [
    {
      value: "1",
      label: "all",
      content: "All  ",
      type: projects,
    },

    {
      value: "2",

      label: "in progress",
      content: "In Progress ",
      type: progress || [],
    },
    {
      value: "3",

      label: "on hold",
      content: "On Hold ",
      type: hold || [],
    },
    {
      value: "4",

      label: "completed",
      content: "Completed ",
      type: completed || [],
    },
  ];

  return (
    <div className="project__page">
      <div className="project__header">
        <p>Projects</p>
        <div className="project__header__buttons">
          <Tooltip title="Projects List View" placement="top">
            <Link
              to="/frame/projects_list"
              style={{
                color: "white",
                border: "1px solid rgb(80, 77, 77)",
              }}
              className="equalizer"
            >
              <AiOutlineUnorderedList />
            </Link>
          </Tooltip>

          <Tooltip title="Filter" placement="top">
            <div className="equalizer" onClick={toggleDrawer("right", true)}>
              <img src={filter} alt="Filter Icon" fontSize={20} />
            </div>
          </Tooltip>
          <div
            className="add__project__button"
            onClick={() => {
              setAddModal(true);
            }}
          >
            + Add Project
          </div>

          <Drawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
          >
            <FilterModal resultCheck={resultCheck} right="right" />
          </Drawer>

          <AddProjectModal
            onClose={() => {
              setAddModal(false);
            }}
            getProjects={getProjects}
            show={showaddModal}
            rr={() => {
              setR(!r);
            }}
          />
        </div>
      </div>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="projects tab"
            className="border-b border-b-[#353535]"
          >
            {categories.map((item, index) => (
              <Tab
                label={`${
                  item.label.toUpperCase() + " [" + item?.type?.length + "]"
                } `}
                value={item.value}
                key={index}
              />
            ))}
          </TabList>
        </Box>

        {/*ALL*/}
        <TabPanel p={8} value={"1"}>
          {loading ? (
            <div className="project__items">
              {projects?.length !== 0 ? (
                resultFilter.length > 1 ? (
                  viewFilter?.map((item, index) => (
                    <DataProjectCard
                      r={r}
                      key={index}
                      data={item}
                      rr={() => {
                        setR(!r);
                      }}
                      getProjects={getProjects}
                    />
                  ))
                ) : (
                  projects?.map((item, index) => (
                    <DataProjectCard
                      r={r}
                      key={index}
                      data={item}
                      rr={() => {
                        setR(!r);
                      }}
                      getProjects={getProjects}
                    />
                  ))
                )
              ) : (
                <ProjectCard
                  rr={() => {
                    setR(!r);
                  }}
                  getProjects={getProjects}
                />
              )}
            </div>
          ) : (
            <LoadingScreen loading />
          )}
        </TabPanel>

        {/* IN PROGRESS*/}
        <TabPanel p={8} value={"2"}>
          <div className="project__items">
            {progress?.length !== 0 ? (
              progress?.map((item, index) => (
                <DataProjectCard
                  r={r}
                  key={index}
                  data={item}
                  rr={() => {
                    setR(!r);
                  }}
                  getProjects={getProjects}
                />
              ))
            ) : (
              <p className="empty__tab"></p>
            )}
          </div>
        </TabPanel>

        {/* ON HOLD*/}
        <TabPanel p={8} value={"3"}>
          <div className="project__items">
            {hold?.length !== 0 ? (
              hold?.map((item, index) => (
                <DataProjectCard
                  r={r}
                  key={index}
                  data={item}
                  rr={() => {
                    setR(!r);
                  }}
                  getProjects={getProjects}
                />
              ))
            ) : (
              <p className="empty__tab"></p>
            )}
          </div>
        </TabPanel>

        {/* COMPLETED*/}
        <TabPanel p={8} value={"4"}>
          <div className="project__items">
            {completed?.length !== 0 ? (
              completed?.map((item, index) => (
                <DataProjectCard
                  r={r}
                  key={index}
                  data={item}
                  rr={() => {
                    setR(!r);
                  }}
                  getProjects={getProjects}
                />
              ))
            ) : (
              <p className="empty__tab"></p>
            )}
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default ProjectPage;
