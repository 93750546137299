import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { SidebarData } from "../SideMenuData";
import { UserSidebarData } from "../UserMenuData";
import { UserPage, AccessNavbar } from "../../components";
import useAuth from "../../hooks/useAuth";
const Layout = () => {
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [active, setActive] = useState();

  const adminRole = auth?.role;

  return (
    <Box className="frame">
      <AccessNavbar
        open={() => setOpen(!open)}
        profile={() => setProfile(!profile)}
      />
      <Stack direction="row" justifyContent="space-between">
        <Box
          className={`${
            open ? "side__bar__shake" : "side__bar"
          }   surface-section  h-screen flex-shrink-0 sticky left-0 z-2 select-none `}
          style={{ flexGrow: 1.5, flexShrink: 1, flexBasis: 0 }}
        >
          <div className="nav_menu fixed" open={open} setOpen={setOpen}>
            {(adminRole === "SUPER_ADMIN" ? SidebarData : UserSidebarData).map(
              (item, index) => {
                return (
                  <div key={index}>
                    <div className="title_text">{item.title}</div>
                    <div>
                      {item.menus.map((menu) => (
                        <li
                          onClick={() => setActive(menu.title)}
                          className={
                            active === menu.title
                              ? menu.cName + "-load"
                              : menu.cName
                          }
                        >
                          <Link
                            className={
                              open ? "link__shake w-full" : "link w-full "
                            }
                            to={`/frame${menu.path}`}
                            state={{ from: menu.msg }}
                          >
                            <span style={{ width: "16px", height: "16px" }}>
                              {menu.icon}
                            </span>
                            <span
                              className={
                                open
                                  ? "txtside__shake truncate"
                                  : "txtside truncate"
                              }
                            >
                              {menu.title}
                            </span>

                            <span
                              style={{
                                width: "16px",
                                height: "16px",
                                position: "absolute",
                                right: 30,
                              }}
                            >
                              {menu.icon2}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </Box>

        {/*  Main page in the middle*/}
        <Box flex={11} className="main__page border-r border-r-[#353535]">
          <Outlet />
        </Box>

        {/*  User Profile page at the side */}
        <Box flex={2} className={`profile__page ${profile && "show__profile"}`}>
          <UserPage />
        </Box>
      </Stack>
    </Box>
  );
};

export default Layout;
