import React, { useState } from "react";
import "./navbar.css";
import Logo from "../../assets/logo.png";
import { LoginModal, ContactModal } from "../Modals";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [showLog, setShowLog] = useState(false);
  const [showContact, setShowContact] = useState(false);

  // console.log("current token", setToken);

  return (
    <div className="hos__navbar container">
      <div className="logo">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
      </div>
      <div className="hos__navbar--links !text-black">
        <NavLink to="/" activeClassName="active" /* className='active' */>
          Home
        </NavLink>
        <NavLink to="/pricing" activeClassName="active">
          Pricing
        </NavLink>
        <NavLink to="/privacy-policy" activeClassName="active">
          Privacy Policy
        </NavLink>
        {/* <NavLink to='/profile' activeClassName='active'>Profile</NavLink> */}
        <p
          href="#"
          onClick={() => setShowContact(true)}
          style={{ cursor: "pointer" }}
        >
          Contact Us
        </p>
        <ContactModal
          onClose={() => setShowContact(false)}
          show={showContact}
        />
        <p
          href="#"
          className="log-btn !bg-black !text-white"
          // setToken={setToken}
          onClick={() => setShowLog(true)}
          style={{ cursor: "pointer" }}
        >
          Login
        </p>
        <LoginModal onClose={() => setShowLog(false)} show={showLog} />
      </div>
    </div>
  );
};

export default Navbar;
