import React, { useState, useEffect, useCallback } from "react";
import "./TextEditor.css";
import { dataMuse } from "../../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineSettings } from "react-icons/md";
import { AccessNavbar } from "../../components";
import { Box, Stack } from "@mui/material";

// React Draft What You See Is What You Get(wysiwyg)
import { EditorState } from "draft-js";
import { convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// PAGE COMPONENTS
import { UserPage } from "../../components";
import Words from "./words/Words";
import Rhyme from "./ryhme/Rhyme";

const TextEditor = () => {
  const [profile, setProfile] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [suggestions, setSuggestions] = useState([]);
  const [currentType, setCurrentType] = useState("Words");

  let { id } = useParams(); //Getting current id from the route parameters
  let navigate = useNavigate();

  let displaySuggestions = suggestions;
  // if (suggestions.length >= 6) {
  //   displaySuggestions = suggestions.slice(0, 5);
  // } else {
  //   displaySuggestions = suggestions;
  // }

  // SHUFFLE THE ARRAY OF THE SUGGESTED RESULTS
  const shuffleSuggestions = useCallback(() => {
    // setSuggestions([]);

    const newSugg = suggestions.sort(() => 0.5 - Math.random());
    setSuggestions([...newSugg]);
  }, [suggestions]);

  useEffect(() => {
    let currentText = convertToRaw(editorState.getCurrentContent())["blocks"][0]
      .text;

    // Checking if the Editor State is empty
    if (currentText.length === 0) setSuggestions([]);
    // Check if a complete word has been inputed in the Editor to initialize the datamuse API
    if (!currentText.slice(-1) === " " || !/\s/.test(currentText)) return;

    let splitString = currentText.split(" ");
    // IIFE for fetching suggested words from the datamuse API
    (async () => {
      const request = await dataMuse.get(
        `/words?lc=${splitString.join("+")}&topics=${id}`
      );
      setSuggestions(request.data); //Saving suggested list of word objects to a state
    })();
  }, [editorState]);

  // Handling the fetch types for the lyricAsssitant
  const handleType = (item) => {
    setCurrentType(item);
  };

  // Keeping track of the Editor State(OnChange Event Handler)
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  //   DUMMY DATA
  let genre = ["Afrobeats", "Amapiano", "Pop", "Jazz", "HipHop", "RnB", "Soul"];
  let fetchType = ["Words", "Rhyme"];

  return (
    <Box className="frame">
      <AccessNavbar profile={() => setProfile(!profile)} />
      <Stack direction="row" justifyContent="space-between">
        <Box flex={1} className="prev__route">
          <div onClick={() => navigate(-1)} className="go__back">
            <BiArrowBack fontSize={30} />
          </div>
        </Box>
        {/*  Main page in the middle*/}
        <Box flex={11} className="middle">
          <Stack direction="row" spacing={3}>
            {/*  Text Editor Section*/}
            <Box className="text__editor__box">
              <div className="header">
                <h4>Song Title</h4>
                <p>Changes saved</p>
              </div>
              <div className="genre__dropdown ">
                <select type="text" name="genre">
                  {genre.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              </div>

              <div className="draft__editor">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
            </Box>

            {/* Suggestion Section*/}
            <Box className="suggestion__box">
              <div className="header">
                <select
                  type="text"
                  name="suggestion"
                  onChange={(e) => handleType(e.target.value)}
                >
                  <option value="Words">Words </option>
                  <option value="Rhyme">Rhyme</option>
                </select>
              </div>

              {currentType === "Words" ? (
                <Words
                  shuffleSuggestions={shuffleSuggestions}
                  suggestions={suggestions}
                />
              ) : (
                <Rhyme />
              )}
            </Box>
          </Stack>
        </Box>

        <hr size="1" width="auto" color="#353535" />

        {/*  User Profile page at the side */}
        <Box flex={3} className={`profile__page ${profile && "show__profile"}`}>
          <UserPage />
        </Box>
      </Stack>
    </Box>
  );
};

export default TextEditor;
