import React from 'react';
import './switchsm.css'

const SwitchMix = ({ idVal,isOn, handleToggle, onColor }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        // id={`react-switch-new`}
        id={idVal}
        type="checkbox"
      />
      <label
        style={{ background: isOn && onColor }}
        className="react-switch-label"
        // htmlFor={`react-switch-new`}
        htmlFor={idVal}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default SwitchMix;
