import React from "react";
import "./accessNavbar.css";
import Side from "../side/Side";

import { Box, Stack } from "@mui/material";
import { AiOutlineSetting } from "react-icons/ai";
import { BiMenuAltLeft, BiBell } from "react-icons/bi";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const AccessNavbar = (props) => {
  const { auth } = useAuth();

  return (
    <div className="access__navbar sticky top-0 z-50">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {/* Side Bar */}
        <Side />

        {/* Navbar */}
        <Box flex={11} p={2}>
          <div className="right__items">
            {/* Menu Icon */}

            <div
              className="menu__icon invisible"
              //  onClick={props.open}
            >
              <BiMenuAltLeft fontSize={30} />
            </div>

            {/* Search Bar 
          
          */}
            <div className="search__container invisible">
              <input
                type="text"
                placeholder={` Search...`}
                id="search__bar"
                // children={<AiOutlineSearch color="gray.300" />}
              />
              <label htmlFor="search__bar"></label>
            </div>

            {/* Right Hand Side Icons */}
            <div className="right__icons space-x-5">
              <Tooltip title="Settings" placement="top">
                <Link to="/frame/settings">
                  <AiOutlineSetting fontSize={25} />
                </Link>
              </Tooltip>
              <BiBell className="hidden" fontSize={25} />

              <Tooltip
                title={auth.firstName + " " + auth.lastName}
                placement="top"
              >
                <div className="profile__icon" onClick={props.profile}>
                  <div className="big_ capitalize">
                    {auth.firstName ? auth.firstName.charAt(0) : null}
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </Box>
      </Stack>
    </div>
  );
};

export default AccessNavbar;
