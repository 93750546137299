import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";

const LogOut = () => {
  const { logOut } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("organization");
    logOut();
    toast.success("Bye, see you later! 👋 ");
    navigate("/");
  }, []);
};

export default LogOut;
