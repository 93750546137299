import React from 'react';
import './modal.css';
import {AiOutlineClose} from "react-icons/ai";
import {RiPantoneFill} from "react-icons/ri";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {BsTrash} from "react-icons/bs";
const TheModal = props => {
    const [coloropen, colorsetOpen] = React.useState(false);
    const colorhandleOpen = () => colorsetOpen(true);
    const colorhandleClose = () => colorsetOpen(false);

    const stylecolor = {
        borderRadius:'1rem',
        position: 'absolute',
        top: '40%',
        left: '70%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        bgcolor: '#0D0D0D',
        boxShadow: 24,
        p: 4,
        padding:0
      };

    return(
        <div className={`modal ${props.show ? 'show': ''}`}>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h4>{props.title}</h4>
                    <div style={{padding:'8px',width:'32px',height:'32px',borderRadius:'6px',background:'#171717',marginLeft:'20rem'}} onClick={props.onClose}><AiOutlineClose /></div>
                </div>
                <div className='modal-body'>
                   <div >
                        <div className='modal-rack-begins' ><div style={{width:'1rem',height:'1rem',borderRadius:'0.3rem',background:'#39D1E5',position:'absolute'}}></div><div style={{marginLeft:'2rem'}}>arranging</div><div style={{display:'flex',position:'absolute',top:'5rem'}}><div style={{marginLeft:'25rem',marginRight:'1rem'}}  onClick={colorhandleOpen}  ><RiPantoneFill /></div><div><BsTrash /></div></div></div>
                        <div className='modal-rack'>capturing</div>
                        <div className='modal-rack'>album</div>
                        <div className='modal-rack'>soundtrack</div>
                        <div className='modal-rack'>add label</div>
                   </div>
                   <div className='modal-done'>{props.footText}</div>
                </div>
            </div>
            <Modal
          open={coloropen}
          onClose={colorhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
            <Box sx={stylecolor}>
        
        <div style={{padding:'0.5rem'}}>

        
         <div >
         <p>Change colors</p>
         <div style={{display:'flex',marginTop:'0.5rem'}}>
             <div style={{borderRadius:'4rem',background:'#FD7972',width:'1rem',height:'1rem'}}></div>
             <div style={{borderRadius:'4rem',background:'#FEB077',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
             <div style={{borderRadius:'4rem',background:'#FFDB6E',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
             <div style={{borderRadius:'4rem',background:'#59ACFF',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
              <div style={{borderRadius:'4rem',background:'#39D1E5',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
         </div>
         <div style={{display:'flex',marginTop:'0.5rem'}}>
         <div style={{borderRadius:'4rem',background:'#51D187',width:'1rem',height:'1rem'}}></div>
         <div style={{borderRadius:'4rem',background:'#B8E869',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
         <div style={{borderRadius:'4rem',background:'#C16DEE',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
         <div style={{borderRadius:'4rem',background:'#FD72DF',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
         <div style={{borderRadius:'4rem',background:'#7A7A7A',width:'1rem',height:'1rem',marginLeft:'1rem'}}></div>
         </div>
         </div>
         </div>
        </Box>
        </Modal>
        </div>
    )
}

export default TheModal