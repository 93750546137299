import React, { useState, useCallback, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import BackDrop from "../../BackDrop";
import Moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai";
import { toast } from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";

const TaskListCard = (props) => {
  const { auth, config } = useAuth();
  const [open, setOpen] = useState(false);
  const [taskMembers, setTaskMembers] = useState(null);
  const [r, setR] = useState(false);

  const date = Moment(props.endDate).format("MMM Do");

  const getTaskMembers = useCallback(
    async (id) => {
      const resp = await axios.get(`/task/${id}`, config);

      const data = resp.data;
      if (resp && data) setTaskMembers(data?.taskMembers);
    },
    [props.id]
  );

  useEffect(() => {
    getTaskMembers(props.id);
  }, [props.id, getTaskMembers]);

  useEffect(() => {
    if (props.getTasks) {
      props.getTasks(props?.ci);
    }
  }, [r, props.id]);

  const changeStatus = async (event, id) => {
    // HANDLES MANAGING THE STATUS OF A TASK
    let { checked } = event.target;
    let status;

    if (checked) {
      status = "COMPLETED";
    } else {
      status = "IN PROGRESS";
    }

    setOpen(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.patch(
        `/task/${id}`,
        JSON.stringify({
          status,
        }),
        config
      );

      setR(!r);
      if (checked) {
        toast.success(`Task completed`);
      } else {
        toast.success("Task open");
      }
      setTimeout(() => {
        setOpen(false);
      }, response);
    } catch (error) {
      console.log(error);
      setOpen(false);

      if (!error.message) {
        toast.error("Check your internet connection");
        return;
      }
      toast.error("Failed!");
    }
    return;
  };

  return (
    <div
      className="px-4 py-3 flex cursor-pointer items-center text-sm rounded-lg border border-neutral-800"
      onClick={() => props.selectTask()}
    >
      {/* Check box and checkbox */}
      <div className="w-[70%]">
        <div className="check__input">
          <Tooltip
            title={
              props.status.toLowerCase() === "completed"
                ? "Open task"
                : "Complete task"
            }
            placement="top"
          >
            <input
              type="checkbox"
              name={props.title}
              id={props.title}
              className="check-with-label"
              onChange={(e) => changeStatus(e, props.id)}
              checked={
                props.status && props.status.toLowerCase() === "completed"
                  ? true
                  : false
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
          <label
            className="label-for-check pl-2"
            // htmlFor={props.title}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {props.title}
          </label>
        </div>
        {open && <BackDrop open={open} />}
      </div>
      {/* Date and team members */}
      <div className="w-[30%] flex justify-between items-center">
        <div className="flex  items-center space-x-2">
          <AiOutlineCalendar className=" text-base" />
          <p>{date}</p>
        </div>

        <div className="flex items-center space-x-3">
          {taskMembers && taskMembers[0] !== "[]"
            ? taskMembers.map((item, index) => (
                <div
                  className="w-8 h-8 pt-2.5 pb-[9px] text-purple-400 text-sm  bg-purple-400 bg-opacity-20 rounded-[8px] justify-center items-center inline-flex"
                  key={index}
                >
                  {item.firstName.charAt(0).toUpperCase()}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default TaskListCard;
