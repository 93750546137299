import React, { useState } from "react";
import "./SetDeadline.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { FaRegCheckSquare } from "react-icons/fa";
import toast from "react-hot-toast";
import axios from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import BackDrop from "../../BackDrop";

const SetDeadline = (props) => {
  const { auth } = useAuth();
  const [value, setValue] = useState(new Date());
  const [time, setTime] = useState("00:00");
  const [open, setOpen] = useState(false);

  const closeP = props.onClose;

  const handleCLick = async (project) => {
    // MAKE AN API REQUEST TO UPDATE THE END DATE TO THIS PROJECT/TASK
    setOpen(true);

    try {
      const response = await axios.patch(
        `${props.type === "task" ? "task" : "project"}/${project.id}`,
        JSON.stringify({
          endDate: new Date(value).toISOString(),
        }),
        {
          headers: {
            Authorization: `Bearer ${auth?.authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setTimeout(() => {
        setOpen(false);
      }, response);

      props.rr();
      if (props.getProjects) {
        await props.getProjects();
      }
      closeP();

      toast.success("Deadline set successfully");
    } catch (error) {
      setOpen(false);

      toast.error("Failed!");
      console.log(error);
    }

    // setPopNotify(true);
  };

  const onChange = (timeValue) => {
    setTime(timeValue);
  };

  return (
    <div
      className={`set__deadline__modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div
        className={`set__deadline__modal--content `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="set__deadline__modal__header">
          <h4>Set deadline for tracking</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>
        {open && <BackDrop open={open} />}

        <Accordion
          style={{
            bg: "black",
            color: "white",
            border: "1px solid #242424",
            fontSize: "14px",
            fontFamily: "poppins",
            borderRadius: "8px",
            padding: "10px 30px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography> Enter a date</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                sx={{ bgColor: "black" }}
              >
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  minDate={props.startDate}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/*
      
        <br />
        <br />
  
        <div className="set__deadline__time">
          <h2>Time: </h2>
  
          <div className="set__deadline__input">
            <TimePicker onChange={onChange} value={time} />
          </div>
        </div>
      */}

        <div className="send__notification">
          <input type="checkbox" />
          <p>Send notification/reminder </p>
        </div>

        <a
          href="#"
          className="submit-tag"
          onClick={() => handleCLick(props.project)}
        >
          Set Deadline
        </a>
      </div>
    </div>
  );
};

export default SetDeadline;
