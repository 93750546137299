import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const StorageData = ({ teamMembers }) => {
  // console.log(teamMembers)
  const result = [];
  const failresult = [];
  const budgetT = [];
  let final = [];
  teamMembers.map((item) => {
    if (item.status === "IN_PROGRESS") {
      result.push(item);
    } else if (item.status === "ON_HOLD") {
      failresult.push(item);
    } else {
      budgetT.push(item);
    }
  });

  final = [result.length, failresult.length, budgetT.length];
  //  console.log(final)
  // const labels = result;

  const options1 = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 18,
          },
        },
      },
    },
    responsive: true,
    cutout: "87%",
    maintainAspectRatio: false,
  };

  const storageData = {
    labels: ["IN PROGRESS", "ON HOLD", "COMPLETED"],
    datasets: [
      {
        data: final,
        backgroundColor: ["#51D187", "#FEB077", "#59ACFF"],
        hoverBackgroundColor: [
          "rgba(81,209,135,0.5)",
          "rgba(254,176,119,0.5)",
          "rgba(89,172,255,0.5)",
        ],
      },
    ],

    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2,
      },
    },
    text: "23%",
  };

  return <Doughnut data={storageData} options={options1} />;
};

export default StorageData;
