import React, { useState } from "react";
import "./padal_lock.css";
import { useLocation } from "react-router-dom";

const Padallock = (props) => {
  const location = useLocation();
  const { from } = location.state;
  const [state, setState] = useState({
    email: null,
    errors: {
      email: "",
    },
  });

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = state.errors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;

      default:
        break;
    }

    setState({ errors, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(state.errors)) {
      console.info("Valid Form");
    } else {
      console.error("Invalid Form");
    }
  };
  return (
    <>
      <div className="p_container">
        <h1>{from}</h1>
        <h3 style={{ paddingBottom: "10px" }}>Coming Soon...</h3>
        {/* 
        
        <form
          onSubmit={handleSubmit}
          style={{ display: "flex", flexDirection: "column", gap: 10 }}
        >
          <input
            placeholder="Enter Email"
            type="email"
            name="email"
            className="input"
            onChange={handleChange}
            noValidate
          />
          {state.errors.email.length > 0 && (
            <span className="error">{state.errors.email}</span>
          )}
          <button type="submit" className="button">
            Submit
          </button>
        </form>
        */}
      </div>
    </>
  );
};

export default Padallock;
