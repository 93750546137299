import React, { useState } from "react";
import { DateRange } from "react-date-range";
import './datepicker.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {RiArrowDropDownLine} from "react-icons/ri";
import {AiOutlineCalendar} from "react-icons/ai";
import moment from "moment";

export default function DatePicker({ onChange, dateopen, datesetOpen }) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection"
    }
  ]);
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };
  const formatDate = (date) => moment(date).format("MMM, DD YYYY HH:mm");
  const Endate =
    state[0].endDate === null
      ? formatDate(state[0].startDate)
      : formatDate(state[0].endDate);
 // const useMe = 'Feb 3, 2022';
  return (
    <div style={{position:'absolute',background:'#171717',borderRadius:'0.8rem'}}>
        <div style={{border:'1px solid #171717',marginTop:'0.4rem'}}>
        <AiOutlineCalendar/>
    <input
    className="input-box"
            type="text"
            readOnly
            onClick={() => datesetOpen(!dateopen)}
            value={
                Endate
            }
          />
          <RiArrowDropDownLine />
        </div>
       
      {dateopen && (<DateRange
        onChange={handleOnChange}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />)}
    </div>
  );
}
