import React from "react";
import "./Words.css";
import { MdOutlineSettings } from "react-icons/md";

const Words = (props) => {
  return (
    <div className="body">
      <div className="top">
        <div className="new__suggestion" onClick={props.shuffleSuggestions}>
          New suggestion
        </div>
        <div className="suggestion__settings">
          <MdOutlineSettings />
          <p>Settings</p>
        </div>
      </div>

      <br />

      <div className="details">
        <div className="suggested__results">
          {props.suggestions.map((item, index) => (
            <p key={index} className="suggested__result">
              {item.word}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Words;
