import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";
import useAuth from "../../../hooks/useAuth";

const EditExpense = (props) => {
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: props.name || "",
    spent: parseInt(props.spent),
  });
  const handleChange = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, spent } = formData;
    setOpen(true);

    try {
      let response;
      if (props.newEx === "new") {
        response = await axios.post(
          `/budgetexpense/${props.taskId}`,
          JSON.stringify({
            name: name,
            spentBudget: spent,
            project: props.projectId,
            task: props.taskId,
          }),
          {
            headers: {
              Authorization: `Bearer ${auth?.authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.patch(
          `/budgetexpense/${props.exId}`,
          JSON.stringify({
            name: name,
            spentBudget: spent,
            // project: props.projectId,
            // task: props.taskId,
          }),
          {
            headers: {
              Authorization: `Bearer ${auth?.authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
      }
      if (props.rr) {
        props.rr();
      }
      props.onClose();

      setTimeout(() => {
        setOpen(false);
      }, response);

      toast.success(
        `${props.newEx === "new" ? "Expense Added" : "Expense Updated"}`,
        {
          duration: 4000,
        }
      );
    } catch (error) {
      setOpen(false);

      if (!error.response) {
        toast.error("Check your internet connection!");
      } else {
        toast.error(error.response.data.message);
      }
      console.log(error);
    }
  };

  return (
    <div
      className={`add__product__modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <form
        className="add__product__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="contact__modal--content-header">
          <h4> Edit Expense</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add__product__inputs">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            value={formData["name"]}
            required
            disabled
          />

          <label htmlFor="spent">
            Amount Spent{" "}
            <span className=" opacity-50">
              (Available budget: {props.budget})
            </span>
          </label>
          <input
            type="number"
            name="spent"
            id="spent"
            min={0}
            onChange={handleChange}
            defaultValue={formData["spent"]}
            required
          />
        </div>
        <button type="submit" className="submit-tag">
          Add Expense
        </button>
      </form>
    </div>
  );
};

export default EditExpense;
