import React, { useState, useEffect } from "react";
import axios, { firstVersion } from "../../api/axios";
import "./admintable.css";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import { EditOrgModal, MemberModal } from "../Modals";
import Tooltip from "@mui/material/Tooltip";
import { delOrg } from "../../util.js";
import BackDrop from "../BackDrop";
import { convertDate } from "../../util.js";

const AdminTable = ({
  allMembers,
  allProjects,
  orgdata,
  orgLenght,
  rr,
  setNew,
}) => {
  const data = orgdata;
  const { auth, currentOrganization } = useAuth();
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [showMember, setShowMember] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [open, setOpen] = useState(false);
  const [resUpdate, setResUpdate] = useState("");
  const [pendingData, setPending] = useState(
    allMembers.filter((edata) => !edata.firstName)
  );
  const [activateData, setActivate] = useState(
    allMembers.filter((edata) => edata.isActive === true)
  );

  const deleteOrg = (id) => {
    toast(
      (t) => (
        <span>
          <p className=" text-lg md:text-xl text-center px-3 ">
            Are you sure you want to delete this organization?
          </p>
          <br />
          <div className="delete__activity flex items-center justify-center space-x-3">
            <div className="cancel" onClick={() => toast.dismiss(t.id)}>
              Cancel
            </div>

            {/*Write the delete functionality for a user post for folder operation */}
            <div
              className="delete"
              onClick={() => {
                delOrg(`organizations/delete/`, id);

                if (rr) {
                  rr();
                }
                setNew();

                toast.dismiss(t.id);
              }}
            >
              Delete
            </div>
          </div>
        </span>
      ),
      {
        position: "top-center",
      }
    );
  };

  const deleteMember = (e) => {
    setOpen(true);
    var config = {
      method: "delete",
      url: `https://www.api.beatlab.app/api/v1/auth/users/${e}/${orgdata.id}`,
      headers: {
        Authorization: "Bearer ".concat(auth?.authToken),
      },
    };

    axios(config)
      .then(function (response) {
        if (rr) {
          rr();
        }
        toast.success("Deleted Member");
        setOpen(false);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Something went wrong!");
        setOpen(false);
      });
    setOpen(false);
  };

  const updateOrg = (e) => {
    setResUpdate(e);
    setShowLog(true);
  };
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = allMembers.filter((item) => {
        return Object.values(item)
          .join("")
          .toLocaleLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  return (
    <div className="admin__profile--table">
      <p className="font-semibold text-lg py-3 leading-relaxed tracking-wider">
        {data.name} Team
      </p>
      {open && <BackDrop open={open} />}
      <div className="admin__profile--search flex items-center pb-4">
        <div className="w-full flex items-center space-x-3">
          <input
            style={{ backgroundColor: "var(--bg-secondary)", color: "white" }}
            onChange={(e) => searchItems(e.target.value)}
            type="text"
            className="w-full"
            placeholder="Search by Name or Email"
          />
          {/* <a
            href="#"
            className="admin__search--icon flex items-center space-x-2"
          >
            <AiOutlineSearch />
            <p>Search</p>
          </a> */}
        </div>

        <div className="w-1/2 flex items-center space-x-2 justify-end">
          <a
            href="#"
            className="admin__search--icon flex items-center space-x-2"
            onClick={() => updateOrg(data.id)}
          >
            <AiOutlineEdit />
            <p>Edit Team</p>
          </a>
          {orgLenght > 1 ? (
            <a
              href="#"
              className="admin__search--icon flex items-center space-x-2"
              onClick={() => deleteOrg(currentOrganization?.id)}
            >
              <AiOutlineDelete />
              <p>Delete Team</p>
            </a>
          ) : null}
        </div>
        <MemberModal
          val={data.id}
          onClose={() => setShowMember(false)}
          show={showMember}
        />
        <EditOrgModal
          val={resUpdate}
          onClose={() => setShowLog(false)}
          show={showLog}
          rr={rr}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div className="admin__table">
          <div className="admin__table--header">
            <h3>User</h3>
            <h3>Role</h3>
            <h3>Activity</h3>
          </div>
          <div className="table__divider" />
          <div className="admin__table--items">
            {searchInput.length > 1
              ? filteredResults?.map((evdata, i) => {
                  return (
                    <div index={i} className="admin__table--item">
                      <div className="user__item">
                        <div className="user__item--image">
                          <img
                            src={
                              evdata.imageUrl ??
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtaP-HPoo7LXGeRZRta0zY1vdavRWm-KJfsw&usqp=CAU"
                            }
                            alt="orgim"
                          />
                        </div>
                        <div className="user__item--info">
                          <h4>{evdata?.email}</h4>
                          <p>
                            {evdata?.mobileNumber
                              ? evdata.phoneNumber
                              : "mobile number unavailable.."}
                          </p>
                        </div>
                      </div>
                      <div className="role__item">
                        <p>{evdata?.role}</p>
                      </div>
                      <div className="activity__item">
                        <p>{convertDate(evdata?.updatedAt)}</p>
                        <div className="edit__item">
                          {evdata.role !== "SUPER_ADMIN" && (
                            <Tooltip title="deleting all members">
                              <a
                                onClick={() => deleteMember(evdata.id)}
                                className="hidden"
                              >
                                <AiOutlineDelete />
                              </a>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : allMembers?.map((evdata, i) => {
                  let datecapture = new Date(evdata?.updatedAt);

                  let mcapture = datecapture.getMonth() + 1;

                  return (
                    <div index={i} className="pt-2 admin__table--item">
                      <div className="user__item">
                        <div className="user__item--image">
                          <img
                            src={
                              data.imageUrl ??
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtaP-HPoo7LXGeRZRta0zY1vdavRWm-KJfsw&usqp=CAU"
                            }
                            alt="orgim"
                          />
                        </div>
                        <div className="user__item--info">
                          <h4>
                            {evdata.firstName
                              ? evdata.firstName + " " + evdata.lastName
                              : "Pending invite..."}
                          </h4>
                          <p>{evdata.email}</p>
                        </div>
                      </div>
                      <div className="role__item">
                        <p>{evdata?.role ? evdata?.role : "USER"}</p>
                      </div>
                      <div className="activity__item">
                        <p>{convertDate(evdata?.updatedAt)}</p>
                        {evdata.role !== "SUPER_ADMIN" && (
                          <div className="edit__item ">
                            {/* <a onClick={() =>  updateOrg(data.id)}  href="#"><AiOutlineEdit /></a> */}

                            <span
                              onClick={() => deleteMember(evdata.id)}
                              className=" cursor-pointer hidden"
                            >
                              <AiOutlineDelete />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
        <div className="admin__profile--stat-section">
          <h3>Stats</h3>
          <div className="admin__stats">
            <div className="stat">
              <h3>{allMembers ? allMembers.length : "0"}</h3>
              <p>Members</p>
            </div>
            <div className="stat">
              <h3>{allProjects ? allProjects.length : "0"}</h3>
              <p>Active Projects</p>
            </div>
            <div className="stat">
              <h3>{pendingData ? pendingData.length : "0"}</h3>
              <p>Pending Invites</p>
            </div>
            <div className="stat">
              <h3>{activateData ? activateData.length : "0"}</h3>
              <p>Available Invites</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminTable;
