import { createContext, useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import { getOrgProjects, sortResponse } from "../util.js";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [userProjects, setUserProjects] = useState([]);

  const UpdateProjectsGlobalState = (updatedState) => {
    setUserProjects(sortResponse(updatedState));
    Cookies.set(
      "projects",
      JSON.stringify({ data: sortResponse(updatedState) })
    );

    return;
  };

  // Gets all of the user's projetcs on first render
  const getUserProjects = useCallback(async () => {
    const res = await getOrgProjects(
      currentOrganization?.id,
      auth?.id,
      auth?.role
    );

    UpdateProjectsGlobalState(sortResponse(res.data));

    return;
  }, [currentOrganization, auth?.id, auth?.role]);

  useEffect(() => {
    if (!auth || !currentOrganization) return;
    getUserProjects();
  }, [auth, getUserProjects, currentOrganization]);

  // Store JWT token as a cookie
  const saveAuth = (userAuth) => {
    Cookies.set("auth", JSON.stringify(userAuth), { expires: 1 }); // Expires after 1 day
    setAuth(userAuth);
  };

  // Save the current organization as a cookie
  const saveOrganization = (organization) => {
    Cookies.set("organization", JSON.stringify(organization));
    setCurrentOrganization(organization);
  };

  // Handles signing out a user
  const logOut = async () => {
    Cookies.remove("auth");
    Cookies.remove("organization");
    Cookies.remove("projects");

    setAuth(null);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
    },
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        config,
        setAuth: saveAuth,
        setIt: setAuth,
        setCurrentOrganization: saveOrganization,
        currentOrganization,
        userProjects,
        UpdateProjectsGlobalState,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
