import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";

const AddSub = (props) => {
  const { auth } = useAuth();
  const { cd } = props.data;
  const [open, setOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });
  const handleChange = (event) => {
    const { value, name } = event.target;

    switch (name) {
      case "name":
        setFormData({ ...formData, [name]: value });
        break;
      default:
        break;
    }
  };
  const handleSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { name } = formData;

    if (name === "") {
      toast.error("Please add a name!");
      return;
    }
    let status = "Pending";

    setOpen(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
          "Content-Type": "application/json",
        },
      };

      //  USE THE ADDING SUBTASK REQUEST
      const response = await axios.post(
        `/subtask/${props?.data?.id}`,
        JSON.stringify({
          name,
          Status: status,
        }),
        config
      );

      if (props.rr) {
        props.rr();
      }
      if (props.cc) {
        props.cc();
      }
      props.onClose();
      setFormData({ name: "" });

      setTimeout(() => {
        setOpen(false);
        setFormData({ name: "" });
      }, response);
      if (props.getTasks) {
        props.getTasks(cd?.id);
      }
      toast.success(` Subtask Added!`, {
        duration: 4000,
      });
    } catch (error) {
      setOpen(false);
      console.log(error);

      toast.error("Failed!");
    }
  };

  return (
    <div
      className={`add__product__modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <form
        className="add__product__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="contact__modal--content-header">
          <h4>Subtask</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add__product__inputs">
          {/* Add Image input */}

          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData["name"]}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-tag">
          Add Subtask
        </button>
      </form>
    </div>
  );
};

export default AddSub;
