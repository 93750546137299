import React from "react";
import "./ImageModal.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "76%",
  boxShadow: 24,
  bgcolor: "#0d0d0d",
};

const ImageModal = (props) => {
  return (
    <div className="image__modal">
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="image-view-modal"
        aria-describedby="imaage-view-description"
        className="image__modal"
      >
        <Box sx={style}>
          <img
            src={props.source}
            alt="full-image"
            className=" cursor-zoom-out"
            onClick={() => props.close()}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ImageModal;
