import React, { useState } from "react";
import { FaCheck, FaArrowUp } from "react-icons/fa"; // Import icons from react-icons library

const PlansBillingPage = () => {
  const [userSubscription, setUserSubscription] = useState({
    plan: "Basic",
    storage: "5 GB",
  });

  const handleUpgrade = (newPlan) => {
    setUserSubscription({
      ...userSubscription,
      plan: newPlan,
    });
    // Additional logic for billing and other updates
  };

  return (
    <div className=" mx-auto p-8">
      <h1 className="font-medium text-2xl mb-8">Plans & Billing</h1>

      <div className=" p-3 rounded-lg shadow-md">
        <h2 className="text-xl font-medium mb-4">Your Current Plan</h2>
        <p className=" mb-6">
          You are currently subscribed to the{" "}
          <span className="font-semibold">{userSubscription.plan}</span> plan.
        </p>

        <div className="mb-6">
          <h3 className="text-lg font-medium mb-2">Current Storage</h3>
          <p className=" flex items-center">
            <FaCheck className="text-green-500 mr-2" />{" "}
            {userSubscription.storage}
          </p>
        </div>

        <h2 className="text-xl font-medium mb-6">Upgrade Your Plan</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {["Advanced", "Premium", "Label", "Editor"].map((plan) => (
            <div
              key={plan}
              className=" p-6 rounded-md transform hover:scale-105 transition-transform duration-300 ease-in-out"
            >
              <h3 className="text-lg font-medium mb-4">{plan}</h3>
              <p className=" mb-4">Includes additional features</p>
              <button
                className="hover:opacity-80 text-white px-4 py-2 rounded-md bg-[#ba68c8] flex items-center"
                onClick={() => handleUpgrade(plan)}
              >
                Upgrade <FaArrowUp className="ml-2" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlansBillingPage;
