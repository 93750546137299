import React, { useState } from "react";
import "./SetBudgetModal.css";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";

const SetBudgetModal = (props) => {
  const { data } = props;
  const { auth } = useAuth();
  const [budget, setBudget] = useState(0);
  const [open, setOpen] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
      "Content-Type": "application/json",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (budget === "") {
      return;
    }

    setOpen(true);

    try {
      const response = await axios.patch(
        `/project/${data.id}`,
        JSON.stringify({
          budget,
        }),
        config
      );
      setBudget(0);

      if (response) {
        if (props.rr) {
          props.rr();
        }
        setOpen(false);
        toast.success(`A New Budget of ${budget} Has Been Updated!`, {
          duration: 4000,
        });
        props.onClose();
      }
    } catch (error) {
      setOpen(false);

      if (!error.response) {
        toast.error("Check your internet connection!");
      } else {
        toast.error("Failed!");
        console.log(error);
      }
    }
  };

  return (
    <div
      className={`add__product__modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <form
        className="add__product__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="contact__modal--content-header">
          <h4> Set Budget</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add__product__inputs">
          {/* Add Image input */}

          <label htmlFor="projectName">Project Name</label>
          <input
            type="text"
            name="projectName"
            id="projectName"
            // Remove when using the API
            value={data?.name}
            disabled
          />

          <label htmlFor="ClientName">Client Name</label>
          <input
            type="text"
            name="clientName"
            id="clientName"
            // Remove when using the API
            value={data?.artistName}
            disabled
          />

          <label htmlFor="new_budget">Enter New Budget</label>
          <input
            type="number"
            name="new_budget"
            id="new_budget"
            min={0}
            onChange={(e) => setBudget(e.target.value)}
            value={budget}
          />

          <label htmlFor="current_ budget">Current Budget Balance</label>
          <input
            type="number"
            name="current_budget"
            id="current_budget"
            value={data?.budget}
            disabled
          />

          {/* //TODO: Display all of the available expenses made on this budget */}
          {/* 
          <label htmlFor="cexpenses">Expenses</label>
          <br />
          <br />
          <br />
          <br />
          <br />
           */}

          <div className="project__duration">
            <div>
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                value={data?.startDate}
                disabled
              />
            </div>
            <div>
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                value={data?.endDate}
                disabled
              />
            </div>
          </div>
        </div>
        <button href="#" type="submit" className="submit-tag">
          Set
        </button>
      </form>
    </div>
  );
};

export default SetBudgetModal;
