import React from "react";

const Privacy = () => {
  return (
    <div className="flex flex-col space-y-5">
      <p className=" font-medium text-2xl ">Privacy and Safety Policy</p>

      <div className=" tracking-wider leading-relaxed space-y-3">
        <p>
        At Beatlab, we value your privacy and dedicated to maintaing the highest level of data protection and user safety. This outlines our commitment to safeguarding your information and ensuring a secure experience while using our platform
        <br />
        Data Usage and Google Drive Integration
        <br/>
        Beatlab operates in conjuction with Google Drive to provide you with seamless access and collaboration capabilities. As part of this integration, you grant our application rights to access, modify, create and delete specific Google Drive files that you use with our app
        We will only interact with the files directly related to yor BeatLab activities
        <br />
        Data Proctection and Privacy
        <br />
        We employ industry standard security measeures to safeguard your personal information. We do not share your data with third parties unless explicitly authorized by you or when required by law. Your information is securely stored and accessible only to authorized personnel
        <br />
        User Safety
        <br />
        <br />
        Your safety is of utmost importance to us. To ensure a secure user experince, we have implemented stringent measures, including:
        </p>

        <ul className="ml-3 pb-3 ">
          <li className="make--sure">
          Verifying user identities to prevent fraudlent accounts
          </li>
          <li className="make--sure">
          Monitoring user activity to detect and prevent any malicious behavior
          </li>
          <li className="make--sure">
          Providing tools to report and block any users who violate our terms of services
          </li>
        </ul>

        <p>
        Providing reporting and blocking tools to address violations of our terms of service
        <br />
        We have also taken steps to protect minors on our platforms. Users under the age of 13 are prohibited from creating accounts, and we have implemented measures to prevent access to inappropriate content.
        <br />
        Privacy Updates
        <br />
        Our Privacy and Safety Policy may be updated periodically. We will notify you of any changes and provide an opportunity to review the updated policy before continuing to use our platform.
        <br />
        Contact Us
        <br />
        If you have any questions or concerns regarding our Privacy and Safety Policy, please reach out to
           us at{" "}
          <span className="text-[#ba68c8]">session@beatlab.app</span>.  We are committed to providing a safe and secure experience for all users.
          <br />
          Thank you for choosing BeatLab. Your privacy and safety are our top priorites
        </p>
      </div>
    </div>
  );
};

export default Privacy;
