import React, { useState, useRef, useEffect } from "react";
import "./SoundPage.css";
import { BsDownload, BsSpeedometer2, BsFillSunFill } from "react-icons/bs";
import { FiMusic } from "react-icons/fi";
import { RiCoinsLine } from "react-icons/ri";
import { CgPiano } from "react-icons/cg";
import { IoIosClose } from "react-icons/io";
import { SiAudiomack } from "react-icons/si";
import { BiEqualizer, BiRadioCircle, BiGridHorizontal } from "react-icons/bi";
// import {CgShapeCircle,CgShapeHalfCircle} from "react-icons/cg"
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import SwitchMix from "../switch/SwitchMix";
import Slider from "./RoundSlider/Slider";
import SmallSlider from "./RoundSlider/SmallSlider";
import { saveAs } from "file-saver";
// import  "./eqenv/main.js"
import EQ, { EqModel, HIGH_PASS, BELL, HIGH_SHELF } from "./eqenv/eq.js";
import { AudioContext, OfflineAudioContext } from "standardized-audio-context";
const eqModel = new EqModel({
  minGain: -12.0,
  maxGain: 12.0,
  minFrequency: 20,
  maxFrequency: 24_000,
  minQ: 0.1,
  maxQ: 50.0,
  bands: [HIGH_PASS, BELL, BELL, BELL, HIGH_SHELF],
});

const SoundPage = () => {
  const audioCtxContainer = useRef(null);
  const [file, setFile] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, volumeControl] = useState(null);
  const ref = useRef(null);

  const [valueEq, setValueEq] = useState(false);
  const [valueComp, setValueComp] = useState(false);
  const [valueSat, setValueSat] = useState(false);
  const [valueIma, setValueIma] = useState(false);
  const [valueLim, setValueLim] = useState(false);
  const [trackGainNode, settrackGainNode] = useState(null);
  const [compressor, setCompressor] = useState(null);
  const [filterNode, setfilterNode] = useState(null);
  // slider value
  const minVal = useState(-40);
  const maxVal = useState(10);

  const gainMinVal = useState(-40);
  const gainMaxVal = useState(10);

  const attMinVal = useState(-40);
  const attMaxVal = useState(1);

  const relMinVal = useState(-40);
  const relMaxVal = useState(1);

  const kneeMinVal = useState(0);
  const kneeMaxVal = useState(40);

  const ratMinVal = useState(1);
  const ratMaxVal = useState(20);

  const qMinVal = useState(0.0001);
  const qMaxVal = useState(100);

  const amMinVal = useState(0);
  const amMaxVal = useState(100);

  const [sliderValue, setSliderValue] = useState(5);

  // slider value
  const mute = document.querySelector("#mute");

  function db2Vol(db) {
    return Math.pow(10, db / 20);
  }

  const onFileChange = (e) => {
    let file = e.target.files[0];
    setFile(file);
    let fileReader = new FileReader();
    fileReader.onload = function (ev) {
      audioCtxContainer.current = new AudioContext();
      audioCtxContainer.current
        .decodeAudioData(ev.target.result)
        .then(function (buffer) {
          playSound(buffer);
        });
    };
    fileReader.readAsArrayBuffer(file);
  };

  const playSound = (buffer, time) => {
    const trackGainNode = audioCtxContainer.current.createGain();

    settrackGainNode(trackGainNode);
    const compressor = audioCtxContainer.current.createDynamicsCompressor();
    setCompressor(compressor);

    const filterNode = audioCtxContainer.current.createBiquadFilter();
    setfilterNode(filterNode);

    const sourceNode = audioCtxContainer.current.createBufferSource();
    sourceNode.buffer = buffer;
    sourceNode
      .connect(compressor)
      .connect(filterNode)
      .connect(trackGainNode)
      .connect(audioCtxContainer.current.destination);

    sourceNode.start(time);
    setIsPlaying(true);
  };
  const onPlayPause = (e) => {
    if (!isPlaying) {
      audioCtxContainer.current.resume();
      setIsPlaying(true);
      // document.getElementById("pauseButton").innerHTML = "Pause";
    } else if (audioCtxContainer.current.state === "running") {
      audioCtxContainer.current.suspend();
      setIsPlaying(false);
    } else if (audioCtxContainer.current.state === "suspended") {
      audioCtxContainer.current.resume();
    } else if (audioCtxContainer.current === "closed") {
      console.log("closed");
    }
  };
  const onMute = (e) => {
    console.log(mute.id);
    if (mute.id === "mute") {
      trackGainNode.gain.setValueAtTime(
        0,
        audioCtxContainer.current.currentTime
      );
      mute.id = "activated";
      mute.textContent = "Unmute";
    } else if (mute.id === "activated") {
      mute.id = "mute";
      trackGainNode.gain.setValueAtTime(
        1,
        audioCtxContainer.current.currentTime
      );
      mute.textContent = "mute";
    }
  };

  const funcvolumeControl = (e) => {
    volumeControl(e);
    //    console.log(compressor.attack.value)

    let vol = db2Vol(volume);
    console.log(vol);
    //  compressor.attack.setValueAtTime(vol,audioCtxContainer.current.currentTime)
    //   console.log(trackGainNode)
    // volumeTracker = vol;
  };
  const stateRef = useRef();
  stateRef.current = trackGainNode;

  const compressorRef = useRef();
  compressorRef.current = compressor;

  const filterNodeRef = useRef();
  filterNodeRef.current = filterNode;

  const handleGetValue = (e) => {
    let volumeStore = parseInt(e.value);
    let vol = db2Vol(volumeStore);
    stateRef.current.gain.setValueAtTime(
      vol,
      audioCtxContainer.current.currentTime
    );
  };

  const handleUpdateQ = (e) => {
    let qStore = parseInt(e.value);
    filterNodeRef.current.Q.value = qStore;
  };

  const handleUpdateAmount = (e) => {
    let amountStore = parseInt(e.value);
    filterNodeRef.current.frequency.value = amountStore;
  };

  const handleUpdateRate = (e) => {
    // let rateStore = parseInt(e.value)
    console.log("Those app");
  };

  const handleUpdateDepth = (e) => {
    //   let depthStore = parseInt(e.value)
    console.log("Those app");
  };

  const handleUpdateGain = (e) => {
    let gainStore = parseInt(e.value);
    let upGain = db2Vol(gainStore);
    stateRef.current.gain.setValueAtTime(
      upGain,
      audioCtxContainer.current.currentTime
    );
  };

  const handleUpdateRatio = (e) => {
    let ratioStore = parseInt(e.value);
    //  let upRat = db2Vol(ratioStore);
    compressorRef.current.ratio.setValueAtTime(
      ratioStore,
      audioCtxContainer.current.currentTime
    );
  };
  const handleUpdateKnee = (e) => {
    let KneeStore = parseInt(e.value);
    //   let upAtt = db2Vol(attackStore);
    compressorRef.current.knee.setValueAtTime(
      KneeStore,
      audioCtxContainer.current.currentTime
    );
  };

  const handleUpdateAttack = (e) => {
    let attackStore = parseInt(e.value);
    let upAtt = db2Vol(attackStore);
    compressorRef.current.attack.setValueAtTime(
      upAtt,
      audioCtxContainer.current.currentTime
    );
  };

  const handleUpdateRelease = (e) => {
    let releaseStore = parseInt(e.value);
    let upRel = db2Vol(releaseStore);
    compressorRef.current.release.setValueAtTime(
      upRel,
      audioCtxContainer.current.currentTime
    );
  };
  const exportFile = () => {
    //  alert('export')

    var blob = new window.Blob([audioCtxContainer.current], {
      type: "audio/wav",
    });
    let url = window.URL.createObjectURL(blob);
    console.log("file: ", url);
    let filename = "final mix";
    saveAs(url, filename);
    audioCtxContainer.current.close();
    setIsPlaying(false);
  };

  return (
    <div className="mst__bg">
      <div className="flex__area">
        <div className="main__area">
          <h3> Master Sound</h3>
          <div className="zone__flex">
            <div className="control__tab">Save presets</div>
            <div className="control__tab">Load presets</div>
            <div className="control__tab">Import From</div>
            <div className="control__tab">Storage</div>
            <div className="control__tab">Project</div>
            <div className="control__tab">Device</div>
          </div>

          <div
            style={{
              display: valueEq || valueComp ? "none" : "grid",
              border: "1px solid #242424",
              borderRadius: "0.4rem",
              marginLeft: "1rem",
              height: "50vh",
              width: "95%",
              gridTemplateColumns: "auto auto auto auto auto auto auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "16%",
                color: "#7A7A7A",
                marginTop: "-0.5rem",
              }}
            >
              <div style={{ marginBottom: "2.5rem" }}>Db</div>
              <div style={{ marginBottom: "2.5rem" }}>-20</div>
              <div style={{ marginBottom: "2.5rem" }}>-40</div>
              <div style={{ marginBottom: "2.5rem" }}>-60</div>
              <div style={{ marginBottom: "2.5rem" }}>-80</div>
              <div>-100</div>
            </div>
            <div
              style={{
                position: "absolute",
                padding: "10px",
                borderRight: "1px solid #242424",
                height: "25%",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                marginLeft: "5%",
                padding: "10px",
                borderRight: "1px solid #242424",
                height: "25%",
              }}
            ></div>
            <div
              style={{
                padding: "10px",
                marginLeft: "15%",
                borderRight: "1px solid #242424",
                position: "absolute",
                height: "25%",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                marginLeft: "25%",
                padding: "10px",
                borderRight: "1px solid #242424",
                height: "25%",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                marginLeft: "35%",
                padding: "10px",
                borderRight: "1px solid #242424",
                height: "25%",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                marginLeft: "45%",
                padding: "10px",
                borderRight: "1px solid #242424",
                height: "25%",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                marginLeft: "55%",
                padding: "10px",
                height: "25%",
                borderLeft: "1px solid #242424",
              }}
            ></div>
            <EQ id="dynamicallyResizingEq" dynResize={true} eq={eqModel} />

            {/* <div style={{padding:'10px',borderRight:'1px solid #242424'}}>1</div>
                    
                        <div style={{padding:'10px',borderRight:'1px solid #242424'}}>2</div>
                        <div style={{padding:'10px',borderRight:'1px solid #242424'}}>3</div>
                        <div style={{padding:'10px',borderRight:'1px solid #242424'}}>4</div>
                        <div style={{padding:'10px',borderRight:'1px solid #242424'}}>5</div>
                        <div style={{padding:'10px',borderRight:'1px solid #242424'}}>6</div>
                        <div style={{padding:'10px'}}>7</div> */}
            <div
              style={{
                background: "#242424",
                width: "30%",
                height: "12vh",
                marginLeft: "20%",
                position: "absolute",
                borderRadius: "0.4rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "35%",
                    height: "12vh",
                    borderRight: "1px solid #171717",
                  }}
                >
                  <FiMusic color="#FCAA1C" />
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "25%", marginLeft: "10%" }}>
                      <Slider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="16"
                        width="2"
                        min={qMinVal}
                        max={qMaxVal}
                        value="10"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        update={(e) => handleUpdateQ(e)}
                        tooltipFormat="changeTooltipQ"
                      />
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Q
                      </span>
                    </div>
                    <div>
                      <Slider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="16"
                        min={minVal}
                        max={maxVal}
                        width="2"
                        value={sliderValue}
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        update={(e) => handleGetValue(e)}
                        tooltipFormat="changeTooltipVolume"
                      />
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Volume
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ width: "30%" }}>
                  <div style={{ padding: "0.3rem" }}>
                    <BsSpeedometer2 />
                  </div>
                  <div style={{ borderTop: "1px solid #171717" }}>
                    <div style={{ marginLeft: "3rem", marginTop: "0.3rem" }}>
                      <SmallSlider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="13"
                        width="2"
                        min={amMinVal}
                        max={amMaxVal}
                        value="0"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        update={(e) => handleUpdateAmount(e)}
                        tooltipFormat="changeTooltipAmount"
                      />
                      <span
                        style={{
                          fontSize: "0.5rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Amount
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "35%",
                    borderLeft: "1px solid #171717",
                    fontSize: "0.7rem",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ padding: "1rem" }}>
                      <div
                        style={{
                          color: "#FCAA1C",
                          border: "1px solid #FCAA1C",
                          borderRadius: "50%",
                          paddingLeft: "0.2rem",
                          width: "1rem",
                          height: "1rem",
                        }}
                      >
                        E
                      </div>
                    </div>
                    <div style={{ color: "#7A7A7A", marginTop: "1rem" }}>
                      Modulate
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "3rem", marginRight: "0.5rem" }}>
                      <SmallSlider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="13"
                        width="2"
                        value="20"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        tooltipFormat="changeTooltipRate"
                        update={(e) => handleUpdateRate(e)}
                      />
                      <span
                        style={{
                          fontSize: "0.5rem",
                          color: "#7A7A7A",
                          position: "absolute",
                          marginLeft: "0.3rem",
                        }}
                      >
                        Rate
                      </span>
                    </div>
                    <div>
                      <SmallSlider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="13"
                        width="2"
                        value="10"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        tooltipFormat="changeTooltipDepth"
                        update={(e) => handleUpdateDepth(e)}
                      />
                      <span
                        style={{
                          fontSize: "0.5rem",
                          color: "#7A7A7A",
                          position: "absolute",
                          marginLeft: "0.3rem",
                        }}
                      >
                        Depth
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                color: "#7A7A7A",
                right: "14%",
                marginTop: "-0.5rem",
              }}
            >
              <div style={{ marginBottom: "2.5rem" }}>Db</div>
              <div style={{ marginBottom: "2.5rem" }}>12</div>
              <div style={{ marginBottom: "2.5rem" }}>6</div>
              <div style={{ marginBottom: "2.5rem" }}>0</div>
              <div style={{ marginBottom: "2.5rem" }}>-6</div>
              <div>Hz</div>
            </div>
          </div>

          <div
            style={{
              display: valueComp ? "grid" : "none",
              border: "1px solid #242424",
              borderRadius: "0.4rem",
              marginLeft: "1rem",
              height: "50vh",
              width: "95%",
              gridTemplateRows: "auto auto auto auto auto auto auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "16%",
                color: "#7A7A7A",
                marginTop: "-0.5rem",
              }}
            >
              <div style={{ marginBottom: "2.5rem" }}>Db</div>
              <div style={{ marginBottom: "2.5rem" }}>-20</div>
              <div style={{ marginBottom: "2.5rem" }}>-40</div>
              <div style={{ marginBottom: "2.5rem" }}>-60</div>
              <div style={{ marginBottom: "2.5rem" }}>-80</div>
              <div>-100</div>
            </div>
            <div style={{ padding: "10px", borderBottom: "1px solid #242424" }}>
              1
            </div>
            <div style={{ padding: "10px", borderBottom: "1px solid #242424" }}>
              2
            </div>
            <div style={{ padding: "10px", borderBottom: "1px solid #242424" }}>
              3
            </div>
            <div style={{ padding: "10px", borderBottom: "1px solid #242424" }}>
              4
            </div>
            <div style={{ padding: "10px" }}>5</div>
            <div
              style={{
                background: "#242424",
                width: "25%",
                height: "12vh",
                marginTop: "5%",
                marginLeft: "20%",
                position: "absolute",
                borderRadius: "0.4rem",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "35%",
                    height: "12vh",
                    borderRight: "1px solid #171717",
                  }}
                >
                  <FiMusic color="#FCAA1C" />
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "1rem", marginLeft: "10%" }}>
                      <Slider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="20"
                        width="2"
                        min={attMinVal}
                        max={attMaxVal}
                        value="0"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        tooltipFormat="changeTooltipAttack"
                        showTooltip="false"
                        update={(e) => handleUpdateAttack(e)}
                      />
                      <span
                        style={{
                          marginRight: "1rem",
                          fontSize: "0.7rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Attack
                      </span>
                    </div>
                    <div>
                      <Slider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="20"
                        width="2"
                        min={relMinVal}
                        max={relMaxVal}
                        value="0"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        tooltipFormat="changeTooltipRelease"
                        showTooltip="false"
                        update={(e) => handleUpdateRelease(e)}
                      />
                      <span
                        style={{
                          fontSize: "0.7rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Release
                      </span>
                    </div>
                  </div>
                </div>
                <div style={{ width: "55%" }}>
                  <div style={{ display: "flex", marginTop: "1.5rem" }}>
                    <div style={{ marginRight: "1rem", marginLeft: "10%" }}>
                      <Slider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="20"
                        width="2"
                        min={ratMinVal}
                        max={ratMaxVal}
                        value="0"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        tooltipFormat="changeTooltipRatio"
                        update={(e) => handleUpdateRatio(e)}
                      />
                      <span
                        style={{
                          marginLeft: "0.3rem",
                          fontSize: "0.7rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Ratio
                      </span>
                    </div>
                    <div style={{ marginRight: "1rem" }}>
                      <Slider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="20"
                        width="2"
                        min={gainMinVal}
                        max={gainMaxVal}
                        value="15"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        tooltipFormat="changeTooltipGain"
                        update={(e) => handleUpdateGain(e)}
                      />
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          fontSize: "0.7rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Gain
                      </span>
                    </div>
                    <div style={{ marginRight: "1rem" }}>
                      <Slider
                        sliderType="min-range"
                        editableTooltip="false"
                        radius="20"
                        width="2"
                        min={kneeMinVal}
                        max={kneeMaxVal}
                        value="35"
                        handleSize="0"
                        handleShape="square"
                        circleShape="pie"
                        startAngle="315"
                        showTooltip="false"
                        tooltipFormat="changeTooltipKnee"
                        update={(e) => handleUpdateKnee(e)}
                      />
                      <span
                        style={{
                          marginLeft: "0.5rem",
                          fontSize: "0.7rem",
                          color: "#7A7A7A",
                          position: "absolute",
                        }}
                      >
                        Knee
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                color: "#7A7A7A",
                right: "14%",
                marginTop: "-0.5rem",
              }}
            >
              <div style={{ marginBottom: "2.5rem" }}>Db</div>
              <div style={{ marginBottom: "2.5rem" }}>12</div>
              <div style={{ marginBottom: "2.5rem" }}>6</div>
              <div style={{ marginBottom: "2.5rem" }}>0</div>
              <div style={{ marginBottom: "2.5rem" }}>-6</div>
              <div>Hz</div>
            </div>
          </div>

          {/* eq controls */}
          <div
            className="control"
            style={{ listStyleType: "none", display: "flex" }}
          >
            <input
              className="file-input"
              type="file"
              accept=".mp3,.wav"
              ref={ref}
              onChange={onFileChange}
            />

            <audio
              style={{ display: "none" }}
              id="track"
              src={file}
              controls
            ></audio>
            <div
              style={{
                marginTop: "0.5rem",
                padding: "0.3rem 0.4rem",
                borderRadius: "0.4rem",
                background: "#BA68C8",
                border: "1px solid #FFB74D",
                paddingLeft: "1rem",
                marginRight: "1rem",
              }}
              onClick={onPlayPause}
            >
              play/pause
            </div>
            <div
              style={{
                marginTop: "0.5rem",
                padding: "0.3rem 0.4rem",
                borderRadius: "0.4rem",
                background: "#BA68C8",
                border: "1px solid #FFB74D",
                paddingLeft: "1rem",
              }}
              id="mute"
              onClick={onMute}
            >
              mute
            </div>
          </div>

          <div style={{ padding: "1rem" }}>
            <div style={{ background: "#7A7A7A", height: "15vh" }}>
              <div style={{ height: "7.5vh" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "15%", textAlign: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "2rem",
                        height: "1.5rem",
                      }}
                    >
                      <div
                        style={{
                          borderTop: "1px solid #3B3B3B",
                          width: "1.5rem",
                          marginTop: "1rem",
                          height: "1px",
                        }}
                      ></div>{" "}
                      <div>
                        <MdArrowDropUp
                          style={{ fontSize: "2rem" }}
                          color="#3B3B3B"
                        />
                      </div>{" "}
                      <div
                        style={{
                          borderTop: "1px solid #3B3B3B",
                          width: "1.5rem",
                          marginTop: "1rem",
                        }}
                      ></div>
                    </div>

                    <div
                      style={{
                        cursor: "pointer",
                        marginLeft: "2.5rem",
                        fontSize: "0.7rem",
                        color: "#0D0D0D",
                        borderRadius: "0.6rem",
                        border: "1px solid #0D0D0D",
                        width: "4rem",
                      }}
                    >
                      Limiter
                    </div>
                  </div>
                  <div style={{ width: "60%" }}>...</div>
                  <div style={{ width: "25%" }}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "0.7rem",
                        color: "#0D0D0D",
                        marginTop: "1.4rem",
                      }}
                    >
                      <div
                        style={{
                          background: "#636363",
                          borderTopLeftRadius: "0.5rem",
                          borderBottomLeftRadius: "0.5rem",
                          marginRight: "0.4rem",
                          padding: "0.2rem",
                        }}
                      >
                        ByPass
                      </div>
                      <div
                        style={{
                          background: "#636363",
                          borderTopRightRadius: "0.5rem",
                          borderBottomRightRadius: "0.5rem",
                          padding: "0.2rem",
                        }}
                      >
                        Match
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ height: "7.5vh" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "15%", textAlign: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "2rem",
                        height: "1.5rem",
                      }}
                    >
                      <div
                        style={{
                          borderTop: "1px solid #3B3B3B",
                          width: "1.5rem",
                          marginTop: "1rem",
                          height: "1px",
                        }}
                      ></div>{" "}
                      <div>
                        <MdArrowDropDown
                          style={{ fontSize: "2rem" }}
                          color="#3B3B3B"
                        />
                      </div>{" "}
                      <div
                        style={{
                          borderTop: "1px solid #3B3B3B",
                          width: "1.5rem",
                          marginTop: "1rem",
                        }}
                      ></div>
                    </div>

                    <div
                      style={{
                        cursor: "pointer",
                        marginLeft: "2.5rem",
                        fontSize: "0.7rem",
                        color: "#0D0D0D",
                        borderRadius: "0.6rem",
                        border: "1px solid #0D0D0D",
                        width: "4rem",
                      }}
                    >
                      AVC
                    </div>
                  </div>
                  <div style={{ width: "60%" }}>...</div>
                  <div style={{ width: "25%" }}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "0.7rem",
                        color: "#0D0D0D",
                      }}
                    >
                      <div style={{ marginRight: "2rem" }}>Pan</div>
                      <div>Width</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        color: "#0D0D0D",
                        fontSize: "0.7rem",
                      }}
                    >
                      <div
                        style={{
                          background: "#3B3B3B",
                          width: "5vh",
                          border: "1px solid #0D0D0D",
                          marginRight: "1rem",
                        }}
                      >
                        C
                      </div>
                      <div
                        style={{
                          background: "#3B3B3B",
                          width: "5vh",
                          border: "1px solid #0D0D0D",
                        }}
                      >
                        0
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="side__area">
          <div
            onClick={() => exportFile()}
            style={{
              marginTop: "0.5rem",
              padding: "0.3rem 0.4rem",
              borderRadius: "0.4rem",
              background: "#BA68C8",
              border: "1px solid #FFB74D",
              paddingLeft: "1rem",
            }}
          >
            <BsDownload /> <span style={{ marginLeft: "0.5rem" }}>Export</span>
          </div>
          <div
            style={{ marginTop: "1rem", marginLeft: "1rem", color: "#242424" }}
          >
            <div className="side__mix__ctrl">
              <div style={{ paddingTop: "0.3rem", marginBottom: "-0.3rem" }}>
                <RiCoinsLine />
              </div>
              <span style={{ marginTop: "-0.3rem" }}>EQ</span>
              <div>
                <CgPiano style={{ fontSize: "1.7rem" }} />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "0.5rem" }}>Mix</span>
                <SwitchMix
                  isOn={valueEq}
                  idVal="eq"
                  onColor="#FCAA1C"
                  handleToggle={() => setValueEq(!valueEq)}
                />
              </div>
            </div>
            <div className="side__mix__ctrl">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.3rem",
                }}
              >
                <IoIosClose />
                <RiCoinsLine />
                <div className="icon__e">E</div>
              </div>
              <span>Compressor</span>
              <div style={{ marginBottom: "-0.5rem" }}>
                <SiAudiomack />
              </div>
              <div>
                <BiGridHorizontal color="#C4C4C4" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "0.5rem" }}>Mix</span>
                <SwitchMix
                  isOn={valueComp}
                  idVal="compressor"
                  onColor="#FCAA1C"
                  handleToggle={() => setValueComp(!valueComp)}
                />
              </div>
            </div>
            <div className="side__mix__ctrl">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.3rem",
                }}
              >
                <IoIosClose />
                <RiCoinsLine />
                <div className="icon__e">E</div>
              </div>
              <span>Saturation</span>
              <div style={{ marginBottom: "-0.5rem" }}>
                <BiEqualizer />
              </div>
              <div>
                <BiGridHorizontal color="#C4C4C4" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "0.5rem" }}>Mix</span>
                <SwitchMix
                  isOn={valueSat}
                  idVal="saturation"
                  onColor="#FCAA1C"
                  handleToggle={() => setValueSat(!valueSat)}
                />
              </div>
            </div>
            <div className="side__mix__ctrl">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.3rem",
                }}
              >
                <IoIosClose />
                <RiCoinsLine />
                <div className="icon__e">E</div>
              </div>
              <span>Imager</span>
              <div style={{ marginBottom: "-0.5rem" }}>
                <BsFillSunFill color="#FCAA1C" />
              </div>
              <div>
                <BiGridHorizontal color="#C4C4C4" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "0.5rem" }}>Mix</span>
                <SwitchMix
                  isOn={valueIma}
                  idVal="imager"
                  onColor="#FCAA1C"
                  handleToggle={() => setValueIma(!valueIma)}
                />
              </div>
            </div>
            <div className="side__mix__ctrl">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "0.3rem",
                }}
              >
                <IoIosClose />
                <RiCoinsLine />
                <div className="icon__e">E</div>
              </div>
              <span>Limiter</span>
              <div style={{ marginBottom: "-0.5rem" }}>
                <BiRadioCircle color="#FCAA1C" />
              </div>
              <div>
                <BiGridHorizontal color="#C4C4C4" />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ marginRight: "0.5rem" }}>Mix</span>
                <SwitchMix
                  isOn={valueLim}
                  idVal="limiter"
                  onColor="#FCAA1C"
                  handleToggle={() => setValueLim(!valueLim)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundPage;
