import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export {ThemeProvider}


export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
 
  '&:hover': {
    color:'white',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  color:'#59ACFF',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'auto',
  color: theme.palette.text.secondary,
  height: 40,
  lineHeight: '40px',
}));

export const darkTheme = createTheme({ palette: { mode: 'dark' } });
export const lightTheme = createTheme({ palette: { mode: 'light' } });
