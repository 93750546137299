import React, {useState} from "react";
import "./MasterSound.css";
import AccessNavbar from "../accessNavbar/AccessNavbar";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import SoundPage from "../soundPage/SoundPage";
import {SidebarData} from "../SideMenuData";

const MasterSound = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box className="frame">
      <AccessNavbar  open={open} setOpen={setOpen} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box flex={2} className={open ? "side__bar__shake" : "side__bar"} >
        <div  className="nav_menu" open={open} setOpen={setOpen}>
        {SidebarData.map((item,index)=>{
          return(
            <li key={index} className={item.cName}>
              <Link   className={open ? "link__shake" : "link"}  to={item.path}>
                {item.icon}
                <span  className={open ? "txtside__shake" : "txtside"} >{item.title}</span>
                </Link>
              </li>
          );
        })}
          </div>
        </Box>

        <Box flex={14} className="project__page__space">
          <SoundPage />
        </Box>
      
      </Stack>
    </Box>
  );
};

export default MasterSound;
