import React from 'react';
import './modal.css';
import {AiOutlineClose} from "react-icons/ai";
import { BsCheckSquare } from 'react-icons/bs';

const TeamModal = props => {
    const [task, setTask] = React.useState(false);
    
    return(
    
        <div className={`modal ${props.team ? 'team': ''}`} onClick={props.onClose}>
            <div className='modal-team-content' onClick={e=>e.stopPropagation()}>
                <div >
                <div style={{display:task?'block':'none',width:'500px',position:'absolute',height:'400px',background:'#0D0D0D'}}><div style={{marginTop:'5rem'}}><BsCheckSquare style={{marginLeft:'13.6rem',marginBottom:'2rem' ,width:'4rem',height:'4rem',color:'#ba68c8'}}/> </div><div style={{marginBottom:'5rem',textAlign:'center'}}>Task successfully submited</div><button style={{ width:'90%', marginLeft: '2rem', background:'#ba68c8',  padding: '12px 24px 13px',  textAlign:'center', borderRadius:'8px', border:'2px solid #CC8B09',color:'white'}} onClick={()=>setTask(false)}>Back to Task</button></div>
                    <h4 style={{textAlign:'center'}}>{props.title}</h4>
                 
                </div>
                <div className='modal-body'>
                   <div>
                      
                       <p>{props.taskName}</p>
                       <div>This task is dedicated to my members in producing a sensational album in January 2023. We are passionate in this project and are likely going to deliver</div>
                       <div>Edit</div>
                       <div><input style={{padding:"12px 20px",border:'none',borderBottom:'1px solid white',boxSizing:'border-box',background:'#0D0D0D',width:'100%',color:'white'}} /></div>
                       <div style={{marginTop:'2rem'}}>team members (3)</div>
                       <div style={{display:'flex'}}>
                      <div className="sbig__">S</div>
                      <div className="pbig__">P</div>
                      <div className="vbig__">V</div>
                       </div>
                   </div>
                   <div onClick={()=>setTask(true)} className='modal-team'>{props.footText}</div>
                </div>
            </div>
        </div>
    )
}

export default TeamModal