import React from 'react';

export const HorizontalLine = ({color}) => (
    <hr
        style={{
            color:color,
            backgroundColor:color,
            border:'none',
            height:"2px",
            marginTop:"9px"
        }}
    />
)