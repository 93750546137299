import React, { useState } from 'react'
import './signupmodal.css'
import CloseIcon from '@mui/icons-material/Close';

const SignupModal = (props) => {

 return (
  <div className={`sign__modal ${props.show ? 'show' : ''}`} onClick={props.onClose}>
    <div className="sign__modal--content" onClick={e => e.stopPropagation()}>
        <div className='sign__modal--content-header'>
          <h4>Contact Us</h4>
          <CloseIcon className='sign__close--icon' onClick={props.onClose} />
        </div>
      <div className="sign__inputs">
        <input type="text" placeholder='Full Name' />
        <input type="email" placeholder='Email Address' />
        <input type="text" placeholder='Label Name' />
        <input type="text" placeholder='Role at Label' />
        <input type="text" placeholder='Estimated Number of Users' />
        <textarea name="Message" id="nessage" cols="30" rows="4" placeholder='Message (Optional)'/>
        {/* <input type="text" placeholder='LABEL' disabled /> */}
      </div>
      <a href="#" className='sign-up-tag'onClick={props.onClose}>Submit</a>
    </div>
</div>
  )
}

export default SignupModal