import React from "react";
import "./side.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

const Side = () => {
  return (
    <Box flex={1} p={2}>
      <Link to="/" className="app__logo">
        <img src={Logo} alt="logo" />
      </Link>
    </Box>
  );
};

export default Side;
