import React from "react";

const Terms = () => {
  return (
    <div className="flex flex-col space-y-5">
      <p className=" font-medium text-2xl ">Terms and Conditions</p>

      <div className=" tracking-wider leading-relaxed space-y-3">
        <p>
        Welcome to BeatLab. The collaboration and productivity tools for music and audio prodiction is designed to help you work more efficiently with your team. By using BeatLab, you agree to the following terms and conditions. Please
        read them carefully before using our service:
        </p>

        <ul className="ml-3 pb-3 ">
          <li className="make--nice">Ownership and Theft Prevention</li>
          <p className="py-3">
          You own the content yo create on BeatLab, but you are responsible for ensuring that any material you upload does not violate copyright or other intellectual property laws. BeatLab reserves the right to remove any material that
          we believe infringes on someone else's intellectual property. Additionally, BeatLab does not tolerate any form of theft, piracy or illegal distribution of audio or music files. Any user found engaging in succ activities will be
          immediately banned from the platform.
          </p>
          <li className="make--nice">Prohibited Use</li>

          <p className="py-3">
          Users are prohibited from using the BeatLab app for any illegal, unauthorized or inappropriate purposes. Inapppropriate use includes, but is not limite to:
          </p>
          <ul className="pb-3 ml-6">
            <li className="make--sure">
              Harassment or discrimination of other users
            </li>
            <li className="make--sure">
              Posting inappropriate or offensive content
            </li>
            <li className="make--sure">
            Using BeatLab to distribute malicious software or engage in hacking activites
            </li>

            <li className="make--sure">
            Engaging in any activites that violate the privacy of other users
            </li>
          </ul>

          <li className="make--nice">User Conduct</li>
          <p className="py-3">
          Any user found to be engaging in any form of misconduct on the BeatLab app will be immediately suspended and may be reported to the appropriate authorities. Misconduct includes, but is not
          limited to:
            <ul className="pb-3 ml-6">
              <li className="make--sure">
                Bullying, harassing, or threatening other users
              </li>
              <li className="make--sure">
                Creating multiple accounts to engage in fraudulent activities
              </li>
              <li className="make--sure">
                Disrupting the BeatLab community through spam or other malicious activities
              </li>
            </ul>
          </p>

          <li className="make--nice">Privacy and Data Protection</li>
          <p className="py-3">
          BeatLab values your privacy and is commited to protecting your personal information. We collect only the data we need to provide you with the best experience possible, and we never sell or share your information with
          third parties. We use industry standard encryption to protect your data, and we regularly review and update our security measures to ensure the highest level of protection. Please refere to the 
          Privacy and safety section for more information
          </p>
          <li className="make--nice">Termination</li>
          <p>
          BeatLab reserves the right to terminate your account at any time with or without notice, if we believe that you have violated any of these terms and conditions.
          </p>
        </ul>

        <p></p>
      </div>
    </div>
  );
};

export default Terms;
