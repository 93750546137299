import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const UserEfficiency = ({ efficiency }) => {
  let bgColor = ["#59ACFF"];

  if (efficiency <= 40) {
    // poor
    bgColor = ["#59ACFF"];
  } else if (efficiency > 40 && efficiency <= 80) {
    // average
    bgColor = ["#FEB077"];
  } else if (efficiency > 80 && efficiency <= 100) {
    // excellent
    bgColor = ["#51D187"];
  }

  const options1 = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 18,
          },
        },
      },
    },
    responsive: true,
    cutout: "87%",
    maintainAspectRatio: false,
  };

  const userData = {
    datasets: [
      {
        data: efficiency ? [efficiency] : [0],
        backgroundColor: bgColor,
        // hoverBackgroundColor: ["rgba(89,172,255,0.5)"],
      },
    ],

    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2,
      },
    },
    text: "23%",
  };

  return <Doughnut data={userData} options={options1} />;
};

export default UserEfficiency;
