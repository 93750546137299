import React, { useCallback, useEffect, useState } from "react";
import "./BudgetDetails.css";
import { Box } from "@mui/material";
import { SetBudgetModal, EditExpense } from "../Modals";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import { sortResponse } from "../../util.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const BudgetDetails = () => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showModal, setModal] = useState(false);
  const [project, setProject] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [r, setR] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [spent, setSpent] = useState(0);
  const [usageText, setUsageText] = useState("");
  const { id } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
      "Content-Type": "application/json",
    },
  };
  const calAvailable = useCallback(
    (expenses) => {
      const totalAvailable = expenses.reduce(
        (acc, curr) => acc + Number(curr.spentBudget),
        0
      );
      setSpent(totalAvailable);
    },
    [r]
  );

  const getProject = async (id) => {
    try {
      const response = await axios.get(`/project/${id}`, config);
      setProject(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTasks = useCallback(async (id) => {
    try {
      const response = await axios.get(`/task/all/${id}`, config);
      setTasks(response.data);
    } catch (error) {
      toast.error(error.message ? error?.message : "Failed");
      console.log(error);
    }
    setLoading(true);
  }, []);

  const getExpenses = useCallback(
    async (id) => {
      try {
        const response = await axios.get(
          `/budgetexpense/expenses/${id}`,
          config
        );

        setExpenses(sortResponse(response.data));
        calAvailable(response.data);
      } catch (error) {
        toast.error(error.message ? error?.message : "Failed");
        console.log(error);
      }
    },
    [expenses]
  );

  useEffect(() => {
    getTasks(id);
    getExpenses(id);
  }, [r]);

  useEffect(() => {
    getProject(id);
  }, [id]);

  const newData = {
    labels: expenses.map((item) => item.name),
    datasets: [
      {
        label: "Project Expenses",
        data: expenses.map((item) => item.spentBudget),
        backgroundColor: [
          "#C16DEE",
          "#59ACFF",
          "#E8BF3A",
          "orange",
          "green",
          "#51D187",
          "red",
          "brown",
          "#FEB077",
          "#493a52",
          "#ba8f70",
          "#877458",
        ],
        borderColor: [
          "#C16DEE",
          "#59ACFF",
          "#E8BF3A",
          "orange",
          "green",
          "#51D187",
          "red",
          "brown",
          "#FEB077",
          "#493a52",
          "#ba8f70",
          "#877458",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Calculates the Total amount spent from the bugdet
  const percentSpent = () => {
    const value = parseInt(Math.ceil((spent / project.budget) * 100));

    if (value === 0) {
      setUsageText("You haven’t spent any of your budget for this project.");
    } else if (value > 0 && value < 50) {
      setUsageText(
        "You've spent less than half of your budget for this project."
      );
    } else if (value === 50) {
      setUsageText("You've spent all of your budget for this project.");
    } else if (value > 50 && value < 100) {
      setUsageText(
        "You've spent more than half of your budget for this project."
      );
    } else if (value === 100) {
      setUsageText("You've spent all of your budget for this project.");
    } else if (value > 100) {
      setUsageText("You've exhausted your budget for this project.");
    }

    return value;
  };

  useEffect(() => {
    percentSpent();
  }, [spent, project.budget]);

  return (
    <Box flex={11} className="main__page">
      <div className="project__page">
        <div className="project__header">
          <p
            style={{
              textTransform: "capitalize",
            }}
          >
            {project.name} Budget Summary
          </p>
          <div className="project__header__buttons">
            <div
              style={{
                boxShadow: `var(--button-shadow)`,
              }}
              className="px-4 py-2 text-center  text-white hover:bg-[#8d2a9e] duration-200 ease-in-out bg-[#ba68c8] rounded-md"
              onClick={() => {
                setModal(true);
              }}
            >
              Set Budget
            </div>

            <SetBudgetModal
              onClose={() => {
                setModal(false);
              }}
              rr={() => getProject(id)}
              show={showModal}
              data={project}
            />
          </div>
        </div>
        <div className="expense__tracking">
          <div className="header">
            <div>EXPENSE TRACKING </div>
          </div>

          <div className="sub__head">
            <div className="budget">
              <p>BUDGET</p>
              <p> {project.budget || 0}</p>
            </div>

            <div className="budget">
              <p>AVAILABLE</p>
              <p className="available">
                {Number(parseInt(project?.budget) - spent) || 0}
              </p>
            </div>

            <div className="budget">
              <p>SPENT</p>
              <p className="spent">{spent || 0}</p>
            </div>
          </div>

          <div className="expenses__details">
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  backgroundColor: "#151517",
                  color: "#fff",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell color="white">
                      <h3>Tasks</h3>
                    </TableCell>
                    <TableCell align="right">
                      <h3>Budget</h3>
                    </TableCell>
                    <TableCell align="right">
                      <h3>Available</h3>
                    </TableCell>
                    <TableCell align="right">
                      <h3>Spent</h3>
                    </TableCell>
                    <TableCell align="right">
                      <h3></h3>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {!loading || tasks.length === 0 ? ( //TODO: USE SKELETON LOADER ON THE TABLE FOR A BETTER UX
                  !loading ? (
                    <TableBody>
                      {[0, 0, 0].map((item, index) => (
                        <TableRow className="animate-pulse" key={index}>
                          <TableCell component="th" scope="row">
                            <p className="h-4 w-32 bg-slate-500 rounded-md"></p>
                          </TableCell>
                          <TableCell align="right">
                            <p className="h-4 bg-slate-500 rounded-md"></p>
                          </TableCell>
                          <TableCell align="right">
                            <p className="h-4 bg-slate-500 rounded-md"></p>
                          </TableCell>
                          <TableCell align="right">
                            <p className="h-4 bg-slate-500 rounded-md"></p>
                          </TableCell>
                          <TableCell align="right">
                            <p className="h-7 bg-slate-500 rounded-md"></p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <p className="p-5 text-lg">
                      There are no tasks on this project
                    </p>
                  )
                ) : (
                  <TableBody>
                    {tasks.map((row, index) => {
                      const findItem = expenses.find(
                        (item) => item.name === row.title
                      );
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.title}
                          </TableCell>
                          <TableCell align="right"> {row.budget}</TableCell>
                          <TableCell align="right" className="available">
                            {findItem ? findItem.availableBudget : row.budget}
                          </TableCell>
                          <TableCell align="right" className="spent">
                            {findItem ? findItem.spentBudget : 0}
                          </TableCell>
                          <TableCell align="right" className="activity">
                            <EditButton
                              info={{
                                name: row.title,
                                budget: row.budget,
                                spent: findItem ? findItem.spentBudget : 0,
                                pId: id,
                                tId: row.id,
                              }}
                              newEx={findItem ? null : "new"}
                              rr={() => setR(!r)}
                              exId={findItem ? findItem.id : null}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
          <div className="budget__usage">
            <div className="left__side">
              <h3>BUDGET USAGE</h3>
              <p>{usageText}</p>
              <div className="details">
                <div className="budget">
                  <p>Amount</p>
                  <p className="spent">{project.budget || 0}</p>
                </div>
                <div className="budget">
                  <p>Percentage</p>
                  <p>
                    <span className="spent">
                      {isNaN((spent / project.budget) * 100)
                        ? 0
                        : ((spent / project.budget) * 100).toFixed()}
                      %
                    </span>{" "}
                    used
                  </p>
                </div>
              </div>
            </div>

            <div className="right__side">
              <Pie data={newData} />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default BudgetDetails;

const EditButton = ({ info, rr, newEx, exId }) => {
  const [openExpenseModal, setOpenExpenseModal] = useState(false);

  return (
    <div>
      <button
        className="px-4 py-2 text-center  text-white hover:scale-105 duration-200 ease-in-out bg-[#ba68c8] rounded-md"
        onClick={() => setOpenExpenseModal(true)}
      >
        Edit
      </button>

      <EditExpense
        show={openExpenseModal}
        onClose={() => setOpenExpenseModal(!openExpenseModal)}
        name={info.name}
        budget={info.budget}
        spent={info.spent}
        projectId={info.pId}
        taskId={info.tId}
        rr={rr}
        newEx={newEx}
        exId={exId}
      />
    </div>
  );
};
