import toast from "react-hot-toast";
import Cookies from "js-cookie";
import axios, { firstVersion } from "../api/axios";
import MicRecorder from "mic-recorder-to-mp3";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const tString = Cookies.get("auth");
const userAuth = tString ? JSON.parse(tString) : {};

export const config = {
  headers: {
    Authorization: `Bearer ${userAuth?.authToken}`,
    "Content-Type": "application/json",
  },
};

export const convertDate = (dateStr) => {
  const date = new Date(dateStr);

  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour12: false,
  });
};

export const handleFile = (file) => {
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      return reader.result;
    };
    reader.onerror = (error) => reject(error);
  });
};
// GET ALL OORGANIZATION
export const getAllOrgs = async () => {
  try {
    const resp = await axios.get(`/organizations`, config);

    return resp.data;
  } catch (error) {
    console.log(error);
    return;
  }
};

// GET A ORGANIZATION
export const getSingleOrg = async (orgId) => {
  try {
    const resp = await axios.get(`/organizations/${orgId}`, config);

    return resp;
  } catch (error) {
    console.log(error);
  }
  return;
};

// GET ALL PROJECTS IN AN ORGANIZATION
export const getOrgProjects = async (orgId, userId, type, gConfig) => {
  try {
    // const resp = await axios.get(
    //   `/project/all_organization_project/${orgId}/${userId}`,
    //   config
    // );
    // let resp;
    // if (type === "SUPER_ADMIN") {
    //   resp = await axios.get(
    //     `/project/all_organization_project/${orgId}`,
    //     config
    //   );
    // } else {
    //   resp = await axios.get(`/project/invitedProjects/${userId}`, config);
    // }

    const resp = await axios.get(`/project/invitedProjects/${userId}`, gConfig);

    return resp;
  } catch (error) {
    console.log(error);
    if (error.response) {
      return { status: error.response.data.message, data: [] };
    }
  }
  return;
};

// EXTRACT PROJECTS IN AN ORGANIZATION
export const getExtractProjects = async (orgId) => {
  try {
    const resp = await axios.get(`/project/${orgId}`, config);

    return resp;
  } catch (error) {
    console.log(error);
  }
  return;
};

// GET ALL CAVE PROJECT
export const getCaveProjects = async (orgId) => {
  try {
    const resp = await axios.get(`/cave/all/${orgId}`, config);

    return resp;
  } catch (error) {
    console.log(error);
  }
  return;
};
// GET CAVE FILE
export const getCaveSingleProjects = async (fid) => {
  try {
    const resp = await axios.get(`/cave/${fid}`, config);

    return resp;
  } catch (error) {
    console.log(error);
  }
  return;
};
// Gets all the members of the currently selected organization
export const getAllMembers = async (orgId) => {
  const resp = await firstVersion.get(`/users/all/${orgId}`, config);

  return resp.data;
};

// GET ALL MEMBERS OF A PROJECT
export const getAllMembersOfProject = async (projId) => {
  try {
    const resp = await axios.get(`/project/project_memebrs${projId}`, config);

    return resp;
  } catch (error) {
    console.log(error);
  }
  return;
};

// Getting item details using the project ID
export const getItem = async (path, id) => {
  try {
    const response = await axios.get(`${path}/${id}`, config);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Deleting an item using the project ID
export const delItem = async (path, id) => {
  try {
    const response = await axios.delete(`${path}/${id}`, config);

    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    toast.error("Failed!");
    console.log(error);
    return error;
  }
};

// Deleting a Folder using the  ID
export const delFolder = async (path, id) => {
  try {
    const response = await axios.delete(`${path}/${id}`, config);

    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    toast.error("Failed!");
    console.log(error);
    return error;
  }
};

// Deleting an Organization by using the  ID
export const delOrg = async (path, id) => {
  try {
    const response = await axios.delete(`${path}/${id}`, config);

    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    toast.error("Failed!");
    console.log(error);
    return error;
  }
};

// Truncating text
export const truncate = (text) => {
  text.replace(/\s+/g, " ");

  if (text.length > 80) {
    const newStr = text.substring(0, 80) + "...";
    return newStr;
  } else {
    return text;
  }
};

// CALCULATES PROJECT/TASK PROGRESS
export const progress = (total, completed) => {
  const percentage = (completed / total) * 100;

  return percentage;
};

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
export const askPermission = (setB) => {
  navigator.getUserMedia(
    { audio: true },
    () => {
      toast.success("Permission Granted!");
      setB(false);
    },
    () => {
      toast.error("Permission Denied");
      setB(true);
    }
  );
};

export const startRecord = (blocked, setR) => {
  if (blocked) {
    toast.success("Permission Denied");
  } else {
    Mp3Recorder.start()
      .then(() => {
        setR(true);
      })
      .catch((e) => console.log(e));
  }
};

export const endRecord = (setBlob, setR) => {
  Mp3Recorder.stop()
    .getMp3()
    .then()(([buffer, blob]) => {
      const theBlob = URL.createObjectURL(blob);
      setBlob(theBlob);
      setR(false);
    })
    .catch((e) => console.log(e));
};

// SORTING RESPONSE DATA DUE TO DATE UPDATED
export const sortResponse = (data) => {
  return data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
};

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#353535",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#51D187",
  },
}));

export const LessLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#353535",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#ED6F69",
  },
}));

// POST COMMENT
export const postComment = async (path, credentials) => {
  try {
    const response = await axios.post(
      `${path}/comment`,
      JSON.stringify(credentials),
      config
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// GET COMMENTS
export const getComments = async (path) => {
  try {
    const response = await axios.get(`${path}/comment`, config);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// Convert Image to base64
export const convertToBase64 = (file) => {
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
      // console.log(reader.result);
      return reader.result;
    };
    reader.onerror = (error) => reject(error);
  });
};

// Calculate time left
export const timeLeft = (start, end) => {
  const startTimestamp = new Date(start).getTime();
  const endTimestamp = new Date(end).getTime();

  const secondsLeft = (endTimestamp - startTimestamp) / 1000;

  const minutesLeft = Math.floor(secondsLeft / 60);
  const hoursLeft = Math.floor(minutesLeft / 60);
  const daysLeft = Math.floor(hoursLeft / 24);

  const timeLeft = {
    days: daysLeft,
    hours: hoursLeft % 24,
    minutes: minutesLeft % 60,
    seconds: Math.floor(secondsLeft % 60),
  };

  return timeLeft;
};

export const timeFormatOnReportSection = (item) => {
  let displayDate;
  let endDate;
  let dateObj = new Date(item.startDate);
  let dateEnd = new Date(item.endDate);
  let month = dateObj.getUTCMonth() + 1;
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();
  let Endmonth = dateEnd.getUTCMonth() + 1;
  let Endday = dateEnd.getUTCDate();
  let Endyear = dateEnd.getUTCFullYear();
  if (month === 9) {
    displayDate = day + " Sep, " + year;
  } else if (month === 1) {
    displayDate = day + " Jan, " + year;
  } else if (month === 2) {
    displayDate = day + " Feb, " + year;
  } else if (month === 3) {
    displayDate = day + " Mar, " + year;
  } else if (month === 4) {
    displayDate = day + " Apr, " + year;
  } else if (month === 5) {
    displayDate = day + " May, " + year;
  } else if (month === 6) {
    displayDate = day + " Jun, " + year;
  } else if (month === 7) {
    displayDate = day + " Jul, " + year;
  } else if (month === 8) {
    displayDate = day + " Aug, " + year;
  } else if (month === 10) {
    displayDate = day + " Oct, " + year;
  } else if (month === 11) {
    displayDate = day + " Nov, " + year;
  } else if (month === 12) {
    displayDate = day + " Dec, " + year;
  }
  // date
  if (Endmonth === 9) {
    endDate = Endday + " Sep, " + Endyear;
  } else if (Endmonth === 1) {
    endDate = Endday + " Jan, " + Endyear;
  } else if (Endmonth === 2) {
    endDate = Endday + " Feb, " + Endyear;
  } else if (Endmonth === 3) {
    endDate = Endday + " Mar, " + Endyear;
  } else if (Endmonth === 4) {
    endDate = Endday + " Apr, " + Endyear;
  } else if (Endmonth === 5) {
    endDate = Endday + " May, " + Endyear;
  } else if (Endmonth === 6) {
    endDate = Endday + " Jun, " + Endyear;
  } else if (Endmonth === 7) {
    endDate = Endday + " Jul, " + Endyear;
  } else if (Endmonth === 8) {
    endDate = Endday + " Aug, " + Endyear;
  } else if (Endmonth === 10) {
    endDate = Endday + " Oct, " + Endyear;
  } else if (Endmonth === 11) {
    endDate = Endday + " Nov, " + Endyear;
  } else if (Endmonth === 12) {
    endDate = Endday + " Dec, " + Endyear;
  }

  return { displayDate, endDate };
};

export const getTimeAgo = (timestamp) => {
  const currentDate = new Date();
  const postDate = new Date(timestamp);
  const timeDifference = currentDate - postDate;

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 365 * day;

  if (timeDifference < minute) {
    const seconds = Math.floor(timeDifference / 1000);
    return seconds <= 1 ? `Just now` : `${seconds} seconds ago`;
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);
    return minutes <= 1 ? `${minutes} min ago` : `${minutes} mins ago`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);
    return hours <= 1 ? `${hours} hour ago` : `${hours} hours ago`;
  } else if (timeDifference < month) {
    const days = Math.floor(timeDifference / day);
    return days <= 1 ? `${days} day ago` : `${days} days ago`;
  } else if (timeDifference < year) {
    const months = Math.floor(timeDifference / month);
    return months <= 1 ? `${months} month ago` : `${months} months ago`;
  } else {
    const years = Math.floor(timeDifference / year);
    return years <= 1 ? `${years} year ago` : `${years} years ago`;
  }
};

// Handles downloading files with clodinary url
export const handleDownload = (url, fileName) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName || "downloaded-file";
  anchor.setAttribute("target", "_blank");

  // Trigger a click event on the anchor element
  anchor.click();
};



export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
}