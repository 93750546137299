import React, { useState } from "react";
// agora
//import {EaseApp,EaseChat } from 'agora-react-uikit'
import { CometChat } from "@cometchat-pro/chat";
// import { CometChatUI } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src"
import { CometChatUI } from "../../cometchat-pro-react-ui-kit-master/CometChatWorkspace/src";
import useAuth from "../../hooks/useAuth";
//sendbird
// import { App as SendbirdApp,
//   withSendBird,
//   sendBirdSelectors
// } from "sendbird-uikit";
// import "sendbird-uikit/dist/index.css";
// import "./chatPageApi.css";
// import toast from "react-hot-toast";
// import Button from '@mui/material/Button';
// import {FcVideoCall,FcCallback, FcEndCall} from "react-icons/fc";
// import SendBirdCall from "sendbird-calls";
// import {
//   APP_ID as appId
// } from './const';
// import { ChatModal } from "../Modals";
// import SBCall from "./Calls";

// const ChatPageApi= ()=> {
//   const [show, setShow] = React.useState(true);

//   const [message, setMessage] = React.useState(null);
//   const [initAction, setInitAction] = React.useState(null);
//   const chooseMessage = (message) => {
//     setMessage(message);
//   };
//   let chatView=
//     (
//       <SendbirdApp appId={appId} userId={message?.userId} theme="dark" showSearchIcon="true" allowProfileEdit="true" />
//   )

//   return (
//     <div className="App">
//      <h2>Welcome, {message?.nickname}   <FcVideoCall  style={{cursor:"pointer"}}  onClick={() => setInitAction("initVideo")} />   <FcCallback style={{cursor:"pointer"}}   onClick={() => setInitAction("initVoice")} /> <FcEndCall style={{cursor:"pointer"}}   onClick={() => setInitAction(null)} /></h2>
//      <div style={{position:"fixed",zIndex:"100"}}>
//      {/* <div style={{display: "inline-flex"}}>  */}
//           {initAction === "initVideo" ? <SBCall appId={appId} userId={message?.userId} initAction="initVideo"/> : ''}
//             {initAction === "initVoice" ? <SBCall appId={appId} userId={message?.userId} initAction="initVoice"/> : ''}
//       </div>
//       {show!==true?chatView
// :"booting UI"}
//      <ChatModal onClose={() => setShow(false)} show={show} chooseMessage={chooseMessage} />

//     </div>
//   );
// }

// export default ChatPageApi;

const ChatPageApi = () => {
  // const { auth } = useAuth();
  // var uid = auth.id;
  // var authKey = "a80db2e3d1ed5ce586c33d2e3f4c0d5d7de937d8";

  // CometChat.login(uid, authKey).then(
  //   (user) => {
  //     console.log("Login Successful:", { user });
  //   },
  //   (error) => {
  //     console.log("Login failed with exception:", { error });
  //   }
  // );

  return (
    <div className="h-[90vh] w-full overflow-hidden overflow-y-hidden">
      <CometChatUI />
    </div>
  );
};
export default ChatPageApi;
