import { useState } from "react";
import "./AddTaskModal.css";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";

const AddTaskModal = (props) => {
  const { auth } = useAuth();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    projectId: props.pi ? props.pi?.id : "none",
    taskMembers: "",
    status: props.stat ? props.stat : "none",
    startDate: "",
    endDate: "",
    budget: 0,
  });

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === "taskMembers") {
      let newMem = value;

      if (/\s/g.test(value)) {
        newMem = value.replace(/\s/g, "").split(",");
      } else {
        newMem = value.split();
      }

      setFormData({ ...formData, [name]: newMem });
      return;
    } else {
      setFormData({ ...formData, [name]: value });
      return;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (parseInt(formData["budget"]) > parseInt(props.availableBudget)) {
      toast.error(
        `Budget has to be within the available project bugdet of ${props.available} `,
        {
          icon: "❗",
          duration: 6000,
        }
      );
      return;
    }
    if (!props.pi && formData["projectId"] === "none") {
      toast.error("Please select a project!", { duration: 3500 });
      return;
    } else if (!props.stat && formData["status"] === "none") {
      toast.error("Please select task status!", { duration: 3500 });
      return;
    }

    setOpen(true);

    const {
      title,
      startDate,
      endDate,
      projectId,
      description,
      taskMembers,
      budget,
      status,
    } = formData;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `/task/${props.pi ? props.pi?.id : projectId}`,
        JSON.stringify({
          title,
          startDate,
          endDate,
          projectId: props.pi ? props.pi?.id : projectId,
          description,
          taskMembers,
          budget,
          status: props.stat ? props.stat : status,
        }),
        config
      );

      if (props.cc) {
        props.cc();
      }
      if (props.rr) {
        props.rr();
      }

      // if (props.getTasks) {
      //   props.getTasks(pi?.id);
      // }
      props.onClose();

      setTimeout(() => {
        setOpen(false);
      }, response);

      // if (props.getProjects) {
      //   props.getProjects();
      // }

      setFormData({
        title: "",
        description: "",
        projectId: props.pi ? props.pi.id : "none",
        taskMembers: "",
        status: props.stat ? props.stat : "none",
        startDate: "",
        endDate: "",
        budget: 0,
      });

      if (props.getTasks) {
        props.getTasks(formData["projectId"]);
      }

      toast.success(` Task Created`, {
        duration: 4000,
      });
    } catch (error) {
      setOpen(false);

      toast.error("Failed!");
      console.log(error);
    }
  };

  return (
    <div
      className={`add__product__modal ${props.show ? "show  " : ""}`}
      onClick={props.onClose}
    >
      <form
        className="add__product__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="contact__modal--content-header">
          <h4>New Task</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add__product__inputs">
          {/* Add Image input */}
          <div className="add_file_container">
            <label htmlFor="add_file" className="add_file">
              +
            </label>
            <input type="file" name="add_file" id="add_file" />
          </div>

          <label htmlFor="title">Task Title</label>
          <input
            type="text"
            name="title"
            id="title"
            onChange={handleChange}
            value={formData["title"]}
            required
          />

          <label htmlFor="projectId">Project</label>
          {props.pi ? (
            <input
              name="projectId"
              type="text"
              disabled
              value={props.pi.name}
              autoComplete="off"
            />
          ) : (
            <select
              name="projectId"
              onChange={handleChange}
              value={formData["projectId"]}
              required
            >
              <option value="none" className="hidden">
                Select a project
              </option>

              {props.projects.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          )}

          <label htmlFor="task__status">Task Status</label>
          {props.stat ? (
            <input
              name="status"
              type="text"
              disabled
              value={
                props.stat.toLowerCase() === "review"
                  ? "IN REVIEW"
                  : props.stat.toUpperCase()
              }
              autoComplete="off"
            />
          ) : (
            <select
              name="status"
              onChange={handleChange}
              value={formData["status"]}
              required
            >
              <option value="none">Select status</option>

              <option value="todo">TODO</option>
              <option value="in progress">IN PROGRESS</option>
              <option value="review">IN REVIEW</option>
            </select>
          )}

          <label htmlFor="description">Description</label>
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="4"
            value={formData["description"]}
            onChange={handleChange}
            required

            // placeholder="Message"
          />
          <div className="project__duration">
            <div>
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                name="startDate"
                id="startDate"
                value={formData["startDate"]}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>
            <div>
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                name="endDate"
                id="endDate"
                min={formData["startDate"]}
                value={formData["endDate"]}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>
          </div>

          <label htmlFor="budget">
            Budget{" "}
            <span className=" opacity-50">
              (Available budget: {props.availableBudget})
            </span>
          </label>
          <input
            type="number"
            name="budget"
            id="budget"
            min={0}
            max={parseInt(props.availableBudget)}
            value={formData["budget"]}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
        <button type="submit" className="submit-tag">
          Create
        </button>
      </form>
    </div>
  );
};

export default AddTaskModal;
