import React, { useState, useEffect, useCallback } from "react";
import "./filter.css";
import { AiOutlineClose,AiOutlinePlus } from "react-icons/ai";
//import axios from "axios";
import axios from "../../api/axios";
import { AddTaskModal } from "../Modals"
import useAuth from "../../hooks/useAuth";
import { HorizontalLine } from "../HorizontalLine";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { FaDropbox } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import ChecklistComponent from "./list/ChecklistComponent";
// import TodoMap from "./list/TodoMap";
import uniqid from "uniqid";
import {
  BsThreeDots,
  BsCalendar,
  BsEmojiSmile,
  BsCardImage,
  BsTrash,
} from "react-icons/bs";
import { darkTheme, ThemeProvider } from "./list/styleMUI.js";
import IconNaira from "../../assets/icon/naira.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Picker from "emoji-picker-react";
import IconZip from "../../assets/icon/ZIP_3.png";
import IconImg from "../../assets/icon/JPG3.png";
import toast from "react-hot-toast";
import Typography from "@mui/material/Typography";
import { AddMember } from "../Modals";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PropTypes from "prop-types";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#51D187" : "#308fe8",
  },
}));
const BorderRedProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#ED6F69" : "#308fe8",
  },
}));
const ArtistChildLetter = ({ color, letter, txtcolor }) => (
  <div
    style={{
      textAlign: "center",
      // width: "2rem",
      // height: "2rem",
      paddingTop: "0.4rem",

      fontSize: "small",
      padding: "0.6rem 0.8rem",
      textAlign:'center',
      borderRadius: "14px",
      border:"1px solid #fead77",
      color: txtcolor,
      backgroundColor: '#7a480b',
      textTransform:'uppercase'
      
    }}
    
  >
    {letter}
  </div>
);
const ArtistLetter = ({ color, letter, txtcolor }) => {
  return (
    <div
      style={{
        textAlign: "center",
        // width: "2rem",
        // height: "2rem",
        paddingTop: "0.4rem",
  
        fontSize: "small",
        padding: "0.6rem 0.8rem",
        textAlign:'center',
        borderRadius: "14px",
        border:"1px solid #c16dee",
        color: txtcolor,
        backgroundColor: '#57157a',
        textTransform:'uppercase'
      }}
    >
      {letter}
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const KEY = "todoApp.todos";
const ViewFilter = (props) => {
  const { auth } = useAuth();
  const [revealData, setRevealData] = useState([]);
  const [todoLen, settodoLen] = useState(0);
  const [todoList, setTodoList] = useState([]);
  const [boleanTodo, setBoleanTodo] = useState(false);
  const [todoId, setTodoId] = useState("");
  const [todo, setTodo] = useState("");
  const [value, setValue] = useState(0);
  const [invite, setInvite] = useState(false);
  const [projectComm, setProjectcomm] = useState([]);
  const [addComm, setAddcomm] = useState([]);
  const [showPicker, setShowPicker] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [pData, setPData] = useState();
  const [r, setR] = useState(true);
  
  const [newTask, setNewTask] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [c, setC] = useState(false);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [available, setAvailable] = useState(0);
  const [singleP, setSingleP] = useState();
  const AuthStr = "Bearer ".concat(auth?.authToken);
  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
      "Content-Type": "application/json",
    },
  }
  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  useEffect(() => {
    const storedTodos = JSON.parse(localStorage.getItem(KEY));
    if (storedTodos) {
      setTodoList(storedTodos);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(KEY, JSON.stringify(todoList));
  }, [todoList]);

  useEffect(() => {
    setRevealData(props.data);
  },[props.data]);
  const handleValChange = (event, newValue) => {
    setValue(newValue);
  };
  
// josh code 
const getAvailableBugdet = useCallback(
  (tasks) => {
    const totalAvailable = tasks.reduce(
      (acc, curr) => acc + Number(curr.budget),
      0
    );
    setAvailable(Number(props?.budget) - totalAvailable);
    return totalAvailable;
  },
  [c]
);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`/task/all/${revealData?.id}`, config);
        const resp = await axios.get(`/project/${revealData?.id}`, config);
       setSingleP(resp.data);
    // console.log(resp)
       setTasks(response.data);
       getAvailableBugdet(response.data);
       // * Get all the subtasks
        let subTasks = [];

        for (let s = 0; s < response.data.length; s++) {
          const task = response.data[s];

          if (task.subTasks && task.subTasks.length > 0) {
            task.subTasks.forEach((sub) => {
              subTasks.push(sub);
            });
          }
        }
        const completedSubtasks = subTasks.filter(
          (item) => item.status && item.status.toLowerCase() === "completed"
        );

        setProgress((completedSubtasks.length / subTasks.length) * 100);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [c, revealData?.id, config]);
  // console.log('**',revealData)
  const handleCheck = async (event, item) => {
    let { checked } = event.target;

    let status;

    if (checked) {
      // SEND POST REQUEST TO CHANGE THE STATUS OF THIS TASK TO "COMPLETED"
      status = "COMPLETED";
    } else {
      // SEND POST REQUEST TO CHANGE THE STATUS OF THIS TASK TO "IN PROGRESS"
      status = "IN PROGRESS";
    }

    setOpen(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.patch(
        `/task/${item.id}`,
        JSON.stringify({
          status,
        }),
        config
      );

      setTimeout(() => {
        setOpen(false);
      }, response);
      setC(!c);
      props.rr();
      if (checked) {
        toast.success(`${item.title} Completed`);
      } else {
        toast.success(`${item.title} In Progress`);
      }
    } catch (error) {
      setOpen(false);
      toast.error("Failed!");

      console.log(error);
    }

    if (checked) {
      return;
    } else {
      return;
    }
  };
  const delItem = async (item) => {
    setOpen(true);

    try {
      const response = await axios.delete(`/task/${item.id}`, config);

      setTimeout(() => {
        setOpen(false);
      }, response);
      setC(!c);

      props.rr();

      toast.success(`${item.title} Has Been Deleted!`);
    } catch (error) {
      setOpen(false);
      toast.error("Failed!");
      console.log(error);
    }
  };

  //end of implement

  const postComment = () => {
    let data = JSON.stringify({ name: auth.firstName, text: inputStr });

    var config = {
      method: "post",
      url: `https://www.api.beatlab.app/api/v1/auth/project/${revealData.id}/comment`,
      headers: {
        Authorization: AuthStr,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success("Commented Posted!");
        // console.log(JSON.stringify(response.data));
        try {
          var config = {
            method: "get",
            url: `https://www.api.beatlab.app/api/v1/auth/project/${revealData.id}/comment`,
            headers: {
              Authorization: AuthStr,
            },
          };

          axios(config)
            .then(function (response) {
              setAddcomm(response.data);
            })
            .catch(function (error) {
              toast.error("Error posting!");
            });
        } catch (err) {
          toast.error("check network!");
          //   console.log(err)
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong!");
        // console.log(error);
      });
  };

  const handleFileClick = () => {
    // inputRef.current.click()
    alert("click file..");
  };

  const handleMemberClick = () => {
    setPData(revealData?.id);
    
    setInvite(true);
  };

  const addTodo = (e) => {
    settodoLen(Object.keys(todoList).length + 1);
    //  console.log(todoList);
    e.preventDefault();
    if (todo === "") {
      return;
    }
    setTodoList([...todoList, { id: uniqid(), todo, tv: false }]);
    setTodo("");
  };
  const dellTodo = (id) => {
    settodoLen(Object.keys(todoList).length - 1);
    const newTodos = todoList.filter((item) => item.id !== id);

    setTodoList(newTodos);
  };

  const btnEditTodo = (item) => {
    setBoleanTodo(true);
    setTodoId(item.id);

    setTodo(item.todo);
  };

  const editTodo = (e) => {
    e.preventDefault();

    if (todo === "") {
      return;
    }

    const newTodos = todoList.map((item) =>
      item.id === todoId ? { id: uniqid(), todo, tv: false } : item
    );

    setTodoList(newTodos);
    setBoleanTodo(false);
    setTodoId("");
    setTodo("");
  };

  const cheked = (id, tv, todo) => {
    //  console.log(id, tv, todo);

    const newTodos = todoList.map((item) =>
      item.id === id ? { id: uniqid(), todo, tv } : item
    );
    // console.log(todoList)
    setTodoList(newTodos);
  };

  const colorDisplay = [
    "orange",
    "#C16DEE",
    "green",
    "#51D187",
    "red",
    "brown",
  ];

  //   date hack by drvicked
  let displayDate;
  let endDate;
  let dateObj = new Date(revealData?.startDate);
  let dateObjEnd = new Date(revealData?.endDate);
  let month = dateObj.getUTCMonth() + 1;
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();

  let monthE = dateObjEnd.getUTCMonth() + 1;
  let dayE = dateObjEnd.getUTCDate();
  let yearE = dateObjEnd.getUTCFullYear();
  if (monthE === 9) {
    endDate = dayE + " Sep, " + yearE;
  } else if (monthE === 1) {
    endDate = dayE + " Jan, " + yearE;
  } else if (monthE === 2) {
    endDate = dayE + " Feb, " + yearE;
  } else if (monthE === 3) {
    endDate = dayE + " Mar, " + yearE;
  } else if (monthE === 4) {
    endDate = dayE + " Apr, " + yearE;
  } else if (monthE === 5) {
    endDate = dayE + " May, " + yearE;
  } else if (monthE === 6) {
    endDate = dayE + " Jun, " + yearE;
  } else if (monthE === 7) {
    endDate = dayE + " Jul, " + yearE;
  } else if (monthE === 8) {
    endDate = dayE + " Aug, " + yearE;
  } else if (monthE === 10) {
    endDate = dayE + " Oct, " + yearE;
  } else if (monthE === 11) {
    endDate = dayE + " Nov, " + yearE;
  } else if (monthE === 12) {
    endDate = dayE + " Dec, " + yearE;
  }
  // begi
  if (month === 9) {
    displayDate = day + " Sep, " + year;
  } else if (month === 1) {
    displayDate = day + " Jan, " + year;
  } else if (month === 2) {
    displayDate = day + " Feb, " + year;
  } else if (month === 3) {
    displayDate = day + " Mar, " + year;
  } else if (month === 4) {
    displayDate = day + " Apr, " + year;
  } else if (month === 5) {
    displayDate = day + " May, " + year;
  } else if (month === 6) {
    displayDate = day + " Jun, " + year;
  } else if (month === 7) {
    displayDate = day + " Jul, " + year;
  } else if (month === 8) {
    displayDate = day + " Aug, " + year;
  } else if (month === 10) {
    displayDate = day + " Oct, " + year;
  } else if (month === 11) {
    displayDate = day + " Nov, " + year;
  } else if (month === 12) {
    displayDate = day + " Dec, " + year;
  }
  // use this api whe max fixes it (project comment)
  // useEffect(() => {
  //   try {
  //     var config = {
  //       method: "get",
  //       url: `https://www.api.beatlab.app/api/v1/auth/project/${revealData?.id}/comment`,
  //       headers: {
  //         Authorization: AuthStr,
  //       },
  //     };
  //     axios(config)
  //       .then(function (response) {
  //         setProjectcomm(response.data);
  //         setAddcomm([]);
  //       })
  //       .catch(function (error) {
  //         console.log("bears");
  //       });
  //   } catch (err) {
  //     console.log("background check");
  //   }
  // });

  const getComment = (e) => {
    try {
      var config = {
        method: "get",
        url: `https://www.api.beatlab.app/api/v1/auth/project/${revealData?.id}/comment`,
        headers: {
          Authorization: AuthStr,
        },
      };
      axios(config)
        .then(function (response) {
          setAddcomm(response.data);
        })
        .catch(function (error) {
          toast.error("network Error");
          // setErrorData(error.message)
        });
    } catch (err) {
      console.log(err);
    }
  };

  const trashTimeline = (e) => {
    var config = {
      method: "delete",
      url: `https://www.api.beatlab.app/api/v1/auth/project/comment/${e.id}/delete`,
      headers: {
        Authorization: AuthStr,
      },
    };

    axios(config)
      .then(function (response) {
        getComment();
        toast.success("Deleted Comment");
      })
      .catch(function (error) {
        toast.error("Something went wrong!");
      });
  };

  return (
    <div className={`those ${props.show ? "filter" : ""}`}>
      <div className="filter-content">
        <div className="those-header">
          {/* <div className="closebtn" onClick={props.onClose}>
            <AiOutlineClose />
            past
          </div> */}
        </div>
        <div className="those-body">
          <div className="parent">
            <div class="child3">
              <div style={{ width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <div className="middle__box">
                    <FaDropbox style={{ fontSize: 40, marginTop: "0.3rem" }} />
                  </div>
                  <div className="middle__item">
                    <span>{revealData?.name}</span>
                    <p>{revealData?.artistName}</p>
                  </div>
                  <div className="middle__dots">{/* <BsThreeDots /> */}</div>
                  <div onClick={props.onClose}>
                    <AiOutlineClose />
                  </div>
                </Box>

                <Box
                  style={{ marginTop: "2rem" }}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      fontSize: "0.7em",
                      marginBottom: "0.5rem",
                      fontWeight: "900",
                    }}
                  >
                    <p>DETAILS</p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <div className="currency__box">
                        <img src={IconNaira} alt="naira" />
                      </div>
                      <div className="ic__text">
                        <p>Budget</p>
                        <p>
                          {revealData?.budget ? (
                            revealData?.budget
                          ) : (
                            <p>....</p>
                          )}
                        </p>
                      </div>

                      <div className="caleft__box">
                        <BsCalendar
                          style={{
                            marginTop: "0.4rem",
                            color: "white",
                            fontSize: "1.3rem",
                          }}
                        />
                      </div>
                      <div className="ic__text">
                        <p>Start Date</p>
                        <p>
                          {revealData?.startDate ? displayDate : <p>....</p>}
                        </p>
                      </div>

                      <div className="caright__box">
                        <BsCalendar
                          style={{
                            marginTop: "0.4rem",
                            color: "#FD7972",
                            fontSize: "1.3rem",
                          }}
                        />
                      </div>
                      <div className="ic__text">
                        <p>End Date</p>
                        <p>{revealData?.endDate ? endDate : <p>....</p>}</p>
                      </div>
                    </Box>
                  </div>
                </Box>

                <Box
                  style={{ marginTop: "6%" }}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <p style={{ fontSize: "1rem" }}>Description</p>
                    <p style={{ fontSize: "0.8rem" }}>
                      {revealData?.description}
                    </p>
                  </div>
                </Box>

                <Box
                  style={{ marginTop: "8%" }}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  {/* <div>
                    <p>
                      Checklist(
                      {(() => {
                        if (todoLen <= 3 && todoLen >= 1) {
                          return "85%";
                        } else if (todoLen <= 5 && todoLen >= 4) {
                          return "50%";
                        } else if (todoLen <= 7 && todoLen >= 6) {
                          return "30%";
                        } else if (todoLen <= 10 && todoLen >= 8) {
                          return "5%";
                        } else {
                          return "100%";
                        }
                      })()}
                      )
                    </p>
                  </div> */}
                  <div style={{ width: "90%", marginTop: "1rem" }}>
                    {(() => {
                      if (progress <= 80 && progress >= 61) {
                        return (
                          <BorderLinearProgress
                            variant="determinate"
                            value={85}
                          />
                        );
                      } else if (progress <= 60 && progress >= 41) {
                        return (
                          <BorderLinearProgress
                            variant="determinate"
                            value={50}
                          />
                        );
                      } else if (progress <= 40 && progress >= 21) {
                        return (
                          <BorderRedProgress variant="determinate" value={30} />
                        );
                      } else if (progress <= 20 && progress >= 0) {
                        return (
                          <BorderRedProgress variant="determinate" value={5} />
                        );
                      } else {
                        return (
                          <BorderLinearProgress
                            variant="determinate"
                            value={100}
                          />
                        );
                      }
                    })()}
                  </div>
                </Box>

                {/* todolist */}
                <div>
                <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                TASKS {progress ? Math.round(progress) : 0}%
              </Typography>

            </AccordionSummary>
            <AccordionDetails>
                <div className="check__list">
                  <div className="check__items" >
                  {tasks.length !== 0 ? (
                    tasks.map((item, index) => (
                      <div key={index} className="check__item">
                        <div  style={{display:'flex'}}>
                        <div className="check__input" style={{width:'30%'}}>
                          <input
                            type="checkbox"
                            name={item.title}
                            id={item.title}
                            className="check-with-label"
                            onChange={(e) => handleCheck(e, item)}
                            checked={
                              item.status.toLowerCase() === "completed"
                                ? true
                                : false
                            }
                          />
                          <label
                            className="label-for-check"
                            htmlFor={item.title}
                          >
                            {item.title}
                          </label>

                        
                        </div>
                          <div style={{width:'70%'}}>
                              <RiDeleteBin5Line
                              style={{ cursor: "pointer",marginLeft:'2rem' }}
                              size={20}
                              onClick={() => delItem(item)}
                            />
                          </div>

                        </div>
                 
                
                      </div>
                    ))
                  ) : (
                    <p>No task on this project</p>
                  )}
                  </div>
                    {/* <div
                      className="add__new__check"
                      onClick={() => setNewTask(true)}
                    >
                      <AiOutlinePlus />
                      <p>Add Task</p>
                    </div> */}
                  
                    </div>
                    </AccordionDetails>
            </Accordion>
                  {/* code for automated checklist */}
                  {/* {todoList.length ? (
                    <p className="d-flex justify-content-center mt-1">
                      You have {todoList.filter((todo) => !todo.tv).length}{" "}
                      tasks to complete!
                    </p>
                  ) : null}
                  <TodoMap
                    list={todoList}
                    editBtn={btnEditTodo}
                    dell={dellTodo}
                    chek={cheked}
                  />
                  <ChecklistComponent
                    add={addTodo}
                    todo={todo} 
                    input={setTodo}
                    bolean={boleanTodo}
                    setBtnToEdit={editTodo}
                  /> */}
                    <AddTaskModal
                      show={newTask}
                      onClose={() => setNewTask(!newTask)}
                      pi={props}
                      rr={props.rr}
                      availableBudget={available}
                      cc={() => setC(!c)}
                      getProjects={props.getProjects}
                  />
                </div>
                {/* todolist */}
                {/* <Box sx={{ width: "100%" }}>
                  <ThemeProvider theme={darkTheme}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleValChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="COMMENTS" {...a11yProps(0)} />
                        <Tab label="ACTIVITY" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </ThemeProvider>
                  <TabPanel value={value} index={0}>
                    <div>
                      <div className="usefilter">
                        <div className="filter__something">
                          <textarea
                            id="story"
                            name="story"
                            rows="3"
                            cols="33"
                            placeholder="Add comments.. "
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                postComment();
                              }
                            }}
                            onChange={(e) => setInputStr(e.target.value)}
                            value={inputStr}
                          ></textarea>
                        </div>
                      
                        <div className="filterbtn">
                          <div
                            className="post-tag"
                            onClick={() => postComment()}
                          >
                            Comments
                          </div>
                          <div className="activity__buttons">
                            <div style={{ height: "30px" }}>
                              <BsEmojiSmile
                                onClick={() => setShowPicker((val) => !val)}
                              />
                              {showPicker && (
                                <Picker onEmojiClick={onEmojiClick} />
                              )}
                            </div>
                            <div>
                              <label className="image_file">
                                <BsCardImage />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {addComm.length > 0
                        ? addComm.map((dizcom, i) => {
                            let dateDB = new Date(dizcom.updatedAt);
                            let today = new Date();
                            let msMi = 60 * 1000;
                            let diffMs = Math.round(
                              Math.abs(dateDB - today) / msMi
                            );

                            let hours = diffMs / 60;
                            let rhours = Math.floor(hours);
                            let minuteget = (hours - rhours) * 60;
                            let rmin = Math.round(minuteget);
                            let commDate = rhours + " hours " + rmin + " min";
                            //  console.log(commDate)
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "0.5rem",
                                  marginLeft: "0.5rem",
                                }}
                              >
                                <div className="profile__icon">
                                  <ArtistLetter
                                    color={
                                      colorDisplay[
                                        Math.floor(Math.random() * 6)
                                      ]
                                    }
                                    letter={dizcom.name.charAt(0)}
                                  />
                                </div>

                                <div
                                  style={{
                                    marginLeft: " 10px",
                                    fontSize: "10px",
                                  }}
                                >
                                  <p style={{ float: "left" }}>{dizcom.name}</p>
                                  <p
                                    style={{
                                      fontStyle: "italic",
                                      marginLeft: "2rem",
                                      display: "inline",
                                    }}
                                  >
                                    {commDate}
                                  </p>
                                  <div>
                                    <p>{dizcom.text}</p>
                                    <BsTrash
                                      onClick={() => trashTimeline(dizcom)}
                                      className="openTrash"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : projectComm.map((dizcom, i) => {
                            let dateDB = new Date(dizcom.updatedAt);
                            let today = new Date();
                            let msMi = 60 * 1000;
                            let diffMs = Math.round(
                              Math.abs(dateDB - today) / msMi
                            );

                            let hours = diffMs / 60;
                            let rhours = Math.floor(hours);
                            let minuteget = (hours - rhours) * 60;
                            let rmin = Math.round(minuteget);
                            let commDate = rhours + " hours " + rmin + " min";
                            //  console.log(commDate)
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "0.5rem",
                                  marginLeft: "0.5rem",
                                }}
                              >
                                <div className="profile__icon">
                                  <ArtistLetter
                                    color={
                                      colorDisplay[
                                        Math.floor(Math.random() * 6)
                                      ]
                                    }
                                    letter={dizcom.name.charAt(0)}
                                  />
                                </div>

                                <div
                                  style={{
                                    marginLeft: " 10px",
                                    fontSize: "10px",
                                  }}
                                >
                                  <p style={{ float: "left" }}>{dizcom.name}</p>
                                  <p
                                    style={{
                                      fontStyle: "italic",
                                      marginLeft: "2rem",
                                      display: "inline",
                                    }}
                                  >
                                    {commDate}
                                  </p>
                                  <div>
                                    <p>{dizcom.text}</p>
                                    <BsTrash
                                      onClick={() => trashTimeline(dizcom)}
                                      className="openTrash"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div style={{ height: "11rem", overflowY: "scroll" }}>
                      {addComm.length > 0
                        ? addComm.map((dizcom, i) => {
                            let dateDB = new Date(dizcom.updatedAt);
                            let today = new Date();
                            let msMi = 60 * 1000;
                            let diffMs = Math.round(
                              Math.abs(dateDB - today) / msMi
                            );

                            let hours = diffMs / 60;
                            let rhours = Math.floor(hours);
                            let minuteget = (hours - rhours) * 60;
                            let rmin = Math.round(minuteget);
                            let commDate = rhours + " hours " + rmin + " min";
                            //  console.log(commDate)
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "0.5rem",
                                  marginLeft: "0.5rem",
                                }}
                              >
                                <div className="profile__icon">
                                  <ArtistLetter
                                    color={
                                      colorDisplay[
                                        Math.floor(Math.random() * 6)
                                      ]
                                    }
                                    letter={dizcom.name.charAt(0)}
                                  />
                                </div>

                                <div
                                  style={{
                                    marginLeft: " 10px",
                                    fontSize: "10px",
                                  }}
                                >
                                  <p style={{ float: "left" }}>{dizcom.name}</p>
                                  <div>
                                    <strong style={{ display: "inline-block" }}>
                                      Posted comment at {commDate}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : projectComm.map((dizcom, i) => {
                            let dateDB = new Date(dizcom.updatedAt);
                            let today = new Date();
                            let msMi = 60 * 1000;
                            let diffMs = Math.round(
                              Math.abs(dateDB - today) / msMi
                            );

                            let hours = diffMs / 60;
                            let rhours = Math.floor(hours);
                            let minuteget = (hours - rhours) * 60;
                            let rmin = Math.round(minuteget);
                            let commDate = rhours + " hours " + rmin + " min";
                            //  console.log(commDate)
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "0.5rem",
                                  marginLeft: "0.5rem",
                                }}
                              >
                                <div className="profile__icon">
                                  <ArtistLetter
                                    color={
                                      colorDisplay[
                                        Math.floor(Math.random() * 6)
                                      ]
                                    }
                                    letter={dizcom.name.charAt(0)}
                                  />
                                </div>

                                <div
                                  style={{
                                    marginLeft: " 10px",
                                    fontSize: "10px",
                                  }}
                                >
                                  <p style={{ float: "left" }}>{dizcom.name}</p>
                                  <div>
                                    <strong style={{ display: "inline-block" }}>
                                      Posted comment at {commDate}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </TabPanel>
                </Box> */}
              </div>
            </div>
            <div class="child2">
              <div style={{ padding: "1rem 1rem", background: "#171717" }}>
                <p style={{ textAlign: "left" }}>Started</p>
              </div>
              <div className="space__create">
                <p>CREATED BY</p>
                <div className="name__">
                  <ArtistChildLetter
                    txtcolor="#fead77"
                    letter={auth.firstName.charAt(0)}
                  />

                  <p style={{ marginLeft: "1rem", marginTop: "6px" }}>
                    {auth.firstName + " " + auth.lastName}
                  </p>
                </div>
                <HorizontalLine color="#242424" />
              </div>
              <div className="space__create">
                <div style={{ display: "flex" }}>
                  <p>MEMBERS </p>
                  <p
                    style={{
                      marginLeft: "9rem",
                      fontSize: "1rem",
                      cursor: "pointer",
                      fontWeight: "200",
                    }}
                    onClick={handleMemberClick}
                  >
                    +
                  </p>
                </div>
                <AddMember
                  onClose={() => setInvite(false)}
                  data={pData}
                  projectMembers={pData?.projectMembers}
                  show={invite}
                  rr={() => {
                    setR(!r);
                  }}
                />

                {singleP?.projectMembers ? (
                  singleP?.projectMembers.map((membrz, i) => (
                    <div className="name__">
                      <ArtistLetter
                        txtcolor="#c16dee"
                       
                        letter={membrz.firstName.charAt(0).toUpperCase()}
                      />
                      <div
                        style={{
                          marginLeft: "1rem",
                          fontSize: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <p>{membrz?.firstName}</p>
                        <p>{membrz?.role}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No members added</p>
                )}
              </div>
              <HorizontalLine color="#242424" />
              <div className="space__create">
                <div style={{ display: "flex" }}>
                  <p>FILES </p>

                  <p
                    style={{
                      marginLeft: "11rem",
                      fontSize: "1rem",
                      fontWeight: "200",
                    }}
                    onClick={handleFileClick}
                  >
                    +
                  </p>
                </div>
                {revealData?.file?.length > 1 ? (
                  revealData?.file.map((filez, i) => {
                    let imgFile;
                    let nameIndex = filez.split("/")[1];
                    console.log(filez);
                    let lastIndex = filez.lastIndexOf(".");
                    let extension = filez.substring(lastIndex + 1);
                    if (extension === "png") {
                      imgFile = IconImg;
                    } else {
                      imgFile = IconZip;
                    }
                    return (
                      <div className="name__">
                        <img
                          src={imgFile}
                          alt="fileproject"
                          style={{ width: "2rem", height: "2rem" }}
                        />

                        <div
                          style={{
                            marginLeft: "1rem",
                            fontSize: "10px",
                            marginTop: "5px",
                          }}
                        >
                          <p>{nameIndex}</p>
                          <p>5.8 MB</p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No files added</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewFilter;
