import React, { useState } from "react";
import { FiHeadphones } from "react-icons/fi";
import { BsPlusSquare, BsThreeDots } from "react-icons/bs";
import { BiPencil, BiTime } from "react-icons/bi";
import { RiDeleteBinLine, RiUserAddLine } from "react-icons/ri";
import { AiOutlineClockCircle } from "react-icons/ai";
import Box from "@mui/material/Box";
import { shuffle } from "lodash";

import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { AddTaskModal } from "../../Modals";

const TaskCard = (props) => {
  // const { data } = props;
  const [showaddModal, setAddModal] = useState(false);
  const [addProj, setAddProj] = useState(false);

  const handleClick = () => {
    if (props.projects.length === 0) {
    } else {
    }
    return;
  };
  return (
    <div
      className="add_project_card"
      style={{ paddingLeft: "25px", paddingTop: "1rem" }}
    >
      <div className="add_project">
        <div className="plus">
          <div className="icon" onClick={() => setAddModal(true)}>
            <BsPlusSquare />
          </div>

          <p>Create New Task</p>
        </div>
      </div>

      <AddTaskModal
        rr={props?.rr}
        onClose={() => {
          setAddModal(false);
        }}
        getTasks={props.getTasks}
        show={showaddModal}
        projects={props.projects}
        pi={props.ci}
        availableBudget={props.availableBudget}

      />
    </div>
  );
};

export default TaskCard;
