import { useState, useEffect, useCallback, useMemo } from "react";
import "./TaskPage.css";
import filterIcon from "../../assets/Filter.png";
import { Link } from "react-router-dom";
import { AiOutlineDown, AiOutlineUnorderedList } from "react-icons/ai";
import { AddTaskModal, AddProjectModal, AddMember } from "../Modals";
import { BiCalendarAlt } from "react-icons/bi";
import { RiStackLine, RiUserAddLine } from "react-icons/ri";
import { BsCheck2 } from "react-icons/bs";
import { FilterModal } from "../Modals";
import Drawer from "@mui/material/Drawer";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TaskCat from "../taskCat/TaskCat";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import toast from "react-hot-toast";
import LoadingScreen from "../preLoader";
import { TaskCard, ProjectCard } from "../Cards";
import { getOrgProjects, sortResponse } from "../../util.js";
import Tooltip from "@mui/material/Tooltip";

const TaskPage = () => {
  const {
    auth,
    currentOrganization,
    userProjects,
    UpdateProjectsGlobalState,
    config,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const [addOptions, setAddOptions] = useState(false);
  const [dropProjs, setDropProjs] = useState(false);
  const [projects, setProjects] = useState(userProjects);
  const [saveP, setSaveP] = useState(userProjects);
  const [tasks, setTasks] = useState(null);
  const [todo, setTodo] = useState([]);
  const [progress, setProgress] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [review, setReview] = useState([]);
  const [curProj, setCurProj] = useState(null);
  const [r, setR] = useState(false);
  const [filter, setFilter] = useState("");
  const [resultFilter, setresultFilter] = useState("");
  const [viewFilter, setViewFilter] = useState([]);

  const [state, setState] = useState({
    task: false,
    board: false,
    project: false,
    invite: false,
  });

  const [stateDraw, setStateDraw] = useState({
    right: false,
  });

  const resultCheck = (resultFilter) => {
    setresultFilter(resultFilter);
    if (resultFilter !== "") {
      const filteredData = tasks?.filter((item) => {
        return Object.values(item)
          .join("")
          .toLocaleLowerCase()
          .includes(resultFilter.toLowerCase());
      });

      setViewFilter(filteredData);
    } else {
      setViewFilter(tasks);
    }
  };

  const getTasks = useCallback(
    async (id) => {
      try {
        const activeProject = projects.find((item) => item.id === id);
        activeProject ? setCurProj(activeProject) : setCurProj(projects[0]);
        const response = await axios.get(`/task/all/${id}`, config);

        setTodo([]);
        setProgress([]);
        setCompleted([]);
        setReview([]);

        setTodo(
          response.data.filter((item) => item.status.toLowerCase() === "todo")
        );
        setProgress(
          response.data.filter(
            (item) => item.status.toLowerCase() === "in progress"
          )
        );
        setCompleted(
          response.data.filter(
            (item) => item.status.toLowerCase() === "completed"
          )
        );

        setReview(
          response.data.filter((item) => item.status.toLowerCase() === "review")
        );

        setTasks(response.data);

        setLoading(true);
      } catch (error) {
        if (!error.response) {
          toast.error("Check your internet connection!");
        }
        console.log(error);
      }
    },
    [tasks, r]
  );

  const available = useMemo(() => {
    if (tasks?.length === 0) {
      return curProj?.budget;
    }
    const totalAvailable = tasks?.reduce(
      (acc, curr) => acc + parseInt(curr.budget),
      0
    );
    const final = parseInt(curProj?.budget) - parseInt(totalAvailable);
    return final;
  }, [tasks]);

  const selectProject = (item) => {
    setCurProj(item);
    setDropProjs(false);
    getTasks(item.id);

    setLoading(false);
  };

  const getProjects = useCallback(async () => {
    try {
      const response = await getOrgProjects(
        currentOrganization?.id,
        auth?.id,
        auth?.role,
        config
      );
      UpdateProjectsGlobalState(response.data); // This will update the global state storing the user's projects
      setProjects(sortResponse(response.data));
      setSaveP(sortResponse(response.data));
      setLoading(false);

      if (response.data.length > 0) {
        getTasks(sortResponse(response?.data)[0].id);
        setCurProj(response.data[0]);
      }
      tasks && setLoading(true);
    } catch (error) {
      console.log(error);
    }
  }, [currentOrganization?.id, auth?.id, auth?.role, r]);

  useEffect(() => {
    if (userProjects.length > 0) {
      getTasks(sortResponse(userProjects)[0].id);
      setCurProj(userProjects[0]);
    }
    tasks && setLoading(true); // Disables the loading state
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateDraw({ ...stateDraw, [anchor]: open });
  };

  const toggleItems = (item, open) => (event) => {
    setState({ ...state, [item]: open });
  };

  const handleClickAway = () => {
    setAddOptions(false);
  };

  const searchProj = (event) => {
    const { value } = event.target;

    let compare = projects.filter((item) =>
      item.name.toLowerCase()?.includes(value.toLowerCase())
    );

    if (value === "") {
      compare = [];
      setProjects(saveP);
      return;
    }

    if (compare.length !== 0 && value !== "") {
      setFilter(compare);
      setProjects(compare);
      return;
    } else {
      setFilter("Not Found");
      return;
    }
  };

  var progFilt;
  if (resultFilter === "IN_PROGRESS") {
    progFilt = <TaskCat type="in progress" data={progress} ci={curProj} />;
  } else if (resultFilter === "COMPLETED") {
    progFilt = <TaskCat type="completed" data={completed} ci={curProj} />;
  } else {
    progFilt = <TaskCat type="todo" data={todo} ci={curProj} />;
  }
  return (
    <div className="task__page">
      <div className="task__header">
        <ClickAwayListener
          onClickAway={() => {
            setDropProjs(false);
          }}
        >
          <div
            className="header__dropdown"
            onClick={() => setDropProjs(!dropProjs)}
          >
            <h2 className="text-lg">
              <span className=" font-semibold">
                {!curProj ? projects[0]?.name : curProj?.name}{" "}
              </span>
              Project Task
            </h2>

            {projects.length > 0 && <AiOutlineDown fontSize={13} />}
          </div>
        </ClickAwayListener>

        {projects.length !== 0 && (
          <div
            className={`projects__dropdown ${
              projects.length > 3 && " overflow-y-scroll"
            } ${dropProjs && "show"}`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="top__part">
              <p>Projects</p>

              <input
                type="text"
                placeholder="Search Project..."
                onChange={(e) => searchProj(e)}
              />
            </div>
            <div className="dropdown__list">
              {filter === "Not Found" ? (
                <p>Not Found</p>
              ) : (
                projects.map((item, index) => (
                  <div
                    className="dropdown__item"
                    key={index}
                    onClick={() => selectProject(item)}
                  >
                    <div className="icon__name">
                      <RiStackLine />
                      <div>{item.name}</div>
                    </div>
                    {index === 0 && !curProj && filter === "" ? (
                      <BsCheck2
                        color="#51D187"
                        className="check__icon"
                        size={20}
                      />
                    ) : null}
                    {curProj === item ? (
                      <BsCheck2
                        color="#51D187"
                        className="check__icon"
                        size={20}
                      />
                    ) : null}
                  </div>
                ))
              )}
            </div>
          </div>
        )}

        <div className="task__header__buttons">
          <Tooltip title="Tasks List View" placement="top">
            <Link
              to="/frame/task_list"
              style={{
                color: "white",
                border: "1px solid rgb(80, 77, 77)",
              }}
              className="equalizer"
            >
              <AiOutlineUnorderedList />
            </Link>
          </Tooltip>

          <Tooltip title="Filter" placement="top">
            <div className="equalizer" onClick={toggleDrawer("right", true)}>
              <img src={filterIcon} alt="icon" fontSize={20} />
            </div>
          </Tooltip>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className="add__task__button"
              onClick={() => {
                setAddOptions(!addOptions);
              }}
            >
              <p>Add</p>
              <div className="task__type__dropdown">
                <AiOutlineDown fontSize={13} />
              </div>
            </div>
          </ClickAwayListener>

          <Drawer
            anchor="right"
            open={stateDraw["right"]}
            onClose={toggleDrawer("right", false)}
          >
            <FilterModal resultCheck={resultCheck} right="right" />
          </Drawer>

          <div className={`lil__add__dropdown ${addOptions && "show"}`}>
            <div className="dropdown__list">
              <div
                className="dropdown__item"
                onClick={toggleItems("task", true)}
              >
                <BiCalendarAlt />
                <div>Task</div>
              </div>

              <div
                className="dropdown__item"
                onClick={toggleItems("project", true)}
              >
                <RiStackLine />
                <div>Project</div>
              </div>

              <div
                className="dropdown__item "
                onClick={toggleItems("invite", true)}
              >
                <RiUserAddLine />
                <div>Invite</div>
              </div>
            </div>
          </div>
        </div>
        <AddMember
          onClose={toggleItems("invite", false)}
          show={state["invite"]}
        />
        <AddTaskModal
          onClose={toggleItems("task", false)}
          show={state["task"]}
          projects={projects}
          availableBudget={available}
          // rr={() => setR(!r)}
          getTasks={getTasks}
        />

        <AddProjectModal
          onClose={toggleItems("project", false)}
          show={state["project"]}
          rr={() => setR(!r)}
          getProjects={getProjects}
        />
      </div>

      <div className="task__page__content">
        {loading ? (
          tasks?.length !== 0 ? (
            resultFilter.length > 1 ? (
              <div className="the__content">{progFilt}</div>
            ) : (
              <div className="the__content">
                {todo.length !== 0 && (
                  <TaskCat
                    type="todo"
                    data={todo}
                    ci={curProj}
                    getTasks={getTasks}
                    availableBudget={available}
                  />
                )}
                {progress.length !== 0 && (
                  <TaskCat
                    type="in progress"
                    data={progress}
                    ci={curProj}
                    getTasks={getTasks}
                    availableBudget={available}
                  />
                )}
                {review.length !== 0 && (
                  <TaskCat
                    type="review"
                    data={review}
                    ci={curProj}
                    getTasks={getTasks}
                    availableBudget={available}
                  />
                )}
                {completed.length !== 0 && (
                  <TaskCat
                    type="completed"
                    data={completed}
                    ci={curProj}
                    getTasks={getTasks}
                    availableBudget={available}
                  />
                )}
              </div>
            )
          ) : projects.length === 0 ? (
            <div
              className="add_project_card"
              style={{ marginLeft: "25px", paddingTop: "1rem" }}
            >
              <ProjectCard />
            </div>
          ) : (
            <TaskCard
              projects={projects}
              ci={curProj}
              getTasks={getTasks}
              availableBudget={available}
              x
            />
          )
        ) : (
          <LoadingScreen loading />
        )}
      </div>
    </div>
  );
};

export default TaskPage;
