import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Search, StyledInputBase } from './styleMUI.js';

import CreateIcon from '@mui/icons-material/Create';

export default function ChecklistComponent({ add, todo, input, bolean, setBtnToEdit }) {
  return (
    <>
      <Box sx={{ flexGrow: 1, mb: 2 }}>
       
            <Search>
            <IconButton
              //type="submit"
              onClick={bolean ? setBtnToEdit : add}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              /* sx={{ m: 'auto' }} */
            >
              {bolean ? <CreateIcon /> : <AddIcon />}
            </IconButton>
              <StyledInputBase
                onChange={(e) => input(e.target.value)}
                placeholder="Add Checklist Item"
                inputProps={{ 'aria-label': 'search' }}
                value={todo}
              />
            </Search>

 
      </Box>
    </>
  );
}
