import "./Missing.css";
import { FaGhost } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Missing = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const returnUser = () => {
    if (!auth.email) {
      navigate("/");
      return;
    } else {
      navigate("/frame/profile-admin");
      return;
    }
  };
  return (
    <section className="not__found">
      <h1>
        4
        <span>
          <FaGhost />
        </span>
        4
      </h1>

      <h2>Error: 404 page not found</h2>
      <p>Sorry, the page you're looking for cannot be accessed</p>

      <br />

      <button className="back" onClick={returnUser}>
        Visit Our Homepage
      </button>
    </section>
  );
};

export default Missing;
