import React, { useState } from "react";
import "./pricingpage.css";
import { Active } from "..";
import Navbar from "../navbar/Navbar";
import {
  SignupModalBasic,
  SignupModalAdvanced,
  SignupModalPremium,
  SignupModalLabel,
  SignupModal,
  CreateOrganization,
} from "../Modals";
import Switch from "../switch/Switch";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";

const PricingPage = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const [showB, setShowB] = useState(false);
  const [showE, setShowE] = useState(false);
  const [showAd, setShowA] = useState(false);
  const [showP, setShowP] = useState(false);
  const [showL, setShowL] = useState(false);

  const [showSignB, setShowSignB] = useState(false);
  const [showSignA, setShowSignA] = useState(false);
  const [showSignP, setShowSignP] = useState(false);
  const [showSignL, setShowSignL] = useState(false);
  const [value, setValue] = useState(false);
  function timeB() {
    setShowB(true);
    setTimeout(function () {
      setShowB(false);
    }, 10000);
  }
  function timeE() {
    setShowE(true);
    setTimeout(function () {
      setShowE(false);
    }, 10000);
  }
  function timeA() {
    setShowA(true);
    setTimeout(function () {
      setShowA(false);
    }, 10000);
  }
  function timeP() {
    setShowP(true);
    setTimeout(function () {
      setShowP(false);
    }, 10000);
  }
  function timeL() {
    setShowL(true);
    setTimeout(function () {
      setShowL(false);
    }, 10000);
  }

  let prices = {
    basic: "Free",
    advanced: "$9.99",
    premium: "$19.99",
    editor: "$5.99",
    label: "Contact Us",
  };

  if (value) {
    prices = {
      basic: "Free",
      advanced: "$107.89",
      premium: "$215.89",
      editor: "$5.99",
      label: "Contact Us",
    };
  }
  const comp = [
    <div className="pricing__section">
      <div className="pricing__section--header">
        <div style={{ display: "flex" }}>
          <h3>BASIC</h3>
          <span
            onClick={() => timeB()}
            style={{
              position: "absolute",
              marginLeft: "7rem",
              marginTop: "0.3rem",
            }}
          >
            <AiOutlineInfoCircle />{" "}
          </span>
        </div>

        <h1>{prices.basic}</h1>
        <a href="#" onClick={() => setShowSignB(true)}>
          Get Started
        </a>
        <CreateOrganization
          onClose={() => setShowSignB(false)}
          show={showSignB}
          plan="basic"
        />
      </div>
      <div className="divider" />
      {showB ? (
        <div
          style={{
            background: "#353535",
            height: "40rem",
            width: "17rem",
            zIndex: "10",
            position: "absolute",
          }}
        >
          Best if you are just starting your career and work with just one (1)
          collaborator.
        </div>
      ) : (
        <div>{""}</div>
      )}
      <div className="pricing__section--prices diff__basic--container">
        <p>2 Users</p>
        <p>1GB/Project</p>
        <Active text="Timeline" />
        <Active text="Assign Tasks" />
        <Active text="Voice Messaging" />
        <p>1 Product Integration</p>
        <Active text="Master Distribution" />
        <Active text="Private Chats" />
        <Active text="List View" />
        <Active text="Goal Setting" />
      </div>
    </div>,
    <div className="pricing__section">
      <div className="pricing__section--header">
        <div style={{ display: "flex" }}>
          <h3>Editor</h3>
          <span
            onClick={() => timeE()}
            style={{
              position: "absolute",
              marginLeft: "7rem",
              marginTop: "0.3rem",
            }}
          >
            <AiOutlineInfoCircle />{" "}
          </span>
        </div>
        <h1>{prices.editor}</h1>
        <a href="#" onClick={() => setShowSignA(true)}>
          Get Started
        </a>
        <CreateOrganization
          onClose={() => setShowSignA(false)}
          show={showSignA}
          plan="advanced"
        />
      </div>
      <div className="divider" />
      {showE ? (
        <div
          style={{
            background: "#353535",
            height: "45rem",
            width: "17rem",
            zIndex: "10",
            position: "absolute",
          }}
        >
          Best if you are a freelancer, contractor or if you work alone e.g. a
          sound engineer or an unsigned producer
        </div>
      ) : (
        <div>{""}</div>
      )}
      <div className="pricing__section--prices diff__basic--container">
        <p>1 Users</p>
        <p>1GB/Project</p>
        <Active text="Timeline" />
        <Active text="Assign Tasks" />
        <Active text="Voice Messaging" />
        <Active text="Dependent on Master Plan" />
        <p>1 Product Integration</p>
        <Active text="Master Distribution" />
        <Active text="Private Chats" />
        <Accordion allowToggle>
          <AccordionItem pt="15px">
            <AccordionPanel pb={4}>
              <Active text="List View" />
              <Active text="Goal Setting" />
              <Active text="Dependent on Master Plan" />
            </AccordionPanel>
            <AccordionButton
              border="none"
              bg="transparent"
              fontSize="15px"
              color="#fff"
              cursor="pointer"
            >
              <Box color="#fff" fontFamily="Poppins" fontWeight="200">
                ....Show All
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </AccordionItem>
        </Accordion>
      </div>
    </div>,
  ];

  return (
    <>
      <Navbar />
      <div className="hos__pricing container">
        <h1>Pricing</h1>
        <div className="price__switch">
          <h5>Monthly</h5>
          <Switch
            isOn={value}
            onColor="#ba68c8"
            handleToggle={() => setValue(!value)}
          />
          <h5>Yearly (save 10%)</h5>
        </div>

        <div className="pricing__container">
          <div style={{ marginTop: "15rem", fontSize: "large" }}>
            {currentPage > 0 && (
              <div
                onClick={() => setCurrentPage(currentPage - 1)}
                style={{
                  border: "1px solid #FFB74D",
                  borderRadius: "0.5rem",
                  padding: "0.1rem 0.3rem",
                  marginRight: "1rem",
                  background: "#ba68c8",
                }}
              >
                {" "}
                <MdOutlineNavigateBefore style={{ marginTop: "0.3rem" }} />
              </div>
            )}
          </div>

          {comp[currentPage]}
          <div className="pricing__section">
            <div className="pricing__section--header">
              <div style={{ display: "flex" }}>
                <h3>ADVANCED</h3>
                <span
                  onClick={() => timeA()}
                  style={{
                    position: "absolute",
                    marginLeft: "9rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <AiOutlineInfoCircle />{" "}
                </span>
              </div>

              <h1>{prices.advanced}</h1>
              <a href="#" onClick={() => setShowSignA(true)}>
                Get Started
              </a>
              <CreateOrganization
                onClose={() => setShowSignA(false)}
                show={showSignA}
                plan="advanced"
              />
            </div>
            <div className="divider" />
            {showAd ? (
              <div
                style={{
                  background: "#353535",
                  height: "40rem",
                  width: "17rem",
                  zIndex: "10",
                  position: "absolute",
                }}
              >
                Best for a small music collective of record label with no more
                than five (5) collaborators
              </div>
            ) : (
              <div>{""}</div>
            )}
            <div className="pricing__section--prices diff__advanced--container">
              <p>5 Users</p>
              <p>5GB/Project</p>
              <Active text="All Basic Features +" />
              <Active text="Deadline" />
              <p>2 Product Integrations</p>
              <Active text="Referrence Track Deconstruction" />
              <Active text="Demo Submission" />
              <Active text="Calendar View" />
            </div>
          </div>
          <div className="pricing__section diff-1">
            <div className="pricing__section--header">
              <div style={{ display: "flex" }}>
                <h3>PREMIUM</h3>
                <span
                  onClick={() => timeP()}
                  style={{
                    position: "absolute",
                    marginLeft: "9rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <AiOutlineInfoCircle />{" "}
                </span>
              </div>

              <h1>{prices.premium}</h1>
              <a href="#" onClick={() => setShowSignP(true)}>
                Get Started
              </a>
              <CreateOrganization
                onClose={() => setShowSignP(false)}
                show={showSignP}
                plan="premium"
              />
            </div>
            <div className="divider" />
            {showP ? (
              <div
                style={{
                  background: "#353535",
                  height: "40rem",
                  width: "17rem",
                  zIndex: "10",
                  position: "absolute",
                  marginTop: "1rem",
                }}
              >
                Best for a boutique record label with no more than ten (10)
                collaborators
              </div>
            ) : (
              <div>{""}</div>
            )}
            <div className="pricing__section--prices">
              <p>10 Users</p>
              <p>10GB/Project</p>
              <Active text="All Advanced Features +" />
              <Active text="Activity Log" />
              <Active text="Budgeting" />
              <Active text="Expense Tracking" />
              <p>4 Product Integrations</p>
              <Active text="Progress Reports" />
              <Active text="Project Efficiency Reports" />
              <Accordion allowToggle>
                <AccordionItem pt="15px">
                  <AccordionPanel pb={4}>
                    <Active text="TimeStamp (Commenting)" />
                    <Active text="Archiving" />
                    <Active text="Legend (1,2, 3*)" />
                    <Active text="Feedback Surveys" />
                    <Active text="One on One Video Meetings" />
                    <Active text="Sounds & Samples" />
                  </AccordionPanel>
                  <AccordionButton
                    border="none"
                    bg="transparent"
                    fontSize="15px"
                    color="#fff"
                    cursor="pointer"
                  >
                    <Box color="#fff" fontFamily="Poppins" fontWeight="200">
                      ....Show All
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div className="pricing__section diff-2">
            <div className="pricing__section--header diff-h1">
              <div style={{ display: "flex" }}>
                <h3>LABEL</h3>
                <span
                  onClick={() => timeL()}
                  style={{
                    position: "absolute",
                    marginLeft: "7rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <AiOutlineInfoCircle />{" "}
                </span>
              </div>

              <h1>{prices.label}</h1>
              <a href="#" onClick={() => setShowSignL(true)}>
                Contact Us
              </a>
              <SignupModalLabel
                onClose={() => setShowSignL(false)}
                show={showSignL}
              />
            </div>
            <div className="divider" />
            {showL ? (
              <div
                style={{
                  background: "#353535",
                  height: "35rem",
                  width: "17rem",
                  zIndex: "10",
                  position: "absolute",
                }}
              >
                Best for a major or independent record label with more than ten
                (10) collaborators.
              </div>
            ) : (
              <div>{""}</div>
            )}
            <div className="pricing__section--prices diff__label--container">
              <p>Contact Us</p>
              <p>Unlimited GB/Project</p>
              <Active text="All Premium Features +" />
              <p>Unlimited Project Integration</p>
              <Active text="Post Release Project Analysis" />
              <Active text="Guest Room" />
            </div>
          </div>
          <div style={{ marginTop: "15rem", fontSize: "large" }}>
            {currentPage < comp.length - 1 && (
              <div
                onClick={() => setCurrentPage(currentPage + 1)}
                style={{
                  border: "1px solid #FFB74D",
                  borderRadius: "0.5rem",
                  padding: "0.1rem 0.3rem",
                  background: "#ba68c8",
                }}
              >
                {" "}
                <MdOutlineNavigateNext style={{ marginTop: "0.3rem" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
