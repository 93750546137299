import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { Provider } from "react-redux";
// import { store } from "../src/redux/store";
import { CometChat } from "@cometchat-pro/chat";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/AuthProvider";

var appID = "235497d0ab14f17f";
var region = "us";

var appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();
CometChat.init(appID, appSetting).then(
  () => {
    if (CometChat.setSource) {
      CometChat.setSource("ui-kit", "web", "reactjs");
    }

    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      // <Provider store={store}>
      <AuthProvider>
        <App />
        <Toaster position="top-right" />
      </AuthProvider>

      // </Provider>
    );
  },
  (error) => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);
