import "./App.css";
import {
  // Navbar,
  Header,
  PricingPage,
  Profile,
  AdminProfile,
  MasterSound,
  Note,
  FramePage,
  TextEditor,
  AuthPage,
  PrivacyPolicyPage,
  Missing,
  PasswordReset,
} from "./components";
import Cookies from "js-cookie";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import { CometChat } from "@cometchat-pro/chat";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  const { setIt, setCurrentOrganization, UpdateProjectsGlobalState } =
    useAuth();
  useEffect(() => {
    if (Cookies.get("organization")) {
      setCurrentOrganization(JSON.parse(Cookies.get("organization")));
    }

    if (Cookies.get("projects")) {
      UpdateProjectsGlobalState(JSON.parse(Cookies.get("projects")).data);
    }

    if (Cookies.get("auth")) {
      setIt(JSON.parse(Cookies.get("auth")));
    } else {
      setIt(null);
    }
  }, [setIt, Cookies.get("auth")]);

  // LOGS USER INTO COMETCHAT
  useEffect(() => {
    if (!Cookies.get("auth")) return;

    const auth = JSON.parse(Cookies.get("auth"));

    var authKey = "a80db2e3d1ed5ce586c33d2e3f4c0d5d7de937d8";

    var UID = auth?.id;
    var name = auth?.firstName + " " + auth?.lastName;
    var user = new CometChat.User(UID);

    var uid = auth?.id;

    user.setName(name);
    // Check if user already exist, or create a new user in the cometchat database
    CometChat.createUser(user, authKey).then(
      (user) => {
        console.log("User created successfully:", user);
      },
      (error) => {
        if (error.code === "ERR_UID_ALREADY_EXISTS") {
          console.log("User creation failed: UID already exists");
        } else {
          console.log("User creation failed with error:", error);
        }
      }
    );

    // Login into cometchat
    CometChat.login(uid, authKey).then(
      (user) => {
        console.log("Login Successful:", { user });
      },
      (error) => {
        console.log("Login failed with exception:", { error });
      }
    );
  }, [Cookies.get("auth")]);

  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Header />} />
        <Route
          exact
          path="/"
          element={
            <Navigate
              replace
              to={Cookies.get("auth") ? "/frame/profile-admin" : "/home"}
            />
          }
        />

        {/* Authentication routes */}
        <Route path="/api/v1/auth/verify/:token" element={<AuthPage />} />
        <Route
          path="/api/v1/auth/password-reset/:token"
          element={<PasswordReset />}
        />

        {/* Public routes */}
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/profile" element={<Profile />} />

        {/* Pricate route */}
        <Route path="/frame/*" element={<FramePage />} />

        {/* Catch all (e.g: 404 pages) */}
        <Route path="*" element={<Missing />}></Route>

        {/* //TODO: Add these routes to the frame */}
        <Route path="lyric-pad/:id" element={<TextEditor />}></Route>
        <Route path="/master_sound" element={<MasterSound />} />
      </Routes>
    </Router>
  );
}

export default App;
