import { useState, useEffect } from "react";
import "./updateProfileModal.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

// bouncei code
import BackDrop from "../../BackDrop";

const UpdateProfileModal = (props) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  var [logo, setLogo] = useState();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    location: "",
    dateOfBirth: "",
  });
  const [open, setOpen] = useState(false);
  const PROF_URL = "https://www.api.beatlab.app/api/v1/users/";

  // Handles user credentials inputted in the available fields
  const handleChange = (event) => {
    const { value, name } = event.target;
    // setFormData(formData.countryCode === ctryCode)
    if (name === "email") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handles submitting of the form
  const AuthStr = "Bearer ".concat(auth?.authToken);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const token = await loginUser(formData);
    if (logo === undefined) {
      logo = "https://eu.ui-avatars.com/api/?name=Team&length=4&font-size=0.33";
    }

    setOpen(true);

    try {
      const response = await axios.patch(
        PROF_URL,
        JSON.stringify({
          firstName: formData["firstName"],

          lastName: formData["lastName"],
          phoneNumber: formData["phoneNumber"],
          dateOfBirth: formData["dateOfBirth"],
          location: formData["location"],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          withCredentials: true,
        }
      );

      setTimeout(() => {
        setOpen(false);
      }, response);
      toast.success("Updated profile, please log in");
      //temporary
      navigate("/home");
    } catch (error) {
      setOpen(false);
      if (!error?.response) {
        toast.error("Check your internet connection!");
      } else if (error.response?.status === 401) {
        toast.error("Unauthorized!");
        console.log(error.response);
      } else if (error.response?.status === 400) {
        toast.error("Missing Value");
        console.log(error.response);
      } else {
        toast.error("Failed!");
        console.log(error);
      }
    }
  };

  return (
    <div
      className={`uprofile__modal ${props.show ? "show" : ""}`}
      id="login=div"
      onClick={props.onClose}
    >
      <form
        className="uprofile__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="uprofile__modal--content-header">
          <h4>Update Profile</h4>
          {/* <CloseIcon className="uprofile__close--icon" onClick={props.onClose} /> */}
        </div>

        {open && <BackDrop open={open} />}

        <div className="uprofile__inputs">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            autoCorrect="off"
            onChange={handleChange}
            required
            autoComplete="off"
          />

          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            onChange={handleChange}
            required
            autoComplete="off"
          />
          <label style={{ color: "gray" }} htmlFor="birth">
            Date Of Birth
          </label>
          <input
            type="date"
            name="dateOfBirth"
            placeholder="Date Of Birth"
            autoCorrect="off"
            onChange={handleChange}
            autoComplete="off"
          />
          <label style={{ color: "gray" }} htmlFor="birth">
            Use Country Code
          </label>
          <input
            type="text"
            placeholder="Phone Number"
            name="phoneNumber"
            onChange={handleChange}
            autoComplete="off"
          />

          <input
            type="text"
            placeholder="Home Address"
            name="location"
            onChange={handleChange}
            autoComplete="off"
          />
        </div>

        <button type="submit" href="#" className="sign-in-tag">
          Submit
        </button>
      </form>
    </div>
  );
};

export default UpdateProfileModal;
