import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  marginLeft:2,
  order:2,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    
    backgroundColor: theme.palette.mode === 'light' ? '#ED6F69' : '#308fe8',
  },
}));


export default function StyleProgressBars() {
  return (
    <Box sx={{ flexGrow: 1 }}>
  
      <BorderLinearProgress variant="determinate" value={0} />
    </Box>
  );
}
