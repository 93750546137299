import React from "react";
import { Bar } from "react-chartjs-2";
import { timeLeft } from "../../util.js";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
const UsersProjectEfficiency = ({ users, ratings }) => {
  let labels = [];
  let excellent = [];
  let poor = [];
  let average = [];
  let rate = ratings;

  users.forEach((user) => {
    labels.push(user);
    excellent.push(20);
    poor.push(40);
    average.push(40);

    return;
  });

  const optionsBar = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      title: {
        display: false,
        text: "Project Efficiency",
      },
    },
    responsive: true,
    legend: {
      labels: {
        boxWidth: 10,
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false,
        gridLines: {
          display: true,
          drawBorder: false,
          borderDash: [3, 3],
          zeroLineColor: "transparent",
        },
        categoryPercentage: 0.7,
        barPercentage: 0.9,
        ticks: {
          beginAtZero: true,
        },
      },
      yAxes: {
        stacked: true,
        gridLines: {
          display: false,
          zeroLineColor: "transparent",
        },
        ticks: {
          max: 100,
          min: 0,
        },
      },
    },
  };

  const dataBar = {
    labels,
    datasets: [
      {
        label: "Poor",
        data: poor,
        backgroundColor: "#59ACFF",
        barThickness: 10,
        order: 2,
      },
      {
        label: "Rating",

        data: rate.map((item) => [item.toFixed(2) - 1, item.toFixed(2) + 1]),
        xAxisID: "x-axis-target",
        order: 1,
        backgroundColor: "#ED6F69",
        barThickness: 15,
      },
      {
        label: "Average",
        data: average,

        backgroundColor: "#FEB077",
        barThickness: 10,
        order: 2,
      },
      {
        label: "Excellent",
        data: excellent,

        backgroundColor: "#51D187",
        barThickness: 10,
        order: 2,
      },
    ],
  };

  return <Bar options={optionsBar} data={dataBar} />;
};

export default UsersProjectEfficiency;
