import React, { useState, useEffect } from "react";
import "./header.css";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CloudQueueOutlinedIcon from "@mui/icons-material/CloudQueueOutlined";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import Navbar from "../navbar/Navbar";
import {
  SignupModalBasic,
  SignupFanModal,
  SignupModal,
  CreateOrganization,
} from "../Modals";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [showSignB, setShowSignB] = useState(false);
  const [showSignF, setShowSignF] = useState(false);

  const navigate = useNavigate;

  const initialH1 = "Creative Collaboration.";
  const initialP = "";
  const [h1, setH1] = useState(initialH1);
  const [p, setP] = useState(initialP);

  const [project, cloud, samples, fluid, dynamix] = [
    {
      h1: "Project Management & Social",
      p: `People is designed to enhance and optimize the execution of music projects through collaborative & social project management functionalities.
      By lifting the veil on the creative processes artists and their teams experience through People's social features, Fans can get closer to their favorite artists while getting a look under the hood of the machines that produce their favorite songs.`,
    },
    {
      h1: "Seamless Cloud Storage & Advanced Encryption",
      p: `CAVE (Cache and Vault Everything) is the robust storage hub within our ecosystem, seamlessly integrating with cloud-based solutions like Google Drive. With Cave, artists and their team members can securely store and exchange files, fostering collaboration and creativity.
      Our app leverages advanced encryption techniques to ensure the utmost security of your files. Every piece of data stored in Cave is fully encrypted, protecting it from potential loss or theft. This encryption guarantees that only authorized individuals have access to your files, providing you with peace of mind and confidentiality.`,
    },
    {
      h1: "Buy And Sell Sounds and Samples",
      p: `Our marketplaces provide sounds and samples essential to the creation of songs and other music products. The marketplaces offer a selection of sounds and samples cutting across various genres which may be licensed for various use cases.`,
    },
    {
      h1: "AI Powered Songwriting Tool",
      p: `Fluid Flow is built on artificial intelligence to enhance the process of writing songs. The flow recorder will allow teams to craft and precisely review song demos from song writers. The lyric pad will provide writing experiences custom to the topic of discussion and the genre of focus. Fluid flow's in-built rhyme suggestion feature will make writers block a thing of the past`,
    },
    {
      h1: "Digital Audio Workstation - Master Your Track",
      p: `Dynamix in its Beta is a mastering tool for use by professional or amateur sound engineers to add the final gloss to music project before the song is distributed. Dynamix will have mastering presets crafted by world class sound engineers and will also allow the user to customize their masters with the five key plug-ins built into the product. The five plug-ins are: a dynamic compressor, a dynamic equalizer,  a saturator, an imager and an adaptive limiter. `,
    },
  ];

  const clearState = () => {
    setH1(initialH1);
    setP(initialP);
  };

  useEffect(() => {
    const timer = setTimeout(() => clearState(), 30000);
    return () => clearTimeout(timer);
  }, [h1, p]);

  return (
    <div className="hos__header">
      <Navbar />
      <div className="hos__header--content container" id="content">
        <h1 className="!text-black">{h1}</h1>
        <p className="!text-black">{p}</p>
        <div className="btn-box">
          <a
            href="#"
            onClick={() => setShowSignB(true)}
            className="button-class !bg-black !text-white !border-none"
          >
            Start a project
          </a>
          <CreateOrganization
            onClose={() => setShowSignB(false)}
            show={showSignB}
            plan="basic"
          />
          <a
            href="#"
            onClick={() => setShowSignF(true)}
            className="button-class !bg-black !text-white !border-none"
          >
            Follow project
          </a>
          <CreateOrganization
            onClose={() => setShowSignF(false)}
            show={showSignF}
            plan="fan"
          />
        </div>
      </div>
      <div className="bg-class realative">
        <div className="hos__header--features container">
          <article>
            <PeopleAltOutlinedIcon />
            <h3>PROJECT MANAGEMENT & SOCIAL</h3>
            <p>
              People is designed to enhance and optimize the execution of
              music...
            </p>
          </article>

          <article>
            <CloudQueueOutlinedIcon />
            <h3>Seamless Cloud Storage & Advanced Encryption</h3>
            <p>
              CAVE (Cache and Vault Everything) is the robust storage hub within
              our ecosystem...
            </p>
          </article>

          <article>
            <AudiotrackOutlinedIcon />
            <h3>BUY AND SELL SOUND & SAMPLES</h3>
            <p>
              Our marketplaces provide sounds and samples essential to the...
            </p>
          </article>

          <article>
            <StackedLineChartOutlinedIcon />
            <h3>AI POWERED SONGWRITING TOOL</h3>
            <p>
              Fluid Flow is built on artificial intelligence to enhance the ...
            </p>
          </article>

          <article>
            <PublishedWithChangesOutlinedIcon />
            <h3>DIGITAL AUDIO WORKSTATION - MASTER YOUR TRACK</h3>
            <p>
              Dynamix in its Beta is a mastering tool for use by professional
              or...
            </p>
          </article>

          {/* READMORE LINKS SEPERATED FOR ALIGNMENT PURPOSES */}
          {/* UNCOMMENT LINKS TO ARTICLE UNDERNEATH P AND SET TO DISPLAY ONLY ON DIFFERENT VIEWS WHEN 
          DESIGNING FOR RESPONSIVENESS
        */}

          <a
            href="#project"
            onClick={() => {
              setH1(project.h1);
              setP(project.p);
            }}
          >
            Read More
          </a>
          <a
            href="#cloud"
            onClick={() => {
              setH1(cloud.h1);
              setP(cloud.p);
            }}
          >
            Read More
          </a>
          <a
            href="#samples"
            onClick={() => {
              setH1(samples.h1);
              setP(samples.p);
            }}
          >
            Read More
          </a>
          <a
            href="#fluid"
            onClick={() => {
              setH1(fluid.h1);
              setP(fluid.p);
            }}
          >
            Read More
          </a>
          <a
            href="#dynamix"
            onClick={() => {
              setH1(dynamix.h1);
              setP(dynamix.p);
            }}
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
