import { useState, useEffect, useCallback } from "react";
import "./organizationmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { getSingleOrg } from "../../../util.js";

// bouncei code
import BackDrop from "../../BackDrop";

//THERE'S A PROBLEM HERE, IT KEEPS ON RERENDERING
const EditOrgModal = (props) => {
  const { auth, currentOrganization } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [open, setOpen] = useState(false);
  const [single, setSingle] = useState();

  const [displayImg, setDisplayImg] = useState(null);
  const ORGN_URL = `/organizations/${currentOrganization?.id}`;

  useEffect(() => {
    // GETS ALL ORGANIZATIONS AND SET THE FIRST ORGANIZATION HAS THE CURRENT ONE
    getSingle();

    // GET PROJECTS
  }, []);

  const getSingle = useCallback(async () => {
    try {
      const response = await getSingleOrg(currentOrganization?.id);

      setSingle(response.data);
    } catch (error) {
      // toast.error('organization not selected')
      console.log(error);
    }
  }, []);

  // Handles user credentials inputted in the available fields
  const handleChange = (event) => {
    const { value, name } = event.target;
    // setFormData(formData.countryCode === ctryCode)
    if (name === "email") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFile = (event) => {
    setFormData({ ...formData, ["logo"]: event.target.files[0] });

    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);

        setDisplayImg(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  // Handles submitting of the form
  const AuthStr = "Bearer ".concat(auth?.authToken);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const token = await loginUser(formData);

    setOpen(true);

    try {
      const response = await axios.patch(
        ORGN_URL,
        JSON.stringify({
          name: formData["name"],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: AuthStr,
          },
          withCredentials: true,
        }
      );

      setTimeout(() => {
        setOpen(false);
      }, response);
      setOpen(false);
      if (props.rr) {
        props.rr();
      }
      props.onClose();
      toast.success("Updated organization");
    } catch (error) {
      setOpen(false);
      if (!error?.response) {
        toast.error("Check your internet connection!");
      } else if (error.response?.status === 401) {
        toast.error("Unauthorized!");
      } else if (error.response?.status === 400) {
        toast.error("Missing Value");
        console.log(error.response);
      } else {
        toast.error("Failed!");
        console.log(error);
      }
    }
  };
  // console.log('$$',currentOrganization)
  return (
    <div
      className={`organization__modal ${props.show ? "show" : ""}`}
      id="login=div"
      onClick={props.onClose}
    >
      <form
        className="organization__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="relative w-[459px] py-4 flex items-center  justify-between">
          <p className=" text-white text-[28px] font-semibold">
            Update Organization
          </p>
          <CloseIcon className="cursor-pointer" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <div className="add_file_container mb-3">
          <label htmlFor="add_file" className=" cursor-pointer pb-4">
            <img
              src={single?.logo}
              alt="Org image"
              className=" object-cover h-44 w-44 rounded-md"
            />
          </label>
        </div>

        <div className="organization__inputs">
          <input
            type="text"
            name="name"
            placeholder={single?.name}
            autoCorrect="off"
            onChange={handleChange}
          />
          <input
            type="text"
            name="address"
            placeholder={single?.owner}
            autoCorrect="off"
            readOnly
          />

          <input
            type="text"
            placeholder={single?.country}
            name="city"
            readOnly
          />

          <input
            type="text"
            name="mobile"
            placeholder={single?.phoneNumber}
            autoCorrect="off"
            readOnly
          />
        </div>

        <button type="submit" href="#" className="sign-in-tag">
          Submit
        </button>
      </form>
    </div>
  );
};

export default EditOrgModal;
