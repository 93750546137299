import React, { useState } from "react";
import "./ProjectCard.css";
import { BsPlusSquare } from "react-icons/bs";

import { AddProjectModal } from "../../Modals";

const ProjectCard = (props) => {
  // const { data } = props;
  const [showaddModal, setAddModal] = useState(false);

  return (
    <div>
      <div className="add_project_card">
        <div className="add_project">
          <div className="plus">
            <div className="icon" onClick={() => setAddModal(true)}>
              <BsPlusSquare />
            </div>

            <p>Create New Project</p>
          </div>
        </div>
      </div>
      <AddProjectModal
        rr={props.rr}
        onClose={() => {
          setAddModal(false);
        }}
        show={showaddModal}
        getProjects={props.getProjects}
      />
    </div>
  );
};

export default ProjectCard;
