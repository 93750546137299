import { useState, useEffect } from "react";
import "./inviteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { firstVersion, axios } from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { getAllMembers } from "../../../util.js";

// bouncei code
import BackDrop from "../../BackDrop";

const INVITE_URL = "/invites";

const InviteModal = (props) => {
  const { auth, currentOrganization, members } = useAuth();
  const [formData, setFormData] = useState({
    senderName: "",
    senderMsg: "",
    email: "",
    role: "",
  });
  const [orgMembers, setOrgMembers] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await getAllMembers(currentOrganization?.id);
      setOrgMembers(data);
    })();
  }, [setOrgMembers, getAllMembers]);
  // Handles user credentials inputted in the available fields
  const handleChange = (event) => {
    const { value, name } = event.target;
    // setFormData(formData.countryCode === ctryCode)
    if (name === "email") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpen(true);

    if (formData["role"] === "") {
      toast.error("Please select a role");
      return;
    }

    try {
      const response = await firstVersion.post(
        "/users/",
        JSON.stringify({
          // senderName: auth.firstName,
          // senderMsg: formData["senderMsg"],
          email: formData["email"],
          role: formData["role"],
          organizationId: currentOrganization?.id,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer ".concat(auth?.authToken),
          },
          withCredentials: true,
        }
      );

      if (response) {
        if (props.rr) {
          props.rr();
        }

        toast.success("Invite sent.");
        setFormData({
          senderName: "",
          senderMsg: "",
          email: "",
          role: "",
        });
        setOpen(false);
        props.onClose();
      }
    } catch (error) {
      setOpen(false);
      if (!error?.response) {
        toast.error("Check your internet connection!");
      }
      toast.error(error.response.data.message);
      console.log(error);
    }
  };
  return (
    <div
      className={`invite__modal ${props.show ? "show" : ""}`}
      id="login=div"
      onClick={props.onClose}
    >
      <form
        className="invite__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="invite__modal--content-header">
          <h4>Invite Member</h4>
          <CloseIcon className="invite__close--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}
        <div className="invite__inputs">
          {/* <input
            type="hidden"
            name="senderName"
            placeholder="Name"
            autoCorrect="off"
            
            value="paul"
          /> */}

          <input
            type="email"
            name="email"
            placeholder="Email"
            autoCorrect="off"
            onChange={handleChange}
            required
          />
          <label style={{ color: "gray" }} htmlFor="role">
            Select Role
          </label>
          <select onChange={handleChange} name="role" id="role" required>
            <option value="" className="hidden">
              Choose a role
            </option>

            <option value="Engineer">Engineer</option>
            <option value="Songwriter">Songwriter</option>
            <option value="Manager">Manager</option>
            <option value="A&R">A&R</option>
            <option value="Playlist Curator">Playlist Curator</option>
            <option value="Label Member">Label Member</option>
          </select>

          {/* <textarea
            name="senderMsg"
            id=""
            cols="30"
            rows="5"
            placeholder="Your Message"
            onChange={handleChange}
          ></textarea> */}

          {/* <input
            type="text"
            placeholder="Your Message"
            name="senderMsg"
            onChange={handleChange}
            required
          /> */}
        </div>

        <button type="submit" href="#" className="sign-in-tag">
          Send
        </button>
      </form>
    </div>
  );
};

export default InviteModal;
