import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./tableReport.css";
import useAuth from "../../hooks/useAuth";
import axios from "../../api/axios";
import { convertDate, timeLeft } from "../../util.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const TableCard = ({ taskId, index }) => {
  const { auth, config } = useAuth();
  const [taskDetails, setTaskDetails] = useState({});

  const getTask = useCallback(async () => {
    try {
      const resp = await axios.get(`/task/${taskId}`, config);
      setTaskDetails(resp.data);
    } catch (error) {
      console.log(error);
    }
  }, [taskId, config]);

  useEffect(() => {
    getTask();
  }, [getTask]);

  const efficiency = useMemo(() => {
    if (Object.keys(taskDetails).length === 0) return 0;
    let projectElapseTime =
      timeLeft(taskDetails?.startDate, new Date()).days * 24;
    let timeAllocated =
      timeLeft(taskDetails?.startDate, taskDetails?.endDate).days * 24;
    let subTasksCompleted = taskDetails?.subTasks?.filter(
      (task) => task.status && task.status.toLowerCase() === "completed"
    );
    let calculateRate =
      subTasksCompleted.length /
      (taskDetails?.subTasks.length === 0 ? 1 : taskDetails?.subTasks.length);
    if (subTasksCompleted.length === 0) {
      return 0;
    } else if (subTasksCompleted.length === taskDetails?.subTasks.length) {
      return 100;
    } else if (calculateRate > 1 && calculateRate < 2) {
      let calVal = calculateRate * 100;
      if (parseInt(calVal) > 99) {
        return 100;
      } else {
        return calVal;
      }
    } else if (Math.round(calculateRate) === 1 && projectElapseTime === 0) {
      return 100;
    } else if (calculateRate < 1) {
      let calVal = calculateRate * 100;
      if (parseInt(calVal) > 99) {
        return 100;
      } else {
        return calVal;
      }
    } else if (calculateRate === 1 && timeAllocated > 0) {
      return 50;
    }
  }, [taskDetails]);

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
      // className={`
      // ${index % 2 === 0 && "bg-[#2b2b2b]"}`}
    >
      <TableCell
        component="th"
        scope="row"
        className={`!flex !space-x-4 !items-center `}
      >
        <div className="w-10 h-10 pt-2.5 pb-[9px] text-purple-400 text-sm  bg-purple-400 bg-opacity-20 rounded-[8px] uppercase justify-center items-center inline-flex">
          {taskDetails.taskMembers && taskDetails.taskMembers.length > 0
            ? taskDetails.taskMembers[0].firstName.charAt(0)
            : auth.firstName.charAt(0)}
        </div>

        {taskDetails.taskMembers && taskDetails.taskMembers.length !== 0 ? (
          <div className="flex items-center space-x-1">
            {taskDetails.taskMembers.map((item, index) => (
              <span key={index} className="capitalize">
                {item.firstName + " " + item.lastName}
                {index + 1 === taskDetails.taskMembers.length ? "" : ","}
              </span>
            ))}
          </div>
        ) : (
          <span className="capitalize">
            {auth.firstName + " " + auth.lastName}
          </span>
        )}
      </TableCell>
      <TableCell align="right">{taskDetails.title}</TableCell>
      <TableCell align="right">{convertDate(taskDetails.startDate)}</TableCell>
      <TableCell align="right">{convertDate(taskDetails.endDate)}</TableCell>

      <TableCell align="right">
        {isNaN(efficiency) ? 0 : Math.round(efficiency)}%
      </TableCell>
    </TableRow>
  );
};

const TableReport = ({ data, report }) => {
  return (
    <div
      className={`table__report ${
        report ? " h-[30vh] overflow-hidden overflow-y-scroll relative" : ""
      }`}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            backgroundColor: "#151517",
            color: "#fff",
          }}
          aria-label="simple table"
        >
          <TableHead className=" sticky">
            <TableRow>
              <TableCell color="white">
                <h3>MEMBERS</h3>
              </TableCell>
              <TableCell align="right">
                <h3>TASK</h3>
              </TableCell>
              <TableCell align="right">
                <h3>START</h3>
              </TableCell>
              <TableCell align="right">
                <h3>FINISH</h3>
              </TableCell>
              <TableCell align="right">
                <h3>EFFICIENCY</h3>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, index) => (
              <TableCard taskId={row.id} key={index} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableReport;
