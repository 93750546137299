import React, { useState } from "react";
import "./BudgetCard.css";
import { FiHeadphones } from "react-icons/fi";
import { SetBudgetModal } from "../../Modals";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../../util.js";

const BudgetCard = ({ data, rr }) => {
  const [showModal, setModal] = useState(false);

  const navigate = useNavigate();

  const details = (id) => {
    navigate(`/frame/budget-details/${id}`);
  };

  return (
    <div className="budget__card">
      <div className="budget__details">
        <div className="budget__card__header">
          <div className="left__side">
            <div className="mi">
              <FiHeadphones color="#1A9CFF" fontSize={30} />
            </div>

            <div
              className="title__and__features"
              onClick={() => details(data.id)}
              style={{ cursor: "pointer" }}
            >
              <div className="title ii">
                <p>{data.name}</p>
              </div>

              <div className="features ii">
                <p>{data.artistName}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="budget__card__body" onClick={() => details(data.id)}>
          <p>{truncate(data.description)}</p>
        </div>
        <div className="budget__card__footer">
          <div
            style={{
              boxShadow: `var(--button-shadow)`,
            }}
            className="px-4 py-2 text-center cursor-pointer  text-white hover:bg-[#8d2a9e] duration-200 ease-in-out bg-[#ba68c8] rounded-md"
            onClick={() => {
              setModal(true);
            }}
          >
            + Set
          </div>
        </div>
      </div>
      <SetBudgetModal
        onClose={() => {
          setModal(false);
        }}
        show={showModal}
        data={data}
        rr={rr}
      />
    </div>
  );
};

export default BudgetCard;
