import React, { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";
import useAuth from "../../../hooks/useAuth";
import { convertDate } from "../../../util.js";
import TableReport from "../../chartAnalysis/tableReport";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const ExportReport = (props) => {
  const { auth, currentOrganization } = useAuth();
  const [open, setOpen] = useState(false);
  const printRef = useRef(null);
  const efficiencyData = {
    // labels: expenses.map((item) => item.name),
    labels: ["Ja", "ss", "sd", "sds", "uihcw", "csdcs"],
    datasets: [
      {
        label: "Team Memeber Efficiency",
        // data: expenses.map((item) => item.spentBudget),
        data: [9, 4, 23, 45, 23, 54],
        backgroundColor: [
          "#C16DEE",
          "#59ACFF",
          "#E8BF3A",
          "orange",
          "green",
          "#51D187",
          "red",
          "brown",
          "#FEB077",
          "#493a52",
          "#ba8f70",
          "#877458",
        ],
        borderColor: [
          "#C16DEE",
          "#59ACFF",
          "#E8BF3A",
          "orange",
          "green",
          "#51D187",
          "red",
          "brown",
          "#FEB077",
          "#493a52",
          "#ba8f70",
          "#877458",
        ],
        borderWidth: 1,
      },
    ],
  };
  console.log("props.dataForTableData", props.dataForTableData);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div
      className={`add__product__modal !bg-black chrome-hide-scrollbar !overflow-y-auto !py-7 !pt-10 hide-scrollbar ${
        props.show ? "show " : ""
      }`}
      onClick={props.onClose}
    >
      <div
        className="add__product__modal--content !top-[50px] !w-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="contact__modal--content-header">
          <h4> Report PDF Preview</h4>
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
          {open && <BackDrop open={open} />}
        </div>

        {/* //TODO: NAIN BODY */}
        <div ref={printRef} id="printContent">
          {/* HEADER SECTION */}
          <div className="space-y-2 flex flex-col items-center text-center">
            <span className=" font-semibold text-base md:text-lg">
              {currentOrganization.name}
            </span>
            <div className="flex items-center space-x-1">
              <span>Admin:</span>
              <span>{auth.firstName + " " + auth.lastName}</span>
            </div>

            <div className="flex items-center space-x-1">
              <span>Date Created:</span>
              <span>{convertDate(currentOrganization.createdAt)}</span>
            </div>
          </div>

          <br />
          <div
            style={{
              border: "1px solid #242424",
              padding: "1rem",
              borderRadius: "1rem",
            }}
            className="flex items-center justify-around"
          >
            {/* TEAM EFFICIRNCY PIE CHART  */}
            <div className="w-[20vw]">
              <h3 className="text-xl text-center">Team members efficiency</h3>
              <div className="table__report">
                <Pie data={efficiencyData} />
              </div>
            </div>

            {/* TASKS EFFICIRNCY PIE CHART  */}
            <div className="w-[20vw]">
              <h3 className="text-xl text-center">Task efficiency</h3>
              <div className="table__report">
                <Pie data={efficiencyData} />
              </div>
            </div>
          </div>

          <br />

          <div
            style={{
              border: "1px solid #242424",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            <h3 className="text-xl">
              Team members summary based on{" "}
              <span
                className="text-[#ba68c8] cursor-pointer"
                // onClick={() => navigate("/frame/tasks")}
              >
                tasks
              </span>
            </h3>

            {props.dataForTableData.length === 0 ? (
              <p className="text-lg p-5">No tasks on this project</p>
            ) : (
              <TableReport report data={props.dataForTableData} />
            )}
          </div>
        </div>

        <button className="submit-tag" onClick={() => handlePrint()}>
          Export PDF
        </button>
      </div>
    </div>
  );
};

export default ExportReport;
