import { useState, useEffect, useCallback, useMemo } from "react";
import "../TaskPage.css";
import DetailsSection from "./DetailsSection";
import filterIcon from "../../../assets/Filter.png";
import { Link } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import { AddTaskModal, AddProjectModal, AddMember } from "../../Modals";
import { BiCalendarAlt } from "react-icons/bi";
import { RiStackLine, RiUserAddLine } from "react-icons/ri";
import { HiViewGrid } from "react-icons/hi";
import { BsCheck2 } from "react-icons/bs";
import { FilterModal } from "../../Modals";
import Drawer from "@mui/material/Drawer";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import LoadingScreen from "../../preLoader";
import { TaskListCard } from "../../Cards";
import { getOrgProjects, sortResponse } from "../../../util.js/index.js";
import Tooltip from "@mui/material/Tooltip";
import { AiOutlinePlus } from "react-icons/ai";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TaskListView = () => {
  const { auth, currentOrganization, config } = useAuth();
  const [loading, setLoading] = useState(false);
  const [dropProjs, setDropProjs] = useState(false);
  const [addOptions, setAddOptions] = useState(false);
  const [saveP, setSaveP] = useState([]);
  const [projects, setProjects] = useState([]);
  const [curProj, setCurProj] = useState(null);
  const [projectMembers, setProjectMembers] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [todo, setTodo] = useState([]);
  const [review, setReview] = useState([]);
  const [progress, setProgress] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [filter, setFilter] = useState("");
  const [r, setR] = useState(false);
  const [viewFilter, setViewFilter] = useState(null);
  const [resultFilter, setresultFilter] = useState("");
  const [detailsSecttion, setDetailsSection] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [state, setState] = useState({
    task: false,
    board: false,
    project: false,
    invite: false,
  });
  const [addTaksObj, setAddTaskObj] = useState({
    type: null,
  });
  const [stateDraw, setStateDraw] = useState({
    right: false,
  });

  const getProjectMembers = useCallback(
    async (id) => {
      try {
        const response = await axios.get(`/project/${id}`, config);

        setProjectMembers(response.data.projectMembers);
        console.log("All project Members", response.data.projectMembers);
      } catch (error) {
        console.log(error);
      }
    },
    [curProj]
  );

  useEffect(() => {
    getProjectMembers(curProj?.id);
  }, [curProj, getProjectMembers]);

  const handleClickAway = () => {
    setAddOptions(false);
  };
  const resultCheck = (resultFilter) => {
    setresultFilter(resultFilter);
    if (resultFilter !== "") {
      const filteredData = tasks?.filter((item) => {
        return Object.values(item)
          .join("")
          .toLocaleLowerCase()
          .includes(resultFilter.toLowerCase());
      });

      setViewFilter(filteredData);
    } else {
      setViewFilter(tasks);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateDraw({ ...stateDraw, [anchor]: open });
  };

  const toggleItems = (item, open) => (event) => {
    setState({ ...state, [item]: open });
  };

  const searchProj = (event) => {
    const { value } = event.target;

    let compare = projects.filter((item) =>
      item.name.toLowerCase()?.includes(value.toLowerCase())
    );

    if (value === "") {
      compare = [];
      setProjects(saveP);
      return;
    }

    if (compare.length !== 0 && value !== "") {
      setFilter(compare);
      setProjects(compare);
      return;
    } else {
      setFilter("Not Found");
      return;
    }
  };

  const getTasks = useCallback(
    async (id) => {
      try {
        const activeProject = projects.find((item) => item.id === id);
        activeProject ? setCurProj(activeProject) : setCurProj(projects[0]);
        const response = await axios.get(`/task/all/${id}`, config);

        setTodo([]);
        setProgress([]);
        setCompleted([]);
        setReview([]);

        setTodo(
          response.data.filter((item) => item.status.toLowerCase() === "todo")
        );
        setProgress(
          response.data.filter(
            (item) => item.status.toLowerCase() === "in progress"
          )
        );
        setCompleted(
          response.data.filter(
            (item) => item.status.toLowerCase() === "completed"
          )
        );

        setReview(
          response.data.filter((item) => item.status.toLowerCase() === "review")
        );

        setTasks(response.data);

        setLoading(true);
      } catch (error) {
        if (!error.response) {
          toast.error("Check your internet connection!");
        }
        console.log(error);
      }
    },
    [tasks, r]
  );

  const selectProject = (item) => {
    setDetailsSection(false);
    setCurProj(item);
    setDropProjs(false);
    getTasks(item.id);
    setLoading(false);
  };

  const selectTask = (item) => {
    setDetailsSection(true);
    setSelectedTask(item);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getOrgProjects(
          currentOrganization?.id,
          auth?.id,
          auth?.role
        );
        setProjects(sortResponse(response.data));

        setLoading(false);

        if (response.data.length > 0) {
          getTasks(sortResponse(response?.data)[0].id);
          setCurProj(response.data[0]);
        }
        tasks && setLoading(true);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [r]);

  const available = useMemo(() => {
    if (tasks?.length === 0) {
      return curProj?.budget;
    }
    const totalAvailable = tasks?.reduce(
      (acc, curr) => acc + parseInt(curr.budget),
      0
    );
    const final = parseInt(curProj?.budget) - parseInt(totalAvailable);
    return final;
  }, [tasks]);

  const handleNewTask = (type) => {
    setState({ ...state, task: true });
    setAddTaskObj({
      type,
      ci: curProj,
    });
  };

  return (
    <div className="flex min-h-screen ">
      {/* TASKS DISPLAYED */}
      <div
        className={`task__page  ${
          detailsSecttion ? "w-[70%] border-r border-r-[#353535]" : "w-full"
        }`}
      >
        <div className="task__header">
          <ClickAwayListener
            onClickAway={() => {
              setDropProjs(false);
            }}
          >
            <div
              className="header__dropdown"
              onClick={() => setDropProjs(!dropProjs)}
            >
              <h2 className="text-lg">
                <span className=" font-semibold">
                  {!curProj ? projects[0]?.name : curProj?.name}{" "}
                </span>
                Project Task
              </h2>

              {projects.length > 0 && <AiOutlineDown fontSize={13} />}
            </div>
          </ClickAwayListener>

          {projects.length !== 0 && (
            <div
              className={`projects__dropdown ${
                projects.length > 3 && " overflow-y-scroll"
              } ${dropProjs && "show"}`}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="top__part">
                <p>Projects</p>

                <input
                  type="text"
                  placeholder="Search Project..."
                  onChange={(e) => searchProj(e)}
                />
              </div>
              <div className="dropdown__list">
                {filter === "Not Found" ? (
                  <p>Not Found</p>
                ) : (
                  projects.map((item, index) => (
                    <div
                      className="dropdown__item"
                      key={index}
                      onClick={() => selectProject(item)}
                    >
                      <div className="icon__name">
                        <RiStackLine />
                        <div>{item.name}</div>
                      </div>
                      {index === 0 && !curProj && filter === "" ? (
                        <BsCheck2
                          color="#51D187"
                          className="check__icon"
                          size={20}
                        />
                      ) : null}
                      {curProj === item ? (
                        <BsCheck2
                          color="#51D187"
                          className="check__icon"
                          size={20}
                        />
                      ) : null}
                    </div>
                  ))
                )}
              </div>
            </div>
          )}

          <div className="task__header__buttons">
            <Tooltip title="Tasks Grid View" placement="top">
              <Link to="/frame/tasks" className="equalizer text-slate-600">
                <HiViewGrid />
              </Link>
            </Tooltip>

            <Tooltip title="Filter" placement="top">
              <div className="equalizer" onClick={toggleDrawer("right", true)}>
                <img src={filterIcon} alt="icon" fontSize={20} />
              </div>
            </Tooltip>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div
                className="add__task__button relative"
                onClick={() => {
                  setAddOptions(!addOptions);
                }}
              >
                <p>Add</p>
                <div className="task__type__dropdown">
                  <AiOutlineDown fontSize={13} />
                </div>
                <div
                  className={`lil__add__dropdown !top-[41px] !right-[0px] ${
                    addOptions ? "show" : "hidden"
                  }`}
                  lil__add__dropdown
                >
                  <div className="dropdown__list">
                    <div
                      className="dropdown__item"
                      onClick={toggleItems("task", true)}
                    >
                      <BiCalendarAlt />
                      <div>Task</div>
                    </div>

                    <div
                      className="dropdown__item"
                      onClick={toggleItems("project", true)}
                    >
                      <RiStackLine />
                      <div>Project</div>
                    </div>

                    <div
                      className="dropdown__item "
                      onClick={toggleItems("invite", true)}
                    >
                      <RiUserAddLine />
                      <div>Invite</div>
                    </div>
                  </div>
                </div>
              </div>
            </ClickAwayListener>

            <Drawer
              anchor="right"
              open={stateDraw["right"]}
              onClose={toggleDrawer("right", false)}
            >
              <FilterModal resultCheck={resultCheck} right="right" />
            </Drawer>
          </div>
          <AddMember
            onClose={toggleItems("invite", false)}
            show={state["invite"]}
          />
          <AddTaskModal
            onClose={toggleItems("task", false)}
            show={state["task"]}
            projects={projects}
            availableBudget={available}
            rr={() => setR(!r)}
            pi={curProj}
            stat={addTaksObj.type}
            getTasks={getTasks}
          />

          <AddProjectModal
            onClose={toggleItems("project", false)}
            show={state["project"]}
            rr={() => setR(!r)}
          />
        </div>

        {loading && curProj ? (
          <div className=" px-[16px] py-[6px] space-y-4 !bg-[#151517] !text-white">
            {/* TODO LIST TASKS */}
            <Accordion className="!bg-[#151517] !text-white">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>TODO [{todo.length}]</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="check__list">
                  <div className="check__items space-y-2">
                    {todo.length !== 0 ? (
                      todo.map((item, index) => (
                        <TaskListCard
                          key={index}
                          ci={curProj?.id}
                          getTasks={getTasks}
                          {...item}
                          selectTask={() => selectTask(item)}
                          rr={() => setR(!r)}
                        />
                      ))
                    ) : (
                      <p>No task on this section</p>
                    )}
                  </div>

                  <div
                    className="add__new__check pt-3 cursor-pointer"
                    onClick={() => handleNewTask("todo")}
                  >
                    <AiOutlinePlus />
                    <p>Add Task</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {/* IN PROGRESS LIST TASKS */}
            <Accordion className="!bg-[#151517] !text-white">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>IN PROGRESS [{progress.length}]</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="check__list">
                  <div className="check__items space-y-2">
                    {projects.length !== 0 ? (
                      progress.map((item, index) => (
                        <TaskListCard
                          key={index}
                          {...item}
                          ci={curProj?.id}
                          getTasks={getTasks}
                          rr={() => setR(!r)}
                          selectTask={() => selectTask(item)}
                        />
                      ))
                    ) : (
                      <p>No task is in progress</p>
                    )}
                  </div>

                  <div
                    className="add__new__check pt-3 cursor-pointer"
                    onClick={() => handleNewTask("in progress")}
                  >
                    <AiOutlinePlus />
                    <p>Add Task</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            {/* IN REVIEW LIST TASKS */}
            {review.length !== 0 && (
              <Accordion className="!bg-[#151517] !text-white">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>IN REVIEW [{review.length}]</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="check__list">
                    <div className="check__items space-y-2">
                      {review.length !== 0 ? (
                        review.map((item, index) => (
                          <TaskListCard
                            ci={curProj?.id}
                            getTasks={getTasks}
                            key={index}
                            {...item}
                            selectTask={() => selectTask(item)}
                            rr={() => setR(!r)}
                          />
                        ))
                      ) : (
                        <p>No task is in review</p>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}

            {/* COMPLETED LIST TASKS */}
            <Accordion className="!bg-[#151517] !text-white">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>COMPLETED [{completed.length}]</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="check__list">
                  <div className="check__items space-y-2">
                    {completed.length !== 0 ? (
                      completed.map((item, index) => (
                        <TaskListCard
                          ci={curProj?.id}
                          getTasks={getTasks}
                          key={index}
                          {...item}
                          selectTask={() => selectTask(item)}
                          rr={() => setR(!r)}
                        />
                      ))
                    ) : (
                      <p>No task completed yet</p>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <LoadingScreen loading />
        )}
      </div>

      {/* DETAILS SECTION */}
      {detailsSecttion && selectedTask && (
        <div className="w-[30%]">
          <DetailsSection
            getTasks={getTasks}
            rr={() => setR(!r)}
            {...selectedTask}
            projectMembers={projectMembers}
            ci={curProj?.id}
          />
        </div>
      )}
    </div>
  );
};

export default TaskListView;
