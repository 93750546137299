import React, { useState, useCallback, useEffect, useMemo } from "react";
import "./admininfo.css";
import StorageData from "./storageChart";
import useAuth from "../../hooks/useAuth";
import { sortResponse, timeLeft } from "../../util.js";
import axios from "../../api/axios";
import { ProjectPerformance, UserEfficiency } from "../charts";
import LoadingEntity from "../LoadingEntity";

const AdminStats = ({ r, rr, allProjects, allMembers }) => {
  const { auth, config } = useAuth();

  const [projectSum, setProjectSum] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [adminUsersTasks, setAdminUsersTasks] = useState([]);
  const [userEfficiency, setUserEfficiency] = useState(0);
  const [j, setJ] = useState(false);
  const [ratings, setRatings] = useState([]);

  // Used for a admin project efficiency
  const getUserProjectsAdmin = useCallback(
    async (projects) => {
      projects.forEach(async (item, index) => {
        const all = await getUserTasksForAdmin(item.id);
        if (index + 1 === projects.length) {
          setJ(true);
        }
      });
    },
    [projectSum]
  );

  // Used for an admin team members efficiency
  const getUserTasksForAdmin = useCallback(
    async (projectId) => {
      let previousLi = userTasks;
      try {
        const resp = await axios.get(`/task/all/${projectId}`, config);
        if (resp && resp.data) {
          resp.data.forEach(function (element, index) {
            element.projectId = projectId;

            previousLi.push(element);
          });

          setAdminUsersTasks(previousLi);
          return previousLi;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [projectSum]
  );

  const getProjects = useCallback(async () => {
    try {
      setProjectSum(sortResponse(allProjects));
      getUserProjectsAdmin(allProjects);
    } catch (error) {
      console.log(error);
    }
  }, [projectSum, r]);

  const getRates = (userProjects, userTasks) => {
    let rate = [];
    userProjects.forEach((item) => {
      // All the tasks for their respective projects

      let allProjectTasks = userTasks.filter(
        (task) => task.projectId === item.id
      );

      // * Get all the subtasks
      let subTasks = []; // Z(2)

      for (let s = 0; s < allProjectTasks.length; s++) {
        const task = allProjectTasks[s];

        if (task.subTasks && task.subTasks.length > 0) {
          task.subTasks.forEach((sub) => {
            subTasks.push(sub);
          });
        }
      }

      // Time elapsed and the Time allocated inxs hours
      let projectElapseTime = timeLeft(item.startDate, new Date()).days * 24; // Y(1)
      let timeAllocated = timeLeft(item.startDate, item.endDate).days * 24; // Y(2)

      let subTasksCompleted = subTasks.filter(
        (task) => task.status && task.status.toLowerCase() === "completed"
      ); // Z(1)

      let finalTime =
        projectElapseTime / (timeAllocated === 0 ? 1 : timeAllocated); // Y
      let finalSubtasks =
        subTasksCompleted.length /
        (subTasks.length === 0 ? 1 : subTasks.length); // Z

      // let calculateRate =
      //   subTasksCompleted.length /
      //   (subTasks.length === 0 ? 1 : subTasks.length);

      let teamMemberEffInDecimal = finalTime + finalSubtasks; // X(1)
      console.log(
        "Team member Eff",
        projectElapseTime,
        timeAllocated,
        subTasksCompleted.length,
        subTasks.length,
        finalSubtasks,
        teamMemberEffInDecimal
      );
      if (subTasksCompleted.length === 0 || subTasks.length === 0) {
        rate.push(1);
      } else if (teamMemberEffInDecimal > 2) {
        rate.push(1);
      } else if (subTasksCompleted.length === subTasks.length) {
        rate.push(99);
      } else if (teamMemberEffInDecimal === 1 && projectElapseTime > 0) {
        rate.push(50);
      } else if (teamMemberEffInDecimal > 1 && teamMemberEffInDecimal < 2) {
        if (finalTime > finalSubtasks) {
          let calVal = (teamMemberEffInDecimal - 1) * 100;

          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalSubtasks > finalTime) {
          let calVal = (2 - teamMemberEffInDecimal) * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalSubtasks > 0.5 && finalTime > 0.5) {
          let calVal = (teamMemberEffInDecimal / 2) * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        }
      } else if (teamMemberEffInDecimal === 1 && projectElapseTime === 0) {
        console.log("Here", item.name);

        rate.push(99);
      } else if (teamMemberEffInDecimal < 1) {
        console.log("Here", item.name);

        if (finalTime < 0.5 && finalSubtasks < 0.5) {
          let calVal = teamMemberEffInDecimal * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalTime > 0.5 && finalSubtasks < 0.5) {
          let calVal = (1 - teamMemberEffInDecimal) * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else if (finalTime < 0.5 && finalSubtasks > 0.5) {
          let calVal = teamMemberEffInDecimal * 100;
          if (parseInt(calVal) > 99) {
            rate.push(99);
          } else {
            rate.push(calVal);
          }
        } else {
          rate.push(20);
        }
      } else {
        rate.push(1);
      }
    });
    //  return array

    // console.log("Various rating", rate);
    return rate;
  };
  // Calculates user efficiency
  const getUserEff = useCallback(() => {
    if (!j) return;

    const ratingArray = getRates(projectSum, adminUsersTasks);
    setRatings(ratingArray);

    const sum = ratingArray.reduce((acc, cur) => acc + cur);
    const average = Math.round(sum / ratingArray.length);

    setUserEfficiency(average);
  }, [j]);

  useEffect(() => {
    // GETS ALL ORGANIZATIONS AND SET THE FIRST ORGANIZATION HAS THE CURRENT ONE
    getProjects();
  }, [r]);

  useEffect(() => {
    getUserEff();
  }, [adminUsersTasks, projectSum, j]);

  let pushArr = [];
  projectSum.map((item, i) => {
    if (item.status === "COMPLETED") {
      pushArr.push(item);
    }
  });

  return (
    <div>
      <div
        className={`admin__profile--info-section ${
          auth?.role === "SUPER_ADMIN" && "space-y-5"
        }`}
      >
        <div className="admin__infos">
          <div className="w-1/2 h-full">
            {auth?.role === "SUPER_ADMIN" ? (
              projectSum.length === 0 ? (
                <p className="text-center">No Projects available</p>
              ) : j ? (
                <div className="flex flex-col justify-center space-y-2 items-center">
                  <ProjectPerformance
                    userProjects={projectSum}
                    userTasks={adminUsersTasks}
                    ratings={ratings}
                  />
                  <p className="font-semibold text-lg leading-relaxed tracking-wider">
                    Project Performance
                  </p>
                </div>
              ) : (
                <LoadingEntity />
              )
            ) : (
              <div
                style={{
                  width: "100%",
                  maxHeight: "230px",
                  position: "relative",
                }}
              >
                <UserEfficiency efficiency={userEfficiency} />

                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    width: "100%",
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                >
                  <p> {userEfficiency}%</p>
                </div>
                <p className="font-semibold text-lg text-center leading-relaxed tracking-wider">
                  My Efficiency
                </p>
              </div>
            )}
          </div>
          <div style={{ display: "flex", width: "50%" }}>
            <div style={{ width: "100%", maxHeight: "230px" }}>
              <StorageData teamMembers={projectSum} />
              <div
                style={{
                  textAlign: "center",
                  bottom: "65%",
                  position: "relative",
                  fontSize: "2rem",
                  fontWeight: "600",
                }}
              >
                <p>{projectSum.length}</p>
              </div>
            </div>
          </div>
        </div>
        {auth.role !== "SUPER_ADMIN" && (
          <>
            {projectSum.length === 0 ? (
              <p className="text-center">No Projects available</p>
            ) : j ? (
              <div className="flex flex-col  justify-center mt-7 space-y-2 items-center">
                <ProjectPerformance
                  userProjects={projectSum}
                  userTasks={adminUsersTasks}
                  ratings={ratings}
                />
                <p className="font-semibold text-lg leading-relaxed tracking-wider">
                  Project Performance
                </p>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminStats;
