import { useCallback, useState } from "react";
import "./ProjectCard.css";
import { FiHeadphones } from "react-icons/fi";
import { BsThreeDots, BsFolder2Open } from "react-icons/bs";
import { RiDeleteBinLine, RiUserAddLine } from "react-icons/ri";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiPencil, BiTime, BiPauseCircle, BiCheckCircle } from "react-icons/bi";
import Box from "@mui/material/Box";
import { shuffle } from "lodash";
import { EditProject, AddMember, SetDeadline } from "../../Modals";
import axios from "../../../api/axios";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import { ClickAwayListener, Tooltip } from "@mui/material";
import {
  truncate,
  BorderLinearProgress,
  LessLinearProgress,
} from "../../../util.js";
import { ProjectDetails } from "../../Modals";
import Moment from "moment";
import BackDrop from "../../BackDrop";
import { useEffect } from "react";
import { useMemo } from "react";

const DataProjectCard = ({ data, r, rr, getProjects }) => {
  const { auth } = useAuth();
  const [showoptions, setOptions] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [singleP, setSingleP] = useState();
  const [details, setDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [invite, setInvite] = useState(false);
  const [showDlModal, setDlModal] = useState(false);
  const [allTasks, setAllTasks] = useState([]);

  const config = {
    headers: {
      Authorization: `Bearer ${auth?.authToken}`,
      "Content-Type": "application/json",
    },
  };

  // Getting project details using the project ID
  const getProject = useCallback(
    async (id) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth?.authToken}`,
          },
        };
        const response = await axios.get(`/project/${id}`, config);
        setSingleP(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    [r]
  );

  const getAllTasks = useCallback(async () => {
    try {
      const response = await axios.get(`/task/all/${data.id}`, config);
      setAllTasks(response.data);

      const completedTasks = response.data.filter(
        (item) => item.status.toLowerCase() === "completed"
      );

      // setProgress((completedTasks.length / response.data.length) * 100);
    } catch (error) {
      console.log(error);
    }
  }, [r]);

  useEffect(() => {
    getProject(data.id);

    // Gets all tasks of a project
    getAllTasks();
  }, [r]);

  const progress = useMemo(() => {
    // * Get all the subtasks
    let subTasks = [];
    for (let s = 0; s < allTasks.length; s++) {
      const task = allTasks[s];

      if (task.subTasks && task.subTasks.length > 0) {
        task.subTasks.forEach((sub) => {
          subTasks.push(sub);
        });
      }
    }
    const completedSubtasks = subTasks.filter(
      (item) => item.status && item.status.toLowerCase() === "completed"
    );
    return (completedSubtasks.length / subTasks.length) * 100;
  }, [allTasks]);

  const delItem = async (item) => {
    setOpen(true);

    try {
      const response = await axios.delete(`/project/${item?.id}`, config);

      setTimeout(() => {
        setOpen(false);
      }, response);
      if (getProjects) {
        getProjects();
      }

      toast.success(`Project Deleted!`);
    } catch (error) {
      setOpen(false);
      toast.error("Failed!");
      console.log(error);
    }
  };

  // const delProject = (item) => {
  //   toast(
  //     (t) => (
  //       <span className="flex items-center space-y-2 justify-center flex-col">
  //         <div className="text-lg md:text-xl text-center">
  //           Are you sure you want to delete this project?
  //         </div>

  //         <div className="delete__activity">
  //           <div className="cancel" onClick={() => toast.dismiss(t.id)}>
  //             Cancel
  //           </div>

  //           {/*Write the delete functionality for a user post */}
  //           <div
  //             className="delete"
  //             onClick={() => {
  //               setOpen(true);

  //               delItem(`project`, item.id);

  //               rr();

  //               toast.dismiss(t.id);
  //               setOpen(false);
  //             }}
  //           >
  //             Delete
  //           </div>
  //         </div>
  //       </span>
  //     ),
  //     {
  //       position: "top-center",
  //       duration: 6000,
  //     }
  //   );
  // };

  const time = Moment(data.endDate).format("MMM Do");

  const holdProject = async (project) => {
    setOpen(true);

    let status;

    if (
      project.status.toLowerCase() === "on_hold" ||
      project.status.toLowerCase() === "on hold"
    ) {
      status = "IN_PROGRESS";
    } else {
      status = "ON_HOLD";
    }

    try {
      const response = await axios.patch(
        `project/${project.id}`,
        JSON.stringify({
          status,
        }),
        config
      );
      setTimeout(() => {
        setOpen(false);
      }, response);
      if (getProjects) {
        getProjects();
      }

      if (
        project.status.toLowerCase() === "on_hold" ||
        project.status.toLowerCase() === "on hold"
      ) {
        toast.success(`${project.name} Removed On Hold!`);
      } else {
        toast.success(`${project.name} Placed On Hold!`);
      }
      setOptions(false);

      // setProjects(response.data);
      // props.onClose();
    } catch (error) {
      setOpen(false);

      toast.error("Failed!");
      console.log(error);
    }

    return;
  };

  const openProject = async (project) => {
    setOpen(true);

    let status;

    status = "IN_PROGRESS";

    try {
      const response = await axios.patch(
        `project/${project.id}`,
        JSON.stringify({
          status,
        }),
        config
      );
      setTimeout(() => {
        setOpen(false);
      }, response);
      if (getProjects) {
        getProjects();
      }

      toast.success(`${project.name} Has Been Opened!`);

      setOptions(false);

      // setProjects(response.data);
      // props.onClose();
    } catch (error) {
      setOpen(false);

      toast.error("Failed!");
      console.log(error);
    }

    return;
  };

  const handleDl = () => {
    setDlModal(!showDlModal);
    return;
  };

  const timeLeft = (start, end) => {
    const startTimestamp = new Date(start).getTime();
    const endTimestamp = new Date(end).getTime();

    const secondsLeft = (endTimestamp - startTimestamp) / 1000;

    const minutesLeft = Math.floor(secondsLeft / 60);
    const hoursLeft = Math.floor(minutesLeft / 60);
    const daysLeft = Math.floor(hoursLeft / 24);

    const timeLeft = {
      days: daysLeft + " " + "days left",
      hours: (hoursLeft % 24) + " " + "hours left",
      minutes: (minutesLeft % 60) + " " + "minutes left",
      seconds: Math.floor(secondsLeft % 60) + " " + "seconds left",
    };

    return timeLeft;
  };

  const colors = ["#FEB077", "#C16DEE", "#39D1E5"];

  return (
    <div className="budget__card">
      {open && <BackDrop open={open} />}

      <div className="budget__details">
        <div className="budget__card__header">
          <div className="left__side">
            <div className="mi">
              <FiHeadphones color="#1A9CFF" fontSize={30} />
            </div>

            <div
              className="title__and__features"
              onClick={() => setDetails(true)}
            >
              <div className="title ii">
                {data.name.replace(/\s+/g, " ").length > 36
                  ? data.name.substring(0, 36) + "..."
                  : data.name}
              </div>
              <div className="features ii">
                <p>{data.artistName}</p>
              </div>
            </div>
          </div>

          <div className="edge__buttons">
            {data.status.toLowerCase() === "on_hold" ||
            data.status.toLowerCase() === "on hold" ? (
              <BiPauseCircle color="#ED6F69" size={25} />
            ) : null}
            {data.status.toLowerCase() === "completed" && (
              <BiCheckCircle color="green" size={25} />
            )}

            <ClickAwayListener
              onClickAway={() => {
                setOptions(false);
              }}
            >
              <Tooltip title="Options" placement="top">
                <div
                  className={`right icon `}
                  onClick={() => {
                    setOptions(!showoptions);
                  }}
                >
                  <BsThreeDots fontSize={20} />
                </div>
              </Tooltip>
            </ClickAwayListener>
          </div>
        </div>

        {/* Dropdown menu */}
        <div
          className={`lil__dropdown__right ${
            showoptions ? "show__dropdown" : ""
          }`}
        >
          <div className="dropdown__list">
            <div
              className="dropdown__item"
              onClick={() => {
                getProject(data.id);
                setEditModal(true);
              }}
            >
              <BiPencil />
              <div>Edit</div>
            </div>

            {data.status.toLowerCase() === "completed" ? (
              <div className="dropdown__item" onClick={() => openProject(data)}>
                <BsFolder2Open />
                <div>Open Project</div>
              </div>
            ) : (
              <div
                className="dropdown__item del"
                onClick={() => {
                  // rr();
                  holdProject(data);
                }}
              >
                <BiPauseCircle />
                <div>
                  {data.status.toLowerCase() === "on_hold" ||
                  data.status.toLowerCase() === "on hold"
                    ? "Remove "
                    : "Put "}
                  On Hold
                </div>
              </div>
            )}

            <div
              className="dropdown__item"
              onClick={() => {
                // localStorage.setItem("project", JSON.stringify(singleP));
                // getProject(data.id);
                setInvite(true);
              }}
            >
              <RiUserAddLine />
              <div>Add Member</div>
            </div>
            <div
              className="dropdown__item"
              onClick={() => {
                handleDl();
                setOptions(false);
              }}
            >
              <AiOutlineClockCircle />
              <div>Add Due Date</div>
            </div>

            <hr width="auto" color="#242424" size="1" />
            <div
              className="dropdown__item delete"
              onClick={() => {
                delItem(data);
                setOptions(false);
              }}
            >
              <RiDeleteBinLine />
              <div>Delete Project</div>
            </div>
          </div>
        </div>

        <EditProject
          onClose={() => setEditModal(false)}
          show={editModal}
          data={singleP}
          rr={rr}
          getProjects={getProjects}
        />
        <SetDeadline
          onClose={() => {
            setDlModal(false);
          }}
          show={showDlModal}
          project={data}
          rr={rr}
          getProjects={getProjects}
        />

        <AddMember
          onClose={() => setInvite(false)}
          data={singleP}
          // id={data.id}
          // id={singleP.id}
          projectMembers={singleP?.projectMembers}
          show={invite}
          rr={rr}
        />

        <ProjectDetails
          singleP={singleP}
          time={time}
          show={details}
          {...data}
          onClose={() => setDetails(!details)}
          rr={rr}
          getProjects={getProjects}
        />
        <div className="pcard__body ii" onClick={() => setDetails(true)}>
          <p>{truncate(data.description)}</p>
        </div>

        <div className="progress">
          <div className="progress__tag py-2">
            <p>Progress</p>
            <p>{progress ? Math.round(progress) : 0}%</p>
          </div>

          <Box sx={{ width: "100%" }}>
            {data.status === "ON_HOLD" || data.status === "on_hold" ? (
              <LessLinearProgress
                variant="determinate"
                value={progress ? Math.round(progress) : 0}
              />
            ) : (
              <BorderLinearProgress
                variant="determinate"
                value={progress ? Math.round(progress) : 0}
              />
            )}
          </Box>
        </div>
        <div className="pcard__footer">
          <div className="duration">
            <BiTime />
            <p>{timeLeft(data.startDate, data.endDate).days}</p>
          </div>

          <div className="members">
            {data?.projectMembers
              ? data.projectMembers.map((item, index) => (
                  <p
                    key={index}
                    style={{ backgroundColor: shuffle(colors)[0] }}
                  >
                    {item.charAt(0) === " " ? item.charAt(1) : item.charAt(0)}
                  </p>
                ))
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataProjectCard;
