import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);


const LineChart =({teamMembers})=> {
  const options = {
    maintainAspectRatio: false,
 responsive: true,
 scales: {
   x: {
     grid: {
       display: false
     }
   },
   y: {
    suggestedMin: 0,
    suggestedMax: 100,
    stepSize:1,
    ticks: {     
      
      beginAtZero: true,

    },
   grid: {
       borderDash: [10, 4],
       color: "#242424"
   }
   }
 },
 plugins: {
   legend: {
       display: false
     },
   title: {
     display: false,
     text: 'Chart.js Line Chart',
   },
 }

  };
  const result=[];
  let pickData =[];
  let colorPicker = [];

  teamMembers.map((item)=>{
    let savesubTask = item?.subTasks
    result.push(item.title);
    const completedsubtasks = savesubTask.filter(
      (item) => item?.status === "COMPLETED"
    );
    let calculatesubtask = completedsubtasks?.length/savesubTask?.length
   
    let checkstatus = item?.status
    //console.log('!',calculatesubtask+'..',item.title,'..',item?.status)
    // return (completed.length / allTasks.length) * 100;
    if(checkstatus === 'COMPLETED'){
      if(isNaN(calculatesubtask)){
        pickData.push(98)
        //todo
        // colorPicker.push('rgb(0, 89, 172)')
        colorPicker.push('rgb(0, 89, 172)')
      }
      else if(calculatesubtask===1){
        pickData.push(98)
        colorPicker.push('rgb(0, 89, 172)')
      }
      else{
        pickData.push(98)
        colorPicker.push('rgb(0, 89, 172)')
      }
      
    }
    else if (checkstatus === "review"){
      if(calculatesubtask===1){
        pickData.push(70)
       
        colorPicker.push(' rgb(255 141 31)')
        // colorPicker.push('rgb(81, 209, 135)')
      }
      else if(isNaN(calculatesubtask)){
        pickData.push(90)
        colorPicker.push(' rgb(255 141 31)')
      }
      else{
        pickData.push(80)
        colorPicker.push(' rgb(255 141 31)')
      }
    }
    else if (checkstatus === "in review"){
      if(isNaN(calculatesubtask)){
        pickData.push(70)
        colorPicker.push(' rgb(255 141 31)')
      }
      else if(calculatesubtask===1){
        pickData.push(80)
        colorPicker.push(' rgb(255 141 31)')
      }
      else{
        pickData.push(60)
        colorPicker.push(' rgb(255 141 31)')
      }

    }
  else if (checkstatus === "todo"){
      if(isNaN(calculatesubtask)){
        pickData.push(30)
        colorPicker.push('rgb(81, 209, 135)')
      }
      else if(calculatesubtask===1){
        pickData.push(40)
        colorPicker.push('rgb(81, 209, 135)')
      }
      else{
        pickData.push(20)
        colorPicker.push('rgb(81, 209, 135)')
      }
    }
    else if (checkstatus === "TODO"){
      if(isNaN(calculatesubtask)){
        pickData.push(30)
        colorPicker.push('rgb(81, 209, 135)')
      }
      else if(calculatesubtask===1){
        pickData.push(40)
        colorPicker.push('rgb(81, 209, 135)')
      }
      else{
        pickData.push(20)
        colorPicker.push('rgb(81, 209, 135)')
      }
    }
    else if (checkstatus === "in progress"){
      if(isNaN(calculatesubtask)){
        pickData.push(50)
        colorPicker.push('#3e444a')
      }
      else if(calculatesubtask===1){
        pickData.push(60)
        colorPicker.push('#3e444a')
      }
      else{
        pickData.push(40)
        colorPicker.push('#3e444a')
      }
    }
    else if (checkstatus === "IN PROGRESS"){
      if(isNaN(calculatesubtask)){
        pickData.push(50)
        colorPicker.push('#3e444a')
      }
      else if(calculatesubtask===1){
        pickData.push(60)
        colorPicker.push('#3e444a')
      }
      else{
        pickData.push(40)
        colorPicker.push('#3e444a')
      }
    }
  
   } 
    )

  const labels = result;
  let valData = pickData;
  // console.log(valData)
  const data = {
    labels,
    datasets: [
      {
        label: '',
        fill: true,
        data: valData,
        borderColor: '#378fe7',
        backgroundColor: '#59ACFF',
        pointBackgroundColor: colorPicker,
      },
    ],
    
   };
     return (<Line options={options} data={data} />);
}
export default LineChart;