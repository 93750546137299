import { useState, useEffect, useMemo } from "react";
import "./AddMember.css";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../../hooks/useAuth";
import axios, { firstVersion } from "../../../api/axios";
import toast from "react-hot-toast";
import BackDrop from "../../BackDrop";
import { getAllMembers } from "../../../util.js";
// import { MultiSelect } from "react-multi-select-component";
import MultiSelect from "multiselect-react-dropdown";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import MultiSelectAdvanced, { MultiSelectAdvancedOption } from 'react-multi-select-advanced'

const AddMember = (props) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { auth, currentOrganization } = useAuth();
  const [open, setOpen] = useState(false);
  const [orgMembers, setOrgMembers] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [msg, setMsg] = useState("");

  function handleSelect(selected) {
    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, selected]);
  }

  function handleRemove(removed) {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((item) => item !== removed)
    );
  }

  useEffect(() => {
    (async () => {
      const resp = await firstVersion.get(
        `/users/all/${currentOrganization?.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.authToken}`,
          },
        }
      );
      setOrgMembers(resp.data);
    })();
  }, [setOrgMembers, getAllMembers]);

  function handleSearch(query) {
    const filteredOptions = orgMembers.filter((option) => {
      Object.values(option).filter((detail) => detail === query);
    });
    setSelectionRange(filteredOptions);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let newLi = [];

    // if (value === "1") {
    // Selected emails

    for (let index = 0; index < selectedItems.length; index++) {
      const element = selectedItems[index];
      const findItem = orgMembers
        .filter((item) => item.firstName)
        .find(
          (item) =>
            item.firstName.toLowerCase() ===
            element[0].split(" ")[0].toLowerCase()
        );

      if (findItem) {
        newLi.push(findItem);
      }
    }

    if (newLi.length === 0) {
      toast.error("Select team members");
      return;
    }

    setOpen(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth?.authToken}`,
          "Content-Type": "application/json",
        },
      };

      for (let index = 0; index < newLi.length; index++) {
        const element = newLi[index];

        if (value === "1") {
          // Assign to member
          await axios.patch(
            `/project/invite_member/${props.data.id}`,
            JSON.stringify({ email: element.email }),

            config
          );
          setSelectedItems([]);
          setMsg("");

          toast.success(`Invite Sent`, {
            duration: 4000,
          });
        } else {
          // Remove Members
          await axios.delete(
            `/project/invite_member/${props.data.id}`,
            JSON.stringify({
              email: element.email,
            }),
            config
          );
          toast.success(`Members Removed`, {
            duration: 4000,
          });
        }
      }
      setSelectedItems([]);
      setMsg("");
      newLi = [];

      props?.rr();
      props.onClose();

      setOpen(false);
    } catch (error) {
      setOpen(false);

      toast.error("Operation failed!");
      console.log(error);
    }
  };

  return (
    <div
      className={`add__product__modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <form
        className="add__product__modal--content"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit}
      >
        <div className="contact__modal--content-header">
          <CloseIcon className="add__product--icon" onClick={props.onClose} />
        </div>

        {open && <BackDrop open={open} />}

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Assign Members" value="1" />
                <Tab label="Remove Member" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="add__product__inputs">
                <MultiSelect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={handleRemove}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  closeIcon="cancel"
                  placeholder="Select team members"
                  closeOnSelect={false}
                  options={orgMembers

                    ?.filter(
                      (item) =>
                        item.firstName !== null &&
                        (props?.projectMembers
                          ? !props?.projectMembers
                              .map((item) => item.email)
                              .includes(item.email)
                          : true)
                    )
                    .map((item) => item.firstName + " " + item.lastName)}
                />
                {/* <label htmlFor="senderMsg">Message</label> */}
                {/* <textarea
                  name="senderMsg"
                  id="senderMsg"
                  cols="30"
                  rows="4"
                  value={msg}
                  placeholder="Write a message here..."
                  onChange={(e) => setMsg(e.target.value)}
                ></textarea> */}
              </div>
            </TabPanel>
            <TabPanel value="2">
              <div className="add__product__inputs">
                <MultiSelect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={handleRemove}
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  closeIcon="cancel"
                  placeholder="Remove selected members"
                  closeOnSelect={false}
                  options={
                    props?.projectMembers
                      ? props?.projectMembers
                          ?.filter((item) => item.firstName !== null)
                          .map((item) => item.firstName + " " + item.lastName)
                      : []
                  }
                />
              </div>
            </TabPanel>
          </TabContext>
        </Box>

        <button type="submit" className="submit-tag">
          {value === "1" ? "Assign Members" : "Remove Members"}
        </button>
      </form>
    </div>
  );
};

export default AddMember;
