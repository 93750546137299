import React, { useEffect, useState } from "react";
import axios from "axios";
import Badge from "@mui/material/Badge";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import { BsCameraVideo, BsPlus, BsThreeDots, BsStopFill } from "react-icons/bs";
import { AiOutlineAudio } from "react-icons/ai";

import useAuth from "../../hooks/useAuth";
import { Box } from "@mui/material";
import { CgAttachment } from "react-icons/cg";
import { BsEmojiSmile } from "react-icons/bs";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { RiSendPlaneFill } from "react-icons/ri";
import Picker from "emoji-picker-react";

// import VoiceModal from "../TheModal/VoiceModal";
// import VideoModal from "../TheModal/VideoModal";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ChatConvo = ({ data }) => {
  const { auth } = useAuth();

  //  console.log(data)
  var addDis;
  var letterDis;
  if (data.senderId !== "") {
    addDis = "chat-item-r";
    letterDis = "letter-item-r";
  } else if (data.receiverId !== "") {
    addDis = "chat-item-s";
    letterDis = "letter-item-s";
  } else {
    addDis = "";
    letterDis = "";
  }
  return (
    <div className={addDis}>
      <div className="chat-item-content">
        <p>{data?.text}</p>
      </div>
      <div className={letterDis}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <div class="big_">{auth?.firstName.charAt(0)}</div>
        </StyledBadge>
      </div>
    </div>
  );
};

const ChatSection = () => {
  const { auth } = useAuth();
  const [getMessage, setGetMessage] = useState([]);
  const [showPicker, setShowPicker] = React.useState(false);
  const [inputStr, setInputStr] = React.useState("");
  const [file, setFile] = useState([]);
  const [flip, setFlip] = useState(false);
  const AuthStr = "Bearer ".concat(auth?.authToken);
  const [getConvo, setConvo] = useState([]);
  // const [receiver, setReceiver] = useState([])

  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  const handleFile = (event) => {
    setFile((prev) => [...prev, URL.createObjectURL(event.target.files[0])]);
  };

  useEffect(() => {
    let cvid = getConvo[0]?._id;
    var config = {
      method: "get",
      url: `https://mybbackendd.herokuapp.com/message/getmsg/${cvid}`,
      headers: {
        Authorization: AuthStr,
      },
    };

    axios(config)
      .then(function (response) {
        setGetMessage(response.data);
      })
      .catch(function (error) {
        toast.error("error fetching message");
        //setErrorData(error.message)
        console.log(error);
      });
  });

  const checkConversation = (e) => {
    // console.log(e)
    var config = {
      method: "get",
      url: `https://mybbackendd.herokuapp.com/conversation/${e}`,
      headers: {
        Authorization: AuthStr,
      },
    };

    axios(config)
      .then(function (response) {
        setConvo(response.data);
      })
      .catch(function (error) {
        toast.error("unknown user found!");
        //setErrorData(error.message)
        console.log(error);
      });
  };

  const postChat = (e) => {
    var demoId = "cc149092-8834-4afd-91ab-e411134e8a49";
    checkConversation(demoId);
    if (getConvo.length >= 1) {
      // console.log(getConvo[0]._id)
      let secret = getConvo[0]._id;
      var data = new FormData();
      data.append("ConversationId", secret);
      data.append("message", inputStr);

      var config = {
        method: "post",
        url: "https://mybbackendd.herokuapp.com/message/addmsg",
        headers: {
          Authorization: AuthStr,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(response);
          toast.success("message sent!");
        })
        .catch(function (error) {
          console.log(error);
          toast.error("Something was wrong!");
        });
    } else {
      //  var data = JSON.stringify({"receiverId":demoId});
      //   var config = {
      //     method: 'post',
      //     url: 'https://mybbackendd.herokuapp.com/conversation',
      //     headers: {
      //       'Authorization': AuthStr,
      //     },
      //     data : data
      //   };
      //   axios(config)
      //   .then(function (response) {
      //     console.log(response.data[0]._id);
      //     toast.success('Chatting with user!')
      //     let secret =response.data[0]._id
      //     var data = new FormData();
      //     data.append('ConversationId', secret);
      //     data.append('message', inputStr);
      //     var config = {
      //       method: 'post',
      //       url: 'https://mybbackendd.herokuapp.com/message/addmsg',
      //       headers: {
      //         'Authorization': AuthStr,
      //       },
      //       data : data
      //     };
      //     axios(config)
      //     .then(function (response) {
      //       console.log(response.data);
      //       toast.success('Wait for reply!')
      //     })
      //     .catch(function (error) {
      //       console.log(error);
      //       toast.error('Something was wrong!')
      //     });
      //   })
      //   .catch(function (error) {
      //     toast.error('error enabling chat!')
      //   });
    }
  };

  const handleAudio = (req) => {
    if (req === "start") {
      setFlip(!flip);
      // startRecord(blocked, setRecording);
    } else if (req === "stop") {
      setFlip(!flip);
    }
  };

  // const startVoice = ()=>{
  //   alert('voice')
  // }
  return (
    <div className="contents">
      <div className="header">
        <div className="left__side">
          <div className="avatar">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <div class="big_">{auth.firstName.charAt(0)}</div>
            </StyledBadge>
          </div>
          <div className="uname">{auth.firstName + " " + auth.lastName}</div>
        </div>
        <div className="right__side">
          {/* <BsCameraVideo onClick={()=>setShowVideo(true)}   />
          <AiOutlineAudio onClick={()=>setShow(true)} /> */}

          <BsCameraVideo />
          <AiOutlineAudio />
          <BsPlus />
          {/* <BsThreeDots  /> */}
          <Link to="/frame/chat_page" style={{ color: "white" }}>
            <BsThreeDots />
          </Link>

          {/* <VoiceModal footText="Start"  title="Create Room" onClose={()=>setShow(false)} show={show} />
          <VideoModal footText="Start"  title="Video Room" onClose={()=>setShowVideo(false)} show={showVideo} /> */}
        </div>
      </div>
      <hr size="1" width="auto" color="#353535" />
      {getMessage.length > 0 && (
        <div>
          {getMessage.map((msg, i) => {
            let displayDate;
            let dateObj = new Date(msg.createdAt);
            let defaultDate = new Date();
            let month = dateObj.getUTCMonth() + 1;
            let day = dateObj.getUTCDate();
            let year = dateObj.getUTCFullYear();
            let defaultmonth = defaultDate.getUTCMonth() + 1;
            let defaultday = defaultDate.getUTCDate();
            let defaultyear = defaultDate.getUTCFullYear();
            if (
              month === defaultmonth &&
              day === defaultday &&
              year === defaultyear
            ) {
              displayDate = "Today";
            } else {
              if (month === 9) {
                displayDate = day + " Sep, " + year;
              } else if (month === 1) {
                displayDate = day + " Jan, " + year;
              } else if (month === 2) {
                displayDate = day + " Feb, " + year;
              } else if (month === 3) {
                displayDate = day + " Mar, " + year;
              } else if (month === 4) {
                displayDate = day + " Apr, " + year;
              } else if (month === 5) {
                displayDate = day + " May, " + year;
              } else if (month === 6) {
                displayDate = day + " Jun, " + year;
              } else if (month === 7) {
                displayDate = day + " Jul, " + year;
              } else if (month === 8) {
                displayDate = day + " Aug, " + year;
              } else if (month === 10) {
                displayDate = day + " Oct, " + year;
              } else if (month === 11) {
                displayDate = day + " Nov, " + year;
              } else if (month === 12) {
                displayDate = day + " Dec, " + year;
              }
            }

            return (
              <div>
                <div className="chat-container">
                  <p className="hr__line">{displayDate}</p>

                  <ChatConvo data={msg} key={i} />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div style={{ position: "fixed", bottom: "0" }}>
        {showPicker && <Picker onEmojiClick={onEmojiClick} />}
        <Box flex={5} className="right__side">
          <div className="make__a__post">
            <div className="chat__message">
              <div className="attach__box">
                <input
                  type="file"
                  name="attach_file"
                  id="attach_file"
                  onChange={handleFile}
                />

                <label htmlFor="attach_file" className="attach_file">
                  <CgAttachment />
                </label>
              </div>

              <div className="emoji__box">
                <BsEmojiSmile onClick={() => setShowPicker((val) => !val)} />
              </div>

              <textarea
                className="darkarea"
                id="chatuser"
                name="chatuser"
                rows="1"
                cols="71"
                placeholder="Type a message here..."
                onChange={(e) => setInputStr(e.target.value)}
                value={inputStr}
              ></textarea>

              <div className="button">
                <div className="voice__box">
                  <div>
                    {!flip ? (
                      <KeyboardVoiceIcon
                        onClick={() => {
                          // askPermission(setBlobUrl);
                          handleAudio("start");
                        }}
                      />
                    ) : (
                      <BsStopFill
                        color="red"
                        size={20}
                        onClick={() => {
                          handleAudio("stop");
                        }}
                      />
                    )}
                  </div>
                </div>
                <div onClick={() => postChat()} className="post-box">
                  <RiSendPlaneFill color="black" />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ChatSection;
