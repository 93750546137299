import React, { useCallback, useEffect, useState } from "react";
import "../ChartAnalysis.css";
import useTable from "../useTable.js";
import TableFooter from "../TableFooter.js";
import { timeFormatOnReportSection, sortResponse } from "../../../util.js";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../api/axios";
import toast from "react-hot-toast";

const SpendSummary = ({ data, rowsPerPage, projId }) => {
  const { config } = useAuth();
  const [expenses, setExpenses] = useState([]);
  // const colorDisplay = [
  //   "orange",
  //   "#C16DEE",
  //   "green",
  //   "#51D187",
  //   "red",
  //   "brown",
  // ];
  const [page, setPage] = useState(1);
  const { slice, range } = useTable(data, page, rowsPerPage);

  const getExpenses = useCallback(
    async (id) => {
      try {
        const response = await axios.get(
          `/budgetexpense/expenses/${id}`,
          config
        );

        setExpenses(sortResponse(response.data));
      } catch (error) {
        toast.error(error.message ? error?.message : "Failed");
        console.log(error);
      }
    },
    [config]
  );

  useEffect(() => {
    if (!projId) return;
    getExpenses(projId);
  }, [projId, getExpenses]);

  return (
    <div
      style={{
        marginLeft: "0.5rem",
        border: "1px solid #242424",
        padding: "1rem",
        paddingBottom: "1.2rem",
        borderRadius: "1rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "80%" }}>
          <h4>Spending Summary</h4>
        </div>
        <div style={{ width: "20%" }}></div>
      </div>
      {slice.map((val, i) => {
        const formatedDates = timeFormatOnReportSection(val);
        const findItem = expenses.find((item) => item.name === val.title);

        return (
          <div key={i} style={{ display: "flex", marginTop: "0.5rem" }}>
            <div className="w-10 h-10 pt-2.5 mr-3 pb-[9px] text-purple-400 text-sm  bg-purple-400 bg-opacity-20 rounded-[8px] uppercase justify-center items-center inline-flex">
              {val.title.charAt(0)}
            </div>

            <div style={{ width: "70%" }}>
              <p style={{ fontSize: "0.8rem" }} className="capitalize">
                {val.title}
              </p>
              <p style={{ fontSize: "0.7rem" }}>
                {formatedDates.displayDate} - {formatedDates.endDate}
              </p>
            </div>
            <div className="flex items-center space-x-6">
              <div style={{ textAlign: "center" }}>
                <p
                  style={{ fontSize: "0.8rem" }}
                  className={
                    parseInt(findItem ? findItem.availableBudget : val.budget) <
                    parseInt(findItem ? findItem.availableBudget : val.budget) /
                      2
                      ? " text-red-600"
                      : "text-[#00FF00]"
                  }
                >
                  {findItem ? findItem.availableBudget : val.budget}
                </p>
                <p style={{ fontSize: "0.7rem" }}>Available</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "0.8rem" }} className={"text-[#00FF00]"}>
                  {findItem ? findItem.spentBudget : 0}
                </p>
                <p style={{ fontSize: "0.7rem" }}>Spent</p>
              </div>
            </div>
          </div>
        );
      })}
      {data.length > 8 && (
        <div style={{ marginTop: "1.5rem" }}>
          <TableFooter
            range={range}
            slice={slice}
            setPage={setPage}
            page={page}
          />
        </div>
      )}
    </div>
  );
};

export default SpendSummary;
